import styled, { css } from 'styled-components';
import {
  Table,
} from 'antd';

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 12px;
`;

export const Label = styled.div`
  color: ${(props) => props.theme.color.gray3};
  width: 160px;
`;

export const Value = styled.div`
  font-weight: 500;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 7px;
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
`;

export const StopsTable = styled(Table)`
  .stops-table {
    &_column {
      font-size: 12px;
      border-bottom-width: 0;
    }
  }
  td.stops-table_column {
    vertical-align: top;
  }
  th.stops-table_column {
    color: ${(props) => props.theme.color.darkgray3};
    font-weight: 400;
  }
`;

export const StopDetailLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StopDetailLabel = styled.div`
  display: inline;
  font-weight: 500;
  ${({ width }) => (width && css`
    min-width: ${width}px
  `)};
`;

export const StopDetailLineMinor = styled(StopDetailLine)`
  color: ${(props) => props.theme.color.gray3};
`;
