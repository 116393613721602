import styled from 'styled-components';

export const ColumnContent = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #333333;
`;

export const AddressContent = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #333333;

  opacity: 0.3;
`;

export const EmptyMonitoringRequests = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  /* identical to box height, or 157% */
  display: flex;
  align-items: center;

  /* Gray 3 */
  color: #828282;
`;

export const DeclineModel = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Gray 1 */
  color: #333333;
`;

export const DeclineModelDesc = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18,75px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Gray 1 */
  color: #333333;
`;

export const DeclineRadio = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray 1 */
  color: #333333;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
`;

export const CardHandler = styled.div`
  margin-bottom: 20px !important;
`;

export const Content = styled.span`
 display:block;
`;

export const ActionContent = styled.span`
 margin: 20px;
 display:block;
`;

export const Bolded = styled.span`
 font-weight: bold;
`;

export const HalfBolded = styled.span`
 font-weight: 500;
`;
