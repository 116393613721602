import { combineReducers } from 'redux';
import {
  CLOSE_GENERATE_REPORT, CLOSE_SHARE_VEHICLES, CREATE_TABLE_CONFIGURATION, EDIT_SHARING, FETCH_SHARES,
  FLEET_RESET_SELECTION_MODE, FLEET_SET_SELECTION_MODE, FLEET_APPEND_SELECTED_ROW,
  FLEET_SELECT_SINGLE_ROW, FLEET_UNSELECT_ROW, FLEET_SELECT_ALL, FLEET_UNSELECT_ALL,
  SUBMIT_GENERATE_REPORT, SUBMIT_SHARE_VEHICLES, UPDATE_TABLE_CONFIGURATION,
} from '../../actions';
import { normalizeSharings } from '../../normalizers';

const selectionModeReducer = (state = null, { type, payload }) => {
  switch (type) {
    case FLEET_SET_SELECTION_MODE:
      return { ...payload.mode };
    case FLEET_RESET_SELECTION_MODE:
    case CLOSE_GENERATE_REPORT:
    case CLOSE_SHARE_VEHICLES:
    case SUBMIT_SHARE_VEHICLES.SUCCESS:
    case EDIT_SHARING.SUCCESS:
    case SUBMIT_GENERATE_REPORT.SUCCESS:
    case UPDATE_TABLE_CONFIGURATION.SUCCESS:
    case CREATE_TABLE_CONFIGURATION.SUCCESS:
      return null;
    default:
      return state;
  }
};

const resolveSharings = (state, sharings) => state.map((row) => {
  if (!row.vehicleId || !sharings) {
    return row;
  }
  const match = sharings.find((sharing) => sharing.vehicleId === row.vehicleId);
  return match || row;
});

const initialState = [];
const selectedRowsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FLEET_SET_SELECTION_MODE:
      if (payload.mode && payload.mode.preselected) {
        return payload.mode.preselected;
      }
      return state;
    case FLEET_APPEND_SELECTED_ROW:
      return [...state, payload.row];
    case FLEET_SELECT_SINGLE_ROW:
      return [payload.row];
    case FLEET_UNSELECT_ROW:
      return state.filter((key) => key.computedId !== payload.row.computedId);
    case FLEET_SELECT_ALL:
      return [...payload.rows];
    case FLEET_UNSELECT_ALL:
    case FLEET_RESET_SELECTION_MODE:
    case CLOSE_GENERATE_REPORT:
    case CLOSE_SHARE_VEHICLES:
    case SUBMIT_SHARE_VEHICLES.SUCCESS:
    case EDIT_SHARING.SUCCESS:
    case SUBMIT_GENERATE_REPORT.SUCCESS:
    case UPDATE_TABLE_CONFIGURATION.SUCCESS:
    case CREATE_TABLE_CONFIGURATION.SUCCESS:
      return initialState;
    case FETCH_SHARES.SUCCESS: {
      const sharings = normalizeSharings(payload.data.sharings);
      return resolveSharings(state, sharings);
    }
    default:
      return state;
  }
};

export default combineReducers({
  selection: selectionModeReducer,
  selectedRows: selectedRowsReducer,
});
