/* eslint-disable no-case-declarations */
import { FETCH_MONITORING_ORDER_SNAPSHOT } from '../../../actions/orders/snapshot.actions';

export default function monitoringOrderSnapshotReducer(state = null, { type, payload }) {
  switch (type) {
    case FETCH_MONITORING_ORDER_SNAPSHOT.SUCCESS:
      return {
        stopSnapshots: payload.data.stopSnapshots,
      };
    case FETCH_MONITORING_ORDER_SNAPSHOT.FAIL:
      return {
        stopSnapshots: [],
      };
    default:
      return state;
  }
}
