import { FETCH_ME } from '../../actions';

const relatedCompaniesInitialState = { loading: null, error: null, data: [] };

export default function relatedCompaniesReducer(state = relatedCompaniesInitialState, action) {
  switch (action.type) {
    case FETCH_ME.SUCCESS:
      return { loading: false, error: null, data: [...action.payload.data.relatedCompanies] };
    case FETCH_ME.START:
      return { ...state, loading: true };
    case FETCH_ME.FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
