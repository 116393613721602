import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InlineFlexMinHeight, UserAvatar } from './SidePanel.styled';

function getUserAbbrev({ firstName, lastName }) {
  const firstLetter = (firstName && firstName.length && firstName[0].toUpperCase()) || '';
  const secondLetter = (lastName && lastName.length && lastName[0].toUpperCase()) || '';
  return `${firstLetter}${secondLetter}`;
}

const UserMenu = ({ user }) => (
  <InlineFlexMinHeight>
    <Tooltip title={`${user.firstName} ${user.lastName}`} placement="right">
      <UserAvatar
        shape="circle"
        size="medium"
      >
        {getUserAbbrev(user)}
      </UserAvatar>
    </Tooltip>
  </InlineFlexMinHeight>
);

UserMenu.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

UserMenu.defaultProps = {
  user: {
    firstName: null,
    lastName: null,
  },
};

export default UserMenu;
