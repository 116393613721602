import axiosActionsFactory from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { UrlService } from '../../../services';

export const DEACTIVATE_ORDER = axiosActionsFactory('DEACTIVATE_ORDER_LOAD');

export const deactivateOrder = (internalId) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);

  return dispatch({
    type: DEACTIVATE_ORDER.START,
    payload: {
      request: {
        url: UrlService.orderDeactivation(internalId),
        method: 'patch',
        params: {
          contextCompanyId,
        },
      },
    },
  });
};
