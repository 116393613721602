export const resizeMap = (ui) => {
  if (ui) {
    ui.getMap().getViewPort().resize();
  }
};

export const zoomInGeoOneStep = async (ui, geo) => {
  if (!ui || !geo) {
    return;
  }
  const currentZoom = ui.getMap().getZoom();
  const screen = ui.getMap().geoToScreen(geo);
  ui.getMap().zoomAt(currentZoom + 1, screen.x, screen.y);
};

export const zoomTo = (ui, location, zoomFactor, animated = true) => new Promise((resolve) => {
  if (!ui || !location) {
    return;
  }
  if (zoomFactor) {
    ui.getMap().setCenter(location);
    ui.getMap().setZoom(zoomFactor);
    resolve();
  } else {
    ui.getMap().setCenter(location, animated);
    setTimeout(resolve, 400);
  }
});

export const getCloserPoint = (a, b, point) => {
  if (!a) {
    return { closer: b, further: a };
  }

  if (!b) {
    return { closer: a, further: b };
  }
  const distanceA = Math.sqrt((a.lat - point.lat) ** 2 + (a.lng - point.lng) ** 2);
  const distanceB = Math.sqrt((b.lat - point.lat) ** 2 + (b.lng - point.lng) ** 2);
  if (distanceA > distanceB) {
    return { closer: b, further: a };
  }

  return { closer: a, further: b };
};
