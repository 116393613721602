import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { info } from '../../services/Logging';
import { getActiveOrdersType, getSelectedCompany } from '../../store/selectors';
import OrdersTable from './Components/OrdersTable';
import openShareComponent from './Components/ShareComponent';
import * as orderActionCreators from '../../store/actions/orders';
import {
  OrderContext,
  ordersResponsePropTypes,
  monitoringOrderRoutePointsResponsePropTypes,
  OrderType,
  paginationPropTypes,
  sortingPropTypes,
} from '../../types';
import { isSuccess } from '../../utils/responseUtils';

class OrderTypeContentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { intervalId: 0, windowHeight: window.innerHeight };
    this.windowResizeListener = (ev) => this.setState({ windowHeight: ev.currentTarget.innerHeight });
    this.bind();
  }

  componentDidMount() {
    this.startFetchingLoop();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intervalId } = this.state;
    const prevCompany = prevProps.selectedCompany;
    const prevRefreshIntervalSeconds = prevProps.refreshIntervalSeconds;

    if (this.shouldRestartFetchingLoop(prevProps)) {
      const auto = this.isAutomatic(prevRefreshIntervalSeconds);
      clearInterval(intervalId);
      if (this.isNotPrevCompany(prevCompany)) {
        this.fetchTableConfiguration()
          .then(() => this.startFetchingLoop(auto));
      } else {
        this.startFetchingLoop(auto);
      }
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    window.removeEventListener('resize', this.windowResizeListener);
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
  }

  setPaginationAndRefresh(pagination) {
    const { setOrdersPagination, orderContext } = this.props;
    setOrdersPagination(pagination, orderContext);
    this.startFetchingLoop();
  }

  setSorting(newSorting) {
    const { setOrdersSorting, orderContext } = this.props;
    Object.assign(newSorting, { field: this.fixSortKey(newSorting.field) });
    setOrdersSorting(newSorting, orderContext);
    this.startFetchingLoop();
  }

  fixSortKey(sortBy) {
    switch (sortBy) {
      case 'firstStop':
        return 'startDate';
      case 'lastStop':
        return 'endDate';
      default:
        return sortBy;
    }
  }

  shouldRestartFetchingLoop(prevProps) {
    const {
      activeType, isActive, sorting,
    } = this.props;
    const prevCompany = prevProps.selectedCompany;
    const prevRefreshIntervalSeconds = prevProps.refreshIntervalSeconds;
    const prevActiveType = prevProps.activeType;
    const prevSorting = prevProps.sorting;
    return ((this.isNotPrevCompany(prevCompany)
      || prevSorting !== sorting
      || (this.isAutomatic(prevRefreshIntervalSeconds))
      || prevActiveType !== activeType) && isActive);
  }

  isNotPrevCompany(prevCompany) {
    const {
      selectedCompany,
    } = this.props;
    return selectedCompany !== prevCompany;
  }

  isAutomatic(prevRefreshIntervalSeconds) {
    const {
      refreshIntervalSeconds,
    } = this.props;
    return refreshIntervalSeconds && !prevRefreshIntervalSeconds;
  }

  bind() {
    this.startFetchingLoop = this.startFetchingLoop.bind(this);
    this.setSorting = this.setSorting.bind(this);
    this.setPaginationAndRefresh = this.setPaginationAndRefresh.bind(this);
    this.openShareModal = this.openShareModal.bind(this);
    this.openConfirmDeactivationModal = this.openConfirmDeactivationModal.bind(this);
  }

  startFetchingLoop(isAutomatic = false) {
    const {
      fetchOrders,
      orderContext,
      orderType,
      refreshIntervalSeconds,
      selectedCompany,
      isActive,
    } = this.props;
    const { intervalId } = this.state;

    if (!isActive || !selectedCompany) {
      clearInterval(intervalId);
      return;
    }

    clearInterval(intervalId);
    if (refreshIntervalSeconds) {
      info(`Scheduling orders refresh in: ${refreshIntervalSeconds * 1000}ms`);
      const newIntervalId = setTimeout(() => {
        this.startFetchingLoop(true);
      }, refreshIntervalSeconds * 1000);
      this.setState({ intervalId: newIntervalId });
    }

    if (!isAutomatic || refreshIntervalSeconds) {
      fetchOrders(isAutomatic, orderContext, orderType);
    }
  }

  openShareModal(order, contextCompanyId, form, dispatch) {
    const { t } = this.props;
    openShareComponent(order, contextCompanyId, t, form, dispatch);
  }

  openConfirmDeactivationModal(internalId) {
    const { t } = this.props;
    Modal.confirm({
      title: t('COMMON.CONFIRM'),
      content: t('ORDER.DEACTIVATION_CONFIRM'),
      onOk: () => this.dispatchDeactivateOrder(internalId),
      okText: t('COMMON.OK'),
      cancelText: t('COMMON.CANCEL'),
    });
  }

  dispatchDeactivateOrder(internalId) {
    const { deactivateOrder, t } = this.props;
    deactivateOrder(internalId).then((response) => {
      if (isSuccess(response)) {
        message.success(t('ORDER.DEACTIVATION_SUCCESSFUL', { internalId }));
        this.startFetchingLoop();
      }
    });
  }

  render() {
    const { windowHeight } = this.state;
    const {
      orders, routes, orderContext, orderType, pagination, sorting,
    } = this.props;

    return (
      <>
        <OrdersTable
          orderContext={orderContext}
          orderType={orderType}
          viewportHeight={windowHeight - 251}
          orders={orders}
          routes={routes}
          pagination={pagination}
          sorting={sorting}
          onSetSorting={this.setSorting}
          onSetPagination={this.setPaginationAndRefresh}
          onShare={this.openShareModal}
          onDeactivate={this.openConfirmDeactivationModal}
        />
      </>
    );
  }
}

OrderTypeContentComponent.propTypes = {
  orderContext: PropTypes.oneOf(Object.values(OrderContext)).isRequired,
  orderType: PropTypes.oneOf(Object.values(OrderType)),
  sorting: sortingPropTypes.isRequired,
  orders: ordersResponsePropTypes.isRequired,
  routes: PropTypes.arrayOf(monitoringOrderRoutePointsResponsePropTypes).isRequired,
  pagination: paginationPropTypes.isRequired,
  refreshIntervalSeconds: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,

  activeType: PropTypes.string.isRequired,
  selectedCompany: PropTypes.string.isRequired,

  setOrdersSorting: PropTypes.func.isRequired,
  setOrdersPagination: PropTypes.func.isRequired,
  deactivateOrder: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
};

OrderTypeContentComponent.defaultProps = {
  orderType: OrderType.TIME,
  refreshIntervalSeconds: 0,
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  activeType: getActiveOrdersType(state),
});

const mapDispatchToProps = {
  deactivateOrder: orderActionCreators.deactivateOrder,
  setOrdersPagination: orderActionCreators.setOrdersPagination,
  setOrdersSorting: orderActionCreators.setOrdersSorting,
  fetchOrders: orderActionCreators.fetchOrders,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderTypeContentComponent));
