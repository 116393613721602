import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { analysedTracePropTypes, sharedVehicle } from '../../../../types';
import { HISTORY_MODE, LAST_LOCATION_MODE } from '../../VehicleDetails.constants';
import { FlexColumn, MapWrapper } from '../../../../components';
import HistoryPlayComponent from '../HistoryPlayComponent/HistoryPlayComponent';
import VehicleDetailsMapManager from './VehicleDetailsMapManager';
import { mergeTrips } from './tripsMerger';
import NOP from '../../../../utils/NOP';

const drawHistoryUntilIndex = async (index, drawer, showProbalyRoute) => {
  if (!index || !drawer) {
    return;
  }
  if (!showProbalyRoute) {
    drawer.showRouteToIndex(index);
  }
};

const VehicleDetailsMapComponent = ({
  showPropableRoute, vehicle, trace, loadingTrace, mapMode, historyRange,
}) => {
  const { t } = useTranslation();
  const [mapManager, setMapManager] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [routePoints, setRoutePoints] = useState(null);
  const [propableRoutePoints, setPropableRoutePoints] = useState(null);
  const [drawingTrace, setDrawingTrace] = useState(false);
  const [events, setEvents] = useState(null);
  const [historyIndex, setHistoryIndex] = useState(0);

  useEffect(() => {
    if (mapManager) {
      mapManager.toggleHistoricRoute(showPropableRoute);
    }
  }, [showPropableRoute, mapManager, historyRange]);

  useEffect(() => {
    function drawMap() {
      const newMapManager = new VehicleDetailsMapManager({ refId: 'map', t });
      newMapManager.addCenterAtButton();
      setTimeout(() => newMapManager.resize(), 1000);
      setMapManager(newMapManager);
    }

    drawMap();
    // purposefully skips t, does not change and is provided at first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleModeSwitch() {
      if (mapMode === LAST_LOCATION_MODE) {
        setRoutePoints(null);
        setEvents(null);
      } else if (mapMode === HISTORY_MODE && mapManager) {
        mapManager.resetInitialCenter();
      }
    }
    handleModeSwitch();
  }, [mapMode, mapManager, setRoutePoints]);

  useEffect(() => {
    function updateLastPosition() {
      const newPosition = vehicle && vehicle.vehicle && vehicle.vehicle.lastPosition;
      if (newPosition && newPosition.length) {
        setLastPosition(newPosition[0]);
      } else {
        setLastPosition(null);
      }
    }

    updateLastPosition();
  }, [setLastPosition, vehicle]);

  useEffect(() => {
    if (!mapManager || mapMode !== LAST_LOCATION_MODE || !vehicle) {
      return NOP;
    }

    function drawLastPositionMarker() {
      mapManager.resize();
      return mapManager.drawLastPositionMarker(lastPosition, vehicle);
    }

    return drawLastPositionMarker();
  }, [lastPosition, t, mapManager, mapMode, vehicle]);

  const choiceAvailableRoute = (prettyRoute, originalRoute) => {
    const isNotAvailable = prettyRoute.length === 0;
    if (isNotAvailable) {
      const refresh = () => {
        window.location.reload(true);
      };

      message.warn(
        <>
          {t('COMMON.WARN_DRAW_ROUTE')}
          <Button size="small" type="link" onClick={() => refresh()}>{t('COMMON.REFRESH_DATA')}</Button>
        </>,
      );
    }
    return isNotAvailable ? originalRoute : prettyRoute;
  };

  useEffect(() => {
    if (mapMode === HISTORY_MODE && trace && vehicle) {
      const points = mergeTrips(trace.trips).map(({
        latitude, longitude, serverTimestamp, positionTimestamp, speed, ignitionState,
      }) => (
        {
          lat: latitude,
          lng: longitude,
          serverTimestamp,
          positionTimestamp,
          speed,
          ignitionState,
          vehicle,
        }
      ));

      const propablePoints = trace.propableRoute.map(({
        coordinateLatitude, coordinateLongitude,
      }) => (
        {
          lat: coordinateLatitude,
          lng: coordinateLongitude,
        }
      ));

      setEvents(trace.events);
      setRoutePoints(points);
      setPropableRoutePoints(choiceAvailableRoute(propablePoints, points));
    }

    // skip mapMode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace, vehicle]);

  useEffect(() => {
    if (mapManager && events && vehicle) {
      const lastTraceRenderer = mapManager.renderTraceAnalysis({ trace, vehicle });
      mapManager.showWholeRoute();
      return () => lastTraceRenderer.dispose();
    }
    return NOP;

    // skips vehicle on purpose, it triggers setting events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, mapManager]);

  useEffect(() => {
    if (!mapManager || !routePoints) {
      setDrawingTrace(false);
      return NOP;
    }

    if (routePoints.length < 1) {
      message.info({ content: t('VEHICLE.NO_HISTORY_ROUTE'), key: 'no-gps', duration: 2 });
      return NOP;
    }

    const routeDisposal = mapManager.drawRouteWithShadow(routePoints, {
      onTap: (index) => setHistoryIndex(index),
    });

    const drawHistoricRoute = mapManager.drawHistoricRoute(propableRoutePoints, { visibility: showPropableRoute });

    const lastPoint = routePoints[routePoints.length - 1];
    const playDotDisposal = mapManager.drawPlayFocusDot(lastPoint);
    mapManager.centerToRouteAndStops();
    mapManager.resize();
    setDrawingTrace(false);
    return () => {
      routeDisposal();
      playDotDisposal();
      drawHistoricRoute();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePoints, mapManager, propableRoutePoints, t]);

  useEffect(() => {
    if (mapManager) {
      mapManager.resetInitialCenter();
    }
  },
  [mapManager, vehicle]);

  const drawTrace = useCallback((index) => drawHistoryUntilIndex(index, mapManager, showPropableRoute),
    [mapManager, showPropableRoute]);

  return (
    <FlexColumn>
      <MapWrapper id="map" />
      {(mapMode === HISTORY_MODE && mapManager) && (
        <HistoryPlayComponent
          history={routePoints}
          loading={loadingTrace}
          onRenderPath={drawTrace}
          drawingTrace={drawingTrace}
          historyIndex={historyIndex}
          setHistoryIndex={setHistoryIndex}
          isEnabledPropableRoute={showPropableRoute}
        />
      )}
    </FlexColumn>
  );
};

VehicleDetailsMapComponent.propTypes = {
  vehicle: sharedVehicle,
  trace: analysedTracePropTypes,
  loadingTrace: PropTypes.bool.isRequired,
  mapMode: PropTypes.oneOf([HISTORY_MODE, LAST_LOCATION_MODE]).isRequired,
  showPropableRoute: PropTypes.bool.isRequired,
  historyRange: PropTypes.arrayOf(MomentPropTypes.momentObj).isRequired,
};

VehicleDetailsMapComponent.defaultProps = {
  vehicle: null,
  trace: null,
};

export default VehicleDetailsMapComponent;
