import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import {
  Button, Col, Switch,
} from 'antd';
import {
  StopOutlined, ShareAltOutlined, FileTextOutlined, EditOutlined, NodeIndexOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FiltersTags, RefreshComponent } from '../../../components/Table';
import { FlexRightSpaced, HeaderButtonsDivider } from '../../../components';
import { DetailsRow } from '../../VehicleDetails/VehicleDetails.styled';
import NOP from '../../../utils/NOP';

const OrdersTableHeader = ({
  size, lastUpdated, ordersLoading, interfaceLoading, onRefresh,
  onUpdateInterval, refreshIntervalSeconds, disableRefreshSelection, editShown,
  onDeactivate, onShare, onEdit, onGenerateReport, filters, updateFilters, clearFilters, showProbalyRoute,
  propableRouteButtonShow,
}) => {
  const { t } = useTranslation();
  const handleClose = (filterName) => {
    updateFilters({ [filterName]: [] });
  };

  return (
    <DetailsRow gutter={[10, 10]}>
      <Col xs={24}>
        <FlexRightSpaced>
          <FiltersTags
            filters={{ ...filters }}
            onRemoveFilter={handleClose}
            onClearFilters={clearFilters}
          />
          {propableRouteButtonShow ? (
            <>
              <div style={{ marginRight: '85px' }}>
                {t('PROPERTIES.SHOW_HIDE_ROUTE')}
                <span> </span>
                <Switch
                  la
                  type="primary"
                  size={size}
                  icon={<NodeIndexOutlined />}
                  onClick={() => showProbalyRoute()}
                />
              </div>
            </>
          ) : (<></>)}
          {onShare && onGenerateReport && (
            <>
              <Button
                type="primary"
                size={size}
                onClick={onShare}
                icon={<ShareAltOutlined />}
              >
                {t('COMMON.SHARE')}
              </Button>
              <Button
                type="primary"
                size={size}
                icon={<FileTextOutlined />}
                onClick={onGenerateReport}
              >
                {t('VEHICLE.GENERATE_REPORT')}
              </Button>
              <HeaderButtonsDivider />
            </>
          )}
          {onEdit && editShown && (
            <>
              <Button
                type="primary"
                size={size}
                onClick={onEdit}
                icon={<EditOutlined />}
              >
                {t('COMMON.EDIT')}
              </Button>
              <HeaderButtonsDivider />
            </>
          )}
          {onDeactivate && (
            <>
              <Button
                type="primary"
                size={size}
                icon={<StopOutlined />}
                onClick={onDeactivate}
              >
                {t('ORDER.DEACTIVATE')}
              </Button>
              <HeaderButtonsDivider />
            </>
          )}
          <RefreshComponent
            lastRefreshed={lastUpdated}
            size={size}
            loading={ordersLoading}
            disabled={interfaceLoading || ordersLoading}
            onRefresh={onRefresh}
            onUpdateRefreshIntervalSeconds={onUpdateInterval}
            refreshIntervalSeconds={refreshIntervalSeconds}
            disableRefreshSelection={disableRefreshSelection}
            compact
          />
        </FlexRightSpaced>
      </Col>
    </DetailsRow>
  );
};

OrdersTableHeader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  lastUpdated: MomentPropTypes.momentObj.isRequired,
  ordersLoading: PropTypes.bool.isRequired,
  interfaceLoading: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onUpdateInterval: PropTypes.func,
  refreshIntervalSeconds: PropTypes.number.isRequired,
  disableRefreshSelection: PropTypes.bool,
  editShown: PropTypes.bool,
  onDeactivate: PropTypes.func,
  onShare: PropTypes.func,
  onEdit: PropTypes.func,
  onGenerateReport: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  updateFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  showProbalyRoute: PropTypes.func,
  propableRouteButtonShow: PropTypes.bool,
};

OrdersTableHeader.defaultProps = {
  size: 'small',
  onUpdateInterval: NOP,
  disableRefreshSelection: false,
  editShown: false,
  onDeactivate: null,
  onShare: null,
  onEdit: null,
  onGenerateReport: null,
  filters: {},
  updateFilters: NOP,
  clearFilters: NOP,
  showProbalyRoute: NOP,
  propableRouteButtonShow: false,
};

export default OrdersTableHeader;
