import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

const SharingSuccessContent = ({ onSelect, name }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Text>
        {t('PERSONALIZATION.CREATION_SUCCESS', { name })}
      </Typography.Text>
      {onSelect && (
      <Button
        onClick={onSelect}
        type="link"
        size="small"
      >
        {t('PERSONALIZATION.CHOOSE_NOW')}
      </Button>
      )}
    </>
  );
};

SharingSuccessContent.propTypes = {
  onSelect: PropTypes.func,
  name: PropTypes.string.isRequired,
};

SharingSuccessContent.defaultProps = {
  onSelect: null,
};

export default SharingSuccessContent;
