import * as moment from 'moment';
import { toElapsedFriendlyString } from '../../../../utils/dateTimeUtils';

function toIgnitionStateString(ignitionState) {
  if (ignitionState) {
    return 'On';
  }
  if (ignitionState === false) {
    return 'Off';
  }
  return '-';
}

export default (data, t, showLastTrace) => {
  const {
    vehicleId, vehicle, locName, order,
  } = data;
  const { licencePlateNumber, lastPosition } = vehicle;
  const { serverTimestamp, speed, ignitionState } = lastPosition[0];
  const lastUpdate = moment.unix(serverTimestamp);
  const elapsedString = toElapsedFriendlyString(lastUpdate, t);
  const locationName = locName && locName.title;
  const ignitionStateString = toIgnitionStateString(ignitionState);
  // button in infobubble needs globally visible function to handle onclick event
  window.showLastTrace = showLastTrace;

  const renderShowLastTraceButton = () => '<button type="button" class="ant-btn ant-btn-primary ant-btn-sm show_last"'
      + `onclick="window.showLastTrace('${vehicleId}')">`
      + `${t('VEHICLE.SHOW_LAST_TRACE')}`
      + '</button>';

  return (
    '<div class="H_bubble_root">'
      + '<div class="H_bubble_wrapper">'
        + '<div class="H_bubble_content">'
          + '<div>'
            + '<div class="H_bubble_content--vehicle-id">'
              + '<i class="anticon">'
                + '<img class="H_bubble_content--vehicle-icon" src="./images/fleet-icon-dark.png" alt="Truck icon" />'
              + '</i>'
              + `<a href="#/vehicles/${vehicleId}">${licencePlateNumber}</a>`
              + '<span class="H_bubble_content--company">'
                + `${' ('}`
                + `${(order?.order?.carrierCompany?.name || '').trim()}`
                + ')'
              + '</span>'
            + '</div>'
          + '</div>'
          + '<div class="horizontal-line"></div>'
          + '<table class="H_bubble_content--details">'
            + '<thead>'
              + '<tr>'
                + '<td>'
                  + '<span class="H_bubble_content--label">'
                    + `${t('PROPERTIES.SPEED')}`
                  + '</span>'
                + '</td>'
                + '<td>'
                  + '<span class="H_bubble_content--data">'
                    + `${speed ? `${speed.toFixed(0)} km/h` : '–'}`
                  + '</span>'
                + '</td>'
              + '</tr>'
              + '<tr>'
                + '<td>'
                  + '<span class="H_bubble_content--label">'
                    + `${t('PROPERTIES.IGNITION')}`
                  + '</span>'
                + '</td>'
                + '<td>'
                  + '<span class="H_bubble_content--data">'
                    + `${ignitionStateString}`
                  + '</span>'
                + '</td>'
              + '</tr>'
              + '<tr>'
                + '<td>'
                  + '<span class="H_bubble_content--label">'
                    + `${t('PROPERTIES.LOCATION')}`
                  + '</span>'
                + '</td>'
                + '<td>'
                  + '<span class="H_bubble_content--data">'
                    + `${locationName || '–'}`
                  + '</span>'
                + '</td>'
              + '</tr>'
              + '<tr>'
                + '<td>'
                  + '<span class="H_bubble_content--label">'
                    + `${t('PROPERTIES.UPDATED')}`
                  + '</span>'
                + '</td>'
                + '<td>'
                  + '<span class="H_bubble_content--data">'
                    + '<div>'
                      + `${lastUpdate.format('DD-MM-YYYY HH:mm')}`
                      + '<div>'
                        + `${elapsedString}`
                      + '</div>'
                    + '</div>'
                  + '</span>'
                + '</td>'
              + '</tr>'
            + '</thead>'
          + '</table>'
          + `${showLastTrace ? renderShowLastTraceButton() : ''}`
        + '</div>'
        + '<div class="triangle-with-shadow" />'
      + '</div>'
    + '</div>'
  );
};
