import PropTypes from 'prop-types';

export const paginationPropTypes = PropTypes.shape({
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
});

export const sortingPropTypes = PropTypes.shape({
  order: PropTypes.oneOf(['descend', 'ascend']),
  columnKey: PropTypes.string,
  field: PropTypes.string,
});
