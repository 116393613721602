/* eslint-disable class-methods-use-this */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ChartsService } from '../../../../services';
import BaseChart from './BaseChart';

class EstimatedWorkingTimeWorkingChart extends React.Component {
  generateChartPoints() {
    const points = ChartsService.getEstimatedWorkingTimeChartPoints();

    const base = points;
    const selected = points.map((x) => ({ x: x.x, y: x.data.active ? x.y : null }));
    return {
      base,
      selected,
    };
  }

  render() {
    return (
      <BaseChart chartId="EstimatedWorkingTimeWorkingChart" getPoints={this.generateChartPoints} indexDivider="2" />
    );
  }
}

export default withTranslation()(EstimatedWorkingTimeWorkingChart);
