import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ActiveSharingActionsComponent = ({
  tooltips, disabled, onEditSharing, onStopSharing,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip
        placement="topLeft"
        title={t(tooltips.edit)}
      >
        <Button
          type="link"
          disabled={disabled}
          icon={<EditOutlined />}
          onClick={onEditSharing}
        />
      </Tooltip>
      <Tooltip
        placement="topLeft"
        title={t(tooltips.stop)}
      >
        <Popconfirm
          title={t('SHARE_VEHICLES.CONFIRM_STOP')}
          onConfirm={onStopSharing}
          okText={t('COMMON.YES')}
          cancelText={t('COMMON.NO')}
          placement="topRight"
        >
          <Button
            type="link"
            disabled={disabled}
            icon={<PauseCircleOutlined />}
          />
        </Popconfirm>
      </Tooltip>
    </>
  );
};

ActiveSharingActionsComponent.propTypes = {
  tooltips: PropTypes.shape({
    edit: PropTypes.string.isRequired,
    stop: PropTypes.string.isRequired,
  }).isRequired,
  onEditSharing: PropTypes.func.isRequired,
  onStopSharing: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ActiveSharingActionsComponent;
