import moment from 'moment';
import { FETCH_SHARES } from '../../actions/sharing';
import { normalizeSharings } from '../../normalizers';
import { RESET_COMPANY_CONTEXT } from '../../actions/company';

const sharingInitState = {
  loading: false,
  majorReload: false,
  error: null,
  lastUpdated: moment(),
  data: {
    page: 1,
    size: 10,
    totalVehicles: 0,
    sharings: [],
  },
};

export default function sharesReducer(state = sharingInitState, { type, payload, meta }) {
  switch (type) {
    case FETCH_SHARES.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      const sharings = normalizeSharings(payload.data.sharings);
      return {
        ...state,
        data: { ...payload.data, sharings },
        loading: false,
        majorReload: false,
        error: undefined,
        lastUpdated: moment(),
      };
    }
    case FETCH_SHARES.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        majorReload: false,
        error: payload,
        data: sharingInitState.data,
        lastUpdated: moment(),
      };
    case FETCH_SHARES.START:
      return {
        ...state, loading: true, majorReload: !payload.silentRefresh, actionId: payload.actionId,
      };
    case RESET_COMPANY_CONTEXT:
      return { ...sharingInitState };
    default:
      return state;
  }
}
