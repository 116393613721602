import { Card, Collapse } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  .ant {
    &-card-actions {
      background-color: white;
      & > li {
          margin: 10px 0;
      }
    }
    &-card-head {
      background-color: #f0f0f0;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const FullWidthStyledCard = styled(Card)`
  .ant {
    &-card-actions {
      background-color: white;
      & > li {
          margin: 10px 0;
      }
    }
    &-card-head {
      background-color: #f0f0f0;
      font-weight: 500;
      font-size: 14px;
    }
    &-card-body {
      padding: 0px;
    }
  }
`;

export const Panel = styled(Collapse.Panel)`
  .ant-collapse-header {
    font-weight: 500;
  }
  
  .ant-timeline-item-last {
    padding-bottom: 0;
  }
`;

export const StyledCollapse = styled(Collapse)`
`;
