import React from 'react';
import GenerateReportModal from '../components/Vehicle/GenerateReportModal/GenerateReportModal';
import ShareVehiclesModal from '../components/Vehicle/ShareVehiclesModal/ShareVehiclesModal';
import VehiclesFilterModal from '../components/Table/VehiclesFilterModal';

const GlobalModalsWrapper = () => (
  <>
    <GenerateReportModal />
    <ShareVehiclesModal />
    <VehiclesFilterModal />
  </>
);

export default GlobalModalsWrapper;
