import { RESET_COMPANY_CONTEXT, RESET_EMAIL_SHARING_PAGINATION } from '../../actions';
import { SET_CARRIERS_PAGINATION } from '../../actions/carriers';

export default function paginationReducer(state = { page: 0, size: 10 }, { type, payload }) {
  switch (type) {
    case SET_CARRIERS_PAGINATION:
      return { ...payload };
    case RESET_COMPANY_CONTEXT:
    case RESET_EMAIL_SHARING_PAGINATION:
      return { ...state, page: 1 };
    default:
      return state;
  }
}
