import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Button, Select, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import NOP from '../../utils/NOP';

const ReloadButton = styled(Button)`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: ${(props) => (props.compact ? '10px !important' : '12px')};
  padding-top: 0;
`;

const RefreshComponent = ({
  loading, onRefresh, lastRefreshed, size, refreshIntervalSeconds, onUpdateRefreshIntervalSeconds, compact,
  disableRefreshSelection,
}) => {
  const { t } = useTranslation();

  const renderLastRefreshedTooltip = () => (
    lastRefreshed ? t('VEHICLE.LAST_REFRESHED', { refreshedAt: lastRefreshed.format('HH:mm') }) : null
  );

  const refreshIntervalOptions = [
    { value: 0, label: t('COMMON.OFF') },
    { value: 30, label: t('COMMON.TIME.SEC_AMOUNT', { amount: 30 }) },
    { value: 60, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 1 }) },
    { value: 120, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 2 }) },
    { value: 300, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 5 }) },
    { value: 600, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 10 }) },
    { value: 1800, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 30 }) },
    { value: 3600, label: t('COMMON.TIME.HOURS_AMOUNT', { amount: 1 }) },
  ];

  const renderRefreshButton = () => (
    <Tooltip
      placement="bottomLeft"
      title={renderLastRefreshedTooltip()}
    >
      <ReloadButton
        size={compact ? 'medium' : size}
        type={compact ? 'link' : null}
        icon={<ReloadOutlined />}
        loading={loading}
        onClick={onRefresh}
        compact={compact ? 'compact' : null}
      >
        {compact ? null : t('COMMON.REFRESH_DATA')}
      </ReloadButton>
    </Tooltip>
  );

  return (
    <>
      {!compact && renderRefreshButton()}
      <Tooltip placement="left" title={t('COMMON.SELECT_REFRESH_INTERVAL')}>
        <Select
          style={{ minWidth: '72px' }}
          disabled={loading || disableRefreshSelection}
          size={size}
          value={refreshIntervalSeconds}
          onChange={onUpdateRefreshIntervalSeconds}
          options={refreshIntervalOptions}
        />
      </Tooltip>
      {compact && renderRefreshButton()}
    </>
  );
};

RefreshComponent.propTypes = {
  loading: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  lastRefreshed: MomentPropTypes.momentObj,
  size: PropTypes.string,
  compact: PropTypes.bool,
  refreshIntervalSeconds: PropTypes.number.isRequired,
  onUpdateRefreshIntervalSeconds: PropTypes.func,
  disableRefreshSelection: PropTypes.bool,
};

RefreshComponent.defaultProps = {
  size: 'small',
  compact: false,
  loading: false,
  lastRefreshed: null,
  onUpdateRefreshIntervalSeconds: NOP,
  disableRefreshSelection: false,
};

export default RefreshComponent;
