import i18n from 'i18next';

const titleMap = {
  '^/$': 'BREADCRUMB.HOME',
  '^/vehicles$': 'BREADCRUMB.FLEET',
  '^/vehicles/[a-zA-Z0-9-]+$': 'BREADCRUMB.VEHICLE_DETAILS',
  '^/orders$': 'BREADCRUMB.ORDERS',
  '^/orders/.*$': 'BREADCRUMB.ORDER_DETAILS',
};

const setTitle = ({ path, title }) => {
  if (title) {
    document.title = `${title} | CO3`;
  } else {
    const pathTitle = Object.entries(titleMap).find(([key]) => new RegExp(key).test(path));
    const translationKey = pathTitle && pathTitle[1];
    document.title = `${translationKey ? i18n.t(translationKey) : 'Fleet'} | CO3`;
  }
};

export default {
  setTitle,
};
