import H from '@here/maps-api-for-javascript';
import inherits from '../common';

// eslint-disable-next-line func-names
const LegendControl = function () {
  H.ui.Control.call(this);
  this.legendButton = new H.ui.base.Button({
    data: {},
    label: '<img class="H_legend_icon" src="./images/map/legend.svg" alt="Ignition state legend" />',
  });
  this.addClass('H_noactive');
  this.addClass('H_legend');
  this.addChild(this.legendButton);
  this.setAlignment(H.ui.LayoutAlignment.LEFT_BOTTOM);
};

export default () => {
  inherits(LegendControl, H.ui.Control);
  return new LegendControl();
};
