/* eslint-disable max-classes-per-file */
import H from '@here/maps-api-for-javascript';
import { message } from 'antd';
import MapManager from '../../../../services/map/MapManager';
import { findBoundingBox, createBubbleOpener } from '../../../../services/map/drawing';
import LastTraceRenderer from './LastTraceRenderer';
import { isSuccess } from '../../../../utils/responseUtils';
import { MapEventType } from '../../../../services/map/mapEventDispatcher';
import theme from '../../../../theme';

const Mode = {
  lastTrace: 'last-trace',
  vehicles: 'vehicles',
};

const choiceAvailableRoute = (route, warnInfo) => {
  if (route.propableRoute.length === 0) {
    message.warn(warnInfo);

    return route.trips.flatMap((
      trip,
    ) => trip.positions.map(({ latitude, longitude, serverTimestamp }, index) => ({
      lat: latitude, lng: longitude, id: `point-${index}`, timestamp: serverTimestamp,
    })));
  }

  return route.propableRoute
    .map(({ coordinateLatitude, coordinateLongitude }) => ({
      lat: coordinateLatitude, lng: coordinateLongitude,
    }));
};

class VehiclesMapManager extends MapManager {
  constructor({
    refId, t, fetchLocation, lastTraceMode, warnInfoContent,
  }) {
    super({
      refId, legend: true, fullscreen: true, t,
    });
    lastTraceMode.onDispose();
    this.fetchLocation = fetchLocation;
    this.showLastTrace = lastTraceMode.fetch
      ? (vehicleId) => lastTraceMode.fetch(vehicleId).then(([trace, details]) => {
        if (isSuccess(trace) && isSuccess(details) && this.isAnalysable(trace.payload.data)) {
          this.lastTraceRenderer = this.renderLastTrace(details.payload.data, trace.payload.data, lastTraceMode);
          const propablePoints = choiceAvailableRoute(trace.payload.data, warnInfoContent);
          this.drawHistoricRoute(propablePoints);
        } else {
          message.error(t('VEHICLE.NO_LAST_24H_TRACE'), 5);
        }
      })
      : null;
    this.vehiclesMarkers = null;
    this.initCenterAtLocation = true;
    this.clusterProvider = null;
    this.clusterLayer = null;
    this.dataPoints = null;
    this.mode = Mode.vehicles;
    this.initBubble();
    this.initClusteringProvider();
  }

  drawHistoricRoute(points) {
    const { routeAsSeparatedLines } = this.createRoute(points, {
      style: {
        strokeColor: theme.color.propableHistoricRoute,
      },
      zIndex: 200,
      volatility: true,
      visibility: true,
    });

    this.historicRoute = routeAsSeparatedLines;

    this.addAllToMap(routeAsSeparatedLines);

    return () => {
      this.removeAllFromMap(routeAsSeparatedLines);
    };
  }

  togleHistoricRoute(visible) {
    if (this.historicRoute) {
      this.historicRoute.forEach((step) => step.setVisibility(visible));
    }
    if (this.lastTraceRenderer) {
      this.lastTraceRenderer.show(visible);
    }
  }

  isAnalysable(trace) {
    return !!trace && trace.trips && trace.trips.length;
  }

  closeLastTrace() {
    if (this.lastTraceRenderer) {
      this.lastTraceRenderer.dispose();
      this.lastTraceRenderer = null;
    }
  }

  initBubble() {
    this.infoBubble = new H.ui.InfoBubble({
      lat: 0,
      lng: 0,
    });
    this.infoBubble.close();
    this.infoBubble.addClass('vehicle-details-infobubble');
    this.infoBubble.addClass('above-marker-infobubble');

    this.ui.addBubble(this.infoBubble);
  }

  resetCentering() {
    this.initCenterAtLocation = true;
  }

  dawClusteredVehiclesMarkers(vehicles) {
    const vehicleIds = (vehicles || []).map((vehicle) => vehicle.vehicleId);

    if (this.infoBubble) {
      const data = this.infoBubble.getData();
      if (data && !vehicleIds.includes(data.vehicleId)) {
        this.InfoBubble.close();
      }
    }

    this.dataPoints = (vehicles && vehicles.length && this.createDataPoints(vehicles)) || [];
    this.clusterProvider.setDataPoints(this.dataPoints);
    if (this.dataPoints.length && this.mode === Mode.vehicles) {
      this.mapEventDispatcher.dispatch({
        type: MapEventType.NEW_BOUNDING,
        payload: {
          bounding: findBoundingBox(this.dataPoints),
          triggerCentering: this.initCenterAtLocation,
        },
      });
      this.initCenterAtLocation = false;
    }

    this.resize();
  }

  initClusteringProvider() {
    this.clusterProvider = this.createClusteringProvider(
      this.fetchLocation, this.showLastTrace, this.infoBubble,
    );
    this.clusterLayer = new H.map.layer.ObjectLayer(this.clusterProvider);
    this.clusterLayer.layerIdentifier = 'cluster';
    this.addLayerToMap(this.clusterLayer);
  }

  renderLastTrace(vehicle, trace, config) {
    return new LastTraceRenderer({
      mapEventDispatcher: this.mapEventDispatcher,
      vehicle,
      trace,
      ui: this.ui,
      t: this.t,
      onDispose: () => {
        this.mode = Mode.vehicles;
        config.onDispose();
        this.addLayerToMap(this.clusterLayer);
        this.mapEventDispatcher.dispatch({
          type: MapEventType.NEW_BOUNDING,
          payload: {
            bounding: findBoundingBox(this.dataPoints),
          },
        });
        this.lastTraceRenderer = null;
        this.removeAllFromMap(this.historicRoute);
        this.historicRoute = null;
      },
      onApply: () => {
        this.mode = Mode.lastTrace;
        config.onApply(vehicle.vehicle.id);
        this.removeLayer(this.clusterLayer);
        this.closeAllBubbles();
      },
    }).apply();
  }

  selectMarker(selectedVehicle) {
    this.closeAllBubbles();
    if (this.dataPoints) {
      this.dataPoints.forEach((marker) => {
        if (marker.data.vehicleId === selectedVehicle.vehicleId) {
          const bubbleOpener = createBubbleOpener(
            this.ui,
            this.t,
            this.fetchLocation,
            this.showLastTrace,
            this.infoBubble,
            true,
          );
          bubbleOpener(marker.data, marker);
        }
      });
    }
  }
}

export default VehiclesMapManager;
