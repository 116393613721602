import { combineReducers } from 'redux';
import { MONITORING_REQUEST_DECLINE, MONITORING_REQUEST_ACCEPT, MONITORING_REQUEST_DEACTIVATE } from '../../actions';

const declineInitialState = { loading: false };

const declineReducer = (state = { ...declineInitialState }, { type }) => {
  switch (type) {
    case MONITORING_REQUEST_DECLINE.START:
      return { ...state, loading: true };
    case MONITORING_REQUEST_DECLINE.SUCCESS:
    case MONITORING_REQUEST_DECLINE.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const acceptReducer = (state = { ...declineInitialState }, { type }) => {
  switch (type) {
    case MONITORING_REQUEST_ACCEPT.START:
      return { ...state, loading: true };
    case MONITORING_REQUEST_ACCEPT.SUCCESS:
    case MONITORING_REQUEST_ACCEPT.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const deactivateReducer = (state = { ...declineInitialState }, { type }) => {
  switch (type) {
    case MONITORING_REQUEST_DEACTIVATE.START:
      return { ...state, loading: true };
    case MONITORING_REQUEST_DEACTIVATE.SUCCESS:
    case MONITORING_REQUEST_DEACTIVATE.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default combineReducers({
  accept: acceptReducer,
  decline: declineReducer,
  deactivate: deactivateReducer,
});
