import { OrderContext } from '../../../types';

const SET_ORDERS_IN_PAGINATION = 'SET_ORDERS_IN_PAGINATION';
const SET_ORDERS_OUT_PAGINATION = 'SET_ORDERS_OUT_PAGINATION';
const SET_ORDERS_ROOT_PAGINATION = 'SET_ORDERS_PAGINATION';

export const SET_ORDERS_PAGINATION = {
  [OrderContext.IN]: SET_ORDERS_IN_PAGINATION,
  [OrderContext.OUT]: SET_ORDERS_OUT_PAGINATION,
  [OrderContext.UNKNOWN]: SET_ORDERS_ROOT_PAGINATION,
};

export const setOrdersPagination = ({ page, size }, orderType = OrderContext.UNKNOWN) => ({
  type: SET_ORDERS_PAGINATION[orderType],
  payload: {
    page,
    size,
  },
});

export const resetOrdersPagination = (orderType = OrderContext.UNKNOWN) => ({
  type: SET_ORDERS_PAGINATION[orderType],
  payload: {
    page: 1,
  },
});
