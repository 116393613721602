import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AimOutlined from '@ant-design/icons/lib/icons/AimOutlined';
import { Skeleton } from 'antd';
import { CellButton } from '../../Styled';
import HereMapsLocation from './HereMapsLocation';
import { fetchOrderLastPositions } from '../../../store/actions';
import { getVehicleLocation } from '../../../store/selectors';

const propTypes = {
  onError: PropTypes.func,
  vehicleId: PropTypes.string.isRequired,
  shipperId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};

const defaultProps = {
  onError: null,
};

const GetOrderVehicleLocation = ({
  onError, vehicleId, shipperId, orderId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const vehicleLocation = useSelector(
    getVehicleLocation(['orders', vehicleId, shipperId, orderId].join('_')),
  );

  const onFetchLocation = useCallback(() => {
    setLoading(true);
    dispatch(fetchOrderLastPositions(vehicleId, shipperId, orderId))
      .catch(() => onError && onError())
      .finally(() => setLoading(false));
  }, [dispatch, vehicleId, shipperId, orderId, onError]);

  const renderGetLocationPrompt = () => (
    <CellButton
      type="link"
      icon={<AimOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        onFetchLocation();
      }}
    >
      {t('COMMON.GET_LOCATION')}
    </CellButton>
  );

  return (
    <Skeleton
      loading={loading}
      active
      title={false}
      paragraph={{
        rows: 3,
        width: [100, 180, 140],
      }}
    >
      {vehicleLocation && vehicleLocation.location ? (
        <HereMapsLocation
          hereLocation={vehicleLocation.location}
          onRefresh={onFetchLocation}
          position={vehicleLocation.coordinates}
          withCoordinates
          withTimestamp
        />
      ) : renderGetLocationPrompt()}
    </Skeleton>
  );
};

GetOrderVehicleLocation.propTypes = propTypes;
GetOrderVehicleLocation.defaultProps = defaultProps;

export default GetOrderVehicleLocation;
