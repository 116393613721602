import PropTypes from 'prop-types';
import { requestCompanyPropTypes } from './company.type';
import { stopsPropTypes } from './stop.type';

export const MonitoringRequestType = {
  TIME: 'time',
  TOUR: 'tour',
  UNKNOWN: 'unknown',
};

export const requestPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  vehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    licencePlateNumber: PropTypes.string,
  })),
  internalId: PropTypes.string,
  shipperCompany: requestCompanyPropTypes,
  carrierCompany: requestCompanyPropTypes,
  personId: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  createdDate: PropTypes.number,
  active: PropTypes.bool,
  statusDate: PropTypes.number,
  system: PropTypes.string,
  user: PropTypes.string,
  stops: stopsPropTypes,
});
