import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

const SharingSuccessContent = ({ goToSharing, additionalInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Text>
        {t('SHARE_VEHICLES.SHARE_SUCCESS')}
      </Typography.Text>
      <>
        {
          additionalInfo.includes(t('SHARE_VEHICLES.PERIOD_WAS_CHANGED'))
            ? (
              <Typography.Text>
                {t('SHARE_VEHICLES.SHARE_CHANGED')}
              </Typography.Text>
            )
            : <></>
        }
      </>
      <Button
        onClick={goToSharing}
        type="link"
        size="small"
      >
        {t('COMMON.VIEW_SHARING')}
      </Button>
    </>
  );
};

SharingSuccessContent.propTypes = {
  goToSharing: PropTypes.func.isRequired,
  additionalInfo: PropTypes.string.isRequired,
};

export default SharingSuccessContent;
