import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, message } from 'antd';
import {
  analysedTracePropTypes,
  estimatedEtaPropTypes,
  geofencingDataPropTypes,
  orderPositionsPerVehiclePropTypes,
  orderPropTypes,
} from '../../../types';
import VehicleDetailsMapManager from '../../VehicleDetails/Components/Map/VehicleDetailsMapManager';
import { fetchLocation } from '../../../store/actions';
import NOP from '../../../utils/NOP';
import { FlexColumn, MapWrapper } from '../../../components';
import HistoryPlayComponent from '../../VehicleDetails/Components/HistoryPlayComponent/HistoryPlayComponent';
import eventBus from '../../../services/EventBus';

const drawHistoryUntilIndex = async (index, drawer, showProbalyRoute) => {
  if (!index || !drawer) {
    return;
  }
  if (!showProbalyRoute) {
    drawer.showRouteToIndex(index);
  }
};

const generatePoints = (positions, vehicle) => {
  if (!positions) {
    return [];
  }

  return positions.map(({
    coordinateLatitude,
    coordinateLongitude,
    serverTimestamp,
    positionTimestamp,
    speed,
    ignitionState,
  }) => (
    {
      lat: coordinateLatitude,
      lng: coordinateLongitude,
      serverTimestamp,
      positionTimestamp,
      speed,
      ignitionState,
      vehicle,
    }
  ));
};

const OrderPositionsMap = ({
  vehicle, orderDetails, loading, eta, analysis, renderBreaks, geofencingWithEtaEnabled, geofencingData,
  showOrHideProbablyRoute,
}) => {
  const { t } = useTranslation();
  const [mapManager, setMapManager] = useState(null);
  const [routePoints, setRoutePoints] = useState(null);
  const [drawingTrace, setDrawingTrace] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    function drawMap() {
      const newMapManager = new VehicleDetailsMapManager({
        refId: 'map',
        fullscreen: true,
        legend: true,
        t,
        fetchLocation: (vehicleId, coords) => dispatch(fetchLocation(vehicleId, coords)),
      });
      setTimeout(() => newMapManager.resize(), 1000);
      setMapManager(newMapManager);
    }
    return drawMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapManager) {
      mapManager.toggleHistoricRoute(showOrHideProbablyRoute);
    }
  }, [showOrHideProbablyRoute, mapManager]);

  useEffect(() => {
    if (geofencingWithEtaEnabled && mapManager
      && geofencingData !== {} && geofencingData !== null) {
      mapManager.drawCrowns(geofencingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geofencingData]);

  useEffect(() => {
    const selectEvent = ({ eventType, eventId }) => {
      mapManager.tapEventIcon({ eventType, eventId });
    };

    return mapManager ? eventBus.subscribe('event-selected', selectEvent) : NOP;
  }, [mapManager]);

  useEffect(() => {
    if (mapManager) {
      mapManager.addCenterAtButton();
      return mapManager.removeCenterAtButton();
    }
    return NOP;
  }, [mapManager]);

  const choiceAvailableRoute = (prettyRoute, originalRoute) => {
    const isNotAvailable = prettyRoute.length === 0;
    if (isNotAvailable) {
      const refresh = () => {
        window.location.reload(true);
      };

      message.warn(
        <>
          {t('COMMON.WARN_DRAW_ROUTE')}
          <Button size="small" type="link" onClick={() => refresh()}>{t('COMMON.REFRESH_DATA')}</Button>
        </>,
      );
    }
    return isNotAvailable ? originalRoute : prettyRoute;
  };

  useEffect(() => {
    if (orderDetails && orderDetails.order && vehicle && mapManager) {
      const selectedVehiclePositions = orderDetails.positions && orderDetails.positions[vehicle.id];
      const points = generatePoints(selectedVehiclePositions && selectedVehiclePositions.positions, vehicle);
      setRoutePoints(points);

      let disposeTrace = NOP;
      if (points && points.length > 0) {
        const disposeMarkers = mapManager.drawHistoryBoundaryMarkers(points, orderDetails);
        const lastPoint = points[points.length - 1];
        const disposeDot = mapManager.drawPlayFocusDot(lastPoint);
        const disposeRoute = mapManager.drawRouteWithShadow(points, {
          onTap: (index) => setHistoryIndex(index),
        });

        const availableRoute = choiceAvailableRoute(orderDetails.positions[vehicle.id].probableRoute, points);
        const disposeHistoricRoute = mapManager.drawHistoricRoute(availableRoute);
        mapManager.showWholeRoute();
        disposeTrace = () => {
          disposeMarkers();
          disposeRoute();
          disposeDot();
          disposeHistoricRoute();
        };
      }

      let disposeStops = NOP;
      if (orderDetails.order.stops) {
        disposeStops = mapManager.drawStops(orderDetails.order.stops);
      }

      mapManager.centerToRouteAndStops();
      setDrawingTrace(false);
      return () => {
        disposeTrace();
        disposeStops();
        setRoutePoints(null);
      };
    }

    // if there is no vehicle but we have stops, draw them
    if (orderDetails && orderDetails.order && orderDetails.order.stops && mapManager) {
      const disposeStops = mapManager.drawStops(orderDetails.order.stops);
      mapManager.centerToRouteAndStops();
      setDrawingTrace(false);
      return () => {
        disposeStops();
      };
    }

    setDrawingTrace(false);
    return NOP;
  // skip choiceAvailableRoute
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mapManager, orderDetails, vehicle]);

  const drawTrace = useCallback((index) => drawHistoryUntilIndex(index, mapManager, showOrHideProbablyRoute),
    [mapManager, showOrHideProbablyRoute]);

  useEffect(() => {
    if (!mapManager || !eta) {
      return () => {};
    }
    return mapManager.drawEta(eta);
  }, [mapManager, eta]);

  useEffect(() => {
    if (mapManager && analysis && vehicle && renderBreaks) {
      const lastTraceRenderer = mapManager.renderTraceAnalysis({
        trace: analysis,
        vehicle: { vehicle },
        config: { startStop: false, centerOnApply: false, minStopLengthSeconds: 1 * 60 },
      });
      return () => lastTraceRenderer.dispose();
    }
    return NOP;
    // skipping mapManager and vehicle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysis, renderBreaks]);

  return (
    <FlexColumn>
      <MapWrapper id="map" />
      {(mapManager) && (
        <HistoryPlayComponent
          history={routePoints}
          loading={loading}
          onRenderPath={drawTrace}
          drawingTrace={drawingTrace}
          historyIndex={historyIndex}
          setHistoryIndex={setHistoryIndex}
          isEnabledPropableRoute={showOrHideProbablyRoute}
        />
      )}
    </FlexColumn>
  );
};

OrderPositionsMap.propTypes = {
  eta: estimatedEtaPropTypes,
  analysis: analysedTracePropTypes,
  orderDetails: PropTypes.shape({
    order: orderPropTypes,
    positions: orderPositionsPerVehiclePropTypes,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  vehicle: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  renderBreaks: PropTypes.bool.isRequired,
  geofencingWithEtaEnabled: PropTypes.bool.isRequired,
  geofencingData: geofencingDataPropTypes,
  showOrHideProbablyRoute: PropTypes.bool.isRequired,
};

OrderPositionsMap.defaultProps = {
  vehicle: null,
  eta: null,
  analysis: null,
  geofencingData: {},
};

export default OrderPositionsMap;
