import { combineReducers } from 'redux';
import paginationReducer from './pagination.reducer';
import selectedVehicleReducer from './selectedVehicle.reducer';
import sortingReducer from './sorting.reducer';
import vehiclesReducer from './vehicles.reducer';

export default combineReducers({
  pagination: paginationReducer,
  selected: selectedVehicleReducer,
  sorting: sortingReducer,
  vehicles: vehiclesReducer,
});
