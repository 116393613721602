import H from '@here/maps-api-for-javascript';
import TripPositionInfoContent from './TripInfoContent';

export const createTripInfoBubbleContent = (position, vehicle, t) => {
  const { vehicleCompany, licencePlateNumber } = vehicle.vehicle;
  const { name: companyName } = vehicleCompany;
  const {
    lat, lng, timestamp,
  } = position;
  return TripPositionInfoContent({
    companyName, licencePlateNumber, lat, lng, timestamp,
  }, t);
};

export const createTripInfoBubble = (position, vehicle, t) => {
  const infoBubble = new H.ui.InfoBubble({ lat: 0, lng: 0 }, {
    content: createTripInfoBubbleContent(position, vehicle, t),
  });
  infoBubble.addClass('trip-info-infobubble');
  infoBubble.addClass('above-infobubble');
  infoBubble.close();
  return infoBubble;
};
