import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_CARRIERS = axiosActions('FETCH_CARRIERS');

const executeSearchCarriers = (companyId, silentRefresh = false, requestBody) => ({
  type: FETCH_CARRIERS.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'post',
      url: UrlService.carriersInvitationSearch(companyId),
      data: requestBody,
    },
  },
});
// eslint-disable-next-line
export const loadInvitedCarriers = (silentRefresh = false, requestBody = { pageNumber: 0, pageSize: 20 }) => (dispatch, getState) => {
  const state = getState();
  const selectedCompany = getSelectedCompany(state);
  return dispatch(executeSearchCarriers(selectedCompany, silentRefresh, requestBody));
};
