import PropTypes from 'prop-types';
import {
  Col, Row, Timeline, Typography,
} from 'antd';
import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import nth from '../../../utils/nth';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../../utils/constants/timeFormats';
import { geofencingDataPropTypes, monitoringOrderSnapshotPropTypes, stopPropTypes } from '../../../types';
import { StopStatus } from '../../../store/normalizers/orders.normalizer';
import StopLocation from './StopLocation';
import { getStopType } from '../../../types/mappers';
import TripStopDetails from './TripStopDetails';

const dotColorByStatus = {
  [StopStatus.PASSED]: 'green',
  [StopStatus.ONGOING]: 'blue',
  [StopStatus.FUTURE]: 'blue',
};

const dotIconByStatus = {
  [StopStatus.PASSED]: <CheckCircleOutlined />,
  [StopStatus.ONGOING]: null,
  [StopStatus.FUTURE]: null,
};

function determineEndDateString(t, { startMoment, endMoment }) {
  if (startMoment.isSame(endMoment)) {
    return (<span className="greyed">{t('ORDER.STOP.FIXED')}</span>);
  }

  if (startMoment.clone().startOf('d').isSame(endMoment.clone().startOf('d'))) {
    return endMoment.format(DEFAULT_TIME);
  }

  return endMoment.format(DEFAULT_DATE_TIME);
}

const TripDetailsLine = styled(Typography.Paragraph)`
  margin-bottom: 3px !important;
  
  .greyed {
    color: ${({ theme }) => theme.color.gray2}
  }
`;

const TripDetailsHeaderLine = styled(TripDetailsLine)`
  font-size: 14px;
  margin-bottom: 3px !important;
`;

const determineStopStatus = (hasGeofencingData) => (hasGeofencingData ? StopStatus.PASSED : StopStatus.FUTURE);

const TripStop = ({
  stop, index, geofencingWithEtaEnabled, eta, hasGeofencingData, geofencingData, monitoringOrderSnapshot,
}) => {
  const { t } = useTranslation();
  const stopType = getStopType(stop);
  return (
    <Timeline.Item
      key={stop.id}
      dot={dotIconByStatus[determineStopStatus(hasGeofencingData)]}
      color={dotColorByStatus[determineStopStatus(hasGeofencingData)]}
    >
      <Row>
        <Col xs={12}>
          <TripDetailsHeaderLine>
            {t('ORDER.STOP_INDEX', { index: (index + 1) + nth(index + 1) })}
            {stopType && `: ${t(`ORDER.STOP_TYPES.${stopType}`)}`}
          </TripDetailsHeaderLine>
          <TripDetailsLine>
            <b>
              {`${stop.timeSlot.startMoment.format(DEFAULT_DATE_TIME)} – `}
              {determineEndDateString(t, stop.timeSlot)}
            </b>
          </TripDetailsLine>
          <TripDetailsLine>
            {stop.name}
          </TripDetailsLine>
          <TripDetailsLine>
            <StopLocation
              place={stop.place}
              latitude={stop.latitude}
              longitude={stop.longitude}
            />
          </TripDetailsLine>
          <TripDetailsLine>
            {stop.additionalInfo}
          </TripDetailsLine>
        </Col>
        <Col xs={12}>
          <TripStopDetails
            stop={stop}
            geofencingWithEtaEnabled={geofencingWithEtaEnabled}
            eta={eta}
            hasGeofencingData={hasGeofencingData}
            geofencingData={geofencingData}
            monitoringOrderSnapshot={monitoringOrderSnapshot}
          />
        </Col>
      </Row>
    </Timeline.Item>
  );
};

TripStop.propTypes = {
  stop: stopPropTypes.isRequired,
  index: PropTypes.number.isRequired,
  geofencingWithEtaEnabled: PropTypes.bool.isRequired,
  eta: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({}),
    onCalculate: PropTypes.func,
    tooltipKey: PropTypes.string,
  }).isRequired,
  geofencingData: geofencingDataPropTypes,
  monitoringOrderSnapshot: PropTypes.shape({
    hasData: PropTypes.bool,
    data: monitoringOrderSnapshotPropTypes,
  }).isRequired,
  hasGeofencingData: PropTypes.bool.isRequired,
};

TripStop.defaultProps = {
  geofencingData: {},
};

export default TripStop;
