/* eslint-disable max-len */
/* eslint func-names:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/no-this-in-sfc:0 */
import H from '@here/maps-api-for-javascript';
import i18n from 'i18next';
import inherits from '../common';
import { scaleRect } from './scaler';
import { zoomTo } from './utils';

const ZoomTo = function (data) {
  H.ui.Control.call(this);
  this.onButtonClick = this.onButtonClick.bind(this);

  this.increaseBtn_ = new H.ui.base.Button({
    data,
    label: `<img class="H_btn_zoom_all" title="${i18n.t('COMMON.MAP.ZOOM_ALL')}" src="./images/map/aim.svg" alt="Zoom all" />`,
    onStateChange: this.onButtonClick,
  });

  this.addChild(this.increaseBtn_);
  this.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
};

export const FIT_GROUP = 'FIT_GROUP';
export const CENTER_LOCATION = 'CENTER_LOCATION';

export const createFitGroupButton = () => {
  inherits(ZoomTo, H.ui.Control);
  ZoomTo.prototype.onButtonClick = function (evt) {
    if (evt.force || evt.currentTarget.getState() === 'down') {
      const {
        ui, boundingBox, location, zoomFactor, type,
      } = this.getData();
      if (!ui || !type) {
        return;
      }
      if (type === FIT_GROUP && boundingBox) {
        ui.getMap().getViewModel().setLookAtData({
          bounds: scaleRect(boundingBox),
        }, true);
      } else if (type === CENTER_LOCATION && location) {
        zoomTo(ui, location, zoomFactor);
      }
    }
  };
  return new ZoomTo({});
};
