const toValueLabel = (values) => values && values.map((value) => ({ label: value, value }));
const fromValueLabel = (values) => values && values.map(({ value }) => value);
export const toApiFiltersModel = (
  filters,
) => filters && Object.entries(filters).map(([fieldName, values]) => ({ fieldName, values: toValueLabel(values) }));

const fromApiFiltersModel = (filters = []) => {
  const clientFilters = {};
  filters.forEach(({ fieldName, values }) => {
    clientFilters[fieldName] = fromValueLabel(values);
  });

  return clientFilters;
};

export const toApiModel = (configuration) => ({
  ...configuration,
  filters: toApiFiltersModel(configuration.filters),
});

export const fromApiModel = (configuration, defaultConfig) => {
  const newFilters = fromApiFiltersModel(configuration.filters) || {};
  if (defaultConfig && defaultConfig.filters) {
    Object.entries(defaultConfig.filters).forEach(([key, val]) => {
      if (!newFilters[key] || !newFilters[key].length) {
        newFilters[key] = val;
      }
    });
  }
  return {
    ...configuration,
    filters: newFilters,
  };
};
