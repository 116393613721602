import PropTypes from 'prop-types';

export const telematicProviderPropTypes = PropTypes.shape({
  providerId: PropTypes.string,
  displayName: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
    i18nKey: PropTypes.string,
  })),
  typeOem: PropTypes.bool,
  providerLogoUrl: PropTypes.string,
});
