import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TourStatisticsComponent from './TourStatisticsComponent';
import { isFeatureEnabled } from '../../store/selectors';
import { FeatureToggle } from '../../store/actions';

const TourStatisticsPage = ({ match: { params: { side, shipperId, orderId } } }) => {
  const history = useHistory();
  const tourStatisticsEnabled = useSelector(isFeatureEnabled(FeatureToggle.TOUR_STATISTICS));

  const goBack = () => {
    history.push(`/orders/${side}/${shipperId}/${orderId}`);
  };

  return (
    tourStatisticsEnabled
      ? <TourStatisticsComponent tourId={orderId} shipperCompanyId={shipperId} goBack={goBack} />
      : null
  );
};

TourStatisticsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      side: PropTypes.string.isRequired,
      shipperId: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TourStatisticsPage;
