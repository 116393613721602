import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Col, Popover, Row, Select,
} from 'antd';
import { SettingsButton, SettingsPopoverContent, SettingsPopoverItem } from './HistoryPlayComponent.styled';

const playbackSpeedOptions = [
  {
    label: '1x',
    value: 1,
  },
  {
    label: '2x',
    value: 2,
  },
  {
    label: '3x',
    value: 3,
  },
  {
    label: '4x',
    value: 4,
  },
  {
    label: '5x',
    value: 6,
  },
];

const HistoryPlaySettingsComponent = ({ disabled, playbackSpeed, onPlaybackSpeedChange }) => {
  const [innerPlaybackSpeed, setInnerPlaybackSpeed] = useState(playbackSpeed);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    onPlaybackSpeedChange(innerPlaybackSpeed);
  }, [innerPlaybackSpeed, onPlaybackSpeedChange]);

  const renderPlaybackSpeedOptions = () => playbackSpeedOptions.map(({ label, value }) => (
    <Select.Option
      key={value}
      value={value}
    >
      {label}
    </Select.Option>
  ));

  const renderSettingsPopover = () => (
    <SettingsPopoverContent>
      <Row align="center" justify="space-between">
        <Col>
          <SettingsPopoverItem strong>
            {t('COMMON.PLAYBACK_SPEED')}
            :
          </SettingsPopoverItem>
        </Col>
        <Col>
          <Select
            size="small"
            value={innerPlaybackSpeed}
            onChange={setInnerPlaybackSpeed}
            bordered={false}
          >
            {renderPlaybackSpeedOptions()}
          </Select>
        </Col>
      </Row>
    </SettingsPopoverContent>
  );

  return (
    <>
      <Popover
        content={renderSettingsPopover()}
        trigger="click"
        visible={!disabled && popoverOpen}
        onVisibleChange={setPopoverOpen}
      >
        <SettingsButton open={popoverOpen} disabled={disabled} />
      </Popover>
    </>
  );
};

HistoryPlaySettingsComponent.propTypes = {
  playbackSpeed: PropTypes.number,
  onPlaybackSpeedChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

HistoryPlaySettingsComponent.defaultProps = {
  playbackSpeed: 1,
  disabled: false,
};

export default HistoryPlaySettingsComponent;
