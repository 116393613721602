import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { FormItem, HStretched } from '../Styled';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../utils/constants/timeFormats';

const endDateValidator = (form) => (_, date) => {
  if (date) {
    const startDateTimeField = form.getFieldValue('startDateTime');
    const endDateTime = date.clone().startOf('minute');
    if (startDateTimeField) {
      const startDateTime = startDateTimeField.clone().startOf('minute');
      if (endDateTime.isBefore(startDateTime)) {
        return Promise.reject();
      }
    }
  }
  return Promise.resolve();
};

const validateEnd = (form) => {
  form.validateFields(['endDateTime']);
};

const onStartDateChange = (form) => (value) => {
  form.setFieldsValue({
    startDateTime: value,
  });
  validateEnd(form);
};

const onEndDateChange = (form) => (value) => {
  form.setFieldsValue({
    endDateTime: value,
  });
  validateEnd(form);
};

const TimeDetails = ({
  form, fromDateLabel, toDateLabel,
  fromDateRequiredMessage, toDateRequiredMessage, toDateAfterStartMessage,
}) => (
  <>
    <HStretched _justify="flex-start">
      <FormItem
        label={fromDateLabel}
        name="startDateTime"
        rules={[{ required: true, message: fromDateRequiredMessage }]}
      >
        <DatePicker
          onSelect={onStartDateChange(form)}
          style={{ width: '100%' }}
          showTime={{
            format: DEFAULT_TIME,
          }}
          format={DEFAULT_DATE_TIME}
        />
      </FormItem>
      <FormItem
        label={toDateLabel}
        name="endDateTime"
        rules={[{
          required: true,
          message: toDateRequiredMessage,
        }, {
          validator: endDateValidator(form),
          message: toDateAfterStartMessage,
        }]}
      >
        <DatePicker
          onSelect={onEndDateChange(form)}
          style={{ width: '100%' }}
          showTime={{
            format: DEFAULT_TIME,
          }}
          format={DEFAULT_DATE_TIME}
        />
      </FormItem>
    </HStretched>
  </>
);

TimeDetails.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
  }).isRequired,
  fromDateLabel: PropTypes.string.isRequired,
  toDateLabel: PropTypes.string.isRequired,
  fromDateRequiredMessage: PropTypes.string.isRequired,
  toDateRequiredMessage: PropTypes.string.isRequired,
  toDateAfterStartMessage: PropTypes.string.isRequired,
};

export default TimeDetails;
