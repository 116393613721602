import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_VEHICLE_DETAILS = axiosActions('VEHICLE_DETAILS_LOAD');
export const fetchVehicleDetails = (vehicleId, providingCompanyId) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  return dispatch({
    type: FETCH_VEHICLE_DETAILS.START,
    payload: {
      actionId: uuidv4(),
      request: {
        url: UrlService.vehicleDetails(selectedCompany, vehicleId, providingCompanyId),
      },
    },
  });
};
