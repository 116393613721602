import moment from 'moment';

export const asMoment = (val) => {
  if (!val || !val.length) {
    return val;
  }
  const valAsMoment = moment(val[0], true);

  if (valAsMoment && moment.isMoment(valAsMoment) && valAsMoment.isValid()) {
    return [valAsMoment];
  }

  return [valAsMoment];
};
