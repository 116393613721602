import moment from 'moment';
import { FETCH_ORDERS, RESET_COMPANY_CONTEXT } from '../../actions';
import { OrderContext } from '../../../types';
import { normalizeOrders } from '../../normalizers/orders.normalizer';

const ordersInitState = {
  loading: false,
  majorReload: false,
  error: null,
  lastUpdated: moment(),
  data: {
    tours: [],
    totalElements: 0,
  },
};

export default (orderType = OrderContext.UNKNOWN) => (state = { ...ordersInitState }, { payload, type, meta }) => {
  switch (type) {
    case FETCH_ORDERS[orderType].START:
      return {
        ...state, loading: true, error: null, majorReload: !payload.executeInBackground, actionId: payload.actionId,
      };
    case FETCH_ORDERS[orderType].SUCCESS:
      if (state.actionId === meta.previousAction.payload.actionId) {
        return {
          ...state,
          loading: false,
          majorReload: false,
          error: null,
          lastUpdated: moment(),
          data: normalizeOrders(payload.data),
        };
      }
      return state;
    case FETCH_ORDERS[orderType].FAIL:
      if (state.actionId === meta.previousAction.payload.actionId) {
        return {
          ...state, loading: false, majorReload: false, error: payload, lastUpdated: moment(),
        };
      }
      return state;
    case RESET_COMPANY_CONTEXT:
      return { ...ordersInitState };
    default:
      return state;
  }
};
