import axiosActionsFactory from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { UrlService } from '../../../services';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_FEATURE_TOGGLES = axiosActionsFactory('FETCH_FEATURE_TOGGLES');

export const FeatureToggle = {
  DISABLED_FLEET: 'DISABLED_FLEET',
  SHOW_LAST_TRACE: 'SHOW_LAST_TRACE',
  TOUR_STATISTICS: 'TOUR_STATISTICS',
  SEARCH_ASSETS: 'SEARCH_ASSETS',
  SHOW_GEOFENCING_WITH_ETA: 'SHOW_GEOFENCING_WITH_ETA',
  SHOW_ROUTE_POINTS_FULL: 'SHOW_ROUTE_POINTS_FULL',
};

export const fetchFeatureToggles = () => async (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);

  await dispatch({
    type: FETCH_FEATURE_TOGGLES.START,
    payload: {
      actionId: uuidv4(),
      request: {
        method: 'get',
        url: UrlService.featureToggles(),
        params: {
          contextCompanyId,
        },
      },
    },
  });
};
