/* eslint-disable no-param-reassign */

import LocationService from './map/LocationService';

const handleLocationServiceResponse = (geocodes, tour, extractStopsFunction) => {
  const stopsWithoutLocation = [];
  for (let i = 0; i < geocodes.length; i += 1) {
    const geocodeForStop = geocodes[i];
    if (geocodeForStop && geocodeForStop.items.length) {
      const { position } = geocodeForStop.items[0];
      if (position) {
        extractStopsFunction(tour)[i].latitude = position.lat;
        extractStopsFunction(tour)[i].longitude = position.lng;
      }
    } else {
      stopsWithoutLocation.push(extractStopsFunction(tour)[i]);
    }
  }

  if (stopsWithoutLocation.length > 0) {
    return { error: { type: 'MISSING_GEOCODES', stops: stopsWithoutLocation } };
  }

  return { tour };
};

const extractRequestStops = (request) => (request.tourMonitoring ? request.tourMonitoring.tourStops : []);

// eslint-disable-next-line max-len
export const enhanceOrdersStopsWithGeocodes = (order) => Promise.all(order.stops.map((stop) => new Promise((resolve, reject) => {
  LocationService.fetchCoordinatesForOrderStopLocation({ ...stop.place }, resolve, reject);
}))).then((geocodes) => handleLocationServiceResponse(geocodes, order, (tour) => tour.stops));

// eslint-disable-next-line max-len
export const enhanceRequestStopsWithGeocodes = (request) => Promise.all(extractRequestStops(request).map((stop) => new Promise((resolve, reject) => {
  LocationService.fetchCoordinatesForRequestStopLocation({ ...stop.address }, resolve, reject);
}))).then((geocodes) => handleLocationServiceResponse(geocodes, request, (tour) => tour.tourMonitoring.tourStops));

export const checkOrderGeocoding = (geocodingResult) => {
  if (geocodingResult.error) {
    const {
      houseNumber, localNumber, city, street, postcode, country,
    } = geocodingResult.error.stops[0].place;
    return {
      success: false,
      location: `${houseNumber}${localNumber ? ` ${localNumber}` : ''} ${street},
       ${postcode} ${city}, ${country}`,
    };
  }
  return {
    order: geocodingResult.tour,
    success: true,
  };
};
