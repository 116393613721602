import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import parse from 'html-react-parser';
import {
  FormInput, FormItem, FormSelect, StyledCheckbox, Consent,
} from '../../../components';
import { Styles } from './TelematicAccount.styled';
import ApiService from '../../../services/ApiService';
import UrlService from '../../../services/UrlService';

const MIN_VAT_NUMBER_LENGTH = 5;

export default function TelematicAccountDetailsSection({
  onSelectChange, selectedProvider, isDetailsDisabled, creationView,
}) {
  const { t } = useTranslation();
  const [extraTexts, setExtraText] = useState({});
  const [borderColor, setBorderColor] = useState(undefined);

  const validateCheckbox = (_, isChecked) => {
    setBorderColor(isChecked ? undefined : 'red');
    return isChecked ? Promise.resolve() : Promise.reject();
  };

  const updateExtraText = (field, value) => {
    const newValue = {};
    newValue[field] = value;
    setExtraText({ ...extraTexts, ...newValue });
  };

  const updateExtraTextForVatNumber = (inputValue, fieldName) => {
    if (inputValue && inputValue.length >= MIN_VAT_NUMBER_LENGTH) {
      ApiService.defaultInstance.get(UrlService.findCompany(), {
        params: {
          taxCountryCode: inputValue.substring(0, 2),
          taxNo: inputValue.substring(2, inputValue.length),
          skipValidation: true,
        },
      }).then((response) => {
        if (response && response.data && response.data.companyName) {
          updateExtraText(fieldName, response.data.companyName);
        } else {
          updateExtraText(fieldName, t('TELEMATICS.ACCOUNT.CREATE.ERRORS.COMPANY_WITH_VAT_NOT_FOUND'));
        }
      }).catch(() => {
        updateExtraText(fieldName, t('TELEMATICS.ACCOUNT.CREATE.ERRORS.COMPANY_WITH_VAT_NOT_FOUND'));
      });
    }
  };

  const renderSelect = ({
    fieldName, i18nKey, optional, options,
  }, wide = true, disabled = true) => (
    <FormItem
      label={t(`TELEMATICS.ACCOUNT.CREATE.LABELS.${i18nKey.toUpperCase()}`)}
      name={fieldName}
      rules={!disabled ? [
        { required: !optional, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
      ] : []}
      style={wide ? Styles.wide : Styles.narrow}
    >
      <FormSelect
        placeholder={t(`TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.${i18nKey.toUpperCase()}`)}
        style={wide ? Styles.wide : Styles.narrow}
        disabled={disabled}
        onChange={onSelectChange}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {option.displayNameKey ? t(`TELEMATICS.ACCOUNT.CREATE.LABELS.${option.displayNameKey.toUpperCase()}`)
              : option.name ? option.name
                : option.value}
          </Select.Option>
        ))}
      </FormSelect>
    </FormItem>
  );

  const renderTextInput = ({
    fieldName, i18nKey, optional,
  }, wide = true, disabled = true) => (
    <FormItem
      label={t(`TELEMATICS.ACCOUNT.CREATE.LABELS.${i18nKey.toUpperCase()}`)}
      name={fieldName}
      rules={!disabled ? [
        { required: !optional, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
        { type: 'string', whitespace: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.BLANK') },
      ] : []}
      style={wide ? Styles.wide : Styles.narrow}
      extra={<small>{extraTexts[fieldName]}</small>}
    >
      <FormInput
        disabled={disabled}
        style={wide ? Styles.wide : Styles.narrow}
        placeholder={t(`TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.${i18nKey.toUpperCase()}`)}
        onChange={AwesomeDebouncePromise((e) => {
          const inputValue = e.target.value;
          if (fieldName === 'vat_number') {
            updateExtraTextForVatNumber(inputValue, fieldName);
          }
        }, 1000)}
      />
    </FormItem>
  );

  const renderPasswordInput = ({
    fieldName, i18nKey, optional,
  }, wide = true, disabled = true) => (
    <FormItem
      label={t(`TELEMATICS.ACCOUNT.CREATE.LABELS.${i18nKey.toUpperCase()}`)}
      name={fieldName}
      rules={!disabled ? [
        { required: !optional, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
        { type: 'string', whitespace: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.BLANK') },
      ] : []}
      style={wide ? Styles.wide : Styles.narrow}
    >
      <FormInput.Password
        disabled={disabled}
        style={wide ? Styles.wide : Styles.narrow}
        placeholder={t(`TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.${i18nKey.toUpperCase()}`)}
      />
    </FormItem>
  );

  const renderTextArea = ({
    fieldName, i18nKey, optional,
  }, wide = true, disabled = true) => (
    <FormItem
      label={t(`TELEMATICS.ACCOUNT.CREATE.LABELS.${i18nKey.toUpperCase()}`)}
      name={fieldName}
      rules={!disabled ? [
        { required: !optional, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
        { type: 'string', whitespace: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.BLANK') },
      ] : []}
      style={wide ? Styles.wide : Styles.narrow}
    >
      <FormInput.TextArea
        disabled={disabled}
        style={wide ? Styles.wideWithMinHeight : Styles.narrow}
        placeholder={t(`TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.${i18nKey.toUpperCase()}`)}
      />
    </FormItem>
  );

  const renderCheckbox = ({
    fieldName, optional, notUpdatable, value,
  }, wide = true, disabled = true) => (
    <FormItem
      name={fieldName}
      valuePropName="checked"
      rules={!disabled ? [
        { required: !optional, message: () => null }, { validator: validateCheckbox },
      ] : []}
      style={wide ? Styles.wide : Styles.narrow}
    >
      <StyledCheckbox
        disabled={disabled}
        bordercolor={borderColor}
        defaultChecked={!creationView && notUpdatable}
      >
        <Consent>
          {parse(value)}
          {!optional
            ? (
              <span style={{ color: 'red' }}>
                *
              </span>
            ) : <></>}
        </Consent>
      </StyledCheckbox>
    </FormItem>
  );

  const renderInput = (attr, wide = true) => {
    const disabled = isDetailsDisabled || (!creationView && attr.notUpdatable);
    switch (attr.type) {
      case 'DROPDOWN':
      case 'LONG_DROPDOWN':
        return renderSelect(attr, wide, disabled);
      case 'PASSWORD':
      case 'LONG_PASSWORD':
        return renderPasswordInput(attr, wide, disabled);
      case 'LONG_TEXT':
        return renderTextArea(attr, wide, disabled);
      case 'CHECKBOX':
      case 'CHECKBOX_CONSENT':
        return renderCheckbox(attr, wide, disabled);
      default: return renderTextInput(attr, wide, disabled);
    }
  };

  const renderForm = () => {
    const rows = [];
    for (let i = 0; i < selectedProvider.fields.length;) {
      const f1 = selectedProvider.fields[i];
      const f2 = i + 1 < selectedProvider.fields.length ? selectedProvider.fields[i + 1] : undefined;
      if (f1.type.startsWith('LONG_') || f1.type.startsWith('CHECKBOX')) {
        rows.push([f1]);
        i += 1;
      } else if (f2 && !f2.type.startsWith('LONG_')) {
        rows.push([f1, f2]);
        i += 2;
      } else {
        rows.push([f1]);
        i += 1;
      }
    }

    return (
      <>
        {rows.map((pair, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row gutter={100} key={`account-details-row-${index}`}>
            <Col span={pair.length > 1 ? 10 : 20}>
              {pair[0] && renderInput(pair[0], pair.length === 1)}
            </Col>
            {pair.length > 1 && (
              <Col span={10}>
                {pair[1] && renderInput(pair[1], false)}
              </Col>
            )}
          </Row>
        ))}
      </>
    );
  };

  return (
    <>
      {selectedProvider && (renderForm())}
    </>
  );
}

TelematicAccountDetailsSection.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    providerId: PropTypes.string,
    displayName: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string,
      i18nKey: PropTypes.string,
      type: PropTypes.string,
    })),
    assistance: PropTypes.shape({
      noAssistance: PropTypes.bool,
    }),
  }),
  isDetailsDisabled: PropTypes.bool,
  creationView: PropTypes.bool,
};

TelematicAccountDetailsSection.defaultProps = {
  selectedProvider: undefined,
  isDetailsDisabled: false,
  creationView: true,
};
