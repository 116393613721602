import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';
import store from './store/reducers';
import theme from './theme';
import CoreLayout from './modules/CoreLayout';
import LoadingBarContainer from './components/Structure/LoadingBar';
import config from './config';

if (config.gtm.id) {
  TagManager.initialize({
    gtmId: config.gtm.id,
  });
}

export default () => {
  if (config.gtm.id) {
    window.dataLayer.push({
      event: 'pageview',
    });
  }
  return (
    <ThroughProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <LoadingBarContainer
              style={{ backgroundColor: theme.color.primary }}
            />
            <CoreLayout theme={theme.isDark ? 'dark' : 'light'} />
          </HashRouter>
        </ThemeProvider>
      </Provider>
    </ThroughProvider>
  );
};
