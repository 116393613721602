export const SET_SHARING_PAGINATION = 'SET_SHARING_PAGINATION';
export const RESET_SHARING_PAGINATION = 'RESET_SHARING_PAGINATION';

export const setSharingsPagination = ({ page, size }) => ({
  type: SET_SHARING_PAGINATION,
  payload: {
    page,
    size,
  },
});

export const resetSharingsPagination = () => ({
  type: RESET_SHARING_PAGINATION,
});
