import styled from 'styled-components';
import { Form } from 'antd';

export const FormItem = styled(Form.Item)`
  width: 338px;
  .ant{
    &-picker {
      width: 100%;
    }
  }
`;
