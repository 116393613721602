import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Skeleton } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DetailsSection = styled(Descriptions)`
  .ant {
    &-descriptions-item-label {
      width: 33%;
    }
  }
;`;

const Detail = styled(Descriptions.Item)`
`;

const DetailsDescriptions = ({ detailsSize, detailsConfig }) => {
  const { t } = useTranslation();

  function prepareDetails(detail) {
    return (
      <Detail
        label={t(detail.title)}
        className={detail.bolded ? 'bolded' : ''}
        key={detail.title}
      >
        {detail.loading()
          ? (
            <Skeleton.Input
              active
              size={detailsSize}
            />
          ) : detail.renderDetail()}

      </Detail>
    );
  }

  const renderDetails = (detailsSection) => detailsSection
    .map((detail) => prepareDetails(detail));

  return (
    <DetailsSection
      bordered
      size={detailsSize}
      column={1}
    >
      {renderDetails(detailsConfig)}
    </DetailsSection>
  );
};

DetailsDescriptions.propTypes = {
  detailsSize: PropTypes.oneOf(['small', 'medium', 'large']),
  detailsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      loading: PropTypes.func.isRequired,
      renderDetail: PropTypes.func.isRequired,
      bolded: PropTypes.bool,
    }),
  ).isRequired,
};

DetailsDescriptions.defaultProps = {
  detailsSize: 'small',
};

export default DetailsDescriptions;
