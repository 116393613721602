import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { StyledCard } from '../../../../components';
import RequestDetailsDescription from './RequestDetailsDescription';
import OrderDetailsDescription from './OrderDetailsDescription';
import { CardHandler } from '../../compontents/RequestComponent';
import MonitoringDetailsDescription from './MonitoringDetailsDescription';
import { isPending } from '../../../../utils/monitoringRequests';

const RequestDetailsPane = ({ requestDetails, onDeactivate }) => {
  const { t } = useTranslation();

  const deactivatedButton = () => (
    <Button
      type="default"
      size="small"
      onClick={onDeactivate}
      icon={<StopOutlined />}
    >
      {t('REQUEST.DEACTIVATE')}
    </Button>
  );

  return (
    <StyledCard
      title={t('REQUEST.DETAILS')}
      extra={isPending(requestDetails)
        ? deactivatedButton()
        : null}
    >
      <CardHandler>
        <RequestDetailsDescription requestDetails={requestDetails} />
      </CardHandler>
      <CardHandler>
        <OrderDetailsDescription requestDetails={requestDetails} />
      </CardHandler>
      <MonitoringDetailsDescription requestDetails={requestDetails} />
    </StyledCard>
  );
};

RequestDetailsPane.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default RequestDetailsPane;
