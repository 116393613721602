import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { useTranslation } from 'react-i18next';
import { getCleanDuration } from '../../../utils/dateTimeUtils';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, MomentPropTypes.momentDurationObj]),
  humanize: PropTypes.bool,
};

const defaultProps = {
  value: null,
  humanize: false,
};

const Duration = ({ value, humanize }) => {
  const { t } = useTranslation();
  const [formatted, setFormatted] = useState(null);

  useEffect(() => {
    if (value) {
      const duration = getCleanDuration(value);
      if (humanize) {
        setFormatted(duration.humanize());
      } else {
        const asMinutes = Math.floor(duration.asMinutes());
        const minutes = asMinutes % 60;
        const hours = (asMinutes - minutes) / 60;
        setFormatted(`${hours}h ${minutes}min`);
      }
    }
  }, [value, humanize]);

  return (<span>{formatted || t('COMMON.MISSING')}</span>);
};

Duration.propTypes = propTypes;
Duration.defaultProps = defaultProps;

export default Duration;
