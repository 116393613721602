import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { uuidv4 } from '../../../utils/uuid';

export const GET_GEOFENCING_INFO = axiosActionsFactory('GET_GEOFENCING_INFO');
export const GEOFENCING_REQUESTED = 'GEOFENCING_REQUESTED';

export const geofencingRequested = (stopId) => (dispatch) => dispatch({
  type: GEOFENCING_REQUESTED,
  payload: {
    actionId: uuidv4(),
    stopId,
  },
});

export const geofencing = (orderId, contextCompanyId) => (dispatch) => dispatch({
  type: GET_GEOFENCING_INFO.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.geofencing(orderId),
      params: {
        contextCompanyId,
      },
    },
  },
});
