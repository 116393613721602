import i18n from 'i18next';
import H from '@here/maps-api-for-javascript';
import { fullscreenIcon } from '../assets/icons';
import { resizeMap } from './utils';

// eslint-disable-next-line func-names
export default function (ui) {
  const myCustomControl = new H.ui.Control();
  myCustomControl.addClass('customControl');
  ui.addControl('myCustomControl', myCustomControl);
  myCustomControl.setAlignment(H.ui.LayoutAlignment.RIGHT_TOP);

  const fullscreenButtonPanel = new H.ui.base.OverlayPanel();
  fullscreenButtonPanel.addClass('H_panel_fullscreen');
  myCustomControl.addChild(fullscreenButtonPanel);

  // store original styles
  const mapDiv = ui.getMap().getElement();
  let divStyle = mapDiv.style;
  if (mapDiv.runtimeStyle) {
    divStyle = mapDiv.runtimeStyle;
  }
  const originalPos = divStyle.position;
  let originalWidth = divStyle.width;
  let originalHeight = divStyle.height;
  // ie8 hack
  if (originalWidth === '') {
    originalWidth = mapDiv.style.width;
  }
  if (originalHeight === '') {
    originalHeight = mapDiv.style.height;
  }
  const originalTop = divStyle.top;
  const originalLeft = divStyle.left;
  const originalZIndex = divStyle.zIndex;
  let bodyStyle = document.body.style;
  if (document.body.runtimeStyle) {
    bodyStyle = document.body.runtimeStyle;
  }
  const originalOverflow = bodyStyle.overflow;

  const fullscreenButton = new H.ui.base.PushButton({
    label: fullscreenIcon(i18n.t('COMMON.MAP.FULLSCREEN')),
    onStateChange(evt) {
      if (evt.currentTarget.getState() === 'down') {
        mapDiv.style.position = 'fixed';
        mapDiv.style.width = '100%';
        mapDiv.style.height = '100%';
        mapDiv.style.top = '0';
        mapDiv.style.left = '0';
        mapDiv.style.zIndex = '100';
        document.body.style.overflow = 'hidden';
      } else {
        // exit full screen
        if (originalPos === '') {
          mapDiv.style.position = 'relative';
        } else {
          mapDiv.style.position = originalPos;
        }
        mapDiv.style.width = originalWidth;
        mapDiv.style.height = originalHeight;
        mapDiv.style.top = originalTop;
        mapDiv.style.left = originalLeft;
        mapDiv.style.zIndex = originalZIndex;
        document.body.style.overflow = originalOverflow;
      }
      resizeMap(ui);
    },
  });
  fullscreenButton.addClass('H_panel_fullscreen_button');
  fullscreenButtonPanel.addChild(fullscreenButton);
  fullscreenButtonPanel.setState(H.ui.base.OverlayPanel.State.OPEN);
}
