import {
  FETCH_FEATURE_TOGGLES,
  FETCH_ME,
  RESET_COMPANY_CONTEXT,
} from '../../actions';

const initialState = {
  fetched: false,
  toggles: [],
};
export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_FEATURE_TOGGLES.START:
      return { ...state, actionId: payload.actionId };
    case FETCH_FEATURE_TOGGLES.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId || !payload.data) {
        return state;
      }
      return { fetched: true, flags: [...payload.data.enabledFeatures] };
    case RESET_COMPANY_CONTEXT:
      return initialState;
    case FETCH_ME.FAIL:
      return { fetched: true, toggles: [], error: true };
    case FETCH_FEATURE_TOGGLES.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId || !payload?.data) {
        return state;
      }
      return { fetched: true, toggles: [] };
    default:
      return state;
  }
};
