import React from 'react';
import {
  Col, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* identical to box height */
  text-align: center;

  /* Gray 1 */
  color: #333333;
`;

const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  /* Gray 1 */
  color: #333333;
`;

const HelpText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;

  /* Gray 1 */
  color: #808080
`;

const SuccessScreenInvitation = () => {
  const { t } = useTranslation();

  const existingUserDescription = (
    <>
      <Row justify="center">
        <Col span={20}>
          <Description style={{ marginBottom: '0px' }}>
            {t('SUCCESS_MODAL_INVITATION.EXISTING_USER_DESCRIPTION_1')}
          </Description>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Description>
            {t('SUCCESS_MODAL_INVITATION.EXISTING_USER_DESCRIPTION_2')}
          </Description>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <div style={{
        backgroundImage: 'url(./images/Vector.svg)',
        backgroundRepeat: 'no-repeat',
      }}
      >
        <Row style={{ height: '56px' }} />
        <Row>
          <Col span={22}>
            <Row justify="center">
              <img
                src="./images/undraw_data_processing_yrrv.svg"
                alt="tick icon"
              />
            </Row>
          </Col>
          <Col span={2} />
        </Row>
        <Row style={{ height: '34px' }} />
        <Row justify="center">
          <HeaderTitle>{t('SUCCESS_MODAL_INVITATION.HEADER')}</HeaderTitle>
        </Row>
        {existingUserDescription}
        <HelpText>
          {t('SUCCESS_MODAL_INVITATION.HELP')}
          {' '}
          <a href={`mailto: ${t('SUCCESS_MODAL_INVITATION.EMAIL')}`}>{t('SUCCESS_MODAL_INVITATION.EMAIL')}</a>
        </HelpText>
        <Row style={{ height: '37px' }} />
      </div>
    </>
  );
};

SuccessScreenInvitation.propTypes = {};

SuccessScreenInvitation.defaultProps = {};

export default SuccessScreenInvitation;
