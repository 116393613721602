import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MapWrapper } from '../../../../components';
import VehiclesMapManager from './VehiclesMapManager';
import {
  closeLastTraceMode, fetchLocation, fetchLastTrace, fetchVehicleDetails, openLastTraceMode, FeatureToggle,
} from '../../../../store/actions';
import {
  isFeatureEnabled, getLastTraceMode, getSelectedVehicle, getVehiclesData, getVehiclesFilters,
} from '../../../../store/selectors';
import VehicleService from '../../../../services/VehicleService';

const VehiclesMap = ({ viewportHeight, tooglePropableRoute }) => {
  const [mapManager, setMapManager] = useState(null);
  const [lastSelectedVehicle, setLastSelectedVehicle] = useState(null);
  const selectedVehicle = useSelector(getSelectedVehicle);
  const lastTraceMode = useSelector(getLastTraceMode);
  const { majorReload, data } = useSelector(getVehiclesData);
  const filters = useSelector(getVehiclesFilters);
  const isLastTraceEnabled = useSelector(isFeatureEnabled(FeatureToggle.SHOW_LAST_TRACE));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getFetchLastTraceFunction = useCallback(
    () => (isLastTraceEnabled ? (vehicleId) => Promise.all([
      dispatch(fetchLastTrace(vehicleId)),
      dispatch(fetchVehicleDetails(vehicleId)),
    ]) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  useEffect(() => {
    if (mapManager) {
      mapManager.togleHistoricRoute(tooglePropableRoute);
    }
  }, [mapManager, tooglePropableRoute]);

  useEffect(() => {
    const refresh = () => {
      window.location.reload(true);
    };

    const warnInfo = (
      <>
        {t('COMMON.WARN_DRAW_ROUTE')}
        <Button size="small" type="link" onClick={() => refresh()}>{t('COMMON.REFRESH_DATA')}</Button>
      </>
    );

    function drawMap() {
      return new VehiclesMapManager({
        refId: 'vehicles-map',
        t,
        fetchLocation: (vehicleId, coords) => dispatch(fetchLocation(vehicleId, coords)),
        lastTraceMode: {
          fetch: getFetchLastTraceFunction(dispatch),
          onApply: (id) => dispatch(openLastTraceMode(id)),
          onDispose: () => dispatch(closeLastTraceMode()),
        },
        warnInfoContent: warnInfo,
      });
    }

    if (!t || !dispatch) {
      return;
    }

    const manager = drawMap();
    manager.addCenterAtButton();
    setMapManager(manager);
  }, [t, dispatch, getFetchLastTraceFunction]);

  useEffect(() => {
    if (mapManager) {
      const filteredVehicles = VehicleService.filterVehicles(data.vehicles, filters);
      mapManager.dawClusteredVehiclesMarkers(filteredVehicles);
    }
  }, [data, mapManager, filters]);

  useEffect(() => {
    if (mapManager && majorReload) {
      mapManager.resetCentering();
    }
  }, [majorReload, mapManager]);

  useEffect(() => {
    if (!mapManager || !selectedVehicle) {
      return;
    }

    if ((selectedVehicle !== lastSelectedVehicle) && selectedVehicle.findOnMap) {
      if (!selectedVehicle.lastPosition.longitude || !selectedVehicle.lastPosition.longitude) {
        message.info(t('VEHICLE.NO_LAST_LOCATION', { vehicle: selectedVehicle.licencePlateNumber }));
      } else {
        mapManager.closeLastTrace();
        mapManager.selectMarker(selectedVehicle);
      }
      setLastSelectedVehicle(selectedVehicle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle, mapManager, t]);

  useEffect(() => {
    if (mapManager && lastTraceMode === null) {
      mapManager.closeLastTrace();
    }
  }, [mapManager, lastTraceMode]);

  return (
    <MapWrapper viewportHeight={viewportHeight} id="vehicles-map" />
  );
};

VehiclesMap.propTypes = {
  viewportHeight: PropTypes.number,
  tooglePropableRoute: PropTypes.bool.isRequired,
};

VehiclesMap.defaultProps = {
  viewportHeight: null,
};

export default VehiclesMap;
