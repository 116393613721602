import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';
import { StopTimeStatus, StopTimeStatusDisplay } from '../OrderDetails/StopTimeStatusLabel';
import { routePointPropTypes } from '../../../types';
import { OrderStatusPropTypes, OrderStatus } from '../../../types/enums/orderType.enum';
import { ERROR_CODE } from '../../../utils/apiError';

const FirstRoutePoint = ({
  order, routePoint, fetchRoutePoints,
}) => {
  const { t } = useTranslation();

  const { firstStop, status: orderStatus, trackingToleranceWindowInMinutes } = order;
  const stopActualArrival = firstStop && firstStop.actualArrival;
  const routePointActualArrival = routePoint && routePoint.actualArrival;
  const actualArrival = stopActualArrival || routePointActualArrival;
  const actualArrivalTime = actualArrival && actualArrival.time;
  const actualArrivalStatus = actualArrival && actualArrival.status;

  const estimatedArrival = routePoint && routePoint.estimatedArrival;
  const estimatedArrivalTime = estimatedArrival && estimatedArrival.time;
  const estimatedArrivalDuration = estimatedArrival && estimatedArrival.duration;
  const estimatedArrivalLength = estimatedArrival && estimatedArrival.length;
  const estimatedArrivalStatus = (() => {
    if (estimatedArrivalTime && firstStop) {
      return estimatedArrivalTime <= firstStop.timeSlot.end ? StopTimeStatus.ON_TIME : StopTimeStatus.TOO_LATE;
    }
    return null;
  })();
  const pointLoading = routePoint && routePoint.loading;

  const onClick = () => fetchRoutePoints(order.id, 'FIRST');

  const renderRefreshButton = (
    <Button
      size="small"
      style={{ padding: 0, margin: 0 }}
      type="link"
      onClick={onClick}
      icon={<SyncOutlined />}
      loading={pointLoading}
      disabled={pointLoading}
    />
  );

  const pointError = routePoint && routePoint.error;

  const renderNotAvailable = () => {
    if (pointLoading) {
      return (t('ORDER.ROUTE_POINT.CALCULATE.START'));
    }
    if (pointError === ERROR_CODE.ROUTE_POINT_UNABLE_TO_CALCULATE) {
      return (
        <Row>
          <Col>
            <Row>
              {t('ORDER.ROUTE_POINT.CALCULATE.FAIL')}
            </Row>
            <Row justify="end">
              {renderRefreshButton}
            </Row>
          </Col>
        </Row>
      );
    }
    if (pointError === ERROR_CODE.ROUTE_POINT_NOT_AVAILABLE_YET) {
      return (
        <Row>
          <Col>
            <Row>
              {t('ORDER.ROUTE_POINT.CALCULATE.WILL_BE_AVAILABLE', { minutes: trackingToleranceWindowInMinutes.before })}
            </Row>
            <Row justify="end">
              {renderRefreshButton}
            </Row>
          </Col>
        </Row>
      );
    }
    if (actualArrivalTime === -1 && orderStatus === OrderStatus.EXPIRED) {
      return (t('ORDER.ROUTE_POINT.NOT_REACHED'));
    }
    return (t('ORDER.ROUTE_POINT.NOT_AVAILABLE'));
  };

  const renderActualArrival = actualArrivalTime !== -1 ? (
    <>
      <span>
        {moment.unix(actualArrivalTime).format(DEFAULT_DATE_TIME)}
      </span>
      <br />
      {actualArrivalStatus && (
        <StopTimeStatusDisplay status={actualArrivalStatus}>
          {t(`ORDER.STOP_TIME_STATUS.${actualArrivalStatus}`)}
        </StopTimeStatusDisplay>
      )}
    </>
  ) : renderNotAvailable();

  const viewTimeToEnd = (seconds) => {
    let totalSeconds = seconds;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    return hours === 0 ? `${minutes}min` : `${hours}h ${minutes}min`;
  };

  const viewLengthToEndInKm = (meters) => `${Math.floor(meters / 1000)}km left`;

  const renderEstimatedArrival = estimatedArrivalTime && estimatedArrivalTime !== -1 ? (
    <>
      <span>
        {moment.unix(estimatedArrivalTime).format(DEFAULT_DATE_TIME)}
      </span>
      <br />
      <span style={{ opacity: 0.6 }}>
        {`${viewTimeToEnd(estimatedArrivalDuration)}, ${viewLengthToEndInKm(estimatedArrivalLength)}`}
      </span>
      <br />
      <Row>
        <Col xs={12}>
          {estimatedArrivalStatus && (
            <StopTimeStatusDisplay status={estimatedArrivalStatus}>
              {t(`ORDER.STOP_TIME_STATUS.${estimatedArrivalStatus}`)}
            </StopTimeStatusDisplay>
          )}
        </Col>
        <Col xs={12}>
          <Row justify="end">
            {renderRefreshButton}
          </Row>
        </Col>
      </Row>
    </>
  ) : renderNotAvailable();

  return actualArrival ? renderActualArrival : renderEstimatedArrival;
};

FirstRoutePoint.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    status: OrderStatusPropTypes,
    firstStop: PropTypes.shape({
      id: PropTypes.string,
      actualArrival: PropTypes.shape({
        time: PropTypes.number,
        status: PropTypes.string,
      }),
      status: PropTypes.string,
    }),
    trackingToleranceWindowInMinutes: PropTypes.shape({
      before: PropTypes.number,
    }),
  }),
  routePoint: routePointPropTypes,
  fetchRoutePoints: PropTypes.func,
};

FirstRoutePoint.defaultProps = {
};

export default FirstRoutePoint;
