import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput, FormItem, HStretched } from '../../../components';

const OrderDetailsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HStretched _justify="flex-start">
        <FormItem
          label={t('ORDER.ORDER_NUMBER')}
          name="internalId"
          rules={[{ required: true, message: t('ORDER.TRANSPORT_ORDER_NUMBER_MISSING') },
            { type: 'string', max: 50, message: t('COMMON.VALIDATION.MAX_50') },
            {
              pattern: new RegExp('^[a-zA-Z0-9-_]+$'),
              type: 'string',
              message: t('ORDER.TRANSPORT_ORDER_NUMBER_INVALID_CHARACTERS'),
            }]}
        >
          <FormInput placeholder={t('ORDER.TRANSPORT_ORDER_NUMBER_PROMPT')} />
        </FormItem>
        <FormItem
          label={t('ORDER.ADDITIONAL_INFO')}
          name="shipperName"
        >
          <FormInput placeholder={t('ORDER.ADDITIONAL_INFO_PROMPT')} />
        </FormItem>
      </HStretched>
    </>
  );
};

export default OrderDetailsSection;
