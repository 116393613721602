import H from '@here/maps-api-for-javascript';

const FIXED_ZOOM = 1;
const DEFAULT_TOLERANCE = 0.00001;

function isSameCoordinate(a, b, tolerance = DEFAULT_TOLERANCE) {
  return Math.abs(a - b) < tolerance;
}

function computeDifference(start, end) {
  if (isSameCoordinate(start, end)) {
    return FIXED_ZOOM;
  }
  return Math.abs(start - end);
}

export const scaleRect = (rect, scale = 0.2) => {
  if (!rect) {
    return rect;
  }
  const horizontalDifference = computeDifference(rect.getLeft(), rect.getRight());
  const verticalDifference = computeDifference(rect.getBottom(), rect.getTop());
  return new H.geo.Rect(
    rect.getTop() + verticalDifference * scale,
    rect.getLeft() - horizontalDifference * scale,
    rect.getBottom() - verticalDifference * scale,
    rect.getRight() + horizontalDifference * scale,
  );
};

export const findBoundingBox = (points) => {
  if (!points || !points.length || !points[0]) {
    return new H.geo.Rect(0, 0, 0, 0);
  }
  return H.geo.Rect.coverPoints(points);
};
