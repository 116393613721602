/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import { fetchEmailShares } from '../../store/actions/emailsharing';

class MyProfileComponent extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <div>My profile</div>
    );
  }
}

const actionCreators = {
  fetchShares: fetchEmailShares,
};

export default connect(null, actionCreators)(MyProfileComponent);
