/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint max-len:0 */
import React from 'react';
import PropTypes from 'prop-types';
import StopIndexTag from './StopIconTag';

const UnknownIndexedIcon = ({ index, onClick, style }) => (
  <div onClick={onClick} style={{ ...style, cursor: onClick ? 'pointer' : 'default' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">
      <circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />
      <circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />
      <g id="g840" transform="translate(-5,-3.7582835)">
        <path d="" stroke="#ffffff" id="path7" />
        <path d="" fill="#ffffff" id="path9" />
      </g>
      <StopIndexTag index={index} />
    </svg>
  </div>
);

UnknownIndexedIcon.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
};

UnknownIndexedIcon.defaultProps = {
  onClick: null,
  style: {},
};

export default UnknownIndexedIcon;
