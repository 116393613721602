import UrlService from '../../../services/UrlService';
import axiosActions, { extractApiFilters } from '../axiosHelpers';
import { OrderContext } from '../../../types';
import {
  getOrdersFilters, getOrdersTypePagination, getOrdersTypeSorting, getSelectedCompany,
} from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';
import { FILTERS } from '../../../modules/Orders/Components/OrdersTable.constants';

export const FETCH_IN_ORDERS = axiosActions('ORDERS_IN_LOAD');
export const FETCH_OUT_ORDERS = axiosActions('ORDERS_OUT_LOAD');
export const FETCH_DEFAULT_ORDERS = axiosActions('ORDERS_LOAD');
export const CLEAR_ORDERS = 'ORDERS_CLEAR';

export const FETCH_ORDERS = {
  [OrderContext.IN]: FETCH_IN_ORDERS,
  [OrderContext.OUT]: FETCH_OUT_ORDERS,
  [OrderContext.UNKNOWN]: FETCH_DEFAULT_ORDERS,
};

const buildSearch = ({ pagination, filters, sorting }) => {
  const search = { page: pagination.page - 1, size: pagination.size };
  if (sorting && sorting.order && sorting.field) {
    search.sortBy = sorting.field;
    search.sortDirection = sorting.order === 'ascend' ? 'ASC' : 'DESC';
  }
  const apiFilters = extractApiFilters(filters, FILTERS, () => true);
  search.filter = apiFilters;

  return search;
};

const executeFetchOrders = (orderType, params, data, executeInBackground) => ({
  type: FETCH_ORDERS[orderType].START,
  payload: {
    actionId: uuidv4(),
    executeInBackground,
    request: {
      method: 'post',
      url: UrlService.ordersByTypeSearch(orderType),
      params,
      data,
    },
  },
});

export const fetchOrders = (executeInBackground = false, orderType, type) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  const pagination = getOrdersTypePagination(state, orderType);
  const filters = getOrdersFilters(orderType)(state);
  const sorting = getOrdersTypeSorting(state, orderType);
  const search = buildSearch({
    pagination, filters, sorting,
  });
  search.filter.type = type.toUpperCase();

  dispatch(executeFetchOrders(orderType, { contextCompanyId }, search, executeInBackground));
};

export const clearOrders = () => ({
  type: CLEAR_ORDERS,
});
