import { OrderContext } from '../../../types';
import { SET_ORDERS_SORTING } from '../../actions';

const INIT_SORTING = {
  order: 'descend',
  field: 'startDate',
};

export default (orderType = OrderContext.UNKNOWN) => (state = { ...INIT_SORTING }, { type, payload }) => {
  switch (type) {
    case SET_ORDERS_SORTING[orderType]:
      return { ...payload };
    default:
      return state;
  }
};
