import { QueryMapper } from '../../../../store/actions/axiosHelpers';

export const ShareDirection = {
  IN: 'IN',
  OUT: 'OUT',
};

export const SharedByCompany = {
  ALL: 'ALL',
  MINE: 'MINE',
};

export const FILTERS = {
  SIDE: {
    name: 'SIDE',
    apiProperty: 'side',
    queryMapper: QueryMapper.takeFirst,
  },
  SHARED_BY_COMPANY: {
    name: 'SHARED_BY_COMPANY',
    apiProperty: 'sharedByCompany',
    queryMapper: [QueryMapper.takeFirst, (value) => (value === SharedByCompany.ALL)],
  },
  VEHICLE_ID: {
    name: 'VEHICLE_ID',
    inTableFilter: (record, filterValue) => {
      const value = record.vehicle.licencePlateNumber;
      const filter = filterValue && filterValue.length && filterValue[0];
      return !filter || !value || value.toLowerCase().includes(filter.toLowerCase());
    },
  },
  STATUS: {
    name: 'STATUS',
    inTableFilter: (record, filterValue) => {
      const value = record.ownershipType;
      return !filterValue || !value || filterValue.includes(value);
    },
  },
  COMPANY_NAME: {
    name: 'COMPANY_NAME',
    inTableFilter: (record, filterValue) => {
      const { name } = record.company;
      const filter = filterValue && filterValue.length && filterValue[0] && filterValue[0].toLowerCase();
      return !filter || (!name || name.toLowerCase().includes(filter));
    },
  },
  START_DATE: {
    name: 'START_DATE',
    apiProperty: 'dateFrom',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toTimestamp],
  },
  END_DATE: {
    name: 'END_DATE',
    apiProperty: 'dateTo',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toTimestamp],
  },
  ACTIVE_STATUS: {
    name: 'ACTIVE_STATUS',
    apiProperty: 'activeStatuses',
  },
};

export const statusOptions = [
  { label: 'DICTIONARY.STATUS.OWNER', value: 'OWNER' },
  { label: 'DICTIONARY.STATUS.LEASE', value: 'LEASE' },
  { label: 'DICTIONARY.STATUS.SHARING', value: 'SHARING' },
];

export const activeStatusOptions = [
  { label: 'DICTIONARY.ACTIVE_STATUS.FUTURE', value: 'FUTURE' },
  { label: 'DICTIONARY.ACTIVE_STATUS.ONGOING', value: 'ONGOING' },
  { label: 'DICTIONARY.ACTIVE_STATUS.EXPIRED', value: 'EXPIRED' },
];

export const API_FILTERS = [
  FILTERS.END_DATE.name,
  FILTERS.START_DATE.name,
  FILTERS.SIDE.name,
  FILTERS.SHARED_BY_COMPANY.name,
  FILTERS.ACTIVE_STATUS.name,
];

export const SKIPPED_FILTERS = [FILTERS.SIDE.name, FILTERS.SHARED_BY_COMPANY.name];

export const isApiFilter = (filter) => API_FILTERS.includes(filter);
