import moment from 'moment';
import React from 'react';
import { StopDetailLabel, StopDetailLine, StopDetailLineMinor } from '../Styled/Containers/ConfirmationDialog';
import { DEFAULT_DATE_TIME } from '../../utils/constants/timeFormats';
import { getStopType } from '../../types/mappers';

export const renderRequestPlace = (stop) => (
  <>
    <StopDetailLine>
      {stop.address.countryCode}
      {', '}
      {stop.address.postcode}
      {' '}
      {stop.address.city}
    </StopDetailLine>
    <StopDetailLineMinor>
      {stop.address.street}
      {' '}
      {stop.address.houseNumber}
      {' '}
      {stop.address.localNumber || ''}
    </StopDetailLineMinor>
  </>
);

export const renderOrderPlace = (stop) => (
  <>
    <StopDetailLine>
      {stop.place.country}
      {', '}
      {stop.place.postcode}
      {' '}
      {stop.place.city}
    </StopDetailLine>
    <StopDetailLineMinor>
      {stop.place.street}
      {' '}
      {stop.place.houseNumber}
      {' '}
      {stop.place.localNumber || ''}
    </StopDetailLineMinor>
    <StopDetailLine>
      {stop.name}
    </StopDetailLine>
    <StopDetailLine>
      {stop.additionalInfo}
    </StopDetailLine>
  </>
);

export const renderTimeSlot = (t, startDatetime, endDatetime) => (
  <>
    <StopDetailLine>
      <StopDetailLabel width="40">{`${t('PROPERTIES.FROM')}:`}</StopDetailLabel>
      {moment.unix(startDatetime).format(DEFAULT_DATE_TIME)}
    </StopDetailLine>
    <StopDetailLine>
      <StopDetailLabel width="40">{`${t('PROPERTIES.TO')}:`}</StopDetailLabel>
      {moment.unix(endDatetime).format(DEFAULT_DATE_TIME)}
    </StopDetailLine>
  </>
);

export const renderRequestStopType = (t, stop) => t(`REQUEST.STOP_TYPES.${stop.stopType}`);

export const renderOrderStopType = (t, stop) => t(`ORDER.STOP_TYPES.${getStopType(stop)}`);
