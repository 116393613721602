import styled from 'styled-components';
import { Table } from 'antd';
import { Svg } from '../Icons/OwnerIcon';

export const StyledTable = styled(Table)`
  margin: 10px 0;
  max-width: 100%;
  .ant {
    &-table {
      margin-bottom: 10px;
        
      &-tbody {
        background-color: ${({ theme }) => theme.color.backgroundLight1};
        margin: 10px 0;
        
        & > .ant-table-row {
          &-nohover {
            &:hover {
              &.ant-table-row-highlighted {
                & > td, .ant-table-column-sort {
                  background-color: ${({ theme }) => theme.color.highlight};
                }
              }
              & > td {
                background-color: ${({ theme }) => theme.color.backgroundLight1};
              }
              .ant-table-column-sort {
                background-color: ${({ theme }) => theme.color.defaultLight};
              }
            }
          }
          &-highlighted {
            background-color: ${({ theme }) => theme.color.highlight};
            .ant-table-column-sort {
              background-color: ${({ theme }) => theme.color.highlight};
            }
            &:hover:not(.ant-table-row-nohover) {
              & > td, .ant-table-column-sort {
                background-color: ${({ theme }) => theme.color.highlightHover};
              }
            }
          }
        }
      }
      &-pagination.ant-pagination {
        float: right;
        margin: 0 0 10px 0 ;
      }
      &-cell {
        &>.anticon {
          font-size: 24px;
        }
        &-centered {
          text-align: center;
          .ant-btn {
           text-align: center;
          }
          .ant-tag-single {
            margin: 0;
          }
        }
        &-actions {
          padding: 8px 4px !important;
          
          .ant-btn {
            width: 26px;
          }
        }
      }
      &-column-sorters {
        padding: 0 16px 0 0;
      }
      &-filter {
        &-column-title {
          padding-left: 8px;
        }
        &-trigger {
         color: ${({ theme }) => theme.color.darkgray1};
          &.active {
            color: ${({ theme }) => theme.color.backgroundLight3};
            background-color: ${({ theme }) => theme.color.primary};
          }
        }
      }
      &-row{
        &-selectable {
           cursor: pointer;
        }
        &-inactive {
          color: ${({ theme }) => theme.color.inactive};
          ${Svg} {
            fill: ${({ theme }) => theme.color.inactive};            
          }
        }
        &-not-selectable {
          cursor: not-allowed;
          color: ${({ theme }) => theme.color.inactive};
          ${Svg} {
            fill: ${({ theme }) => theme.color.inactive};            
          }
        }
      }
    }
  }
  
  tr > th {
    background-color: ${({ theme }) => theme.color.backgroundLight3} !important;
  }
  
  tr > th, tr > td, tr > .ant-table-cell-fix-right-first::after {
    border-right-color: ${({ theme }) => theme.color.backgroundLight4} !important;
  }
  
  tr > th, tr:not(:last-child) > td {
    border-bottom-color: ${({ theme }) => theme.color.backgroundLight4} !important;
  }
`;

export const EmptyStyledTable = styled(Table)`
  margin: 10px 0;
  max-width: 100%;
  min-width: 100%;
  .ant {
    &-table {
      margin-bottom: 10px;
        
      &-tbody {
        background-color: ${({ theme }) => theme.color.backgroundLight2};
        margin: 10px 0;
        
        & > .ant-table-row {
          &-nohover {
            &:hover {
              &.ant-table-row-highlighted {
                & > td, .ant-table-column-sort {
                  background-color: ${({ theme }) => theme.color.highlight};
                }
              }
              & > td {
                background-color: ${({ theme }) => theme.color.backgroundLight1};
              }
              .ant-table-column-sort {
                background-color: ${({ theme }) => theme.color.defaultLight};
              }
            }
          }
          &-highlighted {
            background-color: ${({ theme }) => theme.color.highlight};
            .ant-table-column-sort {
              background-color: ${({ theme }) => theme.color.highlight};
            }
            &:hover:not(.ant-table-row-nohover) {
              & > td, .ant-table-column-sort {
                background-color: ${({ theme }) => theme.color.highlightHover};
              }
            }
          }
        }
      }
      &-pagination.ant-pagination {
        float: right;
        margin: 0 0 10px 0 ;
      }
      &-cell {
        &>.anticon {
          font-size: 24px;
        }
        &-centered {
          text-align: center;
          .ant-btn {
           text-align: center;
          }
          .ant-tag-single {
            margin: 0;
          }
        }
        &-actions {
          padding: 8px 4px !important;
          
          .ant-btn {
            width: 26px;
          }
        }
      }
      &-column-sorters {
        padding: 0 16px 0;
      }
      &-filter {
        &-column-title {
          padding-left: 8px;
        }
        &-trigger {
         color: ${({ theme }) => theme.color.darkgray1};
          &.active {
            color: ${({ theme }) => theme.color.backgroundLight3};
            background-color: ${({ theme }) => theme.color.primary};
          }
        }
      }
      &-row{
        &-selectable {
           cursor: pointer;
        }
        &-inactive {
          color: ${({ theme }) => theme.color.inactive};
          ${Svg} {
            fill: ${({ theme }) => theme.color.inactive};            
          }
        }
        &-not-selectable {
          cursor: not-allowed;
          color: ${({ theme }) => theme.color.inactive};
          ${Svg} {
            fill: ${({ theme }) => theme.color.inactive};            
          }
        }
      }
    }
  }
  
  tr > th {
    background-color: ${({ theme }) => theme.color.backgroundLight3} !important;
  }
  
  tr > th, tr > td, tr > .ant-table-cell-fix-right-first::after {
    border-right-color: ${({ theme }) => theme.color.backgroundLight4} !important;
  }
  
  tr > th, tr:not(:last-child) > td {
    border-bottom-color: ${({ theme }) => theme.color.backgroundLight4} !important;
  }

  .ant-table-tbody > tr > td {
    text-align: left;
    border-bottom: 0px;
    border-left: 0px;
  }
`;
