import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItem, FormSelect, HStretched } from '../../../components';

const getVehiclesPickerPlaceholder = (form, t) => (
  form.getFieldValue('carrierCompanyId') ? t('ORDER.VEHICLES_PROMPT') : t('ORDER.CARRIER_BEFORE_VEHICLE')
);

const OrderCarrierDetails = ({
  form, findAvailableVehicles, companies, companyVehicles, disableCompanies,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <HStretched _justify="flex-start">
        <FormItem
          label={t('ORDER.CARRIERS_COMPANY')}
          name="carrierCompanyId"
          rules={[{ required: true, message: t('ORDER.CARRIER_MISSING') }]}
        >
          <FormSelect
            showSearch
            placeholder={t('ORDER.CARRIER_PROMPT')}
            optionFilterProp="children"
            onSelect={findAvailableVehicles}
            loading={companies.loading}
            disabled={disableCompanies}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {companies.data.map((company) => (
              <Select.Option key={company.companyId} value={company.companyId}>
                {company.companyName}
              </Select.Option>
            ))}
          </FormSelect>
        </FormItem>
        <FormItem
          label={t('ORDER.VEHICLE')}
          name="vehicleId"
          rules={[{ required: true, message: t('ORDER.VEHICLES_MISSING') }]}
        >
          <FormSelect
            loading={companies.loading}
            showSearch
            disabled={!form.getFieldValue('carrierCompanyId')}
            placeholder={getVehiclesPickerPlaceholder(form, t)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {companyVehicles.map(({
              vehicleId, licencePlateNumber,
            }) => <Select.Option key={vehicleId} value={vehicleId}>{licencePlateNumber}</Select.Option>)}
          </FormSelect>
        </FormItem>
      </HStretched>
    </>
  );
};

OrderCarrierDetails.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
  }).isRequired,
  findAvailableVehicles: PropTypes.func.isRequired,
  companies: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
    })),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  companyVehicles: PropTypes.arrayOf(PropTypes.shape({
    vehicleId: PropTypes.string.isRequired,
    licencePlateNumber: PropTypes.string.isRequired,
  })).isRequired,
  disableCompanies: PropTypes.bool,
};

OrderCarrierDetails.defaultProps = {
  disableCompanies: false,
};

export default OrderCarrierDetails;
