import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_MONITORING_ORDER_SNAPSHOT = axiosActionsFactory('FETCH_MONITORING_ORDER_SNAPSHOT');

export const fetchMonitoringOrderShapshot = (monitoringOrderId, contextCompanyId) => (dispatch) => dispatch({
  type: FETCH_MONITORING_ORDER_SNAPSHOT.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.snapshot(monitoringOrderId),
      params: {
        contextCompanyId,
      },
    },
  },
});
