import H from '@here/maps-api-for-javascript';

export const createMapMask = ({ onTap } = {}) => {
  const maskStyle = {
    fillColor: 'rgba(255, 255, 255, 0.5)',
    lineWidth: 0,
  };

  const mask = new H.map.Rect(new H.geo.Rect(-180, -180, 180, 180), { style: maskStyle, data: { } });
  if (onTap) {
    mask.addEventListener('tap', () => {
      onTap();
    });
  }
  return mask;
};
