import {
  COMPANY_INITIALIZED, FETCH_LAST_USED_TABLE_CONFIGURATION, SET_CURRENT_TABLE_CONFIGURATION,
} from '../../actions';
import { TableName } from '../../selectors';

const initState = { company: null, fetched: false, initialized: false };
export default function selectedCompanyReducer(state = initState, { type, payload }) {
  switch (type) {
    case FETCH_LAST_USED_TABLE_CONFIGURATION.SUCCESS:
    case SET_CURRENT_TABLE_CONFIGURATION:
      if (payload && payload.data && payload.data.tableName === TableName.SelectedCompany) {
        return {
          ...state,
          company: payload.data.configurationId,
          fetched: true,
        };
      }
      return state;
    case COMPANY_INITIALIZED:
      return { ...state, initialized: true };
    default:
      return state;
  }
}
