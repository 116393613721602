import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';

export const FETCH_VEHICLE_REMARK = axiosActions('VEHICLE_REMARK_LOAD');
export const ADD_VEHICLE_REMARK = axiosActions('ADD_VEHICLE_REMARK_LOAD');
export const EDIT_VEHICLE_REMARK = axiosActions('EDIT_VEHICLE_REMARK_LOAD');
export const REMOVE_VEHICLE_REMARK = axiosActions('REMOVE_VEHICLE_REMARK_LOAD');
export const OPEN_EDIT_VEHICLE_REMARK = 'OPEN_EDIT_VEHICLE_REMARK';
export const EDIT_VEHICLE_REMARK_FORM = 'EDIT_VEHICLE_REMARK_FORM';
export const CLOSE_EDIT_VEHICLE_REMARK = 'CLOSE_EDIT_VEHICLE_REMARK';
export const fetchVehicleRemark = (vehicleId) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());

  return dispatch({
    type: FETCH_VEHICLE_REMARK.START,
    payload: {
      request: {
        url: UrlService.vehicleRemark(selectedCompany, vehicleId),
      },
    },
  });
};

export const submitAddVehicleRemark = ({ selectedVehicle, remark }) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());

  return dispatch({
    type: ADD_VEHICLE_REMARK.START,
    payload: {
      request: {
        method: 'post',
        url: UrlService.vehicleRemark(selectedCompany, selectedVehicle),
        data: {
          companyId: selectedCompany,
          vehicleId: selectedVehicle,
          remark,
        },
      },
    },
  });
};

export const submitEditVehicleRemark = ({ selectedVehicle, remark, note }) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  const {
    remarkId, companyId, vehicleId,
  } = note;

  return dispatch({
    type: EDIT_VEHICLE_REMARK.START,
    payload: {
      request: {
        method: 'put',
        url: UrlService.vehicleRemark(selectedCompany, selectedVehicle),
        data: {
          remarkId,
          companyId,
          vehicleId,
          remark,
        },
      },
    },
  });
};

export const submitRemoveVehicleRemark = (selectedVehicle) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());

  return dispatch({
    type: REMOVE_VEHICLE_REMARK.START,
    payload: {
      request: {
        method: 'delete',
        url: UrlService.vehicleRemark(selectedCompany, selectedVehicle),
      },
    },
  });
};

export const openEditVehicleRemark = () => ({
  type: OPEN_EDIT_VEHICLE_REMARK,
});

export const editVehicleRemarkForm = (editedValue) => ({
  type: EDIT_VEHICLE_REMARK_FORM,
  payload: {
    editedValue,
  },
});

export const closeEditVehicleRemark = () => ({
  type: CLOSE_EDIT_VEHICLE_REMARK,
});
