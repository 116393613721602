import { stopsByTimeSlotAndSequence } from './order.mapper';
import { OrderStatus } from '../enums/orderType.enum';

export const toOrdersTableProps = ({
  id, internalId, vehicles, shipperCompany, carrierCompany, name, startDate, endDate, creationDate,
  trackingToleranceWindowInMinutes, status, stops, actualTravelledDistanceInMeters,
}) => {
  const hasStops = stops && stops.length;
  const sortedStops = hasStops ? stops.sort(stopsByTimeSlotAndSequence) : stops;
  const firstStop = hasStops ? sortedStops[0] : null;
  const lastStop = hasStops ? sortedStops[sortedStops.length - 1] : null;
  const lastReachedStop = status === OrderStatus.EXPIRED
    ? stops.filter((stop) => stop.actualArrival
      && stop.actualArrival.time
      && stop.actualArrival.time !== -1
      && stop.status).pop()
    : null;

  return {
    id,
    internalId,
    vehicles,
    shipperCompany,
    carrierCompany,
    name,
    startDate,
    endDate,
    creationDate,
    trackingToleranceWindowInMinutes,
    status,
    stops: sortedStops,
    firstStop,
    lastStop,
    lastReachedStop,
    actualTravelledDistanceInMeters,
  };
};
