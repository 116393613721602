import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, Col, Form, Row, Switch,
} from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ToursMap from './components/map/ToursMap';
import TourStatisticsTablesComponent from './components/TourStatisticTablesComponent';
import TourChartsComponent from './components/TourChartsComponent';
import * as statisticsActions from '../../store/actions/orders/statistics.actions';
import { isSuccess } from '../../utils/responseUtils';
import { TourService } from '../../services';
import { GoBackButton, Loader } from '../../components';

const StatisticsWrapper = styled.div`
  min-width: 1755px;
`;

class TourStatisticsComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      stats: '',
      loading: true,
      byPositionTimestamp: true,
      minDistance: 500,
      active: true,
      errorLog: '',
    };
    this.fetchStats = this.fetchStats.bind(this);
    this.handleByPositionTimestampSwitchChange = this.handleByPositionTimestampSwitchChange.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
  }

  componentDidMount() {
    this.fetchStats();
  }

  handleByPositionTimestampSwitchChange(value) {
    this.setState({ byPositionTimestamp: value });
    this.fetchStats();
  }

  onBackClick() {
    const { history } = this.props;
    history.push('');
  }

  setStats(stats) {
    const { t } = this.props;
    const loaded = stats.positions.length > 0;
    if (!loaded) {
      this.setState({
        active: false,
        errorLog: { error: t('Statistics.TourStatisticsComponent.getStatsDefaultError') },
        loading: false,
      });
    } else {
      this.setState({
        stats,
        loading: false,
      });
    }
  }

  fetchStats() {
    const { fetchTourStatistics, tourId, shipperCompanyId } = this.props;
    const { byPositionTimestamp, minDistance } = this.state;
    this.setState({ loading: true });
    fetchTourStatistics(tourId, shipperCompanyId, byPositionTimestamp, minDistance).then((action) => {
      if (isSuccess(action)) {
        const stats = byPositionTimestamp
          ? action.payload.data.statisticsByPositionTimestamp
          : action.payload.data.statisticsByCoordinates;
        TourService.setTourStatistics(stats);
        this.setStats(stats);
      } else {
        this.setState({
          active: false,
          loading: false,
          errorLog: action,
        });
      }
    });
  }

  renderMap() {
    const { t } = this.props;
    return (
      <Card title={t('Statistics.TourStatisticsComponent.mapCardTitle')} style={{ width: 900 }}>
        <div style={{ height: '78vh' }}>
          <ToursMap mapUseCase="singleTourStatistics" />
        </div>
      </Card>
    );
  }

  renderTracesTypeForm() {
    const { t } = this.props;
    const { byPositionTimestamp, loading } = this.state;
    return (
      <Form layout="inline" size="default">
        <Form.Item
          className="is-carrier"
          label={t('Statistics.TourStatisticsComponent.tracesUniqueType')}
        >
          <Switch
            disabled={loading}
            size="default"
            defaultChecked={byPositionTimestamp}
            onChange={this.handleByPositionTimestampSwitchChange}
            name="byPositionTimestamp"
          />
        </Form.Item>
      </Form>
    );
  }

  renderContent() {
    const { t } = this.props;
    const {
      active, stats, errorLog,
    } = this.state;
    return (
      <div>
        {stats.tour && active
          ? (
            <Row className="app-row">
              <Col span={12} className="statsTab">
                {this.renderMap()}
              </Col>
              <Col span={12} align="center" className="statsTab">
                <div className="tablesAndCharts">
                  <TourStatisticsTablesComponent stats={stats} />
                  <TourChartsComponent />
                </div>
              </Col>
            </Row>
          )
          : (
            <div className="errorMessage">
              <h1>
                {t('Statistics.TourStatisticsComponent.error')}
                :
                {' '}
                {errorLog.message}
              </h1>
            </div>
          )}
      </div>
    );
  }

  renderHeader() {
    const { goBack, t, tourId } = this.props;

    return (
      <Row justify="space-between" align="middle">
        {goBack ? (
          <GoBackButton
            style={{ minHeight: '32px' }}
            size="medium"
            onClick={goBack}
            text="Statistics.TourStatisticsComponent.backToList"
          />
        ) : null}
        <span className="site-page-header">
          {t('Statistics.TourStatisticsComponent.pageTitle', { tourId })}
        </span>
        {this.renderTracesTypeForm()}
      </Row>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <StatisticsWrapper>
        {this.renderHeader()}
        { loading
          ? <Loader />
          : this.renderContent()}
      </StatisticsWrapper>
    );
  }
}

TourStatisticsComponent.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tourId: PropTypes.string.isRequired,
  shipperCompanyId: PropTypes.string.isRequired,
  fetchTourStatistics: PropTypes.func.isRequired,
  goBack: PropTypes.func,
};

TourStatisticsComponent.defaultProps = {
  goBack: null,
};

const mapDispatchToProps = {
  fetchTourStatistics: statisticsActions.fetchTourStatistics,
};

export default withTranslation()(
  withRouter(
    connect(null, mapDispatchToProps)(
      TourStatisticsComponent,
    ),
  ),
);
