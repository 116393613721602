import PropTypes from 'prop-types';

export const OrderStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  FUTURE: 'FUTURE',
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN',
  REQUEST_PENDING: 'REQUEST_PENDING',
  REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
  REQUEST_DECLINE: 'REQUEST_DECLINE',
  REQUEST_DEACTIVATED: 'REQUEST_DEACTIVATED',
  REQUEST_ACCEPT: 'REQUEST_ACCEPT',
  REQUEST_DECLINED: 'REQUEST_DECLINED',
};

export const OrderStatusPropTypes = PropTypes.oneOf(Object.values(OrderStatus));

export const isExpired = (orderStatus) => orderStatus === 'EXPIRED';
