export class MapEventDispatcher {
  constructor() {
    this.listeners = [];
  }

  dispatch(event) {
    this.listeners.forEach((listener) => listener.notify(event));
  }

  subscribe(listener) {
    this.listeners.push(listener);
  }

  unsibscribe(listener) {
    this.listeners = this.listeners.filter((oldListener) => oldListener !== listener);
  }
}

export const MapEventType = {
  NEW_BOUNDING: 'NEW_BOUNDING',
};
