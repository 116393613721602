import React from 'react';
import { message } from 'antd';
import ErrorMessageContent from '../../components/Structure/Header/ErrorMessageContent';
import { isError } from '../../utils/responseUtils';
import { isIgnoredErrorCode, isIgnoredErrorStatus } from '../../utils/apiError';

export default () => (next) => (action) => {
  if (isError(action)
    && action.error
    && action.error.response
    && !isIgnoredErrorCode(action.error.response.data.errorCode)
    && !isIgnoredErrorStatus(action.error.response.status)) {
    message.error({
      content: (<ErrorMessageContent
        status={action.error.response.status}
        data={action.error.response.data}
      />),
      key: 'error',
    }, 10);
  }
  next(action);
};
