import React, { useCallback, useEffect, useState } from 'react';
import {
  Row, Col, Button, Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon, { SearchOutlined, CalendarOutlined, StopOutlined } from '@ant-design/icons';
import {
  InputFilterDropdown, DateFilterDropdown, SelectFilterDropdown, StyledTable, EmptyStyledTable,
} from '../../components';
import {
  mergeUpdateInterfaceFilters, loadRequestOutgoing, clearInterfaceFiltersExcept, deactivateMonitoringRequestCommand,
} from '../../store/actions';
import {
  getRequestsOutgoing, TableName, getRequestsOutgoingFilters, getMeId,
} from '../../store/selectors';
import TableLoadingComponent from '../../components/Table/TableLoadingComponent';
import { FiltersTags } from '../../components/Table';
import { asMoment } from '../../types/mappers';
import { FILTERS } from '../Orders/Components/OrdersTable.constants';
import OrderStatus from '../Orders/Components/OrderStatusComponent';
import {
  ColumnContent, AddressContent,
} from './compontents/RequestComponent';
import { DateTime } from '../../components/Vehicle/Data';
import { isSuccess } from '../../utils/responseUtils';

const pageSizeOptions = ['5', '10', '20', '50', '100', '200'];

const statusOptions = [
  { label: 'PROPERTIES.REQUEST_PENDING', value: 'PENDING' },
  { label: 'PROPERTIES.REQUEST_ACCEPTED', value: 'ACCEPTED' },
  { label: 'PROPERTIES.REQUEST_DECLINED', value: 'DECLINED' },
  { label: 'PROPERTIES.REQUEST_DEACTIVATED', value: 'DEACTIVATED' },
];

const OutgoingRequest = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestsOutgoing } = useSelector(getRequestsOutgoing);
  const filters = useSelector(getRequestsOutgoingFilters);
  const [sortOption, setSortOption] = useState({});
  const [paginationOption, setPaginationOption] = useState(undefined);
  const userId = useSelector(getMeId);

  const updateFilters = (filter) => {
    dispatch(mergeUpdateInterfaceFilters(
      TableName.RequestsOutgoing,
      filter,
    ));
  };

  function sortField(sorter) {
    return Object.keys(sorter).length === 0
      ? undefined : `${sorter.field},${sorter.order === 'descend' ? 'desc' : 'asc'}`;
  }

  const onChange = useCallback((
    pagination, f, sorter,
  ) => {
    setPaginationOption(pagination);
    setSortOption(sorter);
    dispatch(loadRequestOutgoing(false, pagination.current - 1, pagination.pageSize, sortField(sorter)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadRequestOutgoing());
  }, [dispatch]);

  const renderTotal = useCallback((total) => (<span>{t('COMMON.TOTAL', { total })}</span>), [t]);

  const reload = () => {
    dispatch(loadRequestOutgoing(undefined, paginationOption?.current - 1, paginationOption?.pageSize,
      Object.keys(sortOption).length === 0 ? undefined : sortField(sortOption)));
  };

  const handleClose = (filterName) => {
    updateFilters({ [filterName]: [] });
    reload();
  };

  const handleClearFilters = () => {
    dispatch(clearInterfaceFiltersExcept(TableName.RequestsOutgoing));
    reload();
  };

  const addressIsEmpty = (carrier) => carrier?.zipCode === null
  || carrier?.countryCode === null
  || carrier?.city === null;

  const getDefaultSortOrder = (fieldName) => (sortOption.field === fieldName ? sortOption.order : undefined);

  const renderColumns = [
    {
      title: [t('REQUEST.ORDER_NUMBER')],
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: false,
      filteredValue: filters.ORDER_NUMBER,
      filterDropdown: InputFilterDropdown(FILTERS.ORDER_NUMBER, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text, record) => (
        <Link to={`/requests/${record.id}/details`}>{text}</Link>
      ),
    },
    {
      title: [t('REQUEST.LICENSE_PLATE')],
      dataIndex: 'licencePlate',
      key: 'licencePlate',
      sorter: false,
      filteredValue: filters.LICENCE_PLATE,
      filterDropdown: InputFilterDropdown(FILTERS.LICENCE_PLATE, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text) => (
        <ColumnContent>{text}</ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.CARRIER')],
      dataIndex: 'carrier',
      key: 'carrier',
      sorter: false,
      filteredValue: filters.CARRIER,
      filterDropdown: InputFilterDropdown(FILTERS.CARRIER, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text, record) => (
        <>
          {
        addressIsEmpty(record.carrier) ? (
          <>
            <ColumnContent>
              {record.carrier.name}
            </ColumnContent>
          </>
        ) : (
          <>
            <ColumnContent>
              {record.carrier.name}
              <AddressContent>
                {record.carrier.countryCode}
                <>,</>
                {record.carrier.zipCode}
                <>,</>
                {record.carrier.city}
              </AddressContent>
            </ColumnContent>
          </>
        )
      }
        </>
      ),
    },
    {
      title: [t('REQUEST.START_DATE')],
      dataIndex: 'timeMonitoringStartDate',
      key: 'timeMonitoringStartDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('timeMonitoringStartDate'),
      filteredValue: asMoment(filters.TIME_MONITORING_START_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.TIME_MONITORING_START_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.END_DATE')],
      dataIndex: 'timeMonitoringEndDate',
      key: 'timeMonitoringEndDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('timeMonitoringEndDate'),
      filteredValue: asMoment(filters.TIME_MONITORING_END_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.TIME_MONITORING_END_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.CREATED_DATE')],
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('createdDate'),
      filteredValue: asMoment(filters.CREATED_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.CREATED_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.STATUS')],
      className: 'ant-table-cell-centered',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('state'),
      fixed: 'right',
      filteredValue: filters.REQUEST_STATE,
      filterDropdown: SelectFilterDropdown(FILTERS.REQUEST_STATE.name, statusOptions, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text) => (<><OrderStatus status={`REQUEST_${text}`} /></>),
    },
    {
      title: [t('REQUEST.ACTIONS')],
      dataIndex: 'actions',
      className: 'ant-table-cell-centered ant-table-cell-actions',
      key: 'actions',
      sorter: false,
      render: (text, record) => {
        const isActive = (state) => state === 'PENDING';

        const styleButton = {
          fontSize: '100%',
          color: isActive(record.state) ? '#1890FF' : '#333333',
          background: 'none',
        };

        return (
          <>
            <Tooltip
              placement="bottomLeft"
              title={isActive(record.state) ? t('REQUEST.TOOLIP_ACTIVE') : t('REQUEST.TOOLIP_NOT_ACTIVE')}
            >
              <Button
                size="small"
                style={{ border: 'none', background: 'none' }}
                icon={(<Icon component={StopOutlined} size="small" style={styleButton} />)}
                disabled={!isActive(record.state)}
                onClick={() => {
                  dispatch(deactivateMonitoringRequestCommand({
                    monitoringRequestId: record.id,
                    shipperCompanyId: record.shipperCompanyId,
                    deactivateData: {
                      deactivatedBy: userId,
                    },
                  })).then((response) => {
                    if (isSuccess(response)) {
                      reload();
                    }
                  });
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col sm="24">
          <FiltersTags
            filters={filters}
            onRemoveFilter={handleClose}
            onClearFilters={handleClearFilters}
          />
        </Col>
        {requestsOutgoing?.data?.total > 0 ? (
          <StyledTable
            locale={
              { emptyText: (<></>) }
            }
            key={TableName.RequestsOutgoing}
            scroll={{ y: 500 }}
            dataSource={requestsOutgoing?.data?.requestsOutgoing}
            rowKey={(record) => `${record.orderNumber}`}
            size="small"
            bordered
            pagination={{
              showSizeChanger: true,
              showTotal: renderTotal,
              pageSizeOptions,
              total: requestsOutgoing?.data?.total,
              disabled: requestsOutgoing?.loading,
              current: requestsOutgoing?.data?.page + 1,
              pageSize: requestsOutgoing?.data?.size,
            }}
            columns={renderColumns}
            loading={TableLoadingComponent(requestsOutgoing?.majorReload)}
            onChange={(p, f, s) => onChange(p, f, s)}
          />
        ) : (
          <EmptyStyledTable
            dataSource={[]}
            rowKey="id"
            size={0}
            bordered
            locale={{ emptyText: t('REQUEST.EMPTY_OUTGOING_INFO') }}
            pagination={{
              showSizeChanger: true,
              showTotal: renderTotal,
              pageSizeOptions,
              total: requestsOutgoing?.data?.total,
              disabled: requestsOutgoing?.loading,
              current: requestsOutgoing?.data?.page + 1,
              pageSize: requestsOutgoing?.data?.size,
            }}
            onChange={(p, f, s) => onChange(p, f, s)}
            columns={renderColumns}
            loading={TableLoadingComponent(requestsOutgoing?.majorReload)}
          />
        )}
      </Row>
      <Row style={{ marginTop: '-10px' }}>
        {requestsOutgoing?.data?.total === 0 ? (
          <Link to="/requests/outgoing/new">{t('REQUEST.CLICK_CREATE_NEW')}</Link>
        ) : null}
      </Row>
    </>
  );
};
export default OutgoingRequest;
