import qs from 'qs';
import UrlService from '../../../services/UrlService';
import axiosActions, { extractApiFilters } from '../axiosHelpers';
import {
  getSelectedCompany, getMeId, getRequestsIncomingFilters, getRequestsOutgoingFilters,
} from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';
import { FILTERS } from '../../../modules/Orders/Components/OrdersTable.constants';

export const FETCH_REQUESTS_OUTGOING = axiosActions('FETCH_REQUESTS_OUTGOING');
export const FETCH_REQUESTS_INCOMING = axiosActions('FETCH_REQUESTS_INCOMING');

const executeRequestOutgoing = (companyId, userId, silentRefresh = false, page, size, sort, filters) => ({
  type: FETCH_REQUESTS_OUTGOING.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'post',
      url: UrlService.requestsOutgoingSearch(companyId, userId),
      data: filters,
      params: {
        page,
        size,
        sort,
      },
    },
  },
});
// eslint-disable-next-line
export const loadRequestOutgoing = (silentRefresh = false, page = 0, size = 200, sort = 'state,asc') => (dispatch, getState) => {
  const state = getState();
  const filters = getRequestsOutgoingFilters(state);
  const selectedCompany = getSelectedCompany(state);
  const userId = getMeId(state);
  return dispatch(executeRequestOutgoing(selectedCompany, userId, silentRefresh, page, size, sort,
    extractApiFilters(filters, FILTERS, () => true)));
};

const executeRequestIncoming = (companyId, userId, silentRefresh = false, page, size,
  sort = ['state,asc', 'createdDate,desc'], filters) => ({
  type: FETCH_REQUESTS_INCOMING.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'post',
      url: UrlService.requestsIncomingSearch(companyId, userId),
      data: filters,
      params: {
        page,
        size,
        sort,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  },
});
// eslint-disable-next-line
export const loadRequestIncoming = (silentRefresh = false, page = 0, size = 200, sort) => (dispatch, getState) => {
  const state = getState();
  const filters = getRequestsIncomingFilters(state);
  const selectedCompany = getSelectedCompany(state);
  const userId = getMeId(state);
  return dispatch(executeRequestIncoming(selectedCompany, userId, silentRefresh, page, size, sort,
    extractApiFilters(filters, FILTERS, () => true)));
};
