import { FETCH_ME } from '../../actions';

const initState = {
  loading: null, error: null, fetched: false, data: {},
};
export default function personMeReducer(state = initState, { type, payload, meta }) {
  switch (type) {
    case FETCH_ME.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        loading: false, fetched: true, error: null, data: { ...payload.data.person },
      };
    case FETCH_ME.START:
      return {
        ...state, loading: true, fetched: false, actionId: payload.actionId,
      };
    case FETCH_ME.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state, error: true, fetched: true, loading: false,
      };
    default:
      return state;
  }
}
