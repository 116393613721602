import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useQuery } from 'react-query';
import { MapWrapper } from '../../../../components';
import SuggesterLocationService from '../../../../services/SuggesterLocationService';
import OrderCreationMapManager from './OrderCreationMapManager';

const OrderCreationMap = ({ field, form }) => {
  const [mapManager, setMapManager] = useState(null);
  const { t } = useTranslation();
  const locationId = Form.useWatch(['stops', field.key, 'searchStopAddress'], form);
  const cordinate = Form.useWatch(['stops', field.key, 'cordinate'], form);
  const { data } = useQuery(['location-details', locationId],
    async () => {
      if (locationId) {
        return SuggesterLocationService.getLocationDetails(locationId);
      }
      return null;
    });

  const saveCordinate = (point) => {
    if (point) {
      form.setFields([{
        name: ['stops', field.key, 'cordinate'],
        value: point,
      }]);
    }
  };

  useEffect(() => {
    if (mapManager && data) {
      saveCordinate(data);
      mapManager.drawMarker(data, (d) => saveCordinate(d));
    }
    // saveCordinate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, mapManager]);

  useEffect(() => {
    function drawMap() {
      const newMapManager = new OrderCreationMapManager({ refId: `${field.key}map`, t });
      setMapManager(newMapManager);
    }

    drawMap();
    // purposefully skips t, does not change and is provided at first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapManager) {
      if (cordinate !== undefined && locationId === undefined) {
        mapManager.drawMarker(cordinate, (d) => saveCordinate(d));
      }
    }
    // saveCordinate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, cordinate, mapManager]);

  return (<><MapWrapper id={`${field.key}map`} /></>);
};

OrderCreationMap.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrderCreationMap;
