import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { IgnitionStateIcon } from '../../Styled';

const IgnitionState = ({ state, icon, label }) => {
  const { t } = useTranslation();
  const [stateName, setStateName] = useState('UNKNOWN');

  useEffect(() => {
    if (state === true) {
      setStateName('ON');
    } else if (state === false) {
      setStateName('OFF');
    } else {
      setStateName('UNKNOWN');
    }
  }, [setStateName, state]);

  return (
    <>
      {icon && <IgnitionStateIcon state={state} />}
      {label
      && (
        <span>
          { icon && (' ') }
          {t(`DICTIONARY.IGNITION.${stateName}`)}
        </span>
      )}
    </>
  );
};

IgnitionState.propTypes = {
  state: PropTypes.bool,
  icon: PropTypes.bool,
  label: PropTypes.bool,
};

IgnitionState.defaultProps = {
  state: null,
  icon: false,
  label: false,
};

export default IgnitionState;
