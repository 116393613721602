import React from 'react';
import { Col } from 'antd';
import { FullHeightRow } from '../../../components';
import CarrierTable from './CarrierTable';

export const CarrierListComponent = () => (
  <FullHeightRow gutter={[10, 10]} style={{ paddingTop: '10px' }}>
    <Col span={15}>
      <CarrierTable
        size="small"
      />
    </Col>
    <Col span={9} />
  </FullHeightRow>
);
