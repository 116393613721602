import { combineReducers } from 'redux';
import relatedCompaniesReducer from './relatedCompanies.reducer';
import selectedCompanyReducer from './selectedCompany.reducer';
import companiesReducer from './companies.reducer';

export default combineReducers({
  relatedCompanies: relatedCompaniesReducer,
  selectedCompany: selectedCompanyReducer,
  companies: companiesReducer,
});
