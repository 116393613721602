import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Collapse, Empty } from 'antd';
import DetailsDescriptions from './DetailsDescriptions';

const Panel = styled(Collapse.Panel)`
  .ant-collapse-header {
    font-weight: 500;
  }
`;

const DetailsDescriptionsCard = ({
  title, detailsSize, detailsConfig,
}) => (
  <Collapse defaultActiveKey={['1']}>
    <Panel header={title} key="1">
      {detailsConfig && detailsConfig.length ? (
        <DetailsDescriptions
          detailsSize={detailsSize}
          detailsConfig={detailsConfig}
        />
      ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </Panel>
  </Collapse>
);

DetailsDescriptionsCard.propTypes = {
  detailsSize: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.string.isRequired,
  detailsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      loading: PropTypes.func.isRequired,
      renderDetail: PropTypes.func.isRequired,
    }),
  ).isRequired,
  // actions: PropTypes.arrayOf(PropTypes.object),
};

DetailsDescriptionsCard.defaultProps = {
  detailsSize: 'small',
};

export default DetailsDescriptionsCard;
