import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { uuidv4 } from '../../../utils/uuid';
import { OrderContext } from '../../../types';

export const FETCH_IN_MONITORING_ORDER_ROUTE_POINTS = axiosActionsFactory(
  'FETCH_IN_MONITORING_ORDER_ROUTE_POINTS',
);
export const FETCH_OUT_MONITORING_ORDER_ROUTE_POINTS = axiosActionsFactory(
  'FETCH_OUT_MONITORING_ORDER_ROUTE_POINTS',
);
export const FETCH_DEFAULT_MONITORING_ORDER_ROUTE_POINTS = axiosActionsFactory(
  'FETCH_DEFAULT_MONITORING_ORDER_ROUTE_POINTS',
);

export const FETCH_MONITORING_ORDER_ROUTE_POINTS = {
  [OrderContext.IN]: FETCH_IN_MONITORING_ORDER_ROUTE_POINTS,
  [OrderContext.OUT]: FETCH_OUT_MONITORING_ORDER_ROUTE_POINTS,
  [OrderContext.UNKNOWN]: FETCH_DEFAULT_MONITORING_ORDER_ROUTE_POINTS,
};

export const fetchMonitoringOrderRoutePoints = (
  monitoringOrderId, contextCompanyId, orderContext, routePointFilter,
) => (dispatch) => dispatch({
  type: FETCH_MONITORING_ORDER_ROUTE_POINTS[orderContext].START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.routePoints(monitoringOrderId),
      params: {
        contextCompanyId,
        routePointFilter,
      },
    },
  },
  monitoringOrderId,
  routePointFilter,
});
