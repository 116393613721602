import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { Elapsed } from './DateTime.styled';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, MomentPropTypes.momentObj]),
  elapsed: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      layout: PropTypes.oneOf(['horizontal', 'vertical']),
    }),
  ]),
};

const defaultProps = {
  value: null,
  elapsed: false,
};

const DateTime = ({ value, elapsed }) => {
  const valueAsDate = value && getCleanMoment(value);
  const { t } = useTranslation();

  const formattedOrEmpty = (date) => (date ? date.format(DEFAULT_DATE_TIME) : t('COMMON.MISSING'));

  const renderComponent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: elapsed.layout === 'horizontal' ? 'row' : 'column',
      }}
    >
      <span>{formattedOrEmpty(valueAsDate)}</span>
      {elapsed && (
        <Elapsed layout={elapsed.layout || 'vertical'}>
          {`(${moment.duration(valueAsDate.diff(moment())).humanize(true)})`}
        </Elapsed>
      )}
    </div>
  );

  const renderMissing = () => (<span>{t('COMMON.MISSING')}</span>);

  return (valueAsDate ? renderComponent() : renderMissing());
};

DateTime.propTypes = propTypes;
DateTime.defaultProps = defaultProps;

export default DateTime;
