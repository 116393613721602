import H from '@here/maps-api-for-javascript';
import onResize from 'simple-element-resize-detector';
import HereMapsService from '../HereMapsService';
import { resizeMap } from './utils';

function isTrafficItem(data) {
  return !!data.TRAFFIC_ITEM_ID;
}

const ZOOM_IN_SATTELITE_SWITCH_AT = 18;
const ZOOM_OUT_SATTELITE_SWITCH_AT = 16;

export const createDefaultMap = (refId) => {
  const layers = HereMapsService.createDefaultLayers();
  const ref = document.getElementById(refId);
  const map = new H.Map(
    ref,
    layers.vector.normal.map,
    {
      pixelRatio: window.devicePixelRatio || 1,
    },
  );

  function setCursorStyle(style) {
    map.getViewPort().element.style.cursor = style;
  }

  function deductPointerStyle(event) {
    if (event && event.target && event.target.getData) {
      const data = event.target.getData();
      if (data && (data.clickable || isTrafficItem(data))) {
        setCursorStyle('pointer');
        return;
      }
    }
    setCursorStyle('auto');
  }

  /* eslint no-unused-vars:0 */
  const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
  const ui = H.ui.UI.createDefault(map, layers);
  onResize(ref, () => resizeMap(ui));
  map.addEventListener('pointermove', deductPointerStyle, false);
  let oldZoom = map.getZoom();
  map.addEventListener('mapviewchangeend', () => {
    const newZoom = map.getZoom();
    if (newZoom > oldZoom && newZoom > ZOOM_IN_SATTELITE_SWITCH_AT) {
      map.setBaseLayer(layers.raster.satellite.map);
    } else if (newZoom < oldZoom && newZoom <= ZOOM_OUT_SATTELITE_SWITCH_AT) {
      map.setBaseLayer(layers.vector.normal.map);
    }
    oldZoom = newZoom;
  });
  return ui;
};

export const createMapOrderCreation = (refId) => {
  const defaultLayers = HereMapsService.createDefaultLayers();
  const map = new H.Map(document.getElementById(refId),
    defaultLayers.vector.normal.map, {
      zoom: 1,
      pixelRatio: window.devicePixelRatio || 1,
    });

  window.addEventListener('resize', () => map.getViewPort().resize());
  const ui = H.ui.UI.createDefault(map, defaultLayers);
  return map;
};

export const createBehavior = (map) => new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
