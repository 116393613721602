import { combineReducers } from 'redux';
import {
  FETCH_LAST_USED_INTERFACE_CONFIGURATION, SET_LAST_USED_INTERFACE_CONFIGURATION,
  UPDATE_LAST_USED_INTERFACE_CONFIGURATION,
} from '../../actions';
import { fromApiModel } from '../../normalizers';
import { TableName } from '../../selectors';
import {
  ALL_COLS as VehicleTableColumns,
} from '../../../modules/Vehicles/Components/VehicleTable/VehiclesTable.constants';
import {
  FILTERS as SharingFilters, SharedByCompany, ShareDirection,
} from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';

function loadingReducer(state = false, { type }) {
  switch (type) {
    case FETCH_LAST_USED_INTERFACE_CONFIGURATION.START:
    case UPDATE_LAST_USED_INTERFACE_CONFIGURATION.START:
      return true;
    case FETCH_LAST_USED_INTERFACE_CONFIGURATION.FAIL:
    case UPDATE_LAST_USED_INTERFACE_CONFIGURATION.FAIL:
    case FETCH_LAST_USED_INTERFACE_CONFIGURATION.SUCCESS:
    case SET_LAST_USED_INTERFACE_CONFIGURATION:
    case UPDATE_LAST_USED_INTERFACE_CONFIGURATION.SUCCESS:
      return false;
    default:
      return state;
  }
}

const defaultConfiguration = {
  filters: {},
  refreshIntervalSeconds: 120,
};

const initialState = {
  [TableName.Sharings]: {
    ...defaultConfiguration,
    columns: [],
    filters: {
      [SharingFilters.SIDE.name]: [ShareDirection.IN],
      [SharingFilters.SHARED_BY_COMPANY.name]: [SharedByCompany.MINE],
    },
  },
  [TableName.Vehicles]: {
    ...defaultConfiguration,
    columns: [...VehicleTableColumns],
  },
  [TableName.VehicleDetails]: {
    ...defaultConfiguration,
  },
  [TableName.OrderDetails]: {
    ...defaultConfiguration,
  },
  [TableName.OrdersSent]: {
    ...defaultConfiguration,
  },
  [TableName.OrdersReceived]: {
    ...defaultConfiguration,
  },
  [TableName.RequestsOutgoing]: {
    ...defaultConfiguration,
  },
  [TableName.RequestIncoming]: {
    ...defaultConfiguration,
  },
};

const updateInterfaceForTable = (configurations, updatedConfiguration) => ({
  ...configurations,
  [updatedConfiguration.tableName]: fromApiModel(updatedConfiguration, initialState[updatedConfiguration.tableName]),
});

function configReducer(state = { ...initialState }, { type, payload, meta }) {
  switch (type) {
    case FETCH_LAST_USED_INTERFACE_CONFIGURATION.START:
      return { ...state, actionId: payload.actionId };
    case FETCH_LAST_USED_INTERFACE_CONFIGURATION.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId || !payload.data) {
        return state;
      }
      return updateInterfaceForTable(state, payload.data);
    case SET_LAST_USED_INTERFACE_CONFIGURATION:
      if (!payload.data) {
        return state;
      }
      return updateInterfaceForTable(state, payload.data);
    default:
      return state;
  }
}

export default combineReducers({
  loading: loadingReducer,
  configurations: configReducer,
});
