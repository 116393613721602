/* eslint-disable class-methods-use-this */
import TourService from './TourService';

class ChartsService {
  getPositionsSpeedChartPoints() {
    if (!TourService.tourStatistics) return [];
    return TourService.tourStatistics.uniquePositions.filter((up) => up.positionTimestamp != null).map((up) => ({
      x: up.positionTimestamp,
      y: (up.speed ? Number(up.speed) : 0).toFixed(2),
      data: up,
    }));
  }

  getDistanceBetweenPositionsChartPoints() {
    if (!TourService.tourStatistics) return [];
    return TourService.tourStatistics.uniquePositions.filter((up) => up.positionTimestamp != null).map((up) => ({
      x: up.positionTimestamp,
      y: (up.distanceFromLastUnique / 1000).toFixed(2),
      data: up,
    }));
  }

  getPositionsCalculatedSpeedChartPoints() {
    if (!TourService.tourStatistics) return [];
    return TourService.tourStatistics.uniquePositions.filter((up) => up.positionTimestamp != null).map((up) => ({
      x: up.positionTimestamp,
      y: (up.speedFromLastUnique).toFixed(2),
      data: up,
    }));
  }

  getEstimatedWorkingTimeChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    let previousUp = TourService.tourStatistics.uniquePositions[0];

    points.push({
      x: previousUp.positionTimestamp * 1,
      y: previousUp.working,
      data: previousUp,
    });
    points.push({
      x: previousUp.positionTimestamp * 1,
      y: previousUp.working,
      data: previousUp,
    });

    for (let i = 1; i < TourService.tourStatistics.uniquePositions.length - 1; i += 1) {
      const up = TourService.tourStatistics.uniquePositions[i];

      points.push({
        x: up.positionTimestamp * 1 - 1,
        y: previousUp.working,
        data: up,
      });
      points.push({
        x: up.positionTimestamp,
        y: up.working,
        data: up,
      });
      previousUp = up;
    }
    return points;
  }

  getIgnitionStateChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    let previousUp = TourService.tourStatistics.uniquePositions[0];

    points.push({
      x: previousUp.positionTimestamp * 1,
      y: previousUp.ignitionState === 'ON',
      data: previousUp,
    });
    points.push({
      x: previousUp.positionTimestamp * 1,
      y: previousUp.ignitionState === 'ON',
      data: previousUp,
    });

    for (let i = 1; i < TourService.tourStatistics.uniquePositions.length - 1; i += 1) {
      const up = TourService.tourStatistics.uniquePositions[i];

      points.push({
        x: up.positionTimestamp * 1 - 1,
        y: previousUp.ignitionState === 'ON',
        data: up,
      });
      points.push({
        x: up.positionTimestamp,
        y: up.ignitionState === 'ON',
        data: up,
      });
      previousUp = up;
    }
    return points;
  }

  getIsUniqueQuarterChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    const previousQuarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[0];
    let previousIsUnique = TourService.tourStatistics.quarterStatistics.isUniques[0];

    points.push({
      x: previousQuarterTime * 1,
      y: previousIsUnique,
      data: {},
    });
    points.push({
      x: previousQuarterTime * 1,
      y: previousIsUnique,
      data: {},
    });

    for (let i = 1; i < TourService.tourStatistics.quarterStatistics.timesForQuarters.length - 1; i += 1) {
      const quarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[i];
      const isUnique = TourService.tourStatistics.quarterStatistics.isUniques[i];

      points.push({
        x: quarterTime * 1,
        y: previousIsUnique,
        data: {},
      });
      points.push({
        x: quarterTime * 1,
        y: isUnique,
        data: {},
      });
      previousIsUnique = isUnique;
    }
    return points;
  }

  getDistanceBetweenInQuarterChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    const previousQuarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[0];
    let previousDistance = (
      TourService.tourStatistics.quarterStatistics.distanceBetweenUniqueTraces[0] / 1000
    ).toFixed(2);

    points.push({
      x: previousQuarterTime * 1,
      y: previousDistance,
      data: {},
    });
    points.push({
      x: previousQuarterTime * 1,
      y: previousDistance,
      data: {},
    });

    for (let i = 1; i < TourService.tourStatistics.quarterStatistics.timesForQuarters.length - 1; i += 1) {
      const quarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[i];
      const distance = (TourService.tourStatistics.quarterStatistics.distanceBetweenUniqueTraces[i] / 1000).toFixed(2);

      points.push({
        x: quarterTime * 1,
        y: previousDistance,
        data: {},
      });
      points.push({
        x: quarterTime * 1,
        y: distance,
        data: {},
      });
      previousDistance = distance;
    }
    return points;
  }

  getTimeBetweenInQuarterChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    const previousQuarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[0];
    let previousTimeBetween = (
      TourService.tourStatistics.quarterStatistics.timeBetweenUniqueTraces[0] / 3600
    ).toFixed(2);

    points.push({
      x: previousQuarterTime * 1,
      y: previousTimeBetween,
      data: {},
    });
    points.push({
      x: previousQuarterTime * 1,
      y: previousTimeBetween,
      data: {},
    });

    for (let i = 1; i < TourService.tourStatistics.quarterStatistics.timesForQuarters.length - 1; i += 1) {
      const quarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[i];
      const timeBetween = (TourService.tourStatistics.quarterStatistics.timeBetweenUniqueTraces[i] / 3600).toFixed(2);

      points.push({
        x: quarterTime * 1,
        y: previousTimeBetween,
        data: {},
      });
      points.push({
        x: quarterTime * 1,
        y: timeBetween,
        data: {},
      });
      previousTimeBetween = timeBetween;
    }
    return points;
  }

  getMeanSpeedBetweenInQuarterChartPoints() {
    if (!TourService.tourStatistics) return [];

    const points = [];

    const previousQuarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[0];
    let previousMeanSpeed = (
      TourService.tourStatistics.quarterStatistics.meanSpeedBetweenUniqueTraces[0] * 3.6
    ).toFixed(2);

    points.push({
      x: previousQuarterTime * 1,
      y: previousMeanSpeed,
      data: {},
    });
    points.push({
      x: previousQuarterTime * 1,
      y: previousMeanSpeed,
      data: {},
    });

    for (let i = 1; i < TourService.tourStatistics.quarterStatistics.timesForQuarters.length - 1; i += 1) {
      const quarterTime = TourService.tourStatistics.quarterStatistics.timesForQuarters[i];
      const meanSpeed = (TourService.tourStatistics.quarterStatistics.meanSpeedBetweenUniqueTraces[i] * 3.6).toFixed(2);

      points.push({
        x: quarterTime * 1,
        y: previousMeanSpeed,
        data: {},
      });
      points.push({
        x: quarterTime * 1,
        y: meanSpeed,
        data: {},
      });
      previousMeanSpeed = meanSpeed;
    }
    return points;
  }

  getPositionTimestampsChartPoints() {
    if (!TourService.tourStatistics) return [];

    return TourService.tourStatistics.uniquePositions.filter((up) => up.positionTimestamp != null).flatMap((up) => [{
      x: up.positionTimestamp * 1 - 1,
      y: false,
      data: up,
    },
    {
      x: up.positionTimestamp,
      y: true,
      data: up,
    },
    {
      x: up.positionTimestamp * 1 + 1,
      y: false,
      data: up,
    },
    ]);
  }
}

export default new ChartsService();
