import axiosActions from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { getCurrentInterfaceConfiguration } from '../../selectors';
import { toApiModel } from '../../normalizers';
import { API_FILTERS } from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_LAST_USED_INTERFACE_CONFIGURATION = axiosActions('LAST_USED_INTERFACE_CONFIGURATION_LOAD');
export const UPDATE_LAST_USED_INTERFACE_CONFIGURATION = axiosActions('UPDATE_LAST_USED_INTERFACE_CONFIGURATION');
export const SET_LAST_USED_INTERFACE_CONFIGURATION = 'SET_LAST_USED_INTERFACE_CONFIGURATION';

export const fetchLastUsedInterfaceConfiguration = (tableName) => ({
  type: FETCH_LAST_USED_INTERFACE_CONFIGURATION.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.lastUsedInterfaceConfiguration(tableName),
    },
  },
});

const updateLastUsedInterfaceConfiguration = (tableName, partialNewConfiguration) => (dispatch, getState) => {
  const state = getState();
  const currentConfig = getCurrentInterfaceConfiguration(tableName)(state);
  const newConfig = { ...currentConfig, ...partialNewConfiguration, tableName };
  const newConfigAsApiModel = toApiModel(newConfig);

  dispatch({
    type: SET_LAST_USED_INTERFACE_CONFIGURATION,
    payload: { data: { ...newConfigAsApiModel } },
  });

  dispatch({
    type: UPDATE_LAST_USED_INTERFACE_CONFIGURATION.START,
    payload: {
      request: {
        method: 'put',
        url: UrlService.lastUsedInterfaceConfiguration(tableName),
        data: { ...newConfigAsApiModel },
      },
    },
  });
};

export const updateInterfaceFilters = (
  tableName, filters,
) => updateLastUsedInterfaceConfiguration(tableName, { filters });

export const mergeUpdateInterfaceFilters = (tableName, newFilters) => (dispatch, getState) => {
  const { filters } = getCurrentInterfaceConfiguration(tableName)(getState());
  dispatch(updateInterfaceFilters(tableName, { ...filters, ...newFilters }));
};

export const clearInterfaceFilters = (tableName) => (dispatch, getState) => {
  const state = getState();
  const { filters } = getCurrentInterfaceConfiguration(tableName)(state);
  const newFilters = {};
  Object.keys(filters).forEach((key) => {
    newFilters[key] = null;
  });
  dispatch(updateInterfaceFilters(tableName, newFilters));
};

export const clearInterfaceFiltersExcept = (tableName, skippedFilters = []) => (dispatch, getState) => {
  const state = getState();
  const { filters } = getCurrentInterfaceConfiguration(tableName)(state);
  const clearedFilters = {};
  let shouldRefresh = false;
  Object.keys(filters).forEach((name) => {
    const isSkipped = skippedFilters.includes(name);
    const isApi = API_FILTERS.includes(name);
    shouldRefresh = shouldRefresh || !!(!isSkipped && isApi && filters[name] && filters[name].length);
    clearedFilters[name] = isSkipped ? filters[name] : null;
  });

  dispatch(mergeUpdateInterfaceFilters(tableName, clearedFilters));
  return shouldRefresh;
};

export const updateInterfaceColumns = (
  tableName, columns,
) => updateLastUsedInterfaceConfiguration(tableName, { columns });

export const updateRefreshInterval = (
  tableName, refreshIntervalSeconds,
) => updateLastUsedInterfaceConfiguration(tableName, { refreshIntervalSeconds });
