/* eslint max-len:0 */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';

const TruckSvg = ({ iconStyle }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="-192 330 210 140.5"
    height="1em"
    width="1em"
    fill="currentColor"
    style={iconStyle}
  >
    <path
      stroke="black"
      d="M17.6,387c-1.3-24.9-11.1-40.2-26.7-42.2c-11.5-1.4-24.9-1.9-31.9-2.1v-7.4
  c0-3.2-2.6-5.3-5.8-5.3h-139.4c-3.2,0-5.8,2.1-5.8,5.3v113.2c0,3.2,2.6,6.5,5.8,6.5h18.5c2.7,9,11,15.5,20.8,15.5
  c9.8,0,18.1-6.5,20.8-15.5h78.2c2.7,9,11,15.5,20.8,15.5c9.8,0,18.1-6.5,20.8-15.5h18c3.2,0,5.7-2.9,5.8-6.1
  C17.7,447.1,18.5,402.3,17.6,387z M-180,342h127v68h-127V342z M-138.9,454.3c-1.9,2.4-4.7,3.9-8,3.9c-3.2,0-6.1-1.5-8-3.9
  c-1.4-1.7-2.2-3.9-2.2-6.3c0-2,0.6-3.8,1.5-5.3c1.8-2.9,5-4.8,8.6-4.8s6.8,1.9,8.6,4.8c1,1.6,1.5,3.4,1.5,5.3
  C-136.7,450.4-137.5,452.6-138.9,454.3z M-19,454.6c-1,1.3-2.4,2.4-4,3.4c0,0,0,0,0,0c0,0,0-0.3,0-0.3c-1.2,0.5-2.6,0.7-4,0.7
  c-3.2,0-6.1-1.6-8-4c-1.4-1.7-2.2-3.9-2.2-6.3c0-2,0.6-3.8,1.5-5.4c1.8-2.9,5-4.7,8.6-4.7c1.4,0,2.8,1,4,1h0c0,0,0.1-0.2,0.1-0.2
  c1.9,0.9,3.5,2.2,4.5,3.9c1,1.6,1.5,3.5,1.5,5.5C-16.8,450.6-17.6,452.9-19,454.6z M6.3,443H-5.9c-2.4-9-10.9-16.4-21.1-16.4
  c-10.2,0-18.7,7.4-21.1,16.4h-77.7c-2.4-9-10.9-16.4-21.1-16.4c-10.2,0-18.7,7.4-21.1,16.4H-180v-21H6.4L6.3,443z M-41,410v-55.7
  c0,0,0,0,0.1,0c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0c0.3,0,0.7,0,1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0c0.1,0,0.3,0,0.4,0
  c1.1,0,2.2,0.1,3.4,0.1c0.2,0,0.5,0,0.7,0c0.4,0,0.7,0,1.1,0c0.9,0,1.7,0.1,2.6,0.1c1.3,0.1,2.6,0.1,3.9,0.2c0.4,0,0.7,0,1.1,0.1
  c0.6,0,1.1,0.1,1.7,0.1c0.4,0,0.8,0.1,1.1,0.1c0.4,0,0.8,0.1,1.1,0.1c3.2,0.3,6.5,0.8,9.6,1.2c0.4,0.1,0.8,0.5,1.2,0.5h0
  c0.1,0,0.3-0.2,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0.1c2.6,0.9,4.7,2.5,6.4,4.7
  c0.1,0.1,0.2,0.3,0.3,0.4c0.9,1.1,1.6,2.4,2.3,3.7c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.4,0.8,0.5,1.3c0.3,0.7,0.5,1.4,0.8,2.1
  c0.1,0.3,0.2,0.6,0.3,0.9c0.4,1.2,0.7,2.3,0.9,3.5c0.3,1.2,0.5,2.3,0.7,3.5c0.1,0.8,0.3,1.5,0.4,2.2c0,0.3,0.1,0.6,0.1,0.8
  c0.1,0.4,0.1,0.8,0.2,1.2c0,0.1,0,0.3,0,0.4c0,0.3,0.1,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.7c0.1,1.2,0.2,2.3,0.2,3.3
  c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.7c0,0.3,0,0.6,0,0.9c0,0.3,0,0.5,0,0.8
  c0,0.1,0,0.3,0,0.4c0,0.4,0,0.9,0,1.3c0,0.3,0,0.6,0,0.9c0,0.4,0,0.8,0,1.1c0,0.4,0,1,0,1.4c0.1,4,0.1,8.5,0.1,13.5H-41z"
    />
  </svg>
);

TruckSvg.propTypes = {
  iconStyle: PropTypes.objectOf(PropTypes.string),
};

TruckSvg.defaultProps = {
  iconStyle: null,
};

const TruckIcon = (props) => {
  const { style } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon component={() => <TruckSvg {...props} />} style={style} />
  );
};
TruckIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
};

TruckIcon.defaultProps = {
  style: null,
};

export default TruckIcon;
