import { GET_TELEMATIC_ACCOUNT, GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY } from '../../actions';
import { normalizeTelematicAccount } from '../../normalizers';

const initial = {
  fetched: false,
  failed: false,
  account: null,
};

function telematicAccountForEdit(state = initial, { type, payload }) {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT.START:
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.START:
      return { ...state, ...initial };
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return {
        ...state,
        fetched: true,
        failed: false,
        account: normalizeTelematicAccount(payload.data),
      };
    case GET_TELEMATIC_ACCOUNT.FAIL:
      return {
        ...state,
        fetched: false,
        failed: true,
        account: null,
      };
    default:
      return state;
  }
}

export default telematicAccountForEdit;
