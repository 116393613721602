import H from '@here/maps-api-for-javascript';
import MapManager from '../../../../services/map/MapManager';
import {
  CENTER_LOCATION, createStopTypeMarker, findBoundingBox, FIT_GROUP, createBubbleContent, createPinMarker,
} from '../../../../services/map/drawing';
import { MapEventType } from '../../../../services/map/mapEventDispatcher';
import theme from '../../../../theme';
import createStopInfoBubble from '../../../../services/map/drawing/infobubbles/stopInfoBubbleCreator';
import NOP from '../../../../utils/NOP';
import { info } from '../../../../services/Logging';
import LastTraceRenderer from '../../../Vehicles/Components/Map/LastTraceRenderer';
import { getStopType } from '../../../../types/mappers';

const followCircleStyle = {
  color: theme.color.lastRouteStroke,
  size: 14,
};

const stopIconStyle = {
  normal: {
    fillColor: theme.color.stop.normal,
  },
  hover: {
    fillColor: theme.color.stop.hover,
  },
};

const defaultLastTraceConfig = {
  trace: false, mask: false, closeButton: false, vehicleLink: false,
};
class VehicleDetailsMapManager extends MapManager {
  constructor({ refId, t, fetchLocation }) {
    super({
      refId, fullscreen: true, legend: false, t, fetchLocation,
    });
    this.initCenterAtLocation = true;
    this.route = null;
    this.historicRoute = null;
    this.routeShadow = null;
    this.routeFollowCircle = null;
    this.stops = null;
    this.lastTraceRenderer = null;
    this.drawnCrowns = [];
    this.fetchLocation = fetchLocation;
  }

  drawCrowns(geofencingData) {
    if (!geofencingData) {
      return;
    }

    geofencingData.polygons.forEach((stopPolygon) => {
      if (this.drawnCrowns.includes(stopPolygon.stopId)) {
        return;
      }

      this.drawnCrowns.push(stopPolygon.stopId);
      const latLngAlt = stopPolygon.polygon.flatMap((point) => [point.lat, point.lng, 100]);
      const lineString = new H.geo.LineString(latLngAlt);

      const crown = new H.map.Polygon(lineString, {
        style: {
          fillColor: theme.color.primaryLowOpacity,
          strokeColor: theme.color.primary,
          lineWidth: 3,
          zIndex: 1,
        },
      });

      this.addToMap(crown);
    });
  }

  drawEta(eta) {
    const { routeAsOneLine } = this.createRoute(eta.path, {
      style: {
        strokeColor: theme.color.routeStroke,
      },
      zIndex: 1200,
      volatility: true,
    });
    this.addToMap(routeAsOneLine);

    return () => {
      this.removeFromMap(routeAsOneLine);
    };
  }

  drawStops(stops) {
    const isStopDrawable = (stop) => stop && stop.latitude && stop.longitude;
    if (!stops) {
      return NOP;
    }

    const stopsMarkers = stops
      .filter(isStopDrawable)
      .map((stop) => this.createStopMarkerWithBubble(stop));

    this.stops = stopsMarkers;
    stopsMarkers.forEach(({ apply }) => apply(this.ui));
    return () => {
      stopsMarkers.forEach(({ dispose }) => dispose(this.ui));
      this.stops = null;
    };
  }

  createStopMarkerWithBubble(stop) {
    const type = getStopType(stop);
    const position = { lat: stop.latitude, lng: stop.longitude };
    const infoBubble = createStopInfoBubble(stop, position, type, this.t);
    const onTap = () => {
      if (infoBubble.getState() === 'open') {
        infoBubble.close();
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.stops && this.stops.forEach((s) => s.closeBubble());
        infoBubble.open();
      }
    };
    const marker = createStopTypeMarker(
      type,
      stop.sequence,
      position,
      stopIconStyle.normal,
      {
        data: { clickable: true },
        zIndex: 2,
        volatility: true,
        onTap,
      },
    );

    return {
      id: stop.id,
      position,
      apply: (ui) => {
        ui.getMap().addObject(marker);
        ui.addBubble(infoBubble);
      },
      dispose: (ui) => {
        ui.getMap().removeObject(marker);
        ui.removeBubble(infoBubble);
      },
      hideInfo: () => {
        infoBubble.close();
      },
      onTap,
      closeBubble: () => infoBubble.close(),
    };
  }

  createPinMarkerWithBubble(pos) {
    const position = { lat: pos.lat, lng: pos.lng };
    const infoBubble = new H.ui.InfoBubble(position);
    infoBubble.addClass('vehicle-details-infobubble');
    infoBubble.addClass('above-marker-infobubble');
    const bubbleOpener = createBubbleContent(
      this.ui,
      this.t,
      this.fetchLocation,
      this.showLastTrace,
      infoBubble,
      true,
    );
    bubbleOpener(pos);
    infoBubble.close();

    const onTap = () => {
      if (infoBubble.getState() === 'open') {
        infoBubble.close();
      } else {
        infoBubble.open();
      }
    };
    const marker = createPinMarker(position, {
      data: { vehicle: pos.vehicle, clickable: true }, zIndex: 2, volatility: true, onTap,
    });

    return {
      id: 'end',
      position,
      apply: (ui) => {
        ui.getMap().addObject(marker);
        ui.addBubble(infoBubble);
      },
      dispose: (ui) => {
        ui.getMap().removeObject(marker);
        ui.removeBubble(infoBubble);
      },
      hideInfo: () => {
        infoBubble.close();
      },
      onTap,
      closeBubble: () => infoBubble.close(),
    };
  }

  drawHistoryBoundaryMarkers(routePoints, order) {
    const a = routePoints[0];
    const b = routePoints[Math.max(0, routePoints.length - 1)];

    const markers = this.createHistoryBoundaryMarkers(a, undefined);
    const markerEnd = this.createPinMarkerWithBubble({ ...b, order });

    if (markers) {
      markerEnd.apply(this.ui);
      this.addAllToMap(markers);
      return () => {
        this.removeAllFromMap(markers);
        markerEnd.dispose(this.ui);
      };
    }
    return NOP;
  }

  drawLastPositionMarker(lastPosition, data) {
    if (!lastPosition) {
      return NOP;
    }
    const marker = this.createPinMarker(lastPosition, { data });
    if (marker) {
      this.addToMap(marker);
      const location = { lat: lastPosition.coordinateLatitude, lng: lastPosition.coordinateLongitude };
      const zoomFactor = 10;
      if (this.initCenterAtLocation) {
        this.zoomTo(location, zoomFactor);
        this.initCenterAtLocation = false;
      }
      this.mapEventDispatcher.dispatch({
        type: MapEventType.NEW_BOUNDING,
        payload: {
          location,
          zoom: zoomFactor,
          type: CENTER_LOCATION,
        },
      });
      return () => this.removeFromMap(marker);
    }
    return NOP;
  }

  centerToRouteAndStops = () => {
    const points = [];
    if (this.route && this.route.routeAsOneLine) {
      const routeBounding = this.route.routeAsOneLine.getBoundingBox();
      points.push(routeBounding.getBottomRight(), routeBounding.getTopLeft());
    }

    if (this.stops) {
      this.stops.forEach(({ position }) => points.push(position));
    }

    const bounding = points.length > 0 ? findBoundingBox(points) : null;

    if (bounding) {
      this.mapEventDispatcher.dispatch({
        type: MapEventType.NEW_BOUNDING,
        payload: {
          bounding,
          triggerCentering: true,
          type: FIT_GROUP,
        },
      });
    }
  }

  drawRouteWithShadow(points, { onTap } = {}) {
    const route = this.drawRoute(points, {
      onTap: (t) => {
        if (t && t.index) {
          onTap(t.index);
        }
      },
      visibility: false,
    });
    const { routeAsSeparatedLines: highlight } = this.createRoute(points, {
      style: {
        strokeColor: theme.color.lastRouteShadow,
      },
      zIndex: 200,
      volatility: true,
      visibility: false,
      onTap: (t) => {
        if (t && t.index) {
          onTap(t.index);
        }
      },
    });
    this.route = route;
    this.addAllToMap(highlight);
    return () => {
      this.removeAllFromMap(route.routeAsSeparatedLines);
      this.removeAllFromMap(highlight);
      this.route = null;
    };
  }

  drawHistoricRoute(points, config) {
    const { routeAsSeparatedLines } = this.createRoute(points, {
      style: {
        strokeColor: theme.color.propableHistoricRoute,
      },
      zIndex: 200,
      visibility: true,
      ...config,
    });

    this.historicRoute = routeAsSeparatedLines;

    this.addAllToMap(routeAsSeparatedLines);

    return () => {
      this.removeAllFromMap(routeAsSeparatedLines);
    };
  }

  drawPlayFocusDot({ lat, lng }) {
    const circle = this.createCircleMarker({
      coordinateLatitude: lat,
      coordinateLongitude: lng,
    }, followCircleStyle, { volatility: true });
    this.addToMap(circle);
    this.routeFollowCircle = circle;
    return () => {
      this.routeFollowCircle = null;
      this.removeFromMap(circle);
    };
  }

  drawRoute(points, config) {
    const { routeAsSeparatedLines, routeAsOneLine } = this.createRoute(points, config);
    this.addAllToMap(routeAsSeparatedLines);
    return { routeAsSeparatedLines, routeAsOneLine };
  }

  showRouteToIndex(index) {
    if (this.route && this.route.routeAsSeparatedLines) {
      this.drawCurrentLocation(index);
      this.route.routeAsSeparatedLines.forEach((step, i) => {
        step.setVisibility(i < index);
      });
    }
  }

  resetInitialCenter() {
    this.initCenterAtLocation = true;
  }

  showWholeRoute() {
    if (this.route) {
      this.route.routeAsSeparatedLines.forEach((step) => step.setVisibility(false));
    }
  }

  toggleHistoricRoute(value) {
    if (this.historicRoute) {
      this.historicRoute.forEach((step) => step.setVisibility(value));
    }
    if (this.route) {
      this.route.routeAsSeparatedLines.forEach((step) => step.setVisibility(!value));
    }
  }

  clearMap() {
    this.routeFollowCircle = null;
    super.clearMap();
    info('Clearing map');
  }

  drawCurrentLocation(index) {
    const point = this.route.routeAsSeparatedLines[Math.max(0, index - 1)];
    if (point) {
      const { lat, lng } = point.getData();
      this.updateRouteFollowCircle(lat, lng);
    }
  }

  updateRouteFollowCircle(lat, lng) {
    if (this.routeFollowCircle) {
      this.routeFollowCircle.setGeometry({
        lat,
        lng,
      });
    }
  }

  renderTraceAnalysis({ vehicle, trace, config = {} }) {
    this.lastTraceRenderer = new LastTraceRenderer({
      mapEventDispatcher: this.mapEventDispatcher,
      vehicle,
      trace,
      ui: this.ui,
      t: this.t,
      onDispose: () => {
        this.mapEventDispatcher.dispatch({
          type: MapEventType.NEW_BOUNDING,
          payload: {
            bounding: findBoundingBox(this.dataPoints),
          },
        });
        this.lastTraceRenderer = null;
      },
      onApply: () => {
        this.closeAllBubbles();
      },
      config: {
        ...defaultLastTraceConfig, ...config,
      },
    });
    return this.lastTraceRenderer.apply();
  }

  tapEventIcon({ eventType, eventId }) {
    switch (eventType) {
      case 'break':
        if (this.lastTraceRenderer) {
          // eslint-disable-next-line no-unused-expressions
          this.stops && this.stops.forEach((s) => s.closeBubble());
          this.lastTraceRenderer.tapMarker(eventId, true);
        }
        break;
      case 'stop': {
        const stop = this.stops && this.stops.find(({ id }) => id === eventId);
        if (stop) {
          this.lastTraceRenderer.closeInfoBubbles();
          stop.onTap();
          this.zoomTo(stop.position, false, true);
        }
        break;
      }
      default:
        break;
    }
  }
}

export default VehicleDetailsMapManager;
