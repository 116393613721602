/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isFeatureEnabled } from '../../store/selectors';
import { FeatureToggle } from '../../store/actions';

const FeatureRoute = ({
  component: Component, feature, disabledIfPresent, fallbackRoute, ...rest
}) => {
  const enabled = useSelector(isFeatureEnabled(feature));

  return (
    <Route
      {...rest}
      render={(props) => (
        (disabledIfPresent ? !enabled : enabled)
          ? <Component {...props} />
          : <Redirect to={fallbackRoute} />
      )}
    />
  );
};

FeatureRoute.propTypes = {
  component: PropTypes.func.isRequired,
  feature: PropTypes.oneOf(Object.values(FeatureToggle)).isRequired,
  fallbackRoute: PropTypes.string,
  disabledIfPresent: PropTypes.bool,
};

FeatureRoute.defaultProps = {
  disabledIfPresent: false,
  fallbackRoute: '/',
};

export default FeatureRoute;
