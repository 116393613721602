import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import {
  getSelectedCompany,
  getVehiclesPagination,
  getVehiclesSorting,
  getVehiclesFilters,
  getCurrentTableConfiguration,
  TableName,
} from '../../selectors';
import { getMappedFiltering } from '../../../types/mappers';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_VEHICLES = axiosActions('VEHICLES_LOAD');
export const FIND_VEHICLES = axiosActions('FIND_VEHICLES_LOAD');

const executeSearchVehicles = (companyId, search, silentRefresh = false) => ({
  type: FETCH_VEHICLES.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'post',
      url: UrlService.vehiclesSearch(companyId),
      data: search,
    },
  },
});

const getMappedSorting = (sorting) => (
  sorting && sorting.order && sorting.field
    ? {
      sortBy: sorting.field === 'vehicleCompany.name' ? 'vehicle.company.name' : null,
      sortDirection: sorting.order === 'ascend' ? 'ASC' : 'DESC',
    }
    : null
);

export const fetchVehicles = (silentRefresh = false) => (dispatch, getState) => {
  const state = getState();
  const selectedCompany = getSelectedCompany(state);
  const pagination = getVehiclesPagination(state);
  const sorting = getVehiclesSorting(state);
  const mappedSorting = getMappedSorting(sorting);
  const filters = getVehiclesFilters(state);
  const mappedFilters = filters ? getMappedFiltering(filters) : {};
  const currentTableConfiguration = getCurrentTableConfiguration(TableName.Fleet)(state);
  return dispatch(executeSearchVehicles(selectedCompany, {
    ...pagination,
    ...mappedFilters,
    ...mappedSorting,
    currentTableConfiguration,
  }, silentRefresh));
};

export const findVehicles = ({ registrationNumber }) => ({
  type: FIND_VEHICLES.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.vehicle(),
      method: 'get',
      params: {
        registrationNumber,
      },
    },
  },
});

export const checkCompanyVehicleRelationExistence = (company, vehicle) => ({
  type: FIND_VEHICLES.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.companyVehicleRelationExists(),
      method: 'post',
      data: {
        company,
        vehicle,
      },
    },
  },
});
