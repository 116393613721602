/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len:0 */
import React from 'react';
import PropTypes from 'prop-types';
import StopIndexTag from './StopIconTag';

const LoadingUnloadingIndexedIcon = ({ index, onClick, style }) => (
  <div onClick={onClick} style={{ ...style, cursor: onClick ? 'pointer' : 'default' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">
      <circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />
      <circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />
      <g id="g840" transform="translate(-0.2408249,0.19361925)">
        <path d="m 5.2408248,11.056381 v 2.9 c 0,0.0552 0.0448,0.1 0.1,0.1 h 9.8000002 c 0.0552,0 0.1,-0.0448 0.1,-0.1 v -2.9" stroke="#ffffff" id="path6" />
        <path d="m 11.736455,6.5526805 v 4.5037005 h 0.75 V 6.5526805 h 1.125 l -1.5,-1.4963 -1.5,1.4963 z" fill="#ffffff" id="path8" />
        <path d="M 8.7822178,9.5957175 V 5.0920179 h -0.75 v 4.5036996 h -1.125 l 1.5,1.4963005 1.5,-1.4963005 z" fill="#ffffff" id="path10" />
      </g>
      <StopIndexTag index={index} />
    </svg>
  </div>
);

LoadingUnloadingIndexedIcon.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
};

LoadingUnloadingIndexedIcon.defaultProps = {
  onClick: null,
  style: {},
};

export default LoadingUnloadingIndexedIcon;
