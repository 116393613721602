import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailsDescriptions from '../../../../components/Styled/Custom/DetailsDescriptions';
import { DateTime, FullWidthStyledCard } from '../../../../components';
import { CardHandler } from '../../compontents/RequestComponent';
import { stopsByTimeSlotAndSequence } from '../../../../types/mappers/request.mapper';

const TourMonitoringDetailsDescription = ({ requestDetails }) => {
  const { t } = useTranslation();

  const toLocationDescription = (address) => `${(address.countryCode || '')}, ${address.postcode} ${address.city},
   ${address.street} ${address.houseNumber} ${(address.localNumber || '')}`;

  const getTourMonitoringDetails = () => (requestDetails.stops || [])
    .sort(stopsByTimeSlotAndSequence)
    .flatMap((stop) => [
      {
        sequence: stop.sequence,
        bolded: true,
        title: `${t('REQUEST.STOP')} ${stop.sequence + 1}`,
        renderDetail: () => t(`REQUEST.STOP_TYPES.${stop.stopType}`),
        loading: () => requestDetails.loading,
      },
      {
        sequence: stop.sequence,
        title: stop.fixedTimeslot ? 'REQUEST.TIMESLOT' : 'PROPERTIES.FROM',
        renderDetail: () => (stop.timeRanges.length > 0 ? (
          <DateTime
            value={stop.timeRanges[0].startDatetime}
          />
        ) : '-'),
        loading: () => requestDetails.loading,
      },
      !stop.fixedTimeslot ? {
        sequence: stop.sequence,
        title: 'PROPERTIES.TO',
        renderDetail: () => (stop.timeRanges.length > 0 ? (
          <DateTime
            value={stop.timeRanges[0].endDatetime}
          />
        ) : '-'),
        loading: () => requestDetails.loading,
      } : null,
      {
        sequence: stop.sequence,
        title: 'REQUEST.PLACE',
        renderDetail: () => toLocationDescription(stop.address),
        loading: () => requestDetails.loading,
      },
    ].filter(Boolean));

  return (
    <CardHandler>
      <FullWidthStyledCard title={t('REQUEST.MONITORING_DETAILS')}>
        <DetailsDescriptions
          detailsSize="small"
          detailsConfig={getTourMonitoringDetails()}
        />
      </FullWidthStyledCard>
    </CardHandler>
  );
};

TourMonitoringDetailsDescription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default TourMonitoringDetailsDescription;
