import { combineReducers } from 'redux';
import { GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY } from '../../actions';
import { normalizeTelematicAccountsForTable } from '../../normalizers';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.START:
      return true;
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.FAIL:
      return false;
    default:
      return state;
  }
};

const accountsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
      return normalizeTelematicAccountsForTable(payload.data);
    default:
      return state;
  }
};

export default combineReducers({
  telematicAccountListForCompanyLoading: loadingReducer,
  telematicAccountListForCompany: accountsReducer,
});
