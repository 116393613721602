import { FleetTabs } from '../../../types/enums/fleet.enum';
import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany, getVehiclesArray, getCompleteSharing } from '../../selectors';
import { ShareDirection } from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';
import { SharingComputedStatus } from '../../../types/sharing.type';

export const SUBMIT_GENERATE_REPORT = axiosActions('GENERATE_REPORT_LOAD');
export const OPEN_GENERATE_REPORT = 'OPEN_GENERATE_REPORT';
export const CLOSE_GENERATE_REPORT = 'CLOSE_GENERATE_REPORT';

const executeOpenGenerateReport = (vehicles, selectedVehicle, fixed = false) => ({
  type: OPEN_GENERATE_REPORT,
  payload: { vehicles, selectedVehicle, fixed },
});

const isOngoingSharing = (status) => status === SharingComputedStatus.Ongoing;

const getVehiclesFromSource = (dataSource, getState) => {
  switch (dataSource) {
    case FleetTabs.VEHICLES: {
      const { vehicles } = getVehiclesArray(getState());
      return vehicles.map(({ vehicle }) => ({
        vehicleId: vehicle.id,
        licencePlateNumber: vehicle.licencePlateNumber,
      }));
    }
    case FleetTabs.SHARING: {
      const { shares, pageState } = getCompleteSharing(getState());
      const vehicleIdToLicence = {};
      shares.data.sharings.forEach((sharing) => {
        const { vehicle, activeStatus } = sharing;
        if (pageState.activeSharingsType === ShareDirection.OUT) {
          vehicleIdToLicence[vehicle.id] = vehicle.licencePlateNumber;
        }
        if (pageState.activeSharingsType === ShareDirection.IN
          && isOngoingSharing(activeStatus)) {
          vehicleIdToLicence[vehicle.id] = vehicle.licencePlateNumber;
        }
      });
      return Object.entries(vehicleIdToLicence)
        .map(([vehicleId, licencePlateNumber]) => ({ vehicleId, licencePlateNumber }));
    }
    default:
      return [];
  }
};

export const openGenerateReport = (selectedVehicle, dataSource) => (dispatch, getState) => {
  const vehicles = getVehiclesFromSource(dataSource, getState);
  dispatch(executeOpenGenerateReport(vehicles, selectedVehicle));
};

export const openGenerateReportForExactVehicle = (vehicleId, licencePlateNumber) => executeOpenGenerateReport(
  [{ vehicleId, licencePlateNumber }],
  { vehicleId, licencePlateNumber },
  true,
);

export const closeGenerateReport = () => ({
  type: CLOSE_GENERATE_REPORT,
});

export const generateReportValidationError = (violation) => ({
  type: SUBMIT_GENERATE_REPORT.FAIL,
  payload: {
    error: violation,
  },
});

const executeSubmitGenerateReport = (vehicleId, momentFrom, momentTo, documentType, companyId) => ({
  type: SUBMIT_GENERATE_REPORT.START,
  payload: {
    request: {
      method: 'post',
      responseType: 'blob',
      url: UrlService.generateReport(),
      data: {
        companyId,
        vehicleId,
        documentType,
        dateFrom: momentFrom.unix(),
        dateTo: momentTo.unix(),
      },
    },
  },
});

export const submitGenerateReport = ({ selectedVehicle, dateRange, type }) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  dispatch(
    executeSubmitGenerateReport(selectedVehicle, dateRange[0], dateRange[1], type, selectedCompany),
  );
};
