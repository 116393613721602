import React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import styled from 'styled-components';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Loader } from '../Structure';
import { isCompanyInitialized } from '../../store/selectors';

const StyledEmpty = styled(Empty)`
  padding-top: 40px;
  
  .ant {
    &-empty {
      &-image {
        height: 50px;
      }
      &-description {
        font-size: 16px;
      }
    }
    &icon {
    font-size: 50px;
    }
  }
`;

const SelectCompanyFirstComponent = () => {
  const { t } = useTranslation();
  const initialized = useSelector(isCompanyInitialized);
  return !initialized ? <Loader text={t('COMPANY.FETCHING')} /> : (
    <StyledEmpty
      description={t('COMMON.SELECT_COMPANY_FIRST')}
      image={<EyeInvisibleOutlined />}
    />
  );
};

export default SelectCompanyFirstComponent;
