import styled from 'styled-components';
import {
  Button, Row, Slider, Typography,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';

export const PlaybackWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
`;

const PlayerButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.backgroundLight2};
  border-color: transparent;
  &:hover {
    border-color: transparent;
      background-color: ${({ theme }) => theme.color.backgroundLight2};
  }
`;

export const PlayButton = styled(PlayerButton)`
  font-size: 20px;
  
  .anticon {
    font-size: 28px
  }
`;

export const SettingsButton = styled(SettingOutlined)`
  font-size: 22px;
  width: 40px;
  color: ${({ open, theme }) => (open ? theme.color.primary : theme.color.defaultText)};
  
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const PlaybackSlider = styled(Slider)`
  z-index: 10;
  margin-top: -7px;
  margin-bottom: 0;
`;

export const PlaybackDetails = styled(Row)`
  line-height: 18px;
  .anticon {
    font-size: 18px;
  }
`;

export const PlaybackDetailsItem = styled.div`
  display: inline-flex;
  .anticon {
    margin-right: 10px;
  }
`;

export const SettingsPopoverContent = styled.div`
  min-width: 200px;
`;

export const SettingsPopoverItem = styled(Typography.Text)`
  line-height: 24px;
`;
