import { combineReducers } from 'redux';
import { ORDERS_SWITCH_TAB, ORDERS_TOGGLE_AUTO_REFRESH } from '../../actions';
import { OrderContext } from '../../../types';

const autoRefreshReducer = (state = true, { type, payload }) => {
  if (type === ORDERS_TOGGLE_AUTO_REFRESH) {
    return payload;
  }
  return state;
};

const activeOrdersTypeReducer = (state = OrderContext.IN, { type, payload }) => {
  if (type === ORDERS_SWITCH_TAB) {
    return payload.nextTab;
  }
  return state;
};

export default combineReducers({
  autoRefresh: autoRefreshReducer,
  activeOrdersType: activeOrdersTypeReducer,
});
