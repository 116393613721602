import { combineReducers } from 'redux';
import { CLEAR_COMPANIES, FETCH_COMPANIES } from '../../actions';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case FETCH_COMPANIES.START:
      return true;
    case FETCH_COMPANIES.SUCCESS:
    case FETCH_COMPANIES.FAIL:
      return false;
    default:
      return state;
  }
};

const companiesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case FETCH_COMPANIES.SUCCESS:
      return payload.data;
    case CLEAR_COMPANIES:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  loading: loadingReducer,
  companies: companiesReducer,
});
