import React from 'react';
import PropTypes from 'prop-types';
import { Highlighteable } from '../../Text';

function prepareStreet(place) {
  if (!place) {
    return null;
  }
  return [place.street, place.houseNumber].filter((v) => v).join(' ');
}

function prepareCountry(place) {
  if (!place) {
    return null;
  }
  const city = [place.postcode, place.city].filter((v) => v).join(' ');
  return [place.country, city].filter((v) => v).join(', ');
}

function prepareCoordinates(latitude, longitude) {
  if (latitude && longitude) {
    return `${parseFloat(latitude).toFixed(4)}, ${parseFloat(longitude).toFixed(4)}`;
  }
  return null;
}

const PlaceInfo = ({
  place, color, latitude, longitude,
}) => {
  const street = prepareStreet(place);
  const country = prepareCountry(place);
  const coordinates = prepareCoordinates(latitude, longitude);

  if (!street && !country) {
    if (coordinates) {
      return (
        <Highlighteable
          content={coordinates}
          color={color}
        />
      );
    }
    return <></>;
  }

  return (
    <span>
      {street && (
        <>
          <Highlighteable
            content={street}
            color={color}
          />
          <br />
        </>
      )}
      {country && (
        <Highlighteable
          content={prepareCountry(place)}
          color={color}
        />
      )}
    </span>
  );
};

PlaceInfo.propTypes = {
  place: PropTypes.shape({
    country: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    houseNumber: PropTypes.string,
  }).isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  color: PropTypes.string.isRequired,
};

PlaceInfo.defaultProps = {
  longitude: null,
  latitude: null,
};

export default PlaceInfo;
