import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const FETCH_COMPANIES = axiosActionsFactory('FETCH_COMPANIES_LOAD');
export const FIND_COMPANY = axiosActionsFactory('FIND_COMPANY_LOAD');
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';

export const clearCompaniesSearch = () => ({
  type: CLEAR_COMPANIES,
});

export const fetchCompanies = (name) => ({
  type: FETCH_COMPANIES.START,
  payload: {
    request: {
      url: UrlService.companiesSearch(),
      params: {
        name,
      },
    },
  },
});

export const findCompanies = ({ taxCountryCode, taxNumber }) => ({
  type: FIND_COMPANY.START,
  payload: {
    request: {
      method: 'get',
      url: UrlService.companies(),
      params: {
        taxCountryCode,
        taxNumber,
      },
    },
  },
});
