import { combineReducers } from 'redux';
import {
  CLOSE_VEHICLES_FILTER_CREATION, CREATE_TABLE_CONFIGURATION, ERASE_CONFIGURATION_DATA,
  FETCH_LAST_USED_TABLE_CONFIGURATION, FETCH_TABLE_CONFIGURATIONS, OPEN_VEHICLES_FILTER_CREATION,
  SET_CURRENT_TABLE_CONFIGURATION, UPDATE_TABLE_CONFIGURATION,
} from '../../actions/personalization';
import { RESET_COMPANY_CONTEXT } from '../../actions/company';
import { fromApiTableConfigurationModel, tableConfigsById } from '../../normalizers';
import { TableName } from '../../selectors';

const handleUpdateTableConfigurationSuccess = (configurations, { data }) => ({
  ...configurations, [data.id]: fromApiTableConfigurationModel(data),
});

function loadingReducer(state = false, { type }) {
  switch (type) {
    case FETCH_TABLE_CONFIGURATIONS.START:
    case CREATE_TABLE_CONFIGURATION.START:
    case UPDATE_TABLE_CONFIGURATION.START:
    case FETCH_LAST_USED_TABLE_CONFIGURATION.START:
      return true;
    case FETCH_TABLE_CONFIGURATIONS.FAIL:
    case CREATE_TABLE_CONFIGURATION.FAIL:
    case UPDATE_TABLE_CONFIGURATION.FAIL:
    case FETCH_LAST_USED_TABLE_CONFIGURATION.FAIL:
    case FETCH_TABLE_CONFIGURATIONS.SUCCESS:
    case CREATE_TABLE_CONFIGURATION.SUCCESS:
    case UPDATE_TABLE_CONFIGURATION.SUCCESS:
    case FETCH_LAST_USED_TABLE_CONFIGURATION.SUCCESS:
      return false;
    default:
      return state;
  }
}

const deductTableName = (configurations) => {
  if (!configurations || !configurations.length) {
    return undefined;
  }
  return configurations[0].tableName;
};

const initialConfigurationState = {
  [TableName.Fleet]: {},
};

const eraseConfigurationData = (state, configurationId) => {
  const { [configurationId]: value, ...withoutErased } = state;
  return withoutErased;
};

function tableConfigurationsReducer(state = initialConfigurationState, { type, payload, meta }) {
  switch (type) {
    case FETCH_TABLE_CONFIGURATIONS.START: {
      return { ...state, actionId: payload.actionId };
    }
    case FETCH_TABLE_CONFIGURATIONS.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      const tableName = deductTableName(payload.data);
      if (!tableName) {
        return state;
      }
      return { ...state, [tableName]: tableConfigsById(payload.data.map(fromApiTableConfigurationModel)) };
    }
    case CREATE_TABLE_CONFIGURATION.SUCCESS:
    case UPDATE_TABLE_CONFIGURATION.SUCCESS:
      return {
        ...state,
        [payload.data.tableName]: handleUpdateTableConfigurationSuccess(state[payload.data.tableName], payload),
      };
    case ERASE_CONFIGURATION_DATA:
      return {
        ...state,
        [payload.tableName]: eraseConfigurationData(state[payload.tableName], payload.configurationId),
      };
    case RESET_COMPANY_CONTEXT:
      return initialConfigurationState;
    default:
      return state;
  }
}

function configurationsCachedReducer(state = {}, { type, payload, meta }) {
  switch (type) {
    case FETCH_TABLE_CONFIGURATIONS.START:
      return { ...state, actionId: payload.actionId };
    case FETCH_TABLE_CONFIGURATIONS.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      const tableName = deductTableName(payload.data);
      if (!tableName) {
        return state;
      }
      return { ...state, [tableName]: true };
    }
    case CREATE_TABLE_CONFIGURATION.SUCCESS:
    case UPDATE_TABLE_CONFIGURATION.SUCCESS:
      return { ...state, [payload.data.tableName]: true };
    case RESET_COMPANY_CONTEXT:
      return {};
    default:
      return state;
  }
}

function currentConfigurationIdReducer(state = { }, { type, payload }) {
  switch (type) {
    case FETCH_LAST_USED_TABLE_CONFIGURATION.SUCCESS:
    case SET_CURRENT_TABLE_CONFIGURATION:
      if (payload.data) {
        return { ...state, [payload.data.tableName]: payload.data.configurationId };
      }
      return state;
    case RESET_COMPANY_CONTEXT:
      return { };
    default:
      return state;
  }
}

function currentConfigurationIdCacheReducer(state = { }, { type, payload }) {
  switch (type) {
    case FETCH_LAST_USED_TABLE_CONFIGURATION.SUCCESS:
    case SET_CURRENT_TABLE_CONFIGURATION:
      if (payload.data) {
        return { ...state, [payload.data.tableName]: true };
      }
      return state;
    case RESET_COMPANY_CONTEXT:
      return { };
    default:
      return state;
  }
}

const initialState = {
  open: false,
  selectedVehicles: [],
  mode: null,
};

function vehicleFilterModalReducer(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case OPEN_VEHICLES_FILTER_CREATION:
      return { ...payload, open: true };
    case CLOSE_VEHICLES_FILTER_CREATION:
      return { ...initialState };
    default:
      return state;
  }
}

export default combineReducers({
  loading: loadingReducer,
  configurations: tableConfigurationsReducer,
  configurationsCached: configurationsCachedReducer,
  currentConfigurationId: currentConfigurationIdReducer,
  currentConfigurationCached: currentConfigurationIdCacheReducer,
  vehicleFilterModal: vehicleFilterModalReducer,
});
