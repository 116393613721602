import { OrderContext } from '../../../types';

const SET_ORDERS_IN_SORTING = 'SET_ORDERS_IN_SORTING';
const SET_ORDERS_OUT_SORTING = 'SET_ORDERS_OUT_SORTING';
const SET_ORDERS_ROOT_SORTING = 'SET_ORDERS_SORTING';

export const SET_ORDERS_SORTING = {
  [OrderContext.IN]: SET_ORDERS_IN_SORTING,
  [OrderContext.OUT]: SET_ORDERS_OUT_SORTING,
  [OrderContext.UNKNOWN]: SET_ORDERS_ROOT_SORTING,
};

export const setOrdersSorting = (newSorting, orderType = OrderContext.UNKNOWN) => ({
  type: SET_ORDERS_SORTING[orderType],
  payload: newSorting,
});
