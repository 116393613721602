/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Chart } from 'chart.js';
import TourService from '../../../../services/TourService';

class BaseChart extends React.Component {
  componentDidMount() {
    this.drawChart();
  }

  componentWillUnmount() {
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) tooltipEl.remove();
  }

  getTooltipConfiguration() {
    const { t, unit } = this.props;
    return {
      enabled: false,

      custom(tooltipModel) {
        let tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        if (tooltipModel.body) {
          const data = tooltipModel.dataPoints[0];

          const innerHtml = `<p><span>${t('Statistics.BaseChart.y-label')}:</span> ${data.yLabel} ${unit}</p>`
            + `<p><span>${t('Statistics.BaseChart.x-label')}:</span> ${new Date(data.xLabel).toLocaleString()}</p>`;

          tooltipEl.innerHTML = innerHtml;
        }

        const position = this._chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
        tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
        tooltipEl.style.pointerEvents = 'none';
      },
    };
  }

  drawChart() {
    const { chartId, indexDivider, mapEventsActive } = this.props;
    const canvas = document.getElementById(chartId);
    const ctx = canvas.getContext('2d');
    const divider = indexDivider ? Number(indexDivider) : 1;
    let chart;
    const chartConfig = {
      type: 'scatter',

      data: {
        datasets: this.generateChartDatasets(),
      },

      options: {
        scales: {
          xAxes: [{
            ticks: {
              type: 'time',
              autoSkip: true,
              maxRotation: 40,
              autoSkipPadding: 5,
              callback(value) {
                return new Date(value).toLocaleString();
              },
            },
          }],
        },
        events: ['mousemove', 'click'],
        onHover: (event, chartElement) => {
          if (mapEventsActive !== false) {
            if (chartElement.length > 0) {
              TourService.setActivePositionByIndex(Math.floor(chartElement[0]._index / divider));
              this.updateChart(chart);
            }
            if (event.type === 'click' && chartElement.length > 0) {
              TourService.centerAtActivePointFcn(Math.floor(chartElement[0]._index / divider));
            }
          }
        },
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        tooltips: this.getTooltipConfiguration(),
      },
    };
    chart = new Chart(ctx, chartConfig);
    TourService.addChartUpdateFunction(this.updateChart.bind(this, chart));
  }

  generateChartDatasets() {
    const { getPoints } = this.props;
    const points = getPoints();
    return [{
      label: 'Distance between unique positions',
      borderColor: 'rgb(255, 99, 132)',
      showLine: true,
      lineTension: 0.1,
      data: points.base,
      pointHitRadius: 5,
      order: 2,
    },
    {
      label: 'Distance between unique positions',
      borderColor: 'rgb(0, 154, 255)',
      pointBorderWidth: 10,
      lineTension: 0.1,
      showLine: true,
      order: 1,
      data: points.selected,
    }];
  }

  updateChart(chart) {
    chart.data.datasets = this.generateChartDatasets();
    chart.update();
  }

  render() {
    const { chartId } = this.props;
    return (
      <div>
        <canvas id={chartId} />
      </div>
    );
  }
}

BaseChart.propTypes = {
  t: PropTypes.func.isRequired,
  unit: PropTypes.string,
  chartId: PropTypes.string.isRequired,
  indexDivider: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mapEventsActive: PropTypes.bool,
  getPoints: PropTypes.func.isRequired,
};

BaseChart.defaultProps = {
  mapEventsActive: true,
  indexDivider: 1,
  unit: '',
};

export default withTranslation()(BaseChart);
