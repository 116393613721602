import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  API_FILTERS, FILTERS, ShareDirection, SKIPPED_FILTERS,
} from './SharesTableComponent.constants';
import {
  clearInterfaceFiltersExcept,
  mergeUpdateInterfaceFilters,
  resetSharingsPagination,
  switchSharingsTab,
} from '../../../../store/actions';
import {
  getActiveSharingsType, getSharingFilters, TableName,
} from '../../../../store/selectors';
import SharesTableHeader from './SharesTableHeader';
import StyledTabs from '../../../../components/Tabs/StyledTabs';
import SharesComponent from './SharesComponent';
import useQuery from '../../../../hooks/useQuery';

const SharesTableComponent = ({
  size, pageSizeOptions, onRefresh, viewportHeight,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const filters = useSelector(getSharingFilters);
  const activeType = useSelector(getActiveSharingsType);

  const updateFilters = (filter) => dispatch(mergeUpdateInterfaceFilters(TableName.Sharings, filter));

  const isApiFilterUpdated = (filter) => filter
    && Object.keys(filter).map((a) => API_FILTERS.includes(a)).reduce((a, b) => a || b);

  const updateFiltersAndRefresh = useCallback((filter) => {
    updateFilters(filter);
    const shouldUpdate = isApiFilterUpdated(filter);
    if (shouldUpdate) {
      dispatch(resetSharingsPagination());
      onRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onRefresh]);

  const clearFiltersAndRefresh = useCallback(() => {
    const shouldRefresh = dispatch(clearInterfaceFiltersExcept(TableName.Sharings, SKIPPED_FILTERS));
    dispatch(resetSharingsPagination());
    if (shouldRefresh) {
      onRefresh();
    }
  }, [dispatch, onRefresh]);

  useEffect(() => {
    const lastActiveTab = filters.SIDE[0];
    const tab = query.get('side') || lastActiveTab || ShareDirection.IN;
    if (tab !== activeType) {
      dispatch(switchSharingsTab(tab));
      updateFiltersAndRefresh({ [FILTERS.SIDE.name]: [tab] });
    }
  }, [query, activeType, dispatch, updateFiltersAndRefresh, filters]);

  const breadcrumbLabel = activeType === ShareDirection.OUT ? t('BREADCRUMB.OUTGOING') : t('BREADCRUMB.RECEIVED');

  return (
    <>
      <BreadcrumbsItem to={`/sharings?side=${activeType}`}>
        { breadcrumbLabel }
      </BreadcrumbsItem>
      <StyledTabs
        defaultActiveKey={ShareDirection.IN}
        activeKey={activeType}
        size="small"
        onChange={(tab) => {
          history.push({
            pathname: '/sharings',
            search: `?side=${tab}`,
          });
        }}
        tabBarExtraContent={(
          <SharesTableHeader
            onRefresh={onRefresh}
            size={size}
            filters={filters}
            clearFilters={clearFiltersAndRefresh}
            updateFilters={updateFiltersAndRefresh}
          />
        )}
      >
        <Tabs.TabPane
          tab={t('BREADCRUMB.OUTGOING')}
          key={ShareDirection.OUT}
        >
          <SharesComponent
            direction={ShareDirection.OUT}
            viewportHeight={viewportHeight}
            size={size}
            onRefresh={onRefresh}
            pageSizeOptions={pageSizeOptions}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('BREADCRUMB.RECEIVED')}
          key={ShareDirection.IN}
        >
          <SharesComponent
            direction={ShareDirection.IN}
            viewportHeight={viewportHeight}
            size={size}
            onRefresh={onRefresh}
            pageSizeOptions={pageSizeOptions}
          />
        </Tabs.TabPane>
      </StyledTabs>
    </>
  );
};

SharesTableComponent.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onRefresh: PropTypes.func.isRequired,
  viewportHeight: PropTypes.number.isRequired,
};

SharesTableComponent.defaultProps = {
  size: 'small',
  pageSizeOptions: ['10', '20', '50', '100', '200'],
};

export default SharesTableComponent;
