import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { UrlService } from '../../../services';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_TOUR_STATISTICS = axiosActions('FETCH_TOUR_STATISTICS_LOAD`');
export const FETCH_TOUR_STATISTIC_POSITIONS = axiosActions('FETCH_TOUR_STATISTIC_POSITIONS_LOAD`');

export const fetchTourStatistics = (tourId, shipperCompanyId) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  return dispatch({
    type: FETCH_TOUR_STATISTICS.START,
    payload: {
      actionId: uuidv4(),
      request: {
        method: 'get',
        url: UrlService.tourStatistics(tourId),
        params: {
          contextCompanyId,
          shipperCompanyId,
        },
      },
    },
  });
};
