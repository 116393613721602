import { combineReducers } from 'redux';
import moment from 'moment';
import { FETCH_VEHICLES, RESET_COMPANY_CONTEXT } from '../../actions';
import { normalizeVehiclesInPlace } from '../../normalizers';

function loadingReducer(state = false, { type }) {
  switch (type) {
    case FETCH_VEHICLES.SUCCESS:
    case FETCH_VEHICLES.FAIL:
      return false;
    case FETCH_VEHICLES.START:
      return true;
    default:
      return state;
  }
}

function updateReducer(state = moment(), { type }) {
  if (type === FETCH_VEHICLES.SUCCESS) {
    return moment();
  }
  return state;
}

function majorReloadReducer(state = false, { type, payload }) {
  switch (type) {
    case FETCH_VEHICLES.SUCCESS:
    case FETCH_VEHICLES.FAIL:
      return false;
    case FETCH_VEHICLES.START:
      return !payload.silentRefresh;
    default:
      return state;
  }
}

function errorReducer(state = null, { type, payload, meta }) {
  switch (type) {
    case FETCH_VEHICLES.START:
      return { ...state, actionId: payload.actionId };
    case FETCH_VEHICLES.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return null;
    case FETCH_VEHICLES.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return payload || null;
    default:
      return state;
  }
}

const dataInitState = {
  vehicles: [],
  totalVehicles: 0,
};

function dataReducer(state = dataInitState, { payload, type, meta }) {
  switch (type) {
    case FETCH_VEHICLES.START:
      return { ...state, actionId: payload.actionId };
    case FETCH_VEHICLES.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return { totalVehicles: payload.data.totalVehicles, vehicles: normalizeVehiclesInPlace(payload.data.vehicles) };
    case RESET_COMPANY_CONTEXT:
      return dataInitState;
    default:
      return state;
  }
}

export default combineReducers({
  data: dataReducer,
  loading: loadingReducer,
  majorReload: majorReloadReducer,
  error: errorReducer,
  lastUpdated: updateReducer,
});
