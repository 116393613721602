import PropTypes from 'prop-types';
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OwnershipType } from '../../../../types';
import {
  activeStatusOptions, FILTERS, ShareDirection, statusOptions,
} from './SharesTableComponent.constants';
import {
  CompanyInfo,
  DateFilterDropdown,
  DateTime,
  Highlighteable,
  InputFilterDropdown,
  SelectFilterDropdown,
  SharingStatus,
  StyledTable,
  TableLoadingComponent,
  VehicleOwnership,
} from '../../../../components';
import {
  getCompleteSharing,
  getFleetPageSelectedRowsKeys,
  getFleetPageState,
  getSharingFilters,
  TableName,
  isFeatureEnabled,
} from '../../../../store/selectors';
import {
  mergeUpdateInterfaceFilters, setSharingsPagination, setSharingsSorting, FeatureToggle,
} from '../../../../store/actions';
import VehicleService from '../../../../services/VehicleService';
import SharingService from '../../../../services/SharingService';
import { asMoment } from '../../../../types/mappers';
import SharesTableActionsComponent from './SharesTableActionsComponent/SharesTableActionsComponent';
import { SharingComputedStatus } from '../../../../types/sharing.type';
import VehiclesTableActionsComponent from '../../../Vehicles/Components/VehicleTable/ActionsComponent';

const SharesComponent = ({
  direction, size, pageSizeOptions, onRefresh, viewportHeight,
}) => {
  const { t } = useTranslation();
  const { pagination, shares, sorting } = useSelector(getCompleteSharing);
  const { selection } = useSelector(getFleetPageState);
  const filters = useSelector(getSharingFilters);
  const selectedRowsKeys = useSelector(getFleetPageSelectedRowsKeys);
  const side = direction;

  const dispatch = useDispatch();

  const updateFilters = (filter) => dispatch(mergeUpdateInterfaceFilters(TableName.Sharings, filter));

  const fleetDisabled = useSelector(isFeatureEnabled(FeatureToggle.DISABLED_FLEET));

  const changePageAndRefresh = useCallback((
    page, pageSize,
  ) => {
    dispatch(setSharingsPagination({ page, size: pageSize }));
    onRefresh();
  }, [dispatch, onRefresh]);

  const setSortingAndRefresh = (sort) => {
    dispatch(setSharingsSorting(sort));
    onRefresh();
  };

  const getSortOrder = (column) => (sorting && sorting.field === column ? sorting.order : false);

  const disabledSharing = (row) => row.ownershipType === OwnershipType.SHARING
  || row.activeStatus !== SharingComputedStatus.Ongoing;

  const disabledReport = (row) => row.activeStatus !== SharingComputedStatus.Ongoing;

  const renderActions = (_, row) => (side === ShareDirection.IN
    ? (
      <VehiclesTableActionsComponent
        vehicle={row.vehicle}
        disabledSharing={disabledSharing(row)}
        disabledReport={disabledReport(row)}
      />
    )
    : (
      <SharesTableActionsComponent
        disabled={side === ShareDirection.IN}
        sharing={row}
        onRefresh={onRefresh}
      />
    )
  );

  const columns = [
    {
      title: t('PROPERTIES.VEHICLE_ID'),
      dataIndex: 'licencePlateNumber',
      key: 'licencePlateNumber',
      render: (_, { vehicleId, vehicle }) => (
        !fleetDisabled ? (
          <Link to={`/vehicles/${vehicleId}`}>
            <Highlighteable
              search={filters.VEHICLE_ID && filters.VEHICLE_ID[0]}
              content={vehicle.licencePlateNumber}
            />
          </Link>
        ) : (
          <Highlighteable
            search={filters.VEHICLE_ID && filters.VEHICLE_ID[0]}
            content={vehicle.licencePlateNumber}
          />
        )
      ),
      filteredValue: filters.VEHICLE_ID,
      onFilter: (value, { vehicle }) => VehicleService.isLicencePlateMatchingFilter(value, vehicle),
      filterDropdown: InputFilterDropdown(FILTERS.VEHICLE_ID, updateFilters, t, true),
      filterIcon: () => <SearchOutlined />,
    },
    {
      title: t('PROPERTIES.TYPE'),
      dataIndex: 'ownershipType',
      key: 'ownershipType',
      width: 90,
      className: 'ant-table-cell-centered',
      render: (_, { ownershipType }) => <VehicleOwnership type={ownershipType} />,
      filteredValue: filters.STATUS,
      filterDropdown: SelectFilterDropdown(FILTERS.STATUS.name, statusOptions, updateFilters, t, true),
      onFilter: (value, vehicle) => VehicleService.isOwnershipTypeMatchingFilter(filters.STATUS, vehicle),
      filterIcon: () => <SearchOutlined />,
    },
    {
      title: t(side === ShareDirection.IN ? 'PROPERTIES.SHARED_FROM' : 'PROPERTIES.SHARED_TO'),
      dataIndex: 'company.name',
      key: 'company.name',
      render: (_, { company }) => (
        <CompanyInfo
          company={company}
          showLocation={false}
          showEmail={side === ShareDirection.OUT}
          highlight={filters.COMPANY_NAME && filters.COMPANY_NAME[0]}
        />
      ),
      filteredValue: filters.COMPANY_NAME,
      filterDropdown: InputFilterDropdown(FILTERS.COMPANY_NAME, updateFilters, t, true),
      onFilter: (filter, vehicle) => SharingService.isCompanyMatchingFilter(filter, side, vehicle),
      filterIcon: () => <SearchOutlined />,
    },
    {
      title: t('PROPERTIES.START_DATE'),
      dataIndex: 'useDateFrom',
      key: 'useDateFrom',
      render: (_, { useDateFrom }) => (<DateTime value={useDateFrom} />),
      filteredValue: asMoment(filters.START_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.START_DATE.name, updateFilters, t),
      filterIcon: () => <CalendarOutlined />,
      defaultSortOrder: 'descend',
      sorter: true,
      sortOrder: getSortOrder('useDateFrom'),
    },
    {
      title: t('PROPERTIES.END_DATE'),
      dataIndex: 'useDateTo',
      key: 'useDateTo',
      sorter: true,
      sortOrder: getSortOrder('useDateTo'),
      render: (_, { useDateTo }) => (<DateTime value={useDateTo} />),
      filteredValue: asMoment(filters.END_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.END_DATE.name, updateFilters, t),
      filterIcon: () => <CalendarOutlined />,
    },
    {
      title: t('PROPERTIES.STATUS'),
      className: 'ant-table-cell-centered',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      sorter: true,
      sortOrder: getSortOrder('activeStatus'),
      width: 120,
      render: (_, { activeStatus }) => (<SharingStatus status={activeStatus} />),
      filteredValue: filters.ACTIVE_STATUS,
      filterDropdown: SelectFilterDropdown(FILTERS.ACTIVE_STATUS.name, activeStatusOptions, updateFilters, t),
      filterIcon: () => <SearchOutlined />,
    },
    {
      visible: true,
      className: 'ant-table-cell-centered ant-table-cell-actions',
      key: 'actions',
      fixed: 'right',
      width: 66,
      render: renderActions,
    },
  ];

  const handleTableChange = (p, f, sorter, { action }) => {
    if (action === 'sort') {
      setSortingAndRefresh(sorter);
    }
  };

  const onRow = useCallback((row) => ({
    onClick: () => {
      if (selection
        && !((row.ownershipType === OwnershipType.SHARING || row.activeStatus !== SharingComputedStatus.Ongoing)
          && side === ShareDirection.IN)) {
        selection.onSelect(row);
      }
    },
  }), [selection, side]);

  const getRowClassName = useCallback((row) => {
    const classes = [];
    if (!selection) {
      classes.push('ant-table-row-nohover');
      if (!row.isActive) {
        classes.push('ant-table-row-inactive');
      }
    } else {
      classes.push('ant-table-row-selectable');
      if (side === ShareDirection.IN
        && (row.ownershipType === OwnershipType.SHARING
          || row.activeStatus !== SharingComputedStatus.Ongoing
          || !row.isActive)) {
        classes.push('ant-table-row-not-selectable');
      }
    }

    return classes.join(' ');
  }, [selection, side]);

  const renderTotal = useCallback((total) => (<span>{t('COMMON.TOTAL', { total })}</span>), [t]);

  return (
    <>
      <StyledTable
        onRow={onRow}
        rowSelection={(selection && {
          ...selection,
          selectedRowKeys: selectedRowsKeys,
          getCheckboxProps: ({ ownershipType, activeStatus }) => ({
            disabled: side === ShareDirection.IN
            && (ownershipType === OwnershipType.SHARING || activeStatus !== SharingComputedStatus.Ongoing),
          }),
        }) || undefined}
        showSorterTooltip={false}
        rowClassName={getRowClassName}
        dataSource={shares.data.sharings}
        rowKey="computedId"
        size={size}
        scroll={{ y: viewportHeight }}
        bordered
        pagination={{
          showSizeChanger: true,
          showTotal: renderTotal,
          pageSizeOptions,
          total: shares.data.totalVehicles,
          disabled: shares.loading,
          current: pagination.page,
          pageSize: pagination.size,
          onChange: changePageAndRefresh,
        }}
        columns={columns}
        loading={TableLoadingComponent(shares.majorReload)}
        onChange={handleTableChange}
      />
    </>
  );
};

SharesComponent.propTypes = {
  direction: PropTypes.oneOf([ShareDirection.IN, ShareDirection.OUT]).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onRefresh: PropTypes.func.isRequired,
  viewportHeight: PropTypes.number.isRequired,
};

SharesComponent.defaultProps = {
  size: 'small',
  pageSizeOptions: ['10', '20', '50', '100', '200'],
};

export default SharesComponent;
