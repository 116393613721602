import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Col, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as vehicleActionCreators from '../../store/actions/vehicleDetails';
import * as pageTitleActionCreators from '../../store/actions/pageTitle.actions';
import * as interfaceConfigurationActionCreators
from '../../store/actions/personalization/interfaceConfiguration.actions';
import {
  analysedTracePropTypes,
  sharedVehicle,
} from '../../types';
import { FleetHeaderComponent } from '../../components';
import { HISTORY_MODE, LAST_LOCATION_MODE } from './VehicleDetails.constants';
import { DetailsRow, VehicleDetailsWrapper } from './VehicleDetails.styled';
import VehicleDetailsMap from './Components/Map/VehicleDetailsMap';
import VehicleDetailsPane from './Components/VehicleDetailsPane';
import VehicleDetailsExtrasBar from './Components/VehicleDetailsExtrasBar';
import {
  getSelectedCompany,
  getVehicleDetailsAnalysedPositionsHistory,
  getVehicleDetailsData,
  getVehicleDetailsRefreshInterval,
  TableName,
} from '../../store/selectors';
import { info } from '../../services/Logging';

class VehicleDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      mapMode: LAST_LOCATION_MODE,
      historyRange: [moment().subtract(1, 'd'), moment()],
      showPropableRoute: true,
    };
  }

  componentDidMount() {
    const { mapMode, historyRange } = this.state;
    if (mapMode === HISTORY_MODE) {
      this.fetchHistory(historyRange);
    }
    this.startFetchingLoop();
    this.fetchInterfaceConfiguration();
  }

  /* eslint no-unused-vars:0 */
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setPageTitle();
    const { selectedCompany, refreshIntervalSeconds } = this.props;
    const { mapMode } = this.state;
    const { selectedCompany: prevCompany, refreshIntervalSeconds: prevInterval } = prevProps;
    const turnedOnAutoRefresh = !prevInterval && refreshIntervalSeconds;
    if (prevCompany !== selectedCompany || turnedOnAutoRefresh) {
      this.refresh(turnedOnAutoRefresh);
    }

    if (prevState) {
      const { mapMode: prevMapMode } = prevState;
      if (prevMapMode && mapMode === HISTORY_MODE && prevMapMode === LAST_LOCATION_MODE) {
        this.setState({ showPropableRoute: true });
      }
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
  }

  handleMapModeChange(event) {
    const { historyRange } = this.state;
    const newMapMode = event.target.value;
    if (newMapMode === HISTORY_MODE) {
      this.fetchHistory(historyRange);
    }
    this.setState({ mapMode: newMapMode });
  }

  handleHistoryRangeChange(range) {
    const { mapMode } = this.state;
    const { t } = this.props;
    if (!range || range.length < 2 || !range[0] || !range[1]) {
      message.warn(t('VEHICLE.INVALID_RANGE'));
      this.setState({ historyRange: range });
      return;
    }

    const snappedRange = this.getSnappedHistory(range);
    this.setState({ historyRange: snappedRange });

    if (mapMode === HISTORY_MODE) {
      this.fetchHistory(snappedRange);
    }
  }

  setPageTitle() {
    const { vehicle, setTitle } = this.props;
    const newRegistrationNumber = vehicle.data && vehicle.data.vehicle && vehicle.data.vehicle.licencePlateNumber;
    if (newRegistrationNumber) {
      setTitle({ title: newRegistrationNumber });
    }
  }

  getSnappedHistory(newHistoryRange) {
    const { t } = this.props;
    if (newHistoryRange[1].diff(newHistoryRange[0], 'h') > 24) {
      message.warn(t('VEHICLE.RANGE_HAD_TO_BE_SHORTENED'));
      return [newHistoryRange[0], newHistoryRange[0].clone().add(1, 'd')];
    }
    return newHistoryRange;
  }

  fetchInterfaceConfiguration() {
    const { fetchLastUsedInterfaceConfiguration } = this.props;
    fetchLastUsedInterfaceConfiguration(TableName.VehicleDetails);
  }

  startFetchingLoop(isAutomatic = false) {
    const {
      fetchVehicleDetails, fetchVehicleRemark, refreshIntervalSeconds, match,
    } = this.props;
    const { intervalId } = this.state;

    clearInterval(intervalId);
    if (refreshIntervalSeconds) {
      info(`Scheduling vehicle details refresh in ${refreshIntervalSeconds}s`);
      const newIntervalId = setTimeout(() => {
        this.startFetchingLoop(true);
      }, refreshIntervalSeconds * 1000);

      this.setState({ intervalId: newIntervalId });
    }

    if (!isAutomatic || refreshIntervalSeconds) {
      fetchVehicleDetails(match.params.vehicleId, match.params.providingCompanyId);
      fetchVehicleRemark(match.params.vehicleId);
    }
  }

  refresh(auto = false) {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }

    this.startFetchingLoop(auto);
  }

  fetchHistory(range) {
    const { fetchVehicleAnalysedPositionsHistory, match } = this.props;
    fetchVehicleAnalysedPositionsHistory(
      match.params.vehicleId,
      range,
      match.params.providingCompanyId,
    );
  }

  render() {
    const {
      match, vehicle, t, positionsHistory, openGenerateReportForExactVehicle, openShareExactVehicle,
      refreshIntervalSeconds, updateRefreshIntervalSeconds,
    } = this.props;

    const { mapMode, historyRange, showPropableRoute } = this.state;

    return (
      <>
        <BreadcrumbsItem to={`/vehicles/${match.params.vehicleId}`}>
          {(vehicle.data && vehicle.data.vehicle && vehicle.data.vehicle.licencePlateNumber)
          || t('BREADCRUMB.VEHICLE_DETAILS')}
        </BreadcrumbsItem>
        <VehicleDetailsWrapper>
          <FleetHeaderComponent
            withConfig={false}
            withRefresh={{
              onRefresh: () => this.refresh(),
              loading: vehicle.loading,
              onUpdateRefreshIntervalSeconds: (val) => updateRefreshIntervalSeconds(TableName.VehicleDetails, val),
              refreshIntervalSeconds,
              lastRefreshed: vehicle.lastUpdated,
              compact: true,
            }}
            onShareVehicles={() => openShareExactVehicle(
              vehicle.data.vehicleId,
              vehicle.data.vehicle.licencePlateNumber,
            )}
            onGenerateReport={() => openGenerateReportForExactVehicle(
              vehicle.data.vehicleId,
              vehicle.data.vehicle.licencePlateNumber,
            )}
            renderInfo={() => (
              <VehicleDetailsExtrasBar
                changePropableRoute={() => {
                  this.setState({ showPropableRoute: !showPropableRoute });
                }}
                propableRoute={showPropableRoute}
                mapMode={mapMode}
                historyRange={historyRange}
                onHistoryRangeChange={(range) => {
                  if (!showPropableRoute) {
                    this.setState({ showPropableRoute: true });
                  }
                  this.handleHistoryRangeChange(range);
                }}
                onMapModeChange={(event) => this.handleMapModeChange(event)}
                size="small"
              />
            )}
          />
          <DetailsRow gutter={[10, 10]}>
            <Col xs={16}>
              <VehicleDetailsMap
                showPropableRoute={showPropableRoute}
                vehicle={vehicle.data}
                mapMode={mapMode}
                trace={positionsHistory.data}
                loadingTrace={positionsHistory.loading}
                historyRange={historyRange}
              />
            </Col>
            <Col xs={8}>
              <VehicleDetailsPane vehicle={vehicle} />
            </Col>
          </DetailsRow>
        </VehicleDetailsWrapper>
      </>
    );
  }
}

VehicleDetailsComponent.propTypes = {
  // domain
  vehicle: PropTypes.shape({
    data: sharedVehicle,
    loading: PropTypes.bool,
    lastUpdated: MomentPropTypes.momentObj,
  }),
  positionsHistory: PropTypes.shape({
    data: analysedTracePropTypes,
    loading: PropTypes.bool,
  }).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  refreshIntervalSeconds: PropTypes.number.isRequired,

  // utils
  match: PropTypes.shape({
    params: PropTypes.shape({
      vehicleId: PropTypes.string,
      providingCompanyId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  fetchLastUsedInterfaceConfiguration: PropTypes.func.isRequired,
  updateRefreshIntervalSeconds: PropTypes.func.isRequired,

  // functions
  fetchVehicleDetails: PropTypes.func.isRequired,
  fetchVehicleRemark: PropTypes.func.isRequired,
  fetchVehicleAnalysedPositionsHistory: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  openGenerateReportForExactVehicle: PropTypes.func.isRequired,
  openShareExactVehicle: PropTypes.func.isRequired,
};

VehicleDetailsComponent.defaultProps = {
  vehicle: {
    data: null,
    loading: false,
  },
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  vehicle: getVehicleDetailsData(state),
  positionsHistory: getVehicleDetailsAnalysedPositionsHistory(state),
  refreshIntervalSeconds: getVehicleDetailsRefreshInterval(state),
});

const actionCreators = {
  fetchVehicleDetails: vehicleActionCreators.fetchVehicleDetails,
  fetchVehicleRemark: vehicleActionCreators.fetchVehicleRemark,
  fetchVehicleAnalysedPositionsHistory: vehicleActionCreators.fetchVehicleAnalysedPositions,
  setTitle: pageTitleActionCreators.setTitle,
  openGenerateReportForExactVehicle: vehicleActionCreators.openGenerateReportForExactVehicle,
  openShareExactVehicle: vehicleActionCreators.openShareExactVehicle,
  updateRefreshIntervalSeconds: interfaceConfigurationActionCreators.updateRefreshInterval,
  fetchLastUsedInterfaceConfiguration:
    interfaceConfigurationActionCreators.fetchLastUsedInterfaceConfiguration,
};

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, actionCreators)(VehicleDetailsComponent),
  ),
);
