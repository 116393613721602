import styled from 'styled-components';
import { Form, Input, Select } from 'antd';

export const SelectWithHiddenTip = styled(Select)`
  .tip {
    display: none;
    padding-left: 10px;
  }
`;

export const InputWithSelectAfter = styled(Input)`
  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.color.backgroundLight1};
    font-size: 18px;
  }
`;

export const InputAffixFormItem = styled(Form.Item)`
  margin-bottom: 0;
  
  .ant-form-item-control-input {
    min-height: 0;
  }
`;
