import styled from 'styled-components';
import {
  Divider, Form, Input, Row, Select, Typography, Checkbox,
} from 'antd';

export const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ _justify }) => _justify || 'space-between'};
`;

export const HStretched = styled(CenteredRow)`
  width: 100%;
  ${({ _position }) => _position && `position: ${_position};`}
  align-items: ${({ _align }) => _align || 'center'};
`;

export const FormInput = styled(Input)`
  max-width: 320px;
  width: 320px;
`;

export const FormSelect = styled(Select)`
  max-width: 320px;
  width: 320px;
`;

export const FormItem = styled(Form.Item)`
  max-width: 320px;
  width: 320px;
  margin-right: 12px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) => (props.bordercolor === 'red' ? 'red' : undefined)};
  }`;

export const Consent = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #333333;
  opacity: 0.8;
`;

export const FormSectionDivider = styled(Divider)`
  margin-top: 7px;
  margin-bottom: 18px;
`;

export const SectionDescription = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.color.gray2};
`;

export const CompressedSection = styled(HStretched)`
  margin-bottom: 12px;
  
  .ant-form-item-label, .ant-form-item-explain {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

export const CompressedMixSection = styled(HStretched)`
  align-items: start;
 
  .ant-form-item-label {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

export const CompressedItem = styled(Form.Item)`
  
  .ant-form-item-label, .ant-form-item-explain {
    display: none;
  }
  
  .ant-form-item-has-error {
    margin-bottom: -22px !important;
  }
`;

export const CompressedItemWithError = styled(Form.Item)`
  
  .ant-form-item-label {
    display: none;
  }
`;

export const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const InlineFlexFullHeight = styled(InlineFlex)`
  height: 100%;
`;

export const FullSpace = styled(HStretched)`
  justify-content: center;
  ${({ height }) => height && `height: ${height};`}
`;

export const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRightSpaced = styled(FlexRight)`
  & > button,
  & > div,
  & > span {
    margin-left: 10px;
  }
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
  max-heigh: 100%;
  overflow-y: auto;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const FlexColumnLeft = styled(FlexColumn)`
  align-items: start;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: ${({ viewportHeight }) => (viewportHeight ? `${viewportHeight}px` : '100%')};

  .H_overlay {
    &::after {
      display: none !important;
    } 
    &::before {
      display: none !important;
    } 
    .H_btn {
      height: auto;
      &.H_active {
        width: 100%;
        background-color: #e1e1e1;
      }
    }
  }
  .H {
    &_ctl {
      &.H_grp {
        background: transparent;
        box-shadow: none;
      }
      & > .H_btn {
        background-color: rgba(31, 38, 42, 0.85);
        text-align: center;

        svg.H_icon {
          fill: #fff !important;
        }
        .H_active {
          background-color: rgb(74, 91, 101);
          svg.H_icon {
            fill: #fff !important;
          }
        }
      }
      .H_btn_zoom_all {
        min-height: 24px;
        transform: translateY(8px);
      }
    }
    &_ui {
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 100%;
    }
    &_ib {
      position: absolute;
      &_content {
        margin: 2px 8px 12px 8px;
        font-family: Roboto, Arial, sans-serif;
      }
      &_body {
        background-color: rgba(255, 255, 255, 0.95);
        bottom: .5em;
        position: absolute;
        color: black;
        font-size: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        z-index: 300;
        margin: 0;
        .triangle-with-shadow {
          width: 20px;
          height: 20px;
          position: absolute;
          overflow: hidden;
          bottom: -4px;
          left: calc(50% - 10px);
          transform: rotate(45deg);
          z-index: 10;
          &:after {
            content: "";
            position: absolute;
            width: 40px;
            height: 10px;
            background: rgba(255, 255, 255, 0.95);
            bottom: 0;
            left: 0;
            transform: rotate(135deg);
          }
        }
        .H_ib_close {
          cursor: pointer;
          background-color: transparent;
          position: absolute;
          right: 1em;
          top: 1em;
          .H_icon {
            fill: rgba(31, 38, 42, 0.85);
            font-size: 5px;
            height: 10px;
          }
        }
        .horizontal-line {
          height: 3px;
          border-bottom: 1px solid #d2d2d6;
          margin-top: 5px;
          margin-bottom: 8px;
        }
      }
      &_tail {
        display: none;
      }
    }
    
    &_legend {
      margin-left: 80px;
      .H_btn {
        width: 110px;
        height: 70px;
      }
        &_icon {
        margin-top: 2px;
      }
    }
    &_noactive {
      cursor: default !important;
      .H_active {
        background-color: rgba(31, 38, 42, 0.85) !important;
      }
    }
    &_l {
      &_horizontal {
        .H_grp {
          &>div:not(:first-child) {
            margin-top: .4em;
          }
          &>.H_btn {
            float: none;
          }
        }
      }
      &_top {
        top: 0;
      }
      &_bottom {
        bottom: 15px;
      }
      &_right {
        position: absolute;
        right: 100%;
        margin-left: -100%;
      }
      &_left {
        position: absolute;
        left: 0;
        margin-left: -100%;
      }
    } 
    &_panel_fullscreen {
      background-color: transparent;
      min-width: 0;
      padding: 0;
      position: static;
      &_button {
        background-color: rgba(31, 38, 42, 0.85) !important;
        text-align: center;
        height: 4em;
        width: 4em !important;
        font-size: 10px;
        padding: 7px;
      }
    }
  }
`;
