import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailsDescriptions from '../../../../components/Styled/Custom/DetailsDescriptions';
import { DateTime, FullWidthStyledCard } from '../../../../components';
import OrderStatus from '../../../Orders/Components/OrderStatusComponent';
import { Content, HalfBolded } from '../../compontents/RequestComponent';
import {
  isAcceptedInfoShown,
  isDeactivated,
  isDeclined, isIncoming, isOutgoing,
} from '../../../../utils/monitoringRequests';

const RequestDetailsDescription = ({ requestDetails }) => {
  const { t } = useTranslation();

  const getDeclineReason = () => (requestDetails.declineInfo.declineReason === 'OTHER'
    ? `Other: ${requestDetails.declineInfo.declineReasonDescription}`
    : t(`REQUEST.DECLINE.${requestDetails.declineInfo.declineReason}`));

  const requestDetailsConfig = [
    {
      title: 'PROPERTIES.STATUS',
      renderDetail: () => (requestDetails.state
        && <OrderStatus status={`REQUEST_${requestDetails.state}`} />),
      loading: () => requestDetails.loading,
    },
    isIncoming(requestDetails) ? {
      title: 'REQUEST.SHIPPER',
      renderDetail: () => requestDetails.shipperInfo?.companyName,
      loading: () => requestDetails.loading,
    } : null,
    isOutgoing(requestDetails) ? {
      title: 'REQUEST.CARRIER',
      renderDetail: () => requestDetails.carrierInfo?.companyName,
      loading: () => requestDetails.loading,
    } : null,
    {
      title: 'REQUEST.LICENSE_PLATE',
      renderDetail: () => (requestDetails.vehicles || []).map((vehicle) => vehicle.licensePlate).join(', '),
      loading: () => requestDetails.loading,
    },
    {
      title: 'REQUEST.CREATION_DATE',
      renderDetail: () => (
        <DateTime
          value={requestDetails.createdAt}
          elapsed={{ layout: 'horizontal' }}
        />
      ),
      loading: () => requestDetails.loading,
    },
    {
      title: 'REQUEST.CREATED_BY',
      renderDetail: () => (
        <>
          <Content>{requestDetails.shipperInfo.userEmail}</Content>
          {requestDetails.shipperInfo.userIp
            ? (
              <Content>
                <HalfBolded>{`[${requestDetails.shipperInfo.userIp}]`}</HalfBolded>
              </Content>
            ) : null}
        </>
      ),
      loading: () => requestDetails.loading,
    },
    isAcceptedInfoShown(requestDetails)
      ? {
        title: 'REQUEST.ACCEPTANCE_DATE',
        renderDetail: () => (requestDetails.acceptedInfo.present
          && requestDetails.acceptedInfo.acceptedAt
          ? (
            <DateTime
              value={requestDetails.acceptedInfo.acceptedAt}
              elapsed={{ layout: 'horizontal' }}
            />
          ) : '-'
        ),
        loading: () => requestDetails.loading,
      } : null,
    isAcceptedInfoShown(requestDetails)
      ? {
        title: 'REQUEST.ACCEPTED_BY',
        renderDetail: () => (requestDetails.acceptedInfo.present
          ? (
            <>
              <Content>{requestDetails.acceptedInfo.acceptedByEmail}</Content>
              {requestDetails.acceptedInfo.acceptedFromIp
                ? (
                  <Content>
                    <HalfBolded>{`[${requestDetails.acceptedInfo.acceptedFromIp}]`}</HalfBolded>
                  </Content>
                ) : null }
            </>
          ) : '-'),
        loading: () => requestDetails.loading,
      } : null,
    isDeclined(requestDetails)
      ? {
        title: 'REQUEST.DECLINE_DATE',
        renderDetail: () => (requestDetails.declineInfo.present
          && requestDetails.declineInfo.declinedAt > 0
          ? (
            <DateTime
              value={requestDetails.declineInfo.declinedAt}
              elapsed={{ layout: 'horizontal' }}
            />
          ) : '-'
        ),
        loading: () => requestDetails.loading,
      } : null,
    isDeclined(requestDetails)
      ? {
        title: 'REQUEST.DECLINED_BY',
        renderDetail: () => (requestDetails.declineInfo.present
          ? (
            <>
              <Content>{requestDetails.declineInfo.declinedByEmail}</Content>
              { requestDetails.declineInfo.declinedFromIp
                ? (
                  <Content>
                    <HalfBolded>{`[${requestDetails.declineInfo.declinedFromIp}]`}</HalfBolded>
                  </Content>
                ) : null }
            </>
          ) : '-'),
        loading: () => requestDetails.loading,
      } : null,
    isDeclined(requestDetails)
      ? {
        title: 'REQUEST.DECLINED_REASON',
        renderDetail: () => (requestDetails.declineInfo.present
          ? (
            <Content>
              <HalfBolded>{getDeclineReason()}</HalfBolded>
            </Content>
          ) : '-'),
        loading: () => requestDetails.loading,
      } : null,
    isDeactivated(requestDetails)
      ? {
        title: 'REQUEST.DEACTIVATED_DATE',
        renderDetail: () => (requestDetails.deactivateInfo.present
          && requestDetails.deactivateInfo.deactivatedAt > 0
          ? (
            <DateTime
              value={requestDetails.deactivateInfo.deactivatedAt}
              elapsed={{ layout: 'horizontal' }}
            />
          ) : '-'
        ),
        loading: () => requestDetails.loading,
      } : null,
    isDeactivated(requestDetails)
      ? {
        title: 'REQUEST.DEACTIVATED_BY',
        renderDetail: () => (requestDetails.deactivateInfo.present
          ? (
            <>
              <Content>{requestDetails.deactivateInfo.deactivatedByEmail}</Content>
              <Content>
                <HalfBolded>{`[${requestDetails.deactivateInfo.deactivatedFromIp}]`}</HalfBolded>
              </Content>
            </>
          ) : '-'),
        loading: () => requestDetails.loading,
      } : null,
  ].filter(Boolean);

  return (
    <FullWidthStyledCard title={t('REQUEST.REQUEST_DETAILS')}>
      <DetailsDescriptions
        detailsSize="small"
        detailsConfig={requestDetailsConfig}
      />
    </FullWidthStyledCard>
  );
};

RequestDetailsDescription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default RequestDetailsDescription;
