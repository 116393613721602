/* eslint-disable max-len */
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { message } from 'antd';
import { copyIcon } from '../../assets/icons';

const TripInfoContent = ({
  companyName, licencePlateNumber, lat, lng, timestamp,
}, t) => {
  const timestampMoment = moment.unix(timestamp);
  window.copyAndNotify = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success(t('COMMON.COPIED_TO_CLIPBOARD')));
  };

  return '<div class="H_bubble_root">'
    + ' <div class="H_bubble_wrapper">'
    + '    <div class="H_bubble_content">'
    + '      <span class="H_bubble_content--company">'
    + `        ${(companyName || '').trim()}`
    + '      </span>'
    + '      <div class="H_bubble_content--vehicle-id">'
    + `        <span>${licencePlateNumber}</span>`
    + '      </div>'
    + '      <div class="horizontal-line"></div>'
    + `      <p class="H_bubble_content--label">${t('PROPERTIES.DATE_TIME')}</p>`
    + '      <p class="H_bubble_content--data">'
    + `        ${timestampMoment ? timestampMoment.format('DD-MM-YYYY HH:mm') : '–'}`
    + '      </p>'
    + `      <p class="H_bubble_content--label" style="margin-bottom:-3px">${t('PROPERTIES.COORDINATES')}</p>`
    + `      <p class="H_bubble_content--data">${lat}, ${lng}`
    + `      <button class="H_bubble_copy_button ant-btn ant-btn-sm ant-btn-icon-only ant-btn-link" onclick="window.copyAndNotify('${lat},${lng}')">`
    + `        <span class="anticon">${copyIcon}</span>`
    + '      </button>'
    + '      </p>'
    + '    </div>'
    + '    <div class="triangle-with-shadow"></div>'
    + '  </div>'
    + '</div>';
};

TripInfoContent.propTypes = {
  companyName: PropTypes.string.isRequired,
  licencePlateNumber: PropTypes.string.isRequired,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default TripInfoContent;
