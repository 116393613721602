import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileTextOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { vehiclesTableRow } from '../../../../types';
import { FleetTabs } from '../../../../types/enums/fleet.enum';
import { openGenerateReport, openShareVehicles } from '../../../../store/actions';
import { getFleetSelectionMode } from '../../../../store/selectors';

const ActionsComponent = ({ vehicle, disabledSharing, disabledReport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectionMode = useSelector(getFleetSelectionMode);
  const generateReport = useCallback(
    () => {
      dispatch(openGenerateReport({
        vehicleId: vehicle.vehicleId || vehicle.id,
        licencePlateNumber: vehicle.licencePlateNumber,
      }, FleetTabs.VEHICLES));
    },
    [dispatch, vehicle],
  );
  const shareVehicle = useCallback(
    () => {
      dispatch(openShareVehicles([{
        vehicleId: vehicle.vehicleId || vehicle.id,
        licencePlateNumber: vehicle.licencePlateNumber,
      }], FleetTabs.VEHICLES));
    },
    [dispatch, vehicle],
  );

  return (
    <>
      <Tooltip
        placement="left"
        title={t('VEHICLE.SHARE_VEHICLE')}
      >
        <Button
          type="link"
          disabled={disabledSharing || selectionMode}
          icon={<ShareAltOutlined />}
          onClick={shareVehicle}
        />
      </Tooltip>
      <Tooltip
        placement="left"
        title={t('VEHICLE.GENERATE_REPORT')}
      >
        <Button
          type="link"
          disabled={disabledReport || selectionMode}
          icon={<FileTextOutlined />}
          onClick={generateReport}
        />
      </Tooltip>
    </>
  );
};

ActionsComponent.propTypes = {
  vehicle: vehiclesTableRow.isRequired,
  disabledSharing: PropTypes.bool,
  disabledReport: PropTypes.bool,
};

ActionsComponent.defaultProps = {
  disabledSharing: false,
  disabledReport: false,
};

export default ActionsComponent;
