import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { OrderStatusPropTypes, OrderStatus as OrderStatusEnum } from '../../../types/enums/orderType.enum';

const configByStatus = {
  INACTIVE: { text: 'PROPERTIES.INACTIVE', color: 'default' },
  ACTIVE: { text: 'PROPERTIES.ACTIVE', color: 'green' },
  FUTURE: { text: 'PROPERTIES.FUTURE', color: 'blue' },
  EXPIRED: { text: 'PROPERTIES.EXPIRED', color: 'default' },
  UNKNOWN: { text: 'PROPERTIES.UNKNOWN_STATUS', color: 'default' },
  REQUEST_PENDING: { text: 'PROPERTIES.REQUEST_PENDING', color: 'blue' },
  REQUEST_ACCEPTED: { text: 'PROPERTIES.REQUEST_ACCEPTED', color: 'green' },
  REQUEST_DECLINED: { text: 'PROPERTIES.REQUEST_DECLINED', color: 'default' },
  REQUEST_DEACTIVATED: { text: 'PROPERTIES.REQUEST_DEACTIVATED', color: 'default' },
  REQUEST_ACCEPT: { text: 'PROPERTIES.REQUEST_ACCEPT', color: '#27AE60' },
  REQUEST_DECLINE: { text: 'PROPERTIES.REQUEST_DECLINE', color: 'default' },
};

const DynamicTag = styled(Tag)`
  ${({ size }) => {
    switch (size) {
      case 'medium':
        return 'font-size: 18px; padding: 5px; margin: 5px';
      case 'large':
        return 'font-size: 22px';
      case 'small':
      default:
        return '';
    }
  }} 
`;

const OrderStatus = ({ status, size }) => {
  const { t } = useTranslation();

  return (
    <DynamicTag size={size} color={configByStatus[status].color} className="ant-tag-single">
      {t(configByStatus[status].text)}
    </DynamicTag>
  );
};

OrderStatus.propTypes = {
  status: OrderStatusPropTypes,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
};

OrderStatus.defaultProps = {
  status: OrderStatusEnum.UNKNOWN,
  size: 'small',
};

export default OrderStatus;
