/* eslint-disable max-len */
const clock = '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <path d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z" fill="#2F80ED"/>'
  + '    <path d="M9.38847 8.73085L7.43887 7.32128V3.9375C7.43887 3.87734 7.38965 3.82812 7.32949 3.82812H6.67187C6.61172 3.82812 6.5625 3.87734 6.5625 3.9375V7.70273C6.5625 7.73827 6.57891 7.77109 6.60762 7.79159L8.86894 9.44042C8.91816 9.47597 8.98652 9.46503 9.02207 9.41718L9.41308 8.88398C9.44863 8.83339 9.43769 8.76503 9.38847 8.73085Z" fill="#2F80ED"/>'
  + '</svg>';

const lastUpdate = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <path d="M14.7656 6.4375H10.7656C10.6968 6.4375 10.6406 6.49375 10.6406 6.5625V7.3125C10.6406 7.38125 10.6968 7.4375 10.7656 7.4375H14.7656C14.8343 7.4375 14.8906 7.38125 14.8906 7.3125V6.5625C14.8906 6.49375 14.8343 6.4375 14.7656 6.4375ZM12.6718 8.5625H10.7656C10.6968 8.5625 10.6406 8.61875 10.6406 8.6875V9.4375C10.6406 9.50625 10.6968 9.5625 10.7656 9.5625H12.6718C12.7406 9.5625 12.7968 9.50625 12.7968 9.4375V8.6875C12.7968 8.61875 12.7406 8.5625 12.6718 8.5625ZM7.45776 5.03906H6.7812C6.68433 5.03906 6.6062 5.11719 6.6062 5.21406V9.08906C6.6062 9.14531 6.63276 9.19688 6.67808 9.22969L9.00464 10.9266C9.08276 10.9828 9.19214 10.9672 9.24839 10.8891L9.64995 10.3406V10.3391C9.7062 10.2609 9.68901 10.1516 9.61089 10.0953L7.6312 8.66406V5.21406C7.63276 5.11719 7.55308 5.03906 7.45776 5.03906Z" fill="#2F80ED"/>'
  + '    <path d="M12.575 10.5296H11.6719C11.5844 10.5296 11.5016 10.5749 11.4547 10.6499C11.2563 10.964 11.025 11.2546 10.7594 11.5202C10.3016 11.978 9.76876 12.3374 9.17657 12.5874C8.56251 12.8468 7.91095 12.978 7.23907 12.978C6.56564 12.978 5.91407 12.8468 5.30157 12.5874C4.70939 12.3374 4.17657 11.978 3.71876 11.5202C3.26095 11.0624 2.90157 10.5296 2.65157 9.93739C2.3922 9.32489 2.26095 8.67333 2.26095 7.99989C2.26095 7.32645 2.3922 6.67645 2.65157 6.06239C2.90157 5.4702 3.26095 4.93739 3.71876 4.47958C4.17657 4.02177 4.70939 3.66239 5.30157 3.41239C5.91407 3.15302 6.5672 3.02177 7.23907 3.02177C7.91251 3.02177 8.56407 3.15302 9.17657 3.41239C9.76876 3.66239 10.3016 4.02177 10.7594 4.47958C11.025 4.7452 11.2563 5.03583 11.4547 5.34989C11.5016 5.42489 11.5844 5.4702 11.6719 5.4702H12.575C12.6828 5.4702 12.7516 5.3577 12.7031 5.26239C11.6844 3.23583 9.61876 1.91864 7.31251 1.89208C3.93595 1.84989 1.13126 4.61395 1.12501 7.98739C1.11876 11.3671 3.85782 14.1093 7.23751 14.1093C9.57345 14.1093 11.6734 12.7874 12.7031 10.7374C12.7516 10.6421 12.6813 10.5296 12.575 10.5296Z" fill="#2F80ED"/>'
  + '</svg>';

const speed = '<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <path d="M12.5867 3.71374L11.7667 4.94707C12.1621 5.73579 12.3558 6.61029 12.3302 7.49222C12.3046 8.37415 12.0606 9.23595 11.62 10.0004H2.38003C1.80748 9.00714 1.57023 7.85582 1.70346 6.71712C1.83669 5.57842 2.33331 4.51296 3.11969 3.67869C3.90607 2.84443 4.94034 2.28577 6.06919 2.08554C7.19804 1.88531 8.36136 2.05416 9.3867 2.56707L10.62 1.74707C9.36436 0.941884 7.87491 0.581076 6.38996 0.722364C4.905 0.863652 3.51033 1.49887 2.42903 2.52641C1.34773 3.55396 0.642275 4.91445 0.425512 6.39028C0.208749 7.86611 0.4932 9.372 1.23337 10.6671C1.34971 10.8686 1.51676 11.0362 1.71792 11.1531C1.91908 11.2701 2.14735 11.3323 2.38003 11.3337H11.6134C11.8483 11.3347 12.0793 11.2735 12.283 11.1564C12.4867 11.0394 12.6559 10.8705 12.7734 10.6671C13.3876 9.60302 13.6959 8.38992 13.6641 7.16171C13.6323 5.9335 13.2618 4.73795 12.5934 3.70707L12.5867 3.71374ZM6.06003 8.27374C6.18386 8.39771 6.33092 8.49605 6.49278 8.56315C6.65464 8.63025 6.82815 8.66479 7.00337 8.66479C7.17859 8.66479 7.35209 8.63025 7.51395 8.56315C7.67582 8.49605 7.82287 8.39771 7.9467 8.27374L11.72 2.61374L6.06003 6.38707C5.93606 6.5109 5.83772 6.65796 5.77062 6.81982C5.70352 6.98168 5.66898 7.15519 5.66898 7.33041C5.66898 7.50563 5.70352 7.67913 5.77062 7.84099C5.83772 8.00286 5.93606 8.14991 6.06003 8.27374Z" fill="#2F80ED"/>'
  + '</svg>';

export default {
  clock,
  lastUpdate,
  speed,
};
