import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { estimatedEtaPropTypes } from '../../../types';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';
import { StopTimeStatus, StopTimeStatusDisplay } from './StopTimeStatusLabel';

const StopEta = ({
  eta, scheduledArrival, calculateEta, loading, tooltipKey,
}) => {
  const { t } = useTranslation();
  const [arrivalStatus, setArrivalStatus] = useState(null);
  useEffect(() => {
    if (!eta || !scheduledArrival) {
      setArrivalStatus(null);
    } else {
      setArrivalStatus(eta.eta.arrival <= scheduledArrival ? StopTimeStatus.ON_TIME : StopTimeStatus.TOO_LATE);
    }
  }, [eta, scheduledArrival]);

  const viewTimeToEnd = (seconds) => {
    let totalSeconds = seconds;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    return hours === 0 ? `${minutes}min` : `${hours}h ${minutes}min`;
  };

  const viewLengthToEndInKm = (meters) => `${Math.floor(meters / 1000)}km left`;

  return (
    <Row justify="end">
      <div style={{ textAlign: 'right' }}>
        {calculateEta && (
          <Tooltip title={tooltipKey && t(tooltipKey)} placement="topRight">
            <Button
              size="small"
              style={{ padding: 0, margin: 0 }}
              type="link"
              onClick={calculateEta}
              icon={(eta || loading) && <SyncOutlined />}
              loading={loading}
              disabled={loading}
            >
              {eta ? t('ORDER.REFRESH_ETA') : t('ORDER.CALCULATE_ETA')}
            </Button>
          </Tooltip>
        )}
        {calculateEta && eta && (
          <>
            <p style={{ margin: 0 }}>{moment.unix(eta.eta.arrival).format(DEFAULT_DATE_TIME)}</p>
            <p style={{ margin: 0, opacity: 0.6 }}>
              {`${viewTimeToEnd(eta.eta.duration)}, ${viewLengthToEndInKm(eta.eta.length)}`}
            </p>
          </>
        )}
        {arrivalStatus && (
          <StopTimeStatusDisplay style={{ marginLeft: '6px' }} status={arrivalStatus}>
            {t(`ORDER.STOP_TIME_STATUS.${arrivalStatus}`)}
          </StopTimeStatusDisplay>
        )}
      </div>
    </Row>
  );
};

StopEta.propTypes = {
  eta: estimatedEtaPropTypes,
  calculateEta: PropTypes.func,
  loading: PropTypes.bool,
  scheduledArrival: PropTypes.number,
  tooltipKey: PropTypes.string,
};

StopEta.defaultProps = {
  eta: null,
  calculateEta: null,
  loading: false,
  scheduledArrival: null,
  tooltipKey: null,
};

export default StopEta;
