import React from 'react';
import { Route } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import RequestsContentHolder from './RequestsContentHolder';
import { getSelectedCompany } from '../../store/selectors';
import RequestCreationPage from './RequestCreation/RequestCreationPage';
import RequestDetails from './RequestDetails/RequestDetailsComponent';

export default () => {
  const { t } = useTranslation();
  const selectedCompany = useSelector(getSelectedCompany);

  return (
    <>
      <BreadcrumbsItem to="/orders">{t('BREADCRUMB.MONITORINGS')}</BreadcrumbsItem>
      {
        !selectedCompany ? <SelectCompanyFirstComponent /> : (
          <>
            <Route
              exact
              path="/requests"
              component={() => <RequestsContentHolder context="outgoing" />}
            />
            <Route
              exact
              path="/requests/outgoing"
              component={() => <RequestsContentHolder context="outgoing" />}
            />
            <Route
              exact
              path="/requests/incoming"
              component={() => <RequestsContentHolder context="incoming" />}
            />
            <Route
              exact
              path="/requests/outgoing/new"
              component={RequestCreationPage}
            />
            <Route
              exact
              path="/requests/:requestId/details"
              component={RequestDetails}
            />
          </>
        )
      }
    </>
  );
};
