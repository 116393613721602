import { ShareDirection } from '../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';

const isCompanyEmailAddressMatchingFilter = (filter, { company }) => (
  !filter || (
    company && company.emailAddresses && company.emailAddresses.length
    && company.emailAddresses[0].toLowerCase().includes(filter.toLowerCase())
  ));

const isCompanyNameMatchingFilter = (filter, { company }) => (
  !filter || (
    company && company.name && company.name.toLowerCase().includes(filter.toLowerCase())
  ));

const isCompanyMatchingFilter = (filter, sharingDirection, vehicle) => isCompanyNameMatchingFilter(filter, vehicle) || (
  sharingDirection === ShareDirection.OUT && isCompanyEmailAddressMatchingFilter(filter, vehicle)
);

export default {
  isCompanyMatchingFilter,
};
