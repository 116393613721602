/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Checkbox, Col, Form, Input, Row,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FlexColumn, HStretched } from '../Styled';
import StopLocationSection from './StopLocationSection';
import StopDatesSection from './StopDatesSection';
import OrderCreationMap from '../../modules/Orders/OrderCreation/Components/OrderCreationMap';

const StopSection = ({
  field, remove, possibleRemove, form, index,
}) => {
  const { t } = useTranslation();

  return (
    <HStretched
      key={field.key}
      _align="flex-start"
      _justify="flex-start"
      _position="relative"
      style={{ marginBottom: '12px' }}
    >
      <Form.Item
        {...field}
        hidden
        initialValue={index}
        key={`sequence-${index}`}
        name={[field.name, 'sequence']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        hidden
        key={`id-${index}`}
        name={[field.name, 'id']}
      >
        {/* <Input /> */}
      </Form.Item>

      <Card align="baseline" style={{ width: '100%' }}>
        <Row gutter={36}>
          <Col xs={10}>
            <StopLocationSection field={field} form={form} />
            <Row type="flex" align="left" style={{ marginTop: '20px' }}>
              <Col xs={16}>
                <StopDatesSection field={field} form={form} />
              </Col>
              <Col xs={2} />
              <Col xs={6}>
                <Form.Item
                  {...field}
                  style={{ minWidth: '120px' }}
                  label={t('ORDER.STOP_TYPE')}
                  name={[field.name, 'stopType']}
                  rules={[{
                    required: true,
                    message: t('COMMON.SELECT_STOP_TYPE'),
                  }]}
                >
                  <Checkbox.Group>
                    <FlexColumn>
                      <Checkbox style={{ marginLeft: '8px' }} value="loading">
                        {t('ORDER.STOP_TYPE_LOADING')}
                      </Checkbox>
                      <Checkbox value="unloading">
                        {t('ORDER.STOP_TYPE_UNLOADING')}
                      </Checkbox>
                    </FlexColumn>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={14}>
            <div style={{ opacity: 0.7 }}>{t('PROPERTIES.STOP_MAP_DESCRIBE')}</div>
            <OrderCreationMap form={form} field={field} />
          </Col>
        </Row>
      </Card>
      {possibleRemove ? (
        <Button
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: '#777',
          }}
          size="small"
          type="link"
          icon={<CloseOutlined />}
          onClick={() => remove(field.name)}
        />
      ) : null }
    </HStretched>
  );
};

StopSection.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  remove: PropTypes.func.isRequired,
  possibleRemove: PropTypes.bool,
  form: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

StopSection.defaultProps = {
  possibleRemove: false,
};

export default StopSection;
