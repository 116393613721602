import { MonitoringRequestType } from '../request.type';
import { RequestStopType } from '../stop.type';

export const findSelectedCompany = (request, companies) => companies.find(({ id }) => id === request.carrierCompanyId);
const findSelectedVehicle = (request, vehicles) => vehicles && vehicles.find(({ id }) => id === request.vehicleId);

const prepareTimeMonitoringInfo = (requestType, request) => (requestType === MonitoringRequestType.TIME ? {
  timeRange: {
    startDatetime: request.startDateTime.startOf('minute').unix(),
    endDatetime: request.endDateTime.startOf('minute').unix(),
  },
} : null);

const defineStopType = (stop) => {
  if (stop.stopType.includes('loading')) {
    return RequestStopType.LOADING;
  }
  return RequestStopType.UNLOADING;
};

const mapStop = (stop) => ({
  sequence: stop.sequence,
  address: {
    countryCode: stop.stopCtry,
    postcode: stop.postcode,
    city: stop.city,
    street: stop.street,
    houseNumber: stop.streetNumber,
    localNumber: stop.localNumber,
  },
  stopType: defineStopType(stop),
  fixedTimeslot: stop.fixed !== undefined && stop.fixed,
  timeRanges: [
    {
      startDatetime: stop.startDateTime.startOf('minute').unix(),
      endDatetime: stop.endDateTime.startOf('minute').unix(),
    },
  ],
});

export const stopsBy = (func) => (a, b) => {
  if (func(a) < func(b)) {
    return -1;
  }

  if (func(a) > func(b)) {
    return 1;
  }

  return 0;
};

export const stopsByTimeSlotAndSequence = stopsBy((stop) => stop.timeRanges[0].startDatetime,
  stopsBy((stop) => stop.sequence));

export const mapStops = (stops) => {
  if (!stops) {
    return [];
  }
  return stops.map((stop) => mapStop(stop)).sort(stopsByTimeSlotAndSequence);
};

const prepareTourMonitoringInfo = (requestType, request) => (
  requestType === MonitoringRequestType.TOUR ? {
    tourStops: mapStops(request.stops),
  }
    : null);

const formToApi = (request, { loggedUserId, contextCompanyId }, vehicles, requestType = MonitoringRequestType.TIME) => {
  const selectedVehicle = findSelectedVehicle(request[0], vehicles);
  return {
    orderNumber: request[0].internalId,
    createdBy: loggedUserId,
    additionalInfo: request[0].shipperName,
    shipperCompanyId: contextCompanyId,
    carrierCompanyId: request[0].carrierCompanyId,
    carrierVehicle: {
      vehicleId: request[0].vehicleId,
      licensePlate: selectedVehicle.licencePlateNumber,
    },
    monitoringType: requestType.toUpperCase(),
    timeMonitoring: prepareTimeMonitoringInfo(requestType, request[1]),
    tourMonitoring: prepareTourMonitoringInfo(requestType, request[1]),
  };
};

export const RequestMapper = {
  formToApi,
};
