import { FILTERS } from '../modules/Vehicles/Components/VehicleTable/VehiclesTable.constants';

const isCompanyMatchingFilter = (filter, { vehicleCompany }) => {
  const { name, country, city } = vehicleCompany;
  return !filter || (name && name.toLowerCase().includes(filter.toLowerCase()))
    || (country && country.toLowerCase().includes(filter.toLowerCase()))
    || (city && city.toLowerCase().includes(filter.toLowerCase()));
};

const isLicencePlateMatchingFilter = (value, { licencePlateNumber }) => (
  !value || (licencePlateNumber && licencePlateNumber.toLowerCase().includes(value.toLowerCase())));

const isMakeMatchingFilter = (value, { make }) => !value || (make && make.toLowerCase().includes(value.toLowerCase()));

const isVehicleMatchingAllFilters = (vehicle, filters) => {
  if (!vehicle || !filters) {
    return true;
  }

  const fullFilters = Object.entries(filters)
    .map(([key, val]) => [FILTERS[key], val])
    .filter(([key, val]) => key.tableFilter && val && val.length);
  return !fullFilters.length
    || fullFilters.every(([filter, value]) => filter.tableFilter(vehicle, value));
};

const isOwnershipTypeMatchingFilter = (filter, { ownershipType }) => !filter || filter.includes(ownershipType);

const isActiveStatusMatchingFilters = (filter, { activeStatus }) => !filter || filter.includes(activeStatus);

const filterVehicles = (vehicles, filters) => (vehicles || []).filter(({ vehicle }) => (
  isVehicleMatchingAllFilters(vehicle, filters)));

export default {
  isCompanyMatchingFilter,
  isLicencePlateMatchingFilter,
  isVehicleMatchingAllFilters,
  isOwnershipTypeMatchingFilter,
  isMakeMatchingFilter,
  filterVehicles,
  isActiveStatusMatchingFilters,
};
