import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToursMapManager from './ToursMapManager';
import { TourService } from '../../../../services';
import { MapWrapper } from '../../../../components';

const ToursMap = ({ mapUseCase }) => {
  const [mapExists, setMapExists] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!mapExists) {
      const manager = new ToursMapManager({ refId: 'tours-map', t });
      manager.addCenterAtButton();
      setMapExists(true);
      switch (mapUseCase) {
        case 'singleTourStatistics':
          TourService.drawTourRoute();
          break;
        default:
          break;
      }
    }
  }, [mapExists, mapUseCase, t]);

  return (
    <MapWrapper id="tours-map" />
  );
};

ToursMap.propTypes = {
  mapUseCase: PropTypes.string.isRequired,
};

export default ToursMap;
