import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Input, Modal, Radio, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import OrderStatus from '../Orders/Components/OrderStatusComponent';
import { DeclineModel, DeclineModelDesc, DeclineRadio } from './compontents/RequestComponent';
import {
  acceptMonitoringRequestCommand,
  declineMonitoringRequestCommand,
} from '../../store/actions/requests/request.action';
import { getMeId } from '../../store/selectors';
import { isSuccess } from '../../utils/responseUtils';

const { TextArea } = Input;

export const RADIO_OPTION = {
  INVALID_VEHICLE_NUMBER: 'INVALID_VEHICLE_NUMBER',
  SHARING_DISAGREE: 'SHARING_DISAGREE',
  INVALID_MONITORING_DETAILS: 'INVALID_MONITORING_DETAILS',
  OTHER: 'OTHER',
};

const ActionIncomingRequest = ({
  shipper, monitoringRequestId, carrierCompanyId, postAction, size,
}) => {
  const dispatch = useDispatch();
  const [cause, setCause] = useState(false);
  const [causeDesc, setCauseDesc] = useState();
  const [decline, setDecline] = useState(false);
  const { t } = useTranslation();
  const userId = useSelector(getMeId);

  const invoke = (action, command) => {
    dispatch(action(command)).then((response) => {
      if (isSuccess(response)) {
        setDecline(false);
        postAction();
      }
    });
  };

  const accept = () => {
    invoke(acceptMonitoringRequestCommand, {
      monitoringRequestId,
      carrierCompanyId,
      data: {
        acceptedBy: userId,
      },
    });
  };

  return (
    <>
      <Modal
        title=""
        width={571}
        closeIcon={(<></>)}
        visible={decline}
        centered="true"
        type="confirm"
        footer={[
          <>
            <Row justify="center" style={{ marginBottom: '30px', marginTop: '10px' }}>
              <Button
                type="text"
                onClick={() => setDecline(false)}
              >
                <u>{t('COMMON.CANCEL')}</u>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const command = {
                    monitoringRequestId,
                    carrierCompanyId,
                    data: {
                      declineReason: cause,
                      description: causeDesc,
                      declinedBy: userId,
                    },
                  };
                  invoke(declineMonitoringRequestCommand, command);
                }}
              >
                {t('COMMON.CONFIRM')}
              </Button>
            </Row>
          </>,
        ]}
      >
        <>
          <Row justify="center">
            <DeclineModel>{t('REQUEST.DECLINE.HEADER')}</DeclineModel>
          </Row>
          <Row>
            <Col sm={3} />
            <Col>
              <DeclineModelDesc>
                {t('REQUEST.DECLINE.DESCRIPTION')}

              </DeclineModelDesc>
            </Col>
          </Row>
          <Row style={{ marginTop: '35px' }}>
            <Col sm={2} />
            <Col sm={22}>
              <Radio.Group name="radiogroup" value={cause} onChange={(e) => setCause(e.target.value)}>
                <Row>
                  <Radio value={RADIO_OPTION.INVALID_VEHICLE_NUMBER}>
                    <DeclineRadio>
                      {t(`REQUEST.DECLINE.${RADIO_OPTION.INVALID_VEHICLE_NUMBER}`)}
                    </DeclineRadio>
                  </Radio>
                </Row>
                <Row style={{ marginTop: '11px' }}>
                  <Radio value={RADIO_OPTION.SHARING_DISAGREE}>
                    <DeclineRadio>
                      {t(`REQUEST.DECLINE.${RADIO_OPTION.SHARING_DISAGREE}`)}
                      {' '}
                      {shipper}
                      .
                    </DeclineRadio>
                  </Radio>
                </Row>
                <Row style={{ marginTop: '11px' }}>
                  <Radio value={RADIO_OPTION.INVALID_MONITORING_DETAILS}>
                    <DeclineRadio>
                      {t(`REQUEST.DECLINE.${RADIO_OPTION.INVALID_MONITORING_DETAILS}`)}
                    </DeclineRadio>
                  </Radio>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <Radio value={RADIO_OPTION.OTHER} />
                  </Col>
                  <Col>
                    <DeclineRadio>
                      <TextArea
                        style={{ width: 395 }}
                        showCount="true"
                        maxLength={100}
                        value={causeDesc}
                        placeholder={t('REQUEST.DECLINE.TEXT_AREA_PLACEHOLDER')}
                        onClick={() => setCause(RADIO_OPTION.OTHER)}
                        onChange={(e) => setCauseDesc(e.target.value)}
                      />
                    </DeclineRadio>
                  </Col>
                </Row>
                <Row />
              </Radio.Group>
            </Col>
          </Row>
        </>
      </Modal>
      <Row justify="center">
        <Col>
          <Button size={size} style={{ border: 'none' }} onClick={() => accept()}>
            <OrderStatus size={size} status="REQUEST_ACCEPT" />
          </Button>
        </Col>
        <Col>
          <Button size={size} style={{ border: 'none' }} onClick={() => setDecline(true)}>
            <OrderStatus size={size} status="REQUEST_DECLINE" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

ActionIncomingRequest.propTypes = {
  monitoringRequestId: PropTypes.string.isRequired,
  shipper: PropTypes.string.isRequired,
  carrierCompanyId: PropTypes.string.isRequired,
  postAction: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
};

ActionIncomingRequest.defaultProps = {
  size: 'small',
};

export default ActionIncomingRequest;
