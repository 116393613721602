import { combineReducers } from 'redux';
import requestsOutgoingReducer from './requestsOutgoing.reducer';
import requestsIncomingReducer from './requestsIncoming.reducer';
import actionReducer from './request.reducer';
import createRequestReducer from './createRequest.reducer';
import requestDetailsReducer from './requestDetails.reducer';

export default combineReducers({
  requestsOugoing: requestsOutgoingReducer,
  requestsIncoming: requestsIncomingReducer,
  action: actionReducer,
  createRequest: createRequestReducer,
  details: requestDetailsReducer,
});
