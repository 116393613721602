/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Form, Input, Select,
} from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { COUNTRIES } from '../../utils/constants/countries';
import {
  CompressedItem, CompressedItemWithError, CompressedMixSection, CompressedSection,
} from '../Styled';
import SuggesterLocationService from '../../services/SuggesterLocationService';

const countriesOptions = COUNTRIES.map(({ isoCode }) => ({
  value: isoCode,
  label: isoCode,
}));

const LocationLabel = styled.p`
  font-weight: 500;
  margin-bottom: 8px;
`;

const StopLocationSection = ({ field, form }) => {
  const { t } = useTranslation();
  const [phrase, setPhrase] = useState('');
  const stopCountry = Form.useWatch(['stops', field.key, 'stopCtry'], form);
  const postcode = Form.useWatch(['stops', field.key, 'postcode'], form);
  const city = Form.useWatch(['stops', field.key, 'city'], form);
  const street = Form.useWatch(['stops', field.key, 'street'], form);
  const streetNumber = Form.useWatch(['stops', field.key, 'streetNumber'], form);
  const { data, isLoading } = useQuery(['address-suggestion', phrase],

    async () => {
      if (phrase?.length > 2) {
        return SuggesterLocationService.searchAddress(phrase);
      }
      return null;
    });

  const mapToLabel = (d) => d?.suggestions?.map((item) => ({
    value: item.locationId,
    label: item.label,
  }));

  const fillAddress = (locationId) => {
    const choiceAddress = data?.suggestions?.find((address) => address.locationId === locationId);
    form.setFields([{
      name: ['stops', field.key, 'stopCtry'],
      value: choiceAddress?.language?.toUpperCase(),
    },
    {
      name: ['stops', field.key, 'postcode'],
      value: choiceAddress?.address?.postalCode,
    },
    {
      name: ['stops', field.key, 'city'],
      value: choiceAddress?.address?.city,
    },
    {
      name: ['stops', field.key, 'street'],
      value: choiceAddress?.address?.street,
    },
    {
      name: ['stops', field.key, 'streetNumber'],
      value: choiceAddress?.address?.houseNumber,
    },
    ]);
  };

  const clearSearchStopAddress = () => {
    setPhrase('');
    form.setFields([{
      name: ['stops', field.key, 'searchStopAddress'],
      value: '',
    }]);
  };

  return (
    <>
      <LocationLabel>{t('PROPERTIES.FILL_IN_ADDRESS')}</LocationLabel>
      <Form.Item
        {...field}
        style={{
          width: '100%',
        }}
        key={`${field.key}searchStopAddress`}
        name={[field.name, 'searchStopAddress']}
        fieldKey={[field.name, 'searchStopAddress']}
        autoComplete="address-suggestion"
      >
        <Select
          clearIcon={<CloseCircleOutlined />}
          suffixIcon={phrase === ''
            ? <SearchOutlined /> : <CloseCircleOutlined onClick={() => clearSearchStopAddress()} />}
          filterOption={false}
          placeholder={t('ORDER.SEARCH_STOP_ADDRESS')}
          options={isLoading ? [] : mapToLabel(data)}
          autoComplete="address-suggestion"
          showSearch
          onSearch={(v) => setPhrase(v)}
          onChange={(e) => fillAddress(e)}
        // prevents chrome country autofill
          onFocus={(event) => {
            event.target.setAttribute('autocomplete', 'address-suggestion');
          }}
          optionFilterProp="label"
        />
      </Form.Item>
      <CompressedSection>
        <Form.Item
          {...field}
          style={{
            minWidth: '100px',
            marginRight: '12px',
          }}
          label={t('ORDER.COUNTRY')}
          key={`${field.key}ctry`}
          name={[field.name, 'stopCtry']}
          fieldKey={[field.name, 'stopCtry']}
          autoComplete="new-password"
          rules={[{
            required: true,
            message: t('ORDER.COUNTRY_REQUIRED'),
          }]}
        >
          <Select
            placeholder={t('ORDER.COUNTRY_PROMPT')}
            options={countriesOptions}
            autoComplete="new-password"
            showSearch
            // prevents chrome country autofill
            onFocus={(event) => {
              event.target.setAttribute('autocomplete', 'new-password');
            }}
            optionFilterProp="label"
            disabled={stopCountry === undefined}
          />
        </Form.Item>
        <Form.Item
          {...field}
          style={{
            width: '100%',
            marginRight: '12px',
          }}
          label={t('ORDER.POSTCODE')}
          key={`${field.key}postcode`}
          name={[field.name, 'postcode']}
          fieldKey={[field.name, 'postcode']}
          rules={[
            { required: true, message: t('ORDER.POSTCODE_REQUIRED') },
            { type: 'string', max: 8, message: t('COMMON.VALIDATION.MAX_8') },
          ]}
          disabled={postcode === undefined}
        >
          <Input placeholder={t('ORDER.POSTCODE_PROMPT')} disabled={postcode === undefined} />
        </Form.Item>
        <Form.Item
          {...field}
          style={{
            width: '100%',
          }}
          label={t('ORDER.CITY')}
          key={`${field.key}city`}
          name={[field.name, 'city']}
          fieldKey={[field.name, 'city']}
          rules={[
            { required: true, message: t('ORDER.CITY_REQUIRED') },
            { type: 'string', max: 50, message: t('COMMON.VALIDATION.MAX_50') },
          ]}
        >
          <Input placeholder={t('ORDER.CITY_PROMPT')} disabled={city === undefined} />
        </Form.Item>
      </CompressedSection>
      <CompressedMixSection>
        <CompressedItemWithError
          {...field}
          style={{
            width: '100%',
            marginRight: '12px',
          }}
          label={t('ORDER.STREET')}
          key={`${field.key}street`}
          name={[field.name, 'street']}
          fieldKey={[field.name, 'street']}
          rules={[
            { required: true, message: t('COMMON.FILL_IN_FIELDS_ABOVE') },
            { type: 'string', max: 50, message: t('COMMON.VALIDATION.MAX_50') },
          ]}
        >
          <Input placeholder={t('ORDER.STREET_PROMPT')} disabled={street === undefined} />
        </CompressedItemWithError>
        <CompressedItem
          {...field}
          style={{ width: '100px', marginRight: '12px' }}
          key={`${field.key}nr`}
          label={t('ORDER.NR')}
          name={[field.name, 'streetNumber']}
          fieldKey={[field.name, 'streetNumber']}
          rules={[
            { required: true },
            { type: 'string', max: 10, message: t('COMMON.VALIDATION.MAX_10') },
          ]}
        >
          <Input placeholder={t('ORDER.NR_PROMPT')} disabled={streetNumber === undefined} />
        </CompressedItem>
        <CompressedItem
          {...field}
          style={{ width: '100px' }}
          key={`${field.key}lnr`}
          label={t('ORDER.LOCAL_NUMBER')}
          name={[field.name, 'localNumber']}
          fieldKey={[field.name, 'localNumber']}
          rules={[{ type: 'string', max: 10, message: t('COMMON.VALIDATION.MAX_10') }]}
        >
          <Input placeholder={t('ORDER.LOCAL_NUMBER_PROMPT')} />
        </CompressedItem>
      </CompressedMixSection>
      <CompressedSection />
      <LocationLabel style={{ opacity: 0.5 }}>{t('PROPERTIES.OPTIONAL_STOP_DETAILS')}</LocationLabel>
      <CompressedMixSection>
        <CompressedItemWithError
          {...field}
          style={{ width: '100%' }}
          key={`${field.key}lnr`}
          label={t('ORDER.STOP_NAME')}
          name={[field.name, 'name']}
          fieldKey={[field.name, 'name']}
          rules={[
            { type: 'string', min: 3, message: t('COMMON.VALIDATION.BETWEEN_3_250') },
            { type: 'string', max: 250, message: t('COMMON.VALIDATION.BETWEEN_3_250') },
          ]}
        >
          <Input placeholder={t('ORDER.STOP_NAME_PROMPT')} />
        </CompressedItemWithError>
      </CompressedMixSection>
      <CompressedSection />
      <CompressedMixSection>
        <CompressedItemWithError
          {...field}
          style={{ width: '100%' }}
          key={`${field.key}lnr`}
          label={t('ORDER.STOP_ADDITIONAL_NAME')}
          name={[field.name, 'additionalInfo']}
          fieldKey={[field.name, 'additionalInfo']}
          rules={[
            { type: 'string', min: 3, message: t('COMMON.VALIDATION.BETWEEN_3_250') },
            { type: 'string', max: 250, message: t('COMMON.VALIDATION.BETWEEN_3_250') },
          ]}
        >
          <Input placeholder={t('ORDER.STOP_ADDITIONAL_NAME_PROMPT')} />
        </CompressedItemWithError>
      </CompressedMixSection>
    </>
  );
};

StopLocationSection.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,

};

export default StopLocationSection;
