import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Badge, Button, Dropdown, Menu, Modal, Select, Tooltip,
} from 'antd';
import {
  CloseOutlined, DeleteOutlined, DownOutlined, EditOutlined, FilterOutlined, TeamOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import CompanyFilterModal from './CompanyFilterModal';
import { TruckIcon } from '../Styled/Icons';
import {
  getCurrentTableConfigurationId, getFleetPageState, getFleetSelectionMode, getTableConfigurations,
  isTableConfigurationsLoading, TableName,
} from '../../store/selectors';
import { SelectionMode, SelectionType } from '../../types/enums/fleet.enum';
import { CloseSelectionButton, FlexRightSpaced } from '../Styled';
import { stringPropSorter } from '../../utils/comparators';
import { getTypeIcon, ViewType } from './TableConfigurationComponent.constants';
import {
  createTableConfiguration, deleteConfiguration, openNewVehiclesFilter, resetFleetSelectionMode,
  resetSharingsPagination,
  resetVehiclesPagination, setFleetSelectionMode,
  setLastUsedTableConfiguration, updateLastUsedTableConfiguration, updateTableConfiguration,
} from '../../store/actions';
import { getConfigurationType, toRowSelection } from '../../types/mappers';
import { ConfigurationType } from '../../types';
import NOP from '../../utils/NOP';

const ViewActionButton = styled(Button)`
  height: 24px;
  max-width: 18px;
  min-width: 18px;
  line-height: 20px;
`;

const Wrapper = styled(FlexRightSpaced)`
  .ant-tooltip-disabled-compatible-wrapper {
    height: 24px;
  }
`;

const TableConfigurationComponent = ({
  size, tableName, disabled, onRefresh, onConfigurationSelected,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectionMode = useSelector(getFleetSelectionMode);
  const { selection, selectedRows } = useSelector(getFleetPageState);
  const configurations = useSelector(getTableConfigurations(tableName));
  const loading = useSelector(isTableConfigurationsLoading);
  const selectedConfigurationId = useSelector(getCurrentTableConfigurationId(tableName));

  const [modalOpen, setCompanyModalOpen] = useState(false);
  const [editConfiguration, setEditConfiguration] = useState(null);
  const [configurationsToShow, setConfigurationsToShow] = useState([]);

  useEffect(() => {
    if (configurations) {
      setConfigurationsToShow(
        Object.values(configurations)
          .map(({ id, name, filters }) => ({
            id, name, filters, type: getConfigurationType(filters),
          }))
          .filter(({ type }) => !!type)
          .sort(stringPropSorter('name')),
      );
    }
  }, [configurations]);

  const updateCurrentConfiguration = (newConfigurationId) => {
    dispatch(updateLastUsedTableConfiguration(tableName, newConfigurationId));
    onConfigurationSelected();
  };

  const setCurrentConfiguration = (newConfigurationId) => {
    dispatch(setLastUsedTableConfiguration(tableName, newConfigurationId));
  };

  const onNewVehiclesTracking = () => {
    if (!selection || selection.mode == null) {
      setEditConfiguration(null);
      setCurrentConfiguration(null);
      dispatch(setFleetSelectionMode({
        mode: SelectionMode.VEHICLES_FILTER,
        type: SelectionType.CHECKBOX,
      }));
      onRefresh();
    } else if (selection.mode === SelectionMode.VEHICLES_FILTER) {
      dispatch(openNewVehiclesFilter(selectedRows, editConfiguration, updateCurrentConfiguration));
    }
  };

  const renderConfigurations = (configsToRender) => configsToRender.map(({ id, name, filters }) => (
    <Select.Option value={id} key={id} label={name}>
      {getTypeIcon(filters)}
      {' '}
      {name}
    </Select.Option>
  ));

  const filterByLabel = (input, option) => option.label.toLowerCase()
    .indexOf(input.toLowerCase()) >= 0;

  const initializeCompanyFilterCreation = () => {
    setEditConfiguration(null);
    setCompanyModalOpen(true);
  };

  const initializeEdit = () => {
    const currentConfiguration = configurations[selectedConfigurationId];
    setEditConfiguration(currentConfiguration);
    const configType = getConfigurationType(currentConfiguration.filters);
    if (configType === ConfigurationType.Companies) {
      setCompanyModalOpen(true);
    }
    if (configType === ConfigurationType.Vehicles) {
      setCurrentConfiguration(null);
      dispatch(setFleetSelectionMode({
        mode: SelectionMode.VEHICLES_FILTER,
        type: SelectionType.CHECKBOX,
      }, toRowSelection(currentConfiguration.filters)));
    }
  };

  const deleteCurrentConfiguration = () => {
    if (!selectedConfigurationId) {
      return;
    }

    dispatch(deleteConfiguration(TableName.Fleet, selectedConfigurationId)).then(onRefresh);
  };

  const isSelectVehicleFilterMode = () => selectionMode === SelectionMode.VEHICLES_FILTER;

  const selectionForOtherModeInProgress = (mode) => selectionMode && selectionMode !== mode;

  const startCreatingNewView = ({ key: type }) => {
    if (type === ViewType.Vehicle) {
      onNewVehiclesTracking();
    } else if (type === ViewType.Company) {
      initializeCompanyFilterCreation();
    }
  };

  const menu = (
    <Menu onClick={startCreatingNewView}>
      <Menu.Item key={ViewType.Vehicle} icon={<TruckIcon style={{ marginRight: '8px' }} />}>
        {t('PERSONALIZATION.NEW_VEHICLES_FILTER')}
      </Menu.Item>
      <Menu.Item key={ViewType.Company} icon={<TeamOutlined />}>
        {t('PERSONALIZATION.NEW_COMPANIES_FILTER')}
      </Menu.Item>
    </Menu>
  );

  const dispatchSaveConfiguration = (configuration) => {
    if (configuration.id) {
      dispatch(resetVehiclesPagination());
      dispatch(resetSharingsPagination());
      return dispatch(updateTableConfiguration(tableName, configuration));
    }
    return dispatch(createTableConfiguration(tableName, configuration));
  };

  const renderVehicleSelectionConfirmation = () => (
    <>
      <Badge
        count={isSelectVehicleFilterMode() ? selectedRows.length : 0}
        title={selectedRows.map((row) => row.licencePlateNumber)
          .join(', ')}
      >
        <Button
          size={size}
          type="primary"
          icon={<TruckIcon />}
          disabled={loading || selectionForOtherModeInProgress(SelectionMode.VEHICLES_FILTER) || !selectedRows.length}
          onClick={onNewVehiclesTracking}
        >
          {t('PERSONALIZATION.CONFIRM_SELECTION')}
        </Button>
      </Badge>
      <Tooltip
        placement="top"
        title={t('PERSONALIZATION.EXIT_VEHICLE_SELECTION')}
      >
        <CloseSelectionButton
          icon={<CloseOutlined />}
          type="primary"
          ghost
          size={size}
          onClick={() => dispatch(resetFleetSelectionMode())}
        />
      </Tooltip>
    </>
  );

  const confirmDeletion = () => {
    Modal.confirm({
      title: t('COMMON.CONFIRM'),
      content: t('PERSONALIZATION.CONFIRM_DELETE'),
      onOk: deleteCurrentConfiguration,
      okText: t('COMMON.DELETE'),
      cancelText: t('COMMON.CANCEL'),
    });
  };

  return (
    <Wrapper>
      {isSelectVehicleFilterMode() && renderVehicleSelectionConfirmation()}
      {!isSelectVehicleFilterMode() && (
        <Dropdown overlay={menu} trigger={['click']} size={size} disabled={disabled || loading}>
          <Button
            disabled={disabled || loading}
            onClick={(e) => e.preventDefault()}
            size={size}
            icon={<FilterOutlined />}
          >
            {t('PERSONALIZATION.NEW_VIEW')}
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
      <Select
        allowClear
        dropdownMatchSelectWidth={false}
        loading={loading}
        disabled={disabled || loading}
        style={{ minWidth: '200px' }}
        size={size}
        showSearch
        placeholder={t('PERSONALIZATION.FIND_FILTERS_CONFIGURATION')}
        optionFilterProp="children"
        filterOption={filterByLabel}
        value={(
          configurationsToShow && configurationsToShow.length
          && configurationsToShow.find(({ id }) => id === selectedConfigurationId))
          ? selectedConfigurationId : null}
        onSelect={updateCurrentConfiguration}
        onClear={updateCurrentConfiguration}
      >
        {renderConfigurations(configurationsToShow)}
      </Select>
      <Tooltip placement="topLeft" title={t('PERSONALIZATION.EDIT_SELECTED_VIEW')}>
        <ViewActionButton
          type="link"
          disabled={!selectedConfigurationId || disabled || loading}
          size="medium"
          icon={<EditOutlined />}
          onClick={initializeEdit}
        />
      </Tooltip>
      <Tooltip placement="topLeft" title={t('PERSONALIZATION.DELETE_SELECTED_VIEW')}>
        <ViewActionButton
          type="link"
          disabled={!selectedConfigurationId || disabled || loading}
          size="medium"
          onClick={confirmDeletion}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
      <CompanyFilterModal
        onConfigurationSelected={onConfigurationSelected}
        configuration={editConfiguration}
        onOk={dispatchSaveConfiguration}
        open={modalOpen}
        onCancel={() => setCompanyModalOpen(false)}
      />
    </Wrapper>
  );
};

TableConfigurationComponent.propTypes = {
  tableName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  onRefresh: PropTypes.func,
  onConfigurationSelected: PropTypes.func,
};

TableConfigurationComponent.defaultProps = {
  size: 'small',
  disabled: false,
  onRefresh: NOP,
  onConfigurationSelected: NOP,
};

export default TableConfigurationComponent;
