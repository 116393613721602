import { SET_SHARING_SORTING } from '../../actions/sharing';

const INIT_SORTING = {
  order: 'descend',
  field: 'useDateFrom',
};

export default (state = { ...INIT_SORTING }, { type, payload }) => {
  switch (type) {
    case SET_SHARING_SORTING:
      return { ...payload };
    default:
      return state;
  }
};
