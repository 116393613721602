import React from 'react';
import PropTypes from 'prop-types';
import { Highlighteable } from '../../Text';

const PersonInfo = ({
  email, firstName, lastName, highlight,
}) => (
  <>
    {(firstName || lastName)
    && (
    <>
      <span>
        <Highlighteable
          content={firstName}
          search={highlight}
        />
        {' '}
        <Highlighteable
          content={lastName}
          search={highlight}
        />
      </span>
      <br />
    </>
    )}
    <Highlighteable
      content={email}
      search={highlight}
    />
  </>
);

PersonInfo.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  highlight: PropTypes.string,
};

PersonInfo.defaultProps = {
  highlight: null,
  email: null,
  firstName: null,
  lastName: null,
};

export default PersonInfo;
