export const SET_CARRIERS_PAGINATION = 'SET_CARRIERS_PAGINATION';
export const RESET_CARRIERS_PAGINATION = 'RESET_CARRIERS_PAGINATION';

export const setCarriersPagination = ({ page, size }) => ({
  type: SET_CARRIERS_PAGINATION,
  payload: {
    page,
    size,
  },
});

export const resetCarriersPagination = () => ({
  type: RESET_CARRIERS_PAGINATION,
});
