import { combineReducers } from 'redux';
import accountFormConfig from './accountFormConfig.reducer';
import telematicAccountListForCompany from './accountList.reducer';
import telematicAccountForEdit from './accountGet.reducer';

export default combineReducers({
  accountFormConfig,
  telematicAccountListForCompany,
  telematicAccountForEdit,
});
