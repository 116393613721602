import { combineReducers } from 'redux';
import { GET_TELEMATIC_ACCOUNT_FORM_CONFIG } from '../../actions';
import { normalizeTelematicAccountsFormConfig } from '../../normalizers';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT_FORM_CONFIG.START:
      return true;
    case GET_TELEMATIC_ACCOUNT_FORM_CONFIG.SUCCESS:
    case GET_TELEMATIC_ACCOUNT_FORM_CONFIG.FAIL:
      return false;
    default:
      return state;
  }
};

const telematicAccountFormConfigReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT_FORM_CONFIG.SUCCESS:
      return normalizeTelematicAccountsFormConfig(payload.data);
    default:
      return state;
  }
};

export default combineReducers({
  telematicAccountFormConfigLoading: loadingReducer,
  telematicAccountFormConfig: telematicAccountFormConfigReducer,
});
