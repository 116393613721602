import moment from 'moment';
import { RESET_COMPANY_CONTEXT } from '../../actions/company';
import { FETCH_EMAIL_SHARES } from '../../actions/emailsharing';
import { getCleanMoment } from '../../../utils/dateTimeUtils';

const sharingInitState = {
  loading: false,
  majorReload: false,
  error: null,
  lastUpdated: moment(),
  data: {
    page: 1,
    size: 10,
    totalVehicles: 0,
    sharings: [],
  },
};

export default function sharesReducer(state = sharingInitState, { type, payload, meta }) {
  switch (type) {
    case FETCH_EMAIL_SHARES.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }

      const sharings = payload.data.sharings.map((sharing) => ({
        orderId: sharing.orderId,
        transportOrderNumber: sharing.transportOrderNumber,
        sharedTo: sharing.email,
        secret: sharing.secret,
        link: sharing.link,
        startDate: getCleanMoment(sharing.startDate),
        endDate: getCleanMoment(sharing.endDate),
        creationDate: getCleanMoment(sharing.createDate),
      }));

      return {
        ...state,
        data: {
          page: 1,
          size: 1,
          sharings,
        },
        loading: false,
        majorReload: false,
        error: undefined,
        lastUpdated: moment(),
      };
    }
    case FETCH_EMAIL_SHARES.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        majorReload: false,
        error: payload,
        data: sharingInitState.data,
        lastUpdated: moment(),
      };
    case FETCH_EMAIL_SHARES.START:
      return {
        ...state, loading: true, majorReload: !payload.silentRefresh, actionId: payload.actionId,
      };
    case RESET_COMPANY_CONTEXT:
      return { ...sharingInitState };
    default:
      return state;
  }
}
