export const getVehicles = ({ vehicles }) => vehicles;
export const getVehiclesData = ({ vehicles }) => vehicles.vehicles;
export const getVehiclesPagination = ({ vehicles }) => vehicles.pagination;
export const getVehiclesSorting = ({ vehicles }) => vehicles.sorting;
export const getVehiclesArray = ({ vehicles }) => vehicles.vehicles.data || [];
export const getSelectedVehicle = ({ vehicles }) => vehicles.selected.vehicle;
export const getSelectedVehicleLastTrace = ({ vehicles }) => vehicles.selected.lastTrace;
export const getLastTraceMode = ({ vehicles }) => vehicles.selected.lastTraceMode;
export const containsAnyNotes = ({ vehicles }) => vehicles.vehicles.data.vehicles.filter(
  (vehicle) => vehicle.remark !== null,
).length;
