import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { StopOutlined, ShareAltOutlined, EditOutlined } from '@ant-design/icons';

const OrderActionsComponent = ({
  onShare, onDeactivate, onEdit, disabledShare, disabledDeactivate, editShown,
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="center">
      { editShown && (
      <Tooltip
        placement="leftBottom"
        title={t('ORDER.EDIT_TOOLTIP')}
      >
        <Button
          type="link"
          disabled={disabledShare}
          icon={<EditOutlined />}
          onClick={onEdit}
        />
      </Tooltip>
      ) }
      <Tooltip
        placement="leftBottom"
        title={t('ORDER.SHARE_TOOLTIP')}
      >
        <Button
          type="link"
          disabled={disabledShare}
          icon={<ShareAltOutlined />}
          onClick={onShare}
        />
      </Tooltip>
      <Tooltip
        placement="leftBottom"
        title={t('ORDER.DEACTIVATION_TOOLTIP')}
      >
        <Button
          type="link"
          disabled={disabledDeactivate}
          icon={<StopOutlined />}
          onClick={onDeactivate}
        />
      </Tooltip>
    </Row>
  );
};

OrderActionsComponent.propTypes = {
  onShare: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  disabledShare: PropTypes.bool,
  disabledDeactivate: PropTypes.bool,
  editShown: PropTypes.bool,
};

OrderActionsComponent.defaultProps = {
  disabledShare: true,
  disabledDeactivate: true,
  editShown: true,
};

export default OrderActionsComponent;
