/* eslint-disable max-len */
export const BIG_CLUSTER_TEMPLATE = '<svg width="52" height="52" xmlns="http://www.w3.org/2000/svg">'
  + '  <g>'
  + '    <ellipse stroke="#000" fill-opacity="0.3" ry="26" rx="26" id="svg_2" cy="26" cx="26" stroke-width="0" fill="#007fff"/>'
  + '    <ellipse stroke="#000" fill-opacity="0.8" ry="13.5" rx="13.5" id="svg_3" cy="26" cx="26" stroke-width="0" fill="#007fff"/>'
  + '    <text transform="matrix(1,0,0,1,0,0) " font-weight="bold" xml:space="preserve" text-anchor="middle" font-family="Helvetica, Arial, sans-serif" font-size="12" id="svg_4" y="30" x="26" stroke-width="0" stroke="#ffffff" fill="#ffffff">{count}</text>'
  + '  </g>'
  + '</svg>';

export const MEDIUM_CLUSTER_TEMPLATE = '<svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">'
  + ' <g>'
  + '  <ellipse stroke="#000" fill-opacity="0.3" ry="21" rx="21" id="svg_2" cy="21" cx="21" stroke-width="0" fill="#007fff"/>'
  + '  <ellipse stroke="#000" fill-opacity="0.8" ry="12" rx="12" id="svg_3" cy="21" cx="21" stroke-width="0" fill="#007fff"/>'
  + '  <text transform="matrix(1,0,0,1,0,0) " font-weight="bold" xml:space="preserve" text-anchor="middle" font-family="Helvetica, Arial, sans-serif" font-size="12" id="svg_4" y="25" x="21" stroke-width="0" stroke="#ffffff" fill="#ffffff">{count}</text>'
  + ' </g>'
  + '</svg>';

export const SMALL_CLUSTER_TEMPLATE = '<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">'
  + ' <g>'
  + '  <ellipse stroke="#000" fill-opacity="0.3" ry="16" rx="16" id="svg_2" cy="16" cx="16" stroke-width="0" fill="#007fff"/>'
  + '  <ellipse stroke="#000" fill-opacity="0.8" ry="10" rx="10" id="svg_3" cy="16" cx="16" stroke-width="0" fill="#007fff"/>'
  + '  <text transform="matrix(1,0,0,1,0,0) " font-weight="bold" xml:space="preserve" text-anchor="middle" font-family="Helvetica, Arial, sans-serif" font-size="12" id="svg_4" y="20" x="16" stroke-width="0" stroke="#ffffff" fill="#ffffff">{count}</text>'
  + ' </g>'
  + '</svg>';
