import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';

export const CircleStatus = ({ text, disabled, color }) => {
  const [clr, setClr] = useState('#489ED7');

  useEffect(() => {
    if (color) {
      setClr(color);
      return;
    }
    setClr(disabled ? '#00000040' : '#489ED7');
  }, [disabled, color]);

  return (
    <>
      <Button
        shape="circle"
        size="small"
        disabled={disabled}
        style={{ background: 'white', borderColor: clr, pointerEvents: 'none' }}
      >
        <p style={{ color: clr }}>{text}</p>
      </Button>
    </>
  );
};

CircleStatus.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

CircleStatus.defaultProps = {
  color: undefined,
};
