import moment from 'moment';
import { FETCH_VEHICLE_DETAILS, RESET_COMPANY_CONTEXT } from '../../actions';
import { normalizeVehicleInPlace } from '../../normalizers';

const detailsInitState = {
  loading: false,
  error: null,
  lastUpdated: moment(),
  data: null,
};

export function detailsReducer(state = detailsInitState, { type, payload, meta }) {
  switch (type) {
    case FETCH_VEHICLE_DETAILS.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        data: normalizeVehicleInPlace(payload.data),
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case FETCH_VEHICLE_DETAILS.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state, loading: false, error: payload, data: null, lastUpdated: moment(),
      };
    case FETCH_VEHICLE_DETAILS.START:
      return { ...state, loading: true, actionId: payload.actionId };
    case RESET_COMPANY_CONTEXT:
      return { ...detailsInitState };
    default:
      return state;
  }
}
