export const SET_VEHICLES_PAGINATION = 'SET_VEHICLES_PAGINATION';
export const RESET_VEHICLES_PAGINATION = 'RESET_VEHICLES_PAGINATION';

export const setVehiclesPagination = ({ page, size }) => ({
  type: SET_VEHICLES_PAGINATION,
  payload: {
    page,
    size,
  },
});

export const resetVehiclesPagination = () => ({
  type: RESET_VEHICLES_PAGINATION,
  payload: {
    page: 1,
  },
});
