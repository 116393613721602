import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Button, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PresetButton, RangeSection, Wrapper } from './HistoryRangePickerComponent.styled';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../../../utils/constants/timeFormats';
import { StyledRangePicker } from '../../../../components';

const HistoryRangePickerComponent = ({
  range, onRangeChange, size, hidden,
}) => {
  const { t } = useTranslation();

  const presets = [
    {
      title: 'COMMON.TIME.LAST_24H',
      onClick: () => onRangeChange([moment().subtract(1, 'd'), moment()]),
    },
    {
      title: 'COMMON.TIME.TODAY',
      onClick: () => onRangeChange([moment().startOf('d'), moment()]),
    },
    {
      title: 'COMMON.TIME.YESTERDAY',
      onClick: () => onRangeChange([moment().startOf('d').subtract(1, 'd'), moment().startOf('d')]),
    },
  ];

  const renderPresets = () => presets.map((preset) => (
    <PresetButton
      size={size}
      type="link"
      onClick={preset.onClick}
      key={preset.title}
    >
      {t(preset.title)}
    </PresetButton>
  ));

  const moveIntervalLeft = () => onRangeChange([
    range[0].clone().subtract(range[1].diff(range[0])),
    range[0],
  ]);

  const moveIntervalRight = () => onRangeChange([
    range[1],
    range[1].clone().add(range[1].diff(range[0])),
  ]);

  const isIntervalMissing = () => !range || range.length < 2 || !range[1] || !range[0];

  const canMoveIntervalRight = () => isIntervalMissing() || range[1].isAfter(moment());

  const canMoveIntervalLeft = () => isIntervalMissing();

  return (
    <Wrapper
      hidden={hidden}
      justify="center"
    >
      <RangeSection
        xs={24}
        xl={14}
      >
        <Tooltip
          placement="top"
          title={t('COMMON.TIME.PREVIOUS_INTERVAL')}
        >
          <Button
            icon={<LeftOutlined />}
            size={size}
            disabled={canMoveIntervalLeft()}
            onClick={moveIntervalLeft}
          />
        </Tooltip>
        <StyledRangePicker
          showTime={{ format: DEFAULT_TIME }}
          format={DEFAULT_DATE_TIME}
          style={{ maxWidth: '320px' }}
          value={range}
          size={size}
          dropdownClassName="ant-picker-shadow-2"
          onCalendarChange={(change) => {
            onRangeChange(change);
          }}
        />
        <Tooltip
          placement="top"
          title={t('COMMON.TIME.NEXT_INTERVAL')}
        >
          <Button
            icon={<RightOutlined />}
            size={size}
            onClick={moveIntervalRight}
            disabled={canMoveIntervalRight()}
          />
        </Tooltip>
      </RangeSection>

      <RangeSection
        xs={24}
        xl={10}
      >
        {renderPresets()}
      </RangeSection>
    </Wrapper>
  );
};

HistoryRangePickerComponent.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  range: PropTypes.arrayOf(MomentPropTypes.momentObj),
  onRangeChange: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

HistoryRangePickerComponent.defaultProps = {
  size: 'small',
  range: null,
  hidden: false,
};

export default HistoryRangePickerComponent;
