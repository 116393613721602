import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';

export const FETCH_FLEET_VEHICLES_REDUCED = axiosActionsFactory('FETCH_FLEET_VEHICLES_REDUCED');
export const CLEAR_FLEET_VEHICLES = 'CLEAR_FLEET_VEHICLES';

export function fetchFleetVehicles(companyId) {
  return {
    type: FETCH_FLEET_VEHICLES_REDUCED.START,
    payload: {
      request: {
        url: UrlService.companyVehiclesForPermanentMonitoring(companyId),
      },
    },
  };
}

export const clearFleetVehicles = () => ({
  type: CLEAR_FLEET_VEHICLES,
});
