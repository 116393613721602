import { Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormSectionDivider, SectionDescription } from '../Styled';

const FormSection = ({
  children, title, subtitle, noDivider,
}) => (
  <>
    <Typography.Title level={5}>{title}</Typography.Title>
    {subtitle && <SectionDescription>{subtitle}</SectionDescription>}
    {!noDivider && <FormSectionDivider />}
    {children}
  </>
);

FormSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noDivider: PropTypes.bool,
};

FormSection.defaultProps = {
  title: undefined,
  subtitle: undefined,
  noDivider: false,
};

export default FormSection;
