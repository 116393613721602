export default {
  type: process.env.NODE_ENV,
  logLevel: process.env.REACT_APP_LOG_LEVEL,
  version: process.env.REACT_APP_VERSION,
  api: {
    url: process.env.REACT_APP_API_URL,
    basePath: process.env.REACT_APP_API_BASE_PATH,
  },
  app: {
    domain: process.env.REACT_APP_CO3_DOMAIN,
  },
  keycloak: {
    core: {
      url: process.env.REACT_APP_KEYCLOAK_URL,
      'auth-server-url': process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      'ssl-required': 'external',
      'public-client': true,
    },
    init: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      useNonce: true,
      enableLogging: process.env.REACT_APP_NODE_ENV !== 'production',
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    },
  },
  hereMaps: {
    apiKey: process.env.REACT_APP_HERE_API_KEY,
  },
  invitation: {
    link: process.env.REACT_APP_INVITATION_LINK,
  },
  gtm: {
    id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  },
};
