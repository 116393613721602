import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import {
  InputFilterDropdown, DateFilterDropdown, SelectFilterDropdown, StyledTable, EmptyStyledTable,
} from '../../components';
import { mergeUpdateInterfaceFilters, loadRequestIncoming, clearInterfaceFiltersExcept } from '../../store/actions';
import { getRequestsIncoming, TableName, getRequestsIncomingFilters } from '../../store/selectors';
import TableLoadingComponent from '../../components/Table/TableLoadingComponent';
import { FILTERS } from '../Orders/Components/OrdersTable.constants';
import { FiltersTags } from '../../components/Table';
import { asMoment } from '../../types/mappers';
import OrderStatus from '../Orders/Components/OrderStatusComponent';
import { ColumnContent, AddressContent } from './compontents/RequestComponent';
import { DateTime } from '../../components/Vehicle/Data';
import ActionIncomingRequest from './ActionIncomingRequest';

const statusOptions = [
  { label: 'PROPERTIES.REQUEST_ACCEPT_OR_DECLINE', value: 'PENDING' },
  { label: 'PROPERTIES.REQUEST_ACCEPTED', value: 'ACCEPTED' },
  { label: 'PROPERTIES.REQUEST_DECLINED', value: 'DECLINED' },
  { label: 'PROPERTIES.REQUEST_DEACTIVATED', value: 'DEACTIVATED' },
];

const pageSizeOptions = ['5', '10', '20', '50', '100', '200'];

const IncomingRequest = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestsIncoming } = useSelector(getRequestsIncoming);
  const filters = useSelector(getRequestsIncomingFilters);
  const [sortOption, setSortOption] = useState({});
  const [paginationOption, setPaginationOption] = useState(undefined);

  const updateFilters = (filter) => {
    dispatch(mergeUpdateInterfaceFilters(
      TableName.RequestIncoming,
      filter,
    ));
  };

  function sortField(sorter) {
    return Object.keys(sorter).length === 0
      ? undefined
      : `${sorter.field},${sorter.order === 'descend'
        ? 'desc' : 'asc'}`;
  }

  const onChange = useCallback((
    pagination, filter, sorter,
  ) => {
    setPaginationOption(pagination);
    setSortOption(sorter);
    dispatch(loadRequestIncoming(false, pagination.current - 1, pagination.pageSize, sortField(sorter)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadRequestIncoming());
  }, [dispatch]);

  const renderTotal = useCallback((total) => (<span>{t('COMMON.TOTAL', { total })}</span>), [t]);

  const reload = () => {
    dispatch(loadRequestIncoming(undefined, paginationOption?.current - 1, paginationOption?.pageSize,
      Object.keys(sortOption).length === 0 ? undefined : sortField(sortOption)));
  };

  const handleClose = (filterName) => {
    updateFilters({ [filterName]: [] });
    reload();
  };

  const handleClearFilters = () => {
    dispatch(clearInterfaceFiltersExcept(TableName.RequestIncoming));
    reload();
  };

  const getDefaultSortOrder = (fieldName) => (sortOption.field === fieldName ? sortOption.order : undefined);

  const addressIsEmpty = (shipper) => shipper?.zipCode === null
  || shipper?.countryCode === null
  || shipper?.city === null;

  const renderColumns = () => [
    {
      title: [t('REQUEST.ORDER_NUMBER')],
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: false,
      filteredValue: filters.ORDER_NUMBER,
      filterDropdown: InputFilterDropdown(FILTERS.ORDER_NUMBER, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text, record) => (
        <Link to={`/requests/${record.id}/details`}>{text}</Link>
      ),
    },
    {
      title: [t('REQUEST.LICENSE_PLATE')],
      dataIndex: 'licencePlate',
      key: 'licencePlate',
      sorter: false,
      filteredValue: filters.LICENCE_PLATE,
      filterDropdown: InputFilterDropdown(FILTERS.LICENCE_PLATE, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text) => (
        <ColumnContent>{text}</ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.SHIPPER')],
      dataIndex: 'shipper',
      key: 'shipper',
      filteredValue: filters.SHIPPER,
      filterDropdown: InputFilterDropdown(FILTERS.SHIPPER, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text, record) => (
        <>
          {
        addressIsEmpty(record.shipper) ? (
          <>
            <ColumnContent>
              {record.shipper.name}
            </ColumnContent>
          </>
        ) : (
          <>
            <ColumnContent>
              {record.shipper.name}
              <AddressContent>
                {record.shipper.countryCode}
                <>,</>
                {record.shipper.zipCode}
                <>,</>
                {record.shipper.city}
              </AddressContent>
            </ColumnContent>

          </>
        )
      }
        </>
      ),
    },
    {
      title: [t('REQUEST.START_DATE')],
      dataIndex: 'timeMonitoringStartDate',
      key: 'timeMonitoringStartDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('timeMonitoringStartDate'),
      filteredValue: asMoment(filters.TIME_MONITORING_START_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.TIME_MONITORING_START_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.END_DATE')],
      dataIndex: 'timeMonitoringEndDate',
      key: 'timeMonitoringEndDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('timeMonitoringEndDate'),
      filteredValue: asMoment(filters.TIME_MONITORING_END_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.TIME_MONITORING_END_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.CREATED_DATE')],
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('createdDate'),
      filteredValue: asMoment(filters.CREATED_DATE),
      filterDropdown: DateFilterDropdown(FILTERS.CREATED_DATE.name, updateFilters, t),
      filterIcon: <CalendarOutlined />,
      render: (text) => (
        <ColumnContent>
          <DateTime value={text} />
        </ColumnContent>
      ),
    },
    {
      title: [t('REQUEST.STATUS')],
      className: 'ant-table-cell-centered',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder('state'),
      width: '181px',
      filteredValue: filters.REQUEST_STATE,
      filterDropdown: SelectFilterDropdown(FILTERS.REQUEST_STATE.name, statusOptions, updateFilters, t),
      filterIcon: <SearchOutlined />,
      render: (text, record) => (
        <>
          { text === 'PENDING'
            ? (
              <>
                <ActionIncomingRequest
                  key={record.id}
                  shipper={record.shipper.name}
                  monitoringRequestId={record.id}
                  carrierCompanyId={record.carrierCompanyId}
                  postAction={() => reload()}
                />
              </>
            )
            : (<><OrderStatus status={`REQUEST_${text}`} /></>)}
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <FiltersTags
            filters={filters}
            onRemoveFilter={handleClose}
            onClearFilters={handleClearFilters}
          />
        </Col>
        {requestsIncoming?.data?.total > 0
          ? (
            <StyledTable
              key={TableName.RequestIncoming}
              scroll={{ y: 500 }}
              dataSource={requestsIncoming?.data?.requestsIncoming}
              rowKey={(record) => `${record.orderNumber}`}
              size="small"
              bordered
              pagination={{
                showSizeChanger: true,
                showTotal: renderTotal,
                pageSizeOptions,
                total: requestsIncoming?.data?.total,
                disabled: requestsIncoming?.loading,
                current: requestsIncoming?.data?.page + 1,
                pageSize: requestsIncoming?.data?.size,
              }}
              columns={renderColumns()}
              loading={TableLoadingComponent(requestsIncoming?.majorReload)}
              onChange={(p, f, s) => onChange(p, f, s)}
            />
          ) : (
            <EmptyStyledTable
              dataSource={[]}
              rowKey="id"
              size={0}
              bordered
              locale={{ emptyText: t('REQUEST.EMPTY_INCOMING_INFO') }}
              pagination={{
                showSizeChanger: true,
                showTotal: (total) => (<span>{t('COMMON.TOTAL', { total })}</span>),
                pageSizeOptions,
                total: 0,
                disabled: 0,
                current: 0,
                pageSize: 0,
              }}
              onChange={(p, f, s) => onChange(p, f, s)}
              columns={renderColumns()}
              loading={TableLoadingComponent(requestsIncoming?.majorReload)}
            />
          )}
      </Row>
    </>
  );
};

export default IncomingRequest;
