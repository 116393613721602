import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag, Modal, Space } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getCarriers } from '../../../store/selectors/carriers.selectors';
import { loadInvitedCarriers } from '../../../store/actions/carriers';
import TableLoadingComponent from '../../../components/Table/TableLoadingComponent';
import { StyledTable } from '../../../components';
import { StatusLegend } from './StatusLegend';
import { CircleStatus } from './CircleStatus';

const InProgress = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 20px;

display: flex;
align-items: center;

color: #1890FF;
`;

const Registered = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 20px;

display: flex;
align-items: center;

color: #52C41A;
`;

const CarrierTable = ({ size, pageSizeOptions }) => {
  const { t } = useTranslation();

  const { carriers } = useSelector(getCarriers);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const renderCompanyName = (_, { companyName }) => (
    <>{companyName}</>
  );

  const renderVatNumber = (_, { vatNumber }) => (
    <>{vatNumber}</>
  );

  const renderEmail = (_, { email }) => (
    <>{email}</>
  );

  const renderGpsProvider = (_, { gpsProvider }) => (
    <>{gpsProvider}</>
  );

  const statusMaker = (status) => {
    switch (status) {
      case 'FULFILLED':
        return (
          <>
            <Tag className="ant-tag-single" color="green" style={{ borderColor: 'black' }}>
              <Registered>
                {t('CARRIERS.REGISTERED')}
              </Registered>
            </Tag>
          </>
        );
      case 'Registration In Progress':
        return (
          <>
            <CircleStatus text="1" disabled={false} />
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('ONB_STATUS.REGISTRATION_IN_PROGRESS')}
              </InProgress>
            </Tag>
          </>
        );
      case 'Registered':
        return (
          <>
            <CircleStatus text="2" disabled={false} />
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('ONB_STATUS.REGISTERED')}
              </InProgress>
            </Tag>
          </>
        );
      case 'API Credentials Gathering':
        return (
          <>
            <CircleStatus text="3" disabled={false} />
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('ONB_STATUS.API_CREDENTIALS_GATHERING')}
              </InProgress>
            </Tag>
          </>
        );
      case 'CO3 - Provider Integration':
        return (
          <>
            <CircleStatus text="4" disabled={false} />
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('ONB_STATUS.CO3_PROVIDER_INTEGRATION')}
              </InProgress>
            </Tag>
          </>
        );
      case 'Onboarding Fleet':
        return (
          <>
            <CircleStatus text="5" disabled={false} />
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('ONB_STATUS.ONBOARDING_FLEET')}
              </InProgress>
            </Tag>
          </>
        );
      case 'Completed':
        return (
          <>
            <CircleStatus text="6" disabled={false} color="#66CB34" />
            <Tag className="ant-tag-single" color="green">
              {t('ONB_STATUS.COMPLETED')}
            </Tag>
          </>
        );
      case 'Paused':
        return (
          <>
            <CircleStatus text="P" disabled />
            <Tag className="ant-tag-single">
              {t('ONB_STATUS.PAUSED')}
            </Tag>
          </>
        );
      case 'Failed - Carrier Unresponsive':
        return (
          <>
            <CircleStatus text="F1" disabled />
            <Tag className="ant-tag-single">
              {t('ONB_STATUS.FAILED_CARRIER_UNRESPONSIVE')}
            </Tag>
          </>
        );
      case 'Failed - No GPS':
        return (
          <>
            <CircleStatus text="F2" disabled />
            <Tag className="ant-tag-single">
              {t('ONB_STATUS.FAILED_NO_GPS')}
            </Tag>
          </>
        );
      case 'Failed - Stopped Cooperation':
        return (
          <>
            <CircleStatus text="F3" disabled />
            <Tag className="ant-tag-single">
              {t('ONB_STATUS.FAILED_STOPPED_COOPERATION')}
            </Tag>
          </>
        );
      case 'Failed - Other':
        return (
          <>
            <CircleStatus text="F4" disabled />
            <Tag className="ant-tag-single">
              {t('ONB_STATUS.FAILED_OTHER')}
            </Tag>
          </>
        );

      default:
        return (
          <>
            <Tag className="ant-tag-single" color="#E6F7FF">
              <InProgress>
                {t('CARRIERS.IN_PROGRESS')}
              </InProgress>
            </Tag>
          </>
        );
    }
  };

  const renderColumns = [
    {
      title: t('CARRIERS.COMPANY_NAME'),
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      render: renderCompanyName,
    },
    {
      title: t('CARRIERS.VAT_NUMBER'),
      dataIndex: 'vatNumber',
      key: 'vatNumber',
      sorter: true,
      render: renderVatNumber,
    },
    {
      title: t('CARRIERS.EMAIL'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: renderEmail,
    },
    {
      title: t('CARRIERS.COOPERATION_TYPE'),
      dataIndex: 'cooperationType',
      key: 'cooperationType',
      sorter: true,
      render: (_, { cooperationType }) => (
        <>{cooperationType}</>),
    },
    {
      title: t('CARRIERS.GPS_PROVIDER'),
      dataIndex: 'gpsProvider',
      key: 'gpsProvider',
      sorter: true,
      render: renderGpsProvider,
    },
    {
      width: '250px',
      showSorterTooltip: false,
      title: () => (
        <>
          {t('CARRIERS.REGISTRATION_STATUS')}
        </>
      ),
      dataIndex: 'stage',
      key: 'stage',
      filterDropdown: (e) => {
        if (e.visible) {
          setVisible(e.visible);
        }
      },
      filterIcon: () => (<QuestionOutlined />),
      sorter: true,
      render: (_, { stage }) => (
        <>
          <div style={{ display: 'inline-flex' }}>
            <Space align="start">
              {statusMaker(stage)}
            </Space>
          </div>
        </>
      ),
    },
  ];

  const [tableColumns] = useState(renderColumns);

  const renderTotal = useCallback((total) => (<span>{t('COMMON.TOTAL', { total })}</span>), [t]);

  const mapSortOrder = (sortOrder) => (sortOrder === 'descend' ? 'DESC' : 'ASC');

  const onChange = useCallback((
    pagination, filters, sorter,
  ) => {
    const sortProperty = Object.keys(sorter).length === 0 ? undefined : sorter.field;
    dispatch(loadInvitedCarriers(false, {
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
      sortProperty,
      sortDirection: sortProperty ? mapSortOrder(sorter.order) : undefined,
    }));
  }, [dispatch]);

  return (
    <>
      <StyledTable
        scroll={{ y: 500 }}
        showSorterTooltip={false}
        dataSource={carriers?.data?.carriers}
        rowKey={(record) => `${record.sharedTo}-${record.orderId}`}
        size={size}
        bordered
        pagination={{
          showSizeChanger: true,
          showTotal: renderTotal,
          pageSizeOptions,
          total: carriers?.data?.totalCarriers,
          disabled: carriers?.loading,
          current: carriers?.data?.page + 1,
          pageSize: carriers?.data?.size,
        }}
        columns={tableColumns}
        loading={TableLoadingComponent(carriers?.majorReload)}
        onChange={(p, f, s) => onChange(p, f, s)}
      />
      <Modal
        id="modal-Id"
        title={t('ONB_STATUS.ONBORDING_STATUSES_TITLE')}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        width={1012}
        footer={null}
      >
        <StatusLegend />
      </Modal>
    </>
  );
};

CarrierTable.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
};

CarrierTable.defaultProps = {
  size: 'small',
  pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
};

export default CarrierTable;
