export function normalizeTelematicAccountsFormConfig(configFromApi) {
  return configFromApi.providers.map(({
    providerId, displayName, uiAttributes, assistance, typeOem, forUpdateOnly,
  }) => ({
    providerId,
    displayName,
    providerLogoUrl: uiAttributes?.providerMetadata?.logoUrl,
    fields: uiAttributes?.formAttributes.map(
      ({
        name: fieldName,
        displayNameKey,
        optional,
        notUpdatable,
        options,
        type,
        value,
      }) => ({
        fieldName,
        i18nKey: (displayNameKey ?? fieldName),
        optional: optional ?? false,
        notUpdatable: notUpdatable ?? false,
        options: options || [],
        type,
        value,
      }),
    ) || [],
    assistance: {
      noAssistance: assistance?.noAssistance ?? false,
      instructionLinks: assistance?.instructionLinks || [],
    },
    typeOem,
    forUpdateOnly: forUpdateOnly ?? false,
  }));
}

export function normalizeTelematicAccountsForTable(accountsPayload) {
  return accountsPayload.accounts.map(({
    accountId,
    accountDisplayName,
    accountDescription,
    providerName,
    deviceCount,
    workingStatus,
    hasFormConfiguration,
  }) => ({
    accountId,
    accountDisplayName,
    accountDescription,
    providerName,
    deviceCount,
    workingStatus,
    hasFormConfiguration,
  }));
}

export function normalizeTelematicAccount(account) {
  return {
    providerId: account.providerId,
    accountId: account.accountId,
    companyId: account.companyId,
    accountDisplayName: account.accountDisplayName,
    accountDescription: account.userDescription,
    credentials: account.credentials,
  };
}
