import { combineReducers } from 'redux';
import FleetPageStateReducer from './fleet-page-state.reducer';
import FleetSelectionReducer from './fleet-selection.reducer';
import FleetVehiclesReducer from './fleet-vehicles.reducer';

export default combineReducers({
  pageState: FleetPageStateReducer,
  selection: FleetSelectionReducer,
  vehicles: FleetVehiclesReducer,
});
