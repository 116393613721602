import { DatePicker } from 'antd';
import styled from 'styled-components';

export const StyledDatePicker = styled(DatePicker)`
  .ant-picker-active-bar {
    height: 3px;
  }
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker{
    &-active-bar {
      height: 3px;
    }
  }
`;
