/* eslint-disable max-len */
import { indexTag } from './parkingIcons';

const active = (index) => '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <g opacity="0.5">'
  + '        <circle cx="17" cy="16" r="12" fill="#2D9CDB"/>'
  + '    </g>'
  + '    <circle cx="17" cy="16" r="9" fill="__FILL_COLOR__" stroke="#2D9CDB" stroke-width="2"/>'
  + '    <path d="M14 13V16M14 19V16M14 16H19C19.5523 16 20 16.4477 20 17V19" stroke="__TEXT_COLOR__"/>'
  + `    ${indexTag(index)}`
  + '</svg>';

const done = () => '<svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="18" r="10" fill="__FILL_COLOR__"/>'
  + '    <circle cx="15" cy="18" r="9.5" stroke="__TEXT_COLOR__"/>'
  + '    <path d="M11 14V18M11 22V18M11 18H18C18.5523 18 19 18.4477 19 19V22" stroke="__TEXT_COLOR__"/>'
  + '    <path d="M23 4C20.2388 4 18 6.23884 18 9C18 11.7612 20.2388 14 23 14C25.7612 14 28 11.7612 28 9C28 6.23884 25.7612 4 23 4ZM23 13.1518C20.7076 13.1518 18.8482 11.2924 18.8482 9C18.8482 6.70759 20.7076 4.84821 23 4.84821C25.2924 4.84821 27.1518 6.70759 27.1518 9C27.1518 11.2924 25.2924 13.1518 23 13.1518Z" fill="#4CAF50"/>'
  + '    <path d="M23.0002 4.84961C20.7078 4.84961 18.8484 6.70898 18.8484 9.0014C18.8484 11.2938 20.7078 13.1532 23.0002 13.1532C25.2926 13.1532 27.152 11.2938 27.152 9.0014C27.152 6.70898 25.2926 4.84961 23.0002 4.84961ZM25.1587 7.36858L22.8082 10.6275C22.7754 10.6734 22.7321 10.7107 22.6819 10.7365C22.6317 10.7623 22.5761 10.7757 22.5197 10.7757C22.4633 10.7757 22.4077 10.7623 22.3575 10.7365C22.3074 10.7107 22.2641 10.6734 22.2312 10.6275L20.8406 8.69894C20.7982 8.63979 20.8406 8.5572 20.9131 8.5572H21.4366C21.5515 8.5572 21.6587 8.613 21.7256 8.70564L22.5203 9.80832L24.2747 7.37528C24.3417 7.28153 24.45 7.22684 24.5638 7.22684H25.0872C25.1598 7.22684 25.2022 7.30943 25.1587 7.36858Z" fill="#F6FFED"/>'
  + '    <path d="M25.0868 7.22266H24.5633C24.4495 7.22266 24.3412 7.27734 24.2743 7.37109L22.5198 9.80413L21.7252 8.70145C21.6582 8.60882 21.5511 8.55301 21.4361 8.55301H20.9127C20.8401 8.55301 20.7977 8.6356 20.8401 8.69476L22.2307 10.6233C22.2636 10.6692 22.3069 10.7065 22.3571 10.7323C22.4073 10.7581 22.4628 10.7715 22.5193 10.7715C22.5757 10.7715 22.6313 10.7581 22.6814 10.7323C22.7316 10.7065 22.7749 10.6692 22.8078 10.6233L25.1582 7.3644C25.2017 7.30525 25.1593 7.22266 25.0868 7.22266Z" fill="#4CAF50"/>'
  + '</svg>';

const hover = () => '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="14" r="10" fill="#2F80ED"/>'
  + '    <circle cx="15" cy="14" r="9.5" stroke="white"/>'
  + '    <path d="M11 10V14M11 18V14M11 14H18C18.5523 14 19 14.4477 19 15V18" stroke="white"/>'
  + '</svg>';

const basic = () => '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="14" r="10" fill="white"/>'
  + '    <circle cx="15" cy="14" r="9.5" stroke="#2F80ED"/>'
  + '    <path d="M11 10V14M11 18V14M11 14H18C18.5523 14 19 14.4477 19 15V18" stroke="#2F80ED"/>'
  + '</svg>';

const indexed = (index) => '<svg width="33" height="34" viewBox="0 0 33 34" fill="none" version="1.1" id="svg14" xmlns="http://www.w3.org/2000/svg">'
  + '<circle cx="15" cy="18" r="10" fill="__FILL_COLOR__" id="circle2" />'
  + '<circle cx="15" cy="18" r="9.5" stroke="__TEXT_COLOR__" id="circle4" />'
  + '<path d="M11 14V18M11 22V18M11 18H18C18.5523 18 19 18.4477 19 19V22" stroke="__TEXT_COLOR__"/>'
  + `${indexTag(index)}`
  + '</svg>';

export default {
  active,
  indexed,
  done,
  hover,
  basic,
};
