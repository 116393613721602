import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { SharingComputedStatus } from '../../../types';

const configByStatus = {
  [SharingComputedStatus.Expired]: { text: 'SHARE_VEHICLES.EXPIRED', color: 'default' },
  [SharingComputedStatus.Ongoing]: { text: 'SHARE_VEHICLES.ONGOING', color: 'green' },
  [SharingComputedStatus.Future]: { text: 'SHARE_VEHICLES.FUTURE', color: 'blue' },
};

const SharingStatus = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Tag color={configByStatus[status].color} className="ant-tag-single">
      {t(configByStatus[status].text)}
    </Tag>
  );
};

SharingStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(SharingComputedStatus)).isRequired,
};

export default SharingStatus;
