/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';
import { SearchDatePicker, SearchDropdownContent } from './FilterDropdown.styled';
import { DEFAULT_DATE } from '../../utils/constants/timeFormats';

const DatePickerDropdown = (columnName, setFilters, t) => ({
  setSelectedKeys, selectedKeys, confirm, clearFilters,
}) => {
  const setFilterValue = (value) => {
    setFilters({ [columnName]: value && value.length ? [value[0].startOf('d')] : null });
  };

  const handleSearch = () => {
    setFilterValue(selectedKeys);
    confirm();
  };

  const handleReset = () => {
    setFilterValue(null);
    clearFilters();
  };

  return (
    <SearchDropdownContent>
      <SearchDatePicker
        placeholder={`${t('COMMON.SEARCH')} ${t(`PROPERTIES.${columnName}`)}`}
        value={selectedKeys && selectedKeys.length && selectedKeys[0]}
        onChange={(date) => setSelectedKeys(date ? [date] : [])}
        format={DEFAULT_DATE}
      />
      <div className="ant-table-filter-dropdown-btns">
        <Button
          onClick={() => handleReset(setFilterValue, clearFilters)}
          size="small"
          type="link"
          disabled={!selectedKeys[0]}
        >
          {t('COMMON.RESET')}
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch(setFilterValue, selectedKeys, confirm)}
          size="small"
        >
          {t('COMMON.OK')}
        </Button>
      </div>
    </SearchDropdownContent>
  );
};

export default DatePickerDropdown;
