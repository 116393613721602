import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FullWidthStyledCard } from '../../../../components';
import {
  ActionContent, Bolded, CardHandler, Content,
} from '../../compontents/RequestComponent';
import ActionIncomingRequest from '../../ActionIncomingRequest';

const ActionHolder = styled.div`
  text-align: center;
  width: 100%;
  padding: 20px;
`;

const RequestDetailsActionComponent = ({ requestDetails, onActionClick }) => {
  const { t } = useTranslation();

  return (
    <CardHandler>
      <FullWidthStyledCard title={t('REQUEST.NEW_REQUEST')}>
        <ActionHolder>
          <Bolded>{t('REQUEST.ACCEPT_AND_SHARE_TITLE')}</Bolded>
          <Content>
            {t('REQUEST.ACCEPT_AND_SHARE_CONTENT_FIRST',
              {
                shipperName: requestDetails.shipperInfo.companyName,
                orderNumber: requestDetails.orderNumber,
              })}
            <Bolded>{requestDetails.orderNumber}</Bolded>
            {t('REQUEST.ACCEPT_AND_SHARE_CONTENT_SECOND')}
          </Content>
          <ActionContent>
            <ActionIncomingRequest
              key={requestDetails.id}
              shipper={requestDetails.shipperInfo.companyName}
              monitoringRequestId={requestDetails.id}
              carrierCompanyId={requestDetails.carrierInfo.companyId}
              postAction={onActionClick}
              size="medium"
            />
          </ActionContent>
        </ActionHolder>
      </FullWidthStyledCard>
    </CardHandler>
  );
};

RequestDetailsActionComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default RequestDetailsActionComponent;
