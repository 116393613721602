import moment from 'moment';

const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

export default (action) => ({
  START: `${action}`,
  SUCCESS: `${action}_${SUCCESS}`,
  FAIL: `${action}_${FAIL}`,
});

const applyMapper = (mapper, value, properties) => {
  if (Array.isArray(mapper)) {
    return mapper.reduce((val, m) => m(val, properties), value);
  }

  return mapper(value, properties);
};

export const QueryMapper = {
  takeFirst: (values) => (values && values.length > 0 && values[0]),
  toTimestamp: (value) => (value && (value.unix ? value.unix() : moment(value).unix())),
  toStartOfDay: (value) => (value && moment(value).startOf('d')),
  toEndOfDay: (value) => (value && moment(value).endOf('d')),
  takeFirstProperty: (value, properties) => {
    const query = {};
    const firstProperty = properties[0];
    query[firstProperty.apiProperty] = applyMapper(firstProperty.queryMapper, value);
    return query;
  },
};

export const extractApiFilters = (filters, definitions, isApiFilter = () => true) => {
  const query = {};
  Object.entries(filters)
    .filter(([key]) => isApiFilter(key))
    .map(([key, value]) => [definitions[key], value])
    .filter(([key, value]) => key && key.apiProperty && value && value.length)
    .forEach(([key, value]) => {
      const {
        apiProperty,
        queryMapper,
        properties,
      } = key;
      query[apiProperty] = queryMapper ? applyMapper(queryMapper, value, properties) : value;
    });
  return query;
};
