/* eslint-disable max-len */
export const indexTag = (index) => ((index > 9) ? (
  '<g>'
  + '  <path id="circle4-3" style="fill:#4D4D4D;stroke:#FFFFFF;stroke-width:0.6632;stroke-miterlimit:2.126" d="M23.2,20.1c3.5,0,6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3c0,0-5.1,0-5.1,0c-3.4-0.1-6.2-2.9-6.2-6.3c0-3.4,2.7-6.1,6-6.3C18,20.1,23.1,20.1,23.2,20.1z" />'
  + `  <text transform="matrix(1 0 0 1 15.4056 29.735)" style="fill:#FFFFFF;font-family:'Helvetica';font-size:9.567px">${index}</text>`
  + '</g>'
) : (
  '<g style="fill:none" id="g855" transform="matrix(0.53150159,0,0,0.53150159,15.518769,18.549358)">'
  + '  <circle cx="14.976333" cy="14.708827" r="11.854009" id="circle4-3" style="fill:#4d4d4d;stroke:#ffffff;stroke-width:1.24779;strokeOpacity:1"/>'
  + '  <text style="font-style:normal;font-weight:normal;font-size:20.0689px;line-height:1.25;font-family:sans-serif;fill:#000000;fill-opacity:1;stroke:none" x="8.4157228" y="22.023979" id="text843">'
  + `    <tspan id="tspan841" x="10" y="21" style="font-size:18px;fill:#ffffff;stroke:none;stroke-opacity:1">${index}</tspan>`
  + '  </text>'
  + '</g>'
));

const active = (index) => '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <g opacity="0.5">'
  + '        <circle cx="17" cy="16" r="12" fill="#2D9CDB"/>'
  + '    </g>'
  + '    <circle cx="17" cy="16" r="9" fill="__FILL_COLOR__" stroke="#2D9CDB" stroke-width="2"/>'
  + '    <path d="M15.9169 16.8681V20H15V12H17.5645C18.3254 12 18.9207 12.2234 19.3505 12.6703C19.7835 13.1172 20 13.7088 20 14.4451C20 15.2216 19.7883 15.8205 19.3649 16.2418C18.9446 16.6593 18.3413 16.8681 17.5549 16.8681H15.9169ZM15.9169 16.0055H17.5645C18.0548 16.0055 18.4304 15.8736 18.6915 15.6099C18.9526 15.3425 19.0831 14.9579 19.0831 14.456C19.0831 13.9799 18.9526 13.5989 18.6915 13.3132C18.4304 13.0275 18.0723 12.8791 17.617 12.8681H15.9169V16.0055Z" fill="__TEXT_COLOR__"/>'
  + `    ${indexTag(index)}`
  + '</svg>';

const hover = () => '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="14" r="10" fill="#2F80ED"/>'
  + '    <circle cx="15" cy="14" r="9.5" stroke="white"/>'
  + '    <path d="M13.9169 14.8681V18H13V10H15.5645C16.3254 10 16.9207 10.2234 17.3505 10.6703C17.7835 11.1172 18 11.7088 18 12.4451C18 13.2216 17.7883 13.8205 17.3649 14.2418C16.9446 14.6593 16.3413 14.8681 15.5549 14.8681H13.9169ZM13.9169 14.0055H15.5645C16.0548 14.0055 16.4304 13.8736 16.6915 13.6099C16.9526 13.3425 17.0831 12.9579 17.0831 12.456C17.0831 11.9799 16.9526 11.5989 16.6915 11.3132C16.4304 11.0275 16.0723 10.8791 15.617 10.8681H13.9169V14.0055Z" fill="white"/>'
  + '</svg>';

const basic = () => '<svg width="33" height="34" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="14" r="10" fill="white"/>'
  + '    <circle cx="15" cy="14" r="9.5" stroke="#2F80ED"/>'
  + '    <path d="M13.9169 14.8681V18H13V10H15.5645C16.3254 10 16.9207 10.2234 17.3505 10.6703C17.7835 11.1172 18 11.7088 18 12.4451C18 13.2216 17.7883 13.8205 17.3649 14.2418C16.9446 14.6593 16.3413 14.8681 15.5549 14.8681H13.9169ZM13.9169 14.0055H15.5645C16.0548 14.0055 16.4304 13.8736 16.6915 13.6099C16.9526 13.3425 17.0831 12.9579 17.0831 12.456C17.0831 11.9799 16.9526 11.5989 16.6915 11.3132C16.4304 11.0275 16.0723 10.8791 15.617 10.8681H13.9169V14.0055Z" fill="#2F80ED"/>'
  + '</svg>';

const done = () => '<svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="18" r="10" fill="__FILL_COLOR__"/>'
  + '    <circle cx="15" cy="18" r="9.5" stroke="__TEXT_COLOR__"/>'
  + '    <path d="M13.9169 18.8681V22H13V14H15.5645C16.3254 14 16.9207 14.2234 17.3505 14.6703C17.7835 15.1172 18 15.7088 18 16.4451C18 17.2216 17.7883 17.8205 17.3649 18.2418C16.9446 18.6593 16.3413 18.8681 15.5549 18.8681H13.9169ZM13.9169 18.0055H15.5645C16.0548 18.0055 16.4304 17.8736 16.6915 17.6099C16.9526 17.3425 17.0831 16.9579 17.0831 16.456C17.0831 15.9799 16.9526 15.5989 16.6915 15.3132C16.4304 15.0275 16.0723 14.8791 15.617 14.8681H13.9169V18.0055Z" fill="__TEXT_COLOR__"/>'
  + '    <path d="M23 4C20.2388 4 18 6.23884 18 9C18 11.7612 20.2388 14 23 14C25.7612 14 28 11.7612 28 9C28 6.23884 25.7612 4 23 4ZM23 13.1518C20.7076 13.1518 18.8482 11.2924 18.8482 9C18.8482 6.70759 20.7076 4.84821 23 4.84821C25.2924 4.84821 27.1518 6.70759 27.1518 9C27.1518 11.2924 25.2924 13.1518 23 13.1518Z" fill="#4CAF50"/>'
  + '    <path d="M23.0002 4.84961C20.7078 4.84961 18.8484 6.70898 18.8484 9.0014C18.8484 11.2938 20.7078 13.1532 23.0002 13.1532C25.2926 13.1532 27.152 11.2938 27.152 9.0014C27.152 6.70898 25.2926 4.84961 23.0002 4.84961ZM25.1587 7.36858L22.8082 10.6275C22.7754 10.6734 22.7321 10.7107 22.6819 10.7365C22.6317 10.7623 22.5761 10.7757 22.5197 10.7757C22.4633 10.7757 22.4077 10.7623 22.3575 10.7365C22.3074 10.7107 22.2641 10.6734 22.2312 10.6275L20.8406 8.69894C20.7982 8.63979 20.8406 8.5572 20.9131 8.5572H21.4366C21.5515 8.5572 21.6587 8.613 21.7256 8.70564L22.5203 9.80832L24.2747 7.37528C24.3417 7.28153 24.45 7.22684 24.5638 7.22684H25.0872C25.1598 7.22684 25.2022 7.30943 25.1587 7.36858Z" fill="#F6FFED"/>'
  + '    <path d="M25.0868 7.22266H24.5633C24.4495 7.22266 24.3412 7.27734 24.2743 7.37109L22.5198 9.80413L21.7252 8.70145C21.6582 8.60882 21.5511 8.55301 21.4361 8.55301H20.9127C20.8401 8.55301 20.7977 8.6356 20.8401 8.69476L22.2307 10.6233C22.2636 10.6692 22.3069 10.7065 22.3571 10.7323C22.4073 10.7581 22.4628 10.7715 22.5193 10.7715C22.5757 10.7715 22.6313 10.7581 22.6814 10.7323C22.7316 10.7065 22.7749 10.6692 22.8078 10.6233L25.1582 7.3644C25.2017 7.30525 25.1593 7.22266 25.0868 7.22266Z" fill="#4CAF50"/>'
  + '</svg>';

const indexed = (index) => '<svg width="33" height="34" viewBox="0 0 33 34" fill="none" version="1.1" id="svg14" xmlns="http://www.w3.org/2000/svg">'
+ '<circle cx="15" cy="18" r="10" fill="__FILL_COLOR__" id="circle2" />'
+ '<circle cx="15" cy="18" r="9.5" stroke="__TEXT_COLOR__" id="circle4" />'
+ '<path d="M13.9169 18.8681V22H13V14H15.5645C16.3254 14 16.9207 14.2234 17.3505 14.6703C17.7835 15.1172 18 15.7088 18 16.4451C18 17.2216 17.7883 17.8205 17.3649 18.2418C16.9446 18.6593 16.3413 18.8681 15.5549 18.8681H13.9169ZM13.9169 18.0055H15.5645C16.0548 18.0055 16.4304 17.8736 16.6915 17.6099C16.9526 17.3425 17.0831 16.9579 17.0831 16.456C17.0831 15.9799 16.9526 15.5989 16.6915 15.3132C16.4304 15.0275 16.0723 14.8791 15.617 14.8681H13.9169V18.0055Z" fill="__TEXT_COLOR__" id="path6" />'
+ `${indexTag(index)}`
+ '</svg>';

export default {
  basic,
  indexed,
  active,
  hover,
  done,
};
