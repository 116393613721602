import {
  createCircleMarker, createPinMarker, createParkingMarker, createBreakMarker, getCloserPoint, createStartMarker,
  zoomTo,
} from '../../../../services/map/drawing';
import {
  createTripInfoBubble, createTripInfoBubbleContent,
} from '../../../../services/map/drawing/infobubbles/tripInfoBubbleCreator';
import createPauseInfoBubble from '../../../../services/map/drawing/infobubbles/pauseInfoBubbleCreator';
import createEndEventInfoBubble from '../../../../services/map/drawing/infobubbles/endEventInfoBubbleCreator';
import createTripStartInfoBubble from '../../../../services/map/drawing/infobubbles/tripStartInfoBubbleCreator';
import { createContinuousLine, createLineAsSteps } from '../../../../services/map/drawing/routeDrawer';
import { createMapMask } from '../../../../services/map/drawing/rectCreator';
import theme from '../../../../theme';
import NOP from '../../../../utils/NOP';
import { EventType } from '../../../../types/enums/eventType.enum';
import { MapEventType } from '../../../../services/map/mapEventDispatcher';
import { info, error } from '../../../../services/Logging';

const routeConfig = {
  data: {
    clickable: true,
  },
  volatility: true,
  visibility: false,
  zIndex: 3000,
};

const routeHoverConfig = {
  data: {
    clickable: true,
  },
  visibility: false,
  volatility: true,
  zIndex: 2900,
};

const pauseBlankStyle = {
  fillColor: theme.color.lightText, textColor: theme.color.routeStroke,
};

const pauseSolidStyle = {
  fillColor: theme.color.routeStroke, textColor: theme.color.lightText,
};

const DEFAULT_CONFIG = {
  breaks: true,
  centerOnApply: true,
  closeButton: true,
  mask: true,
  startStop: true,
  trace: true,
  vehicleLink: true,
  minStopLengthSeconds: 0,
};

const MIN_BREAK_LENGTH_SECONDS = 9 * 60 * 60;
const ALLOW_BREAK_DURATION_IN_SECONDS = 60 * 1;
class LastTraceRenderer {
  constructor({
    mapEventDispatcher, vehicle, trace, onApply, onDispose, ui, t, config = {},
  }) {
    this.mapEventDispatcher = mapEventDispatcher;
    this.vehicle = vehicle;
    this.trace = trace;
    this.onApply = onApply;
    this.onDispose = onDispose;
    this.ui = ui;
    this.t = t;
    this.config = { ...DEFAULT_CONFIG, ...config };

    this.initObjects();
  }

  dispose() {
    info('Disposing last trace renderer');
    if (this.mask) {
      try {
        this.ui.getMap().removeObject(this.mask);
      } catch (e) {
        error(e);
      }
    }
    Object.values(this.markers).filter((o) => !!o).forEach((marker) => marker.dispose(this.ui));
    this.trips.filter((o) => !!o).forEach((trip) => trip.dispose(this.ui));

    if (this.onDispose) {
      this.onDispose();
    }
  }

  show(visible) {
    if (this.line) {
      this.line.setVisibility(!visible);
    }
    if (this.trips) {
      this.trips.forEach((trip) => trip.toogleVisibility(this.ui, visible));
    }
  }

  apply() {
    info('Applying last trace renderer');
    if (this.mask) {
      this.ui.getMap().addObject(this.mask);
    }
    Object.values(this.markers).forEach((marker) => marker.apply(this.ui));
    this.trips.forEach((trip) => trip.apply(this.ui));

    if (this.onApply) {
      this.onApply();
    }

    if (this.config.centerOnApply) {
      this.mapEventDispatcher.dispatch({
        type: MapEventType.NEW_BOUNDING,
        payload: {
          bounding: this.getBounding(),
          triggerCentering: true,
        },
      });
    }

    return this;
  }

  createTripPause(event, index) {
    const type = event.duration < MIN_BREAK_LENGTH_SECONDS ? 'PARKING' : 'BREAK';
    const { latitude: lat, longitude: lng } = event.location.position;
    const infoBubble = createPauseInfoBubble({ lat, lng }, this.vehicle, event, type, this.t, this.config);
    const position = { coordinateLongitude: lng, coordinateLatitude: lat };
    const markerCreator = type === 'PARKING' ? createParkingMarker : createBreakMarker;
    const marker = markerCreator('indexed', position, pauseBlankStyle, {
      data: { ...event, clickable: true },
      volatility: true,
      index,
    });
    const onTap = () => {
      if (infoBubble.getState() === 'open') {
        this.closeInfoBubbles();
      } else {
        this.closeInfoBubbles(infoBubble);
        infoBubble.open();
      }
    };
    const markerOnHover = markerCreator('indexed', position, pauseSolidStyle, {
      data: event,
      volatility: true,
      visibility: false,
      onTap,
      index,
    });
    const highlight = createCircleMarker(position,
      { color: theme.color.routeStrokeHighlight, size: 32 },
      { volatility: true, visibility: false });

    const onHighlight = (on) => {
      markerOnHover.setVisibility(on);
      marker.setVisibility(!on);
    };
    marker.addEventListener('pointerenter', () => {
      onHighlight(true);
    });
    markerOnHover.addEventListener('pointerleave', () => {
      onHighlight(false);
    });

    return {
      closeInfoBubble: (except) => {
        if (infoBubble !== except) {
          infoBubble.close();
        }
      },
      onTap,
      onHighlight,
      apply: (ui) => {
        ui.getMap().addObjects([marker, highlight, markerOnHover]);
        ui.addBubble(infoBubble);
      },
      dispose: (ui) => {
        try {
          ui.getMap().removeObjects([marker, highlight, markerOnHover]);
          ui.removeBubble(infoBubble);
        } catch (e) {
          error(e);
        }
      },
      centerAt: () => {
        zoomTo(this.ui, { lat, lng }, false, true);
      },
    };
  }

  initMarkers() {
    const eventMarkers = {};
    this.trace.events
      .filter(({ type, duration }) => !(type === EventType.BREAK && duration < this.config.minStopLengthSeconds))
      .forEach((event, index) => {
        const last = index === this.trace.events.length - 1;
        const marker = this.createEventMarker(event, index, last);
        if (marker) {
          eventMarkers[event.id] = marker;
        }
      });
    return eventMarkers;
  }

  createEventMarker(event, index, last) {
    if (event.type === EventType.START) {
      return this.config.startStop ? this.createTripStart(event, index) : null;
    }

    if ((event.type === EventType.END)) {
      return this.config.startStop ? this.createTripEnd(event, index) : null;
    }

    if (last && this.config.startStop && event.duration > ALLOW_BREAK_DURATION_IN_SECONDS) {
      return this.createTripEnd(event, index);
    }

    if (this.config.breaks && event.duration > ALLOW_BREAK_DURATION_IN_SECONDS) {
      return this.createTripPause(event, index);
    }

    return null;
  }

  createTripEnd(event, index) {
    const { lastPosition } = this.vehicle.vehicle;
    let ignitionState;
    if (lastPosition && lastPosition.length > 0) {
      ignitionState = lastPosition[0].ignitionState;
    }
    const trip = this.trace.trips.find(({ endEventId }) => endEventId === event.id);
    const { latitude: coordinateLatitude, longitude: coordinateLongitude } = event.location.position;

    const infoBubble = createEndEventInfoBubble(
      trip && trip.details, event, this.vehicle, () => this.dispose(), this.t, this.config,
    );
    const onTap = () => {
      if (infoBubble.getState() === 'open') {
        this.closeInfoBubbles();
      } else {
        this.closeInfoBubbles(infoBubble);
        infoBubble.open();
      }
    };

    let pauseMarker = {
      onHighlight: NOP,
      apply: NOP,
      dispose: NOP,
    };
    if (event.type === EventType.BREAK) {
      const breakType = event.duration < MIN_BREAK_LENGTH_SECONDS ? 'PARKING' : 'BREAK';
      const markerCreator = breakType === 'PARKING' ? createParkingMarker : createBreakMarker;
      const position = { coordinateLatitude, coordinateLongitude };
      const basicPauseMarker = markerCreator('active', position, pauseSolidStyle, {
        data: { ...event, clickable: true },
        zIndex: 2,
        volatility: true,
        index,
      });
      const pauseOnHoverMarker = markerCreator('active', position, pauseBlankStyle, {
        data: event,
        zIndex: 2,
        volatility: true,
        visibility: false,
        onTap,
        index,
      });
      const pauseHighlightMarker = createCircleMarker(position,
        { color: theme.color.routeStrokeHighlight, size: 32 },
        { volatility: true, visibility: false });

      const onHighlight = (on) => {
        pauseOnHoverMarker.setVisibility(on);
        basicPauseMarker.setVisibility(!on);
      };
      basicPauseMarker.addEventListener('pointerenter', () => {
        onHighlight(true);
      });
      pauseOnHoverMarker.addEventListener('pointerleave', () => {
        onHighlight(false);
      });

      pauseMarker = {
        onHighlight,
        marker: basicPauseMarker,
        markerOnHover: pauseOnHoverMarker,
        highlight: pauseHighlightMarker,
        apply: (ui) => {
          ui.getMap().addObjects([basicPauseMarker, pauseHighlightMarker, pauseOnHoverMarker]);
        },
        dispose: (ui) => {
          try {
            ui.getMap().removeObjects([basicPauseMarker, pauseHighlightMarker, pauseOnHoverMarker]);
          } catch (e) {
            error(e);
          }
        },
      };
    }

    const marker = createPinMarker({
      coordinateLatitude,
      coordinateLongitude,
      ignitionState,
      break: event.type === EventType.BREAK,
    }, {
      data: this.vehicle,
      zIndex: 1,
      onTap,
      volatility: true,
      onPointerMove: () => pauseMarker.onHighlight(true),
      onPointerLeave: () => pauseMarker.onHighlight(false),
    });
    return {
      onTap,
      onHighlight: pauseMarker.onHighlight,
      closeInfoBubble: (except) => {
        if (infoBubble !== except) {
          infoBubble.close();
        }
      },
      apply: (ui) => {
        pauseMarker.apply(ui);
        ui.getMap().addObject(marker);
        ui.addBubble(infoBubble);
      },
      dispose: (ui) => {
        try {
          pauseMarker.dispose(ui);
          ui.getMap().removeObject(marker);
          ui.removeBubble(infoBubble);
        } catch (e) {
          error(e);
        }
      },
    };
  }

  createTripStart(event) {
    const infoBubble = createTripStartInfoBubble(event, this.t);
    const onTap = () => {
      if (infoBubble.getState() === 'open') {
        this.closeInfoBubbles();
      } else {
        this.closeInfoBubbles(infoBubble);
        infoBubble.open();
      }
    };
    const marker = createStartMarker({
      coordinateLatitude: event.location.position.latitude,
      coordinateLongitude: event.location.position.longitude,
    }, {
      color: theme.color.lastRouteStroke,
    }, {
      onTap,
    });
    return {
      closeInfoBubble: () => infoBubble.close(),
      onHighlight: NOP,
      onTap,
      apply: (ui) => {
        ui.addBubble(infoBubble);
        ui.getMap().addObject(marker);
      },
      dispose: (ui) => {
        try {
          ui.removeBubble(infoBubble);
          ui.getMap().removeObject(marker);
        } catch (e) {
          error(e);
        }
      },
    };
  }

  highlightEvents(ids, visibility) {
    if (!ids) {
      return;
    }
    ids.filter((id) => !!id).forEach((id) => {
      this.markers[id].onHighlight(visibility);
    });
  }

  initTrips() {
    return this.trace.trips.map((trip) => this.createTrip(trip));
  }

  createTrip(trip) {
    const points = trip.positions.map(({ latitude, longitude, serverTimestamp }, index) => ({
      lat: latitude, lng: longitude, id: `point-${index}`, timestamp: serverTimestamp,
    }));
    const infoBubble = createTripInfoBubble({}, this.vehicle, this.t);
    const onHoverRoute = createContinuousLine(points, routeHoverConfig);
    const positionDots = this.createPositionDots({
      points,
      onTap: (point) => {
        if (infoBubble.getState() === 'open' && infoBubble.getData() === point) {
          this.closeInfoBubbles();
        } else {
          this.closeInfoBubbles(infoBubble);
          infoBubble.setContent(createTripInfoBubbleContent(point, this.vehicle, this.t));
          infoBubble.setPosition(point);
          infoBubble.setData(point);
          infoBubble.open();
        }
      },
      onPointerMove: (_, dot) => {
        onHoverRoute.setVisibility(true);
        dot.setVisibility(true);
      },
      onPointerLeave: (_, dot) => {
        onHoverRoute.setVisibility(false);
        dot.setVisibility(false);
      },
    });
    const positionsMap = {};
    positionDots.forEach((dot) => { positionsMap[dot.id] = dot; });
    positionsMap[`point-${points.length - 1}`] = this.markers[trip.endEventId];
    const route = createLineAsSteps(points, {
      ...routeConfig,
      onTap: ({ start, end }, { currentPointer }) => {
        if (start.id === 'point-0') {
          positionsMap['point-1'].onTap(end);
        } else {
          const eventPoint = this.ui.getMap().screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
          const { closer } = getCloserPoint(start, end, eventPoint);
          if (positionsMap[closer.id].onTap) {
            positionsMap[closer.id].onTap();
          }
        }
      },
      onPointerMove: ({ start, end }, { currentPointer }) => {
        onHoverRoute.setVisibility(true);
        if (start.id === 'point-0') {
          positionsMap['point-1'].onHighlight(true);
        } else {
          const eventPoint = this.ui.getMap().screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
          const { closer, further } = getCloserPoint(start, end, eventPoint);
          positionsMap[closer.id].onHighlight(true);
          positionsMap[further.id].onHighlight(false);
        }
      },
      onPointerLeave: ({ start, end }) => {
        onHoverRoute.setVisibility(false);
        positionsMap[end.id].onHighlight(false);
        positionsMap[start.id].onHighlight(false);
      },
    });
    return {
      closeInfoBubble: (except) => {
        if (infoBubble !== except) {
          infoBubble.close();
        }
      },
      apply: (ui) => {
        ui.getMap().addObjects([...positionDots, ...route, onHoverRoute]);
        ui.addBubble(infoBubble);
      },
      dispose: (ui) => {
        try {
          ui.getMap().removeObjects([...positionDots, ...route, onHoverRoute]);
          ui.removeBubble(infoBubble);
        } catch (e) {
          error(e);
        }
      },
      toogleVisibility: (ui, visible) => {
        route.forEach((step) => step.setVisibility(!visible));
        onHoverRoute.setVisibility(!visible);
      },
    };
  }

  createPositionDots({
    points, onTap, onPointerMove, onPointerLeave,
  }) {
    return points.map((point) => {
      const marker = createCircleMarker(point, {
        color: theme.color.white, size: 8,
      }, {
        data: { clickable: true },
        visibility: false,
        volatility: true,
        onTap: (m) => onTap(point, m),
        onPointerMove: (m) => onPointerMove(point, m),
        onPointerLeave: (m) => onPointerLeave(point, m),
      });

      marker.onHighlight = (on) => {
        marker.setVisibility(on);
      };
      marker.onTap = () => onTap(point);
      marker.id = point.id;

      return marker;
    });
  }

  initMask() {
    return createMapMask({
      onTap: () => this.closeInfoBubbles(),
    });
  }

  initObjects() {
    this.markers = this.initMarkers();
    this.trips = this.config.trace ? this.initTrips() : [];
    this.mask = this.config.mask ? this.initMask() : null;
  }

  getBounding() {
    const allPositions = this.trace.trips.flatMap((
      trip,
    ) => trip.positions.map(({ latitude, longitude, serverTimestamp }, index) => ({
      lat: latitude, lng: longitude, id: `point-${index}`, timestamp: serverTimestamp,
    })));
    const line = createContinuousLine(allPositions, { visibility: true, volatility: true });
    // this.line = line;
    return line && line.getBoundingBox();
  }

  closeInfoBubbles(except) {
    Object.values(this.markers).forEach((marker) => marker.closeInfoBubble(except));
    this.trips.forEach((trip) => trip.closeInfoBubble(except));
  }

  tapMarker(eventId, zoom = false) {
    const marker = this.markers && this.markers[eventId];
    if (!marker) {
      return;
    }

    marker.onTap();
    if (zoom && marker.centerAt) {
      marker.centerAt();
    }
  }
}

export default LastTraceRenderer;
