/* eslint no-param-reassign:0 */
/* eslint prefer-rest-params:0 */
/* eslint no-underscore-dangle:0 */
// eslint-disable-next-line func-names
export default function (childCtor, parentCtor) {
  function TempCtor() {
  }

  TempCtor.prototype = parentCtor.prototype;
  childCtor.superClass_ = parentCtor.prototype;
  childCtor.prototype = new TempCtor();
  childCtor.prototype.constructor = childCtor;
  childCtor.base = (me, methodName) => {
    const args = new Array(arguments.length - 2);
    for (let i = 2; i < arguments.length; i += 1) {
      args[i - 2] = arguments[i];
    }
    return parentCtor.prototype[methodName].apply(me, args);
  };
}
