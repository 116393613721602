import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Bolded, Content, HalfBolded } from '../../compontents/RequestComponent';
import { DateTime } from '../../../../components';
import RequestStatusTimelineItem from './RequestStatusTimelineItem';
import { getCleanMoment } from '../../../../utils/dateTimeUtils';
import {
  isAccepted,
  isDeactivated,
  isDeactivatedAfterAcceptation,
  isDeclined, isIncoming,
  isPending,
} from '../../../../utils/monitoringRequests';

const RequestStatusTimeline = ({ requestDetails }) => {
  const { t } = useTranslation();

  const sendItem = () => ({
    disabled: true,
    last: false,
    color: 'green',
    renderDetail: () => (
      <>
        {`${t('REQUEST.TIMELINE_STATUS.SEND')} `}
        <Bolded>{requestDetails.carrierInfo.companyName}</Bolded>
        <DateTime
          value={requestDetails.createdAt}
        />
      </>
    ),
  });

  const getDeclineReason = () => (requestDetails.declineInfo.declineReason === 'OTHER'
    ? `Other: ${requestDetails.declineInfo.declineReasonDescription}`
    : t(`REQUEST.DECLINE.${requestDetails.declineInfo.declineReason}`));

  const getLastActionDate = () => {
    if (isAccepted(requestDetails)) {
      return getCleanMoment(requestDetails.acceptedInfo.acceptedAt);
    }
    if (isDeclined(requestDetails)) {
      return getCleanMoment(requestDetails.declineInfo.declinedAt);
    }
    if (isDeactivated(requestDetails)) {
      return getCleanMoment(requestDetails.deactivateInfo.deactivatedAt);
    }
    return moment();
  };

  function calculateTimeDuration() {
    return ` (${moment.duration(getCleanMoment(requestDetails.createdAt)
      .diff(getLastActionDate())).humanize(true)})`;
  }

  const pendingItem = () => ({
    disabled: !isPending(requestDetails),
    last: isPending(requestDetails),
    color: isPending(requestDetails) ? 'blue' : 'green',
    renderDetail: () => (
      <>
        <Content>
          {`${t('REQUEST.TIMELINE_STATUS.PENDING')} `}
          <Bolded>{requestDetails.carrierInfo.companyName}</Bolded>
        </Content>
        <Content>
          {`${t('PROPERTIES.STATUS')} - `}
          <Bolded>{t('PROPERTIES.REQUEST_PENDING')}</Bolded>
          {calculateTimeDuration()}
        </Content>
      </>
    ),
  });

  const acceptedItem = () => (isAccepted(requestDetails) || isDeactivatedAfterAcceptation(requestDetails) ? {
    disabled: true,
    last: false,
    color: 'green',
    renderDetail: () => (
      <>
        <Content>
          {`${t('REQUEST.TIMELINE_STATUS.ACCEPTED')} `}
          <Bolded>{requestDetails.carrierInfo.companyName}</Bolded>
        </Content>
        <Content>
          <DateTime
            value={requestDetails.acceptedInfo.acceptedAt}
          />
        </Content>
        <Content>
          {`${t('PROPERTIES.STATUS')} - `}
          <Bolded>{t('PROPERTIES.REQUEST_ACCEPTED')}</Bolded>
        </Content>
      </>
    ),
  } : null);

  // eslint-disable-next-line max-len
  const linkToOrder = () => `/orders/details/${isIncoming(requestDetails) ? 'in' : 'out'}/${requestDetails.shipperInfo.companyId}/${requestDetails.orderNumber}`;

  const createdItem = () => (isAccepted(requestDetails) || isDeactivatedAfterAcceptation(requestDetails) ? {
    disabled: isDeactivatedAfterAcceptation(requestDetails),
    last: isAccepted(requestDetails),
    color: 'green',
    renderDetail: () => (
      <>
        <Content>
          {`${t('REQUEST.TIMELINE_STATUS.CREATED')} `}
          <Link target="_blank" to={linkToOrder()}>
            {requestDetails.orderNumber}
          </Link>
        </Content>
        <Content>
          <DateTime
            value={requestDetails.acceptedInfo.acceptedAt}
          />
        </Content>
      </>
    ),
  } : null);

  const declinedItem = () => (isDeclined(requestDetails) ? {
    disabled: false,
    last: true,
    color: 'green',
    renderDetail: () => (
      <>
        <Content>
          {`${t('REQUEST.TIMELINE_STATUS.DECLINED')} `}
          <Bolded>{requestDetails.carrierInfo.companyName}</Bolded>
        </Content>
        <Content>
          <DateTime
            value={requestDetails.declineInfo.declinedAt}
          />
        </Content>
        <Content>
          {`${t('PROPERTIES.STATUS')} - `}
          <HalfBolded>{t('PROPERTIES.REQUEST_DECLINED')}</HalfBolded>
        </Content>
        <Content>
          {`${t('REQUEST.DECLINED_REASON')} - `}
          <HalfBolded>
            {getDeclineReason()}
          </HalfBolded>
        </Content>
      </>
    ),
  } : null);

  const deactivatedItem = () => (isDeactivated(requestDetails) ? {
    disabled: false,
    last: true,
    color: 'green',
    renderDetail: () => (
      <>
        <Content>
          {`${t('REQUEST.TIMELINE_STATUS.DEACTIVATED')} `}
          <Bolded>{requestDetails.shipperInfo.companyName}</Bolded>
        </Content>
        <Content>
          <DateTime
            value={requestDetails.deactivateInfo.deactivatedAt}
          />
        </Content>
        <Content>
          {`${t('PROPERTIES.STATUS')} - `}
          <Bolded>{t('PROPERTIES.REQUEST_DEACTIVATED')}</Bolded>
        </Content>
      </>
    ),
  } : null);

  const items = [
    sendItem(),
    pendingItem(),
    acceptedItem(),
    createdItem(),
    declinedItem(),
    deactivatedItem(),
  ].filter(Boolean);

  const prepareItem = (item, index) => <RequestStatusTimelineItem key={index} item={item} index={index} />;

  return (
    <Timeline>
      {items.map((item, index) => prepareItem(item, index))}
    </Timeline>
  );
};

RequestStatusTimeline.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default RequestStatusTimeline;
