import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { uuidv4 } from '../../../utils/uuid';
import { Styles } from './TelematicAccount.styled';
import { FormSelect } from '../../../components';

const TelematicProviderSelect = forwardRef(({
  providerGroups, disabled, defaultValue, onChange,
}) => {
  const { t } = useTranslation();

  const filter = (input, option) => {
    const i = input.toLowerCase();
    const c = (option?.children ?? '').toLowerCase();
    return c.replaceAll(' ', '').includes(i) || c.includes(i);
  };

  return (
    <>
      <FormSelect
        showSearch
        defaultValue={defaultValue}
        placeholder={t('TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.TELEMATIC_PROVIDER_SELECT')}
        filterOption={filter}
        onChange={onChange}
        disabled={disabled}
        style={Styles.wide}
      >
        {providerGroups.map(({ label, providers }) => (
          <Select.OptGroup label={label} key={uuidv4()}>
            {providers.map(({ providerId, displayName }) => (
              <Select.Option key={providerId} value={providerId}>
                {displayName}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </FormSelect>
    </>
  );
});

TelematicProviderSelect.propTypes = {
  providerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      providers: PropTypes.arrayOf(
        PropTypes.shape({
          providerId: PropTypes.string,
          displayName: PropTypes.string,
          fields: PropTypes.arrayOf(PropTypes.shape({
            fieldName: PropTypes.string,
            i18nKey: PropTypes.string,
          })),
        }),
      ),
    }),
  ),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

TelematicProviderSelect.defaultProps = {
  providerGroups: [],
  disabled: false,
  defaultValue: undefined,
  onChange: undefined,
};

export default TelematicProviderSelect;
