import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import vehiclesReducer from './vehicles';
import userReducer from './user';
import companyReducer from './company';
import vehicleDetailsReducer from './vehicleDetails';
import sharingReducer from './sharing';
import emailSharingReducer from './emailsharing';
import locationReducer from './location.reducer';
import fleetReducer from './fleet';
import ordersReducer from './orders';
import requestReducer from './requests';
import personalizationReducer from './personalization';
import errorCatcher from '../middlewares/errorCatcher.middleware';
import titleSetter from '../middlewares/titleSetter.middleware';
import ApiService from '../../services/ApiService';
import { loadingBarMiddleware, loadingBarReducer } from '../../components/Structure/LoadingBar';
import geofencingReducer from './geofencing/geofencing.reducer';
import carrierListReducer from './carriers';
import requestsOutgoingReducer from './requests/requestsOutgoing.reducer';
import requestsIncomingReducer from './requests/requestsIncoming.reducer';
import telematicAccounts from './telematicAccounts';

const reducer = combineReducers({
  geofencing: geofencingReducer,
  vehicles: vehiclesReducer,
  user: userReducer,
  company: companyReducer,
  vehicleDetails: vehicleDetailsReducer,
  sharing: sharingReducer,
  emailSharing: emailSharingReducer,
  carrierList: carrierListReducer,
  location: locationReducer,
  fleet: fleetReducer,
  orders: ordersReducer,
  request: requestReducer,
  loadingBar: loadingBarReducer,
  personalization: personalizationReducer,
  requestsOutgoing: requestsOutgoingReducer,
  requestsIncoming: requestsIncomingReducer,
  telematicAccounts,
});

export default createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      axiosMiddleware(ApiService.defaultInstance),
      errorCatcher,
      titleSetter,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['LOAD', 'SUCCESS', 'FAIL'],
      }),
    ),
  ),
);
