/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ParkingIndexedIcon = ({ index, onClick }) => (
  <div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      version="1.1"
      id="svg14"
    >
      <circle
        cx="15"
        cy="18"
        r="10"
        fill="white"
        id="circle2"
      />
      <circle
        cx="15"
        cy="18"
        r="9.5"
        stroke="#2F80ED"
        id="circle4"
      />
      <path
        d="M13.9169 18.8681V22H13V14H15.5645C16.3254 14 16.9207 14.2234 17.3505 14.6703C17.7835 15.1172 18 15.7088 18 16.4451C18 17.2216 17.7883 17.8205 17.3649 18.2418C16.9446 18.6593 16.3413 18.8681 15.5549 18.8681H13.9169ZM13.9169 18.0055H15.5645C16.0548 18.0055 16.4304 17.8736 16.6915 17.6099C16.9526 17.3425 17.0831 16.9579 17.0831 16.456C17.0831 15.9799 16.9526 15.5989 16.6915 15.3132C16.4304 15.0275 16.0723 14.8791 15.617 14.8681H13.9169V18.0055Z"
        fill="#2F80ED"
        id="path6"
      />

      {index > 9 ? (
        <g>
          <path
            id="circle4-3"
            style={{
              fill: '#4D4D4D', stroke: '#FFFFFF', strokeWidth: '0.6632', strokeMiterlimit: '2.126',
            }}
            d="M23.2,20.1c3.5,0,6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3c0,0-5.1,0-5.1,0c-3.4-0.1-6.2-2.9-6.2-6.3
        c0-3.4,2.7-6.1,6-6.3C18,20.1,23.1,20.1,23.2,20.1z"
          />
          <text transform="matrix(1 0 0 1 15.4056 29.735)" style={{ fill: '#FFFFFF', fontFamily: 'Helvetica', fontSize: '9.567px' }}>{index}</text>
        </g>
      ) : (
        <g
          style={{ fill: 'none' }}
          id="g855"
          transform="matrix(0.53150159,0,0,0.53150159,15.518769,18.549358)"
        >
          <circle
            cx="14.976333"
            cy="14.708827"
            r="11.854009"
            id="circle4-3"
            style={{
              fill: '#4d4d4d', stroke: '#ffffff', strokeWidth: '1.24779', strokeOpacity: '1',
            }}
          />
          <text
            style={{
              fontStyle: 'normal', textAlign: 'center', fontWeight: 'normal', fontSize: '20px', lineHeight: '1.25', fontFamily: 'sans-serif', fill: '#000000', fillOpacity: '1', stroke: 'none',
            }}
            x="8.4157228"
            y="22.023979"
            id="text843"
          >
            <tspan
              id="tspan841"
              x="10"
              y="21"
              style={{
                fontSize: '18px', fill: '#ffffff', stroke: 'none', strokeOpacity: '1',
              }}
            >
              {index}
            </tspan>
          </text>
        </g>
      )}

    </svg>
  </div>
);

ParkingIndexedIcon.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

ParkingIndexedIcon.defaultProps = {
  onClick: null,
};

export default ParkingIndexedIcon;
