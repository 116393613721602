import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FullHeightRow, StyledTabs } from '../../components';
import { CarrierTab } from '../../types/enums/carrierType.enum';
import CarrierHeaderComponent from './CarrierHeaderComponent';
import CarrierSearch from './CarrierSearch';
import { CarrierListComponent } from './CarrierList/CarrierListComponent';
import { loadInvitedCarriers } from '../../store/actions/carriers';

const CarriersContentHolder = ({ context }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [carrierTab] = useState(context === 'search' ? CarrierTab.FIND_CARRIER : CarrierTab.CARRIER_LIST);
  const breadcrumbTypeLabel = carrierTab === CarrierTab.FIND_CARRIER
    ? t('BREADCRUMB.FIND_CARRIER')
    : t('BREADCRUMB.MY_CARRIERS');
  const dispatch = useDispatch();

  useEffect(() => {
    if (carrierTab === 'list') {
      dispatch(loadInvitedCarriers(false));
    }
  }, [carrierTab, dispatch]);

  const breadcrumbTypeUrl = carrierTab === CarrierTab.FIND_CARRIER
    ? '/carriers/search'
    : '/carriers/list';

  return (
    <>
      <BreadcrumbsItem to={breadcrumbTypeUrl}>{breadcrumbTypeLabel}</BreadcrumbsItem>
      <StyledTabs
        defaultActiveKey={CarrierTab.FIND_CARRIER}
        activeKey={carrierTab}
        size="small"
        onChange={(carrierContext) => {
          history.push({
            pathname: `/carriers/${carrierContext}`,
          });
        }}
        tabBarExtraContent={(
          <CarrierHeaderComponent
            withRefresh={{
              enabled: carrierTab === CarrierTab.CARRIER_LIST,
              loading: true,
              compact: true,
            }}
          />
        )}
      >
        <Tabs.TabPane
          tab={t('BREADCRUMB.FIND_CARRIER')}
          key={CarrierTab.FIND_CARRIER}
        >
          <CarrierSearch />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t('BREADCRUMB.MY_CARRIERS')}
          key={CarrierTab.CARRIER_LIST}
        >
          <FullHeightRow gutter={[10, 10]} style={{ paddingTop: '10px' }}>
            <Col span={24}>
              <CarrierListComponent />
            </Col>
          </FullHeightRow>
        </Tabs.TabPane>
      </StyledTabs>
    </>
  );
};

CarriersContentHolder.propTypes = {
  context: PropTypes.string.isRequired,
};

export default CarriersContentHolder;
