import moment from 'moment';
import axiosActions from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { getSelectedCompany } from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_VEHICLE_ANALYSED_POSITIONS = axiosActions('VEHICLE_ANALYSED_POSITIONS_LOAD');

const defaultRange = [moment().subtract(1, 'd'), moment()];

export const fetchVehicleAnalysedPositions = (
  vehicleId,
  range = defaultRange,
  providingCompanyId = null,
) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  if (!range || range.length < 2 || !range[0] || !range[1]) {
    return;
  }
  const rangeFrom = range[0].unix();
  const rangeTo = range[1].unix();

  dispatch({
    type: FETCH_VEHICLE_ANALYSED_POSITIONS.START,
    payload: {
      actionId: uuidv4(),
      request: {
        url: UrlService.vehicleAnalysedPositions(vehicleId),
        params: {
          dateFrom: Math.min(rangeFrom, rangeTo),
          dateTo: Math.max(rangeFrom, rangeTo),
          contextCompanyId: selectedCompany,
          providingCompanyId,
        },
      },
    },
  });
};
