import UrlService from '../../../services/UrlService';
import { SUBMIT_GENERATE_REPORT } from '../vehicleDetails';

export const generateMonitoringReport = (internalId, shipperCompanyId, contextCompanyId, documentType) => ({
  type: SUBMIT_GENERATE_REPORT.START,
  payload: {
    request: {
      method: 'post',
      responseType: 'blob',
      url: UrlService.generateMonitoringReport(),
      data: {
        internalId,
        shipperCompanyId,
        contextCompanyId,
        documentType,
      },
    },
  },
});
