import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { getSelectedCompany } from '../../selectors';

export const FETCH_REQUEST_DETAILS = axiosActionsFactory('FETCH_REQUEST_DETAILS');

const fetchRequestDetails = (requestId, companyId) => (dispatch) => dispatch({
  type: FETCH_REQUEST_DETAILS.START,
  payload: {
    request: {
      url: UrlService.requestsDetails(requestId, companyId),
    },
  },
});

export const loadRequestDetails = (requestId) => (dispatch, getState) => {
  const state = getState();
  const selectedCompany = getSelectedCompany(state);
  return dispatch(fetchRequestDetails(requestId, selectedCompany));
};
