import { useTranslation } from 'react-i18next';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { monitoringOrderSnapshotPropTypes } from '../../../types';
import { FlexRight } from '../../../components';
import { StopTimeStatusDisplay } from './StopTimeStatusLabel';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';

const MonitoringOrderStopSnapshot = ({ monitoringOrderSnapshotData }) => {
  const { t } = useTranslation();

  const Time = styled.span`
    margin-left: 6px;
  `;

  const arrivalTime = monitoringOrderSnapshotData && monitoringOrderSnapshotData.arrivalTime;
  const departureTime = monitoringOrderSnapshotData && monitoringOrderSnapshotData.departureTime;
  const arrivalStatus = monitoringOrderSnapshotData && monitoringOrderSnapshotData.arrivalStatus;
  const departureStatus = monitoringOrderSnapshotData && monitoringOrderSnapshotData.departureStatus;

  return (
    <Row justify="end">
      <Col style={{ textAlign: 'right' }}>
        {arrivalTime && arrivalTime !== -1 && (
          <FlexRight>
            <span style={{ whiteSpace: 'nowrap' }}>
              {t('ORDER.GEOFENCING_ARRIVED')}
              :
            </span>
            <Time>
              {moment.unix(arrivalTime).format(DEFAULT_DATE_TIME)}
            </Time>
            {arrivalStatus && (
              <StopTimeStatusDisplay style={{ marginLeft: '6px' }} status={arrivalStatus}>
                {t(`ORDER.STOP_TIME_STATUS.${arrivalStatus}`)}
              </StopTimeStatusDisplay>
            )}
          </FlexRight>
        )}
        {departureTime && departureTime !== -1 && (
          <FlexRight>
            <span style={{ whiteSpace: 'nowrap' }}>
              {t('ORDER.GEOFENCING_DEPARTED')}
              :
            </span>
            <Time>
              {moment.unix(departureTime).format(DEFAULT_DATE_TIME)}
            </Time>
            {departureStatus && (
              <StopTimeStatusDisplay style={{ marginLeft: '6px' }} status={departureStatus}>
                {t(`ORDER.STOP_TIME_STATUS.${departureStatus}`)}
              </StopTimeStatusDisplay>
            )}
          </FlexRight>
        )}
      </Col>
    </Row>
  );
};

MonitoringOrderStopSnapshot.propTypes = {
  monitoringOrderSnapshotData: monitoringOrderSnapshotPropTypes,
};

MonitoringOrderStopSnapshot.defaultProps = {
  monitoringOrderSnapshotData: {},
};

export default MonitoringOrderStopSnapshot;
