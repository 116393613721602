import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_EMAIL_SHARES = axiosActions('EMAIL_SHARES_LOAD');
export const DELETE_EMAIL_SHARES = axiosActions('EMAIL_SHARES_DELETE');

const executeEmailSharesSearch = (companyId, silentRefresh = false) => ({
  type: FETCH_EMAIL_SHARES.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'get',
      url: UrlService.emailSharingsSearch(),
      params: {
        contextCompanyId: companyId,
      },
    },
  },
});

export const executeEmailSharesDelete = (orderId, secret) => ({
  type: DELETE_EMAIL_SHARES.START,
  payload: {
    actionId: uuidv4(),
    request: {
      method: 'delete',
      url: UrlService.emailSharingsDelete(orderId),
      params: {
        secret,
      },
    },
  },
});

export const fetchEmailShares = (silentRefresh = false) => (dispatch, getState) => {
  const state = getState();
  const selectedCompany = getSelectedCompany(state);

  return dispatch(executeEmailSharesSearch(selectedCompany, silentRefresh));
};
