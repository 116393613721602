import H from '@here/maps-api-for-javascript';
import { BIG_CLUSTER_TEMPLATE, MEDIUM_CLUSTER_TEMPLATE, SMALL_CLUSTER_TEMPLATE } from '../assets/clusterMarkers';
import { isValidPosition } from '../../../store/normalizers';
import { createBubbleOpener, createPinMarker } from './markerCreator';
import { MAP_CLUSTER_Z_INDEX } from './mapConsts';
import { zoomInGeoOneStep } from './utils';

const getClusterSvgTemplateBySize = (size) => {
  if (size < 5) {
    return {
      radius: 16,
      icon: SMALL_CLUSTER_TEMPLATE,
    };
  }

  if (size < 20) {
    return {
      radius: 21,
      icon: MEDIUM_CLUSTER_TEMPLATE,
    };
  }

  return {
    radius: 26,
    icon: BIG_CLUSTER_TEMPLATE,
  };
};

const clusteringConfig = {
  clusteringOptions: {
    eps: 16,
    minWeight: 2,
    strategy: H.clustering.Provider.Strategy.GRID,
  },
  theme: {
    getClusterPresentation(cluster) {
      const weight = cluster.getWeight();
      const { radius, icon } = getClusterSvgTemplateBySize(weight);
      const svgString = icon.replace(/{count}/g, weight);
      const clusterIcon = new H.map.Icon(svgString, {
        size: { w: radius * 2, h: radius * 2 },
        anchor: { x: radius, y: radius },
      });
      return new H.map.Marker(cluster.getPosition(), {
        data: {
          ...cluster,
          clickable: true,
        },
        icon: clusterIcon,
        zIndex: MAP_CLUSTER_Z_INDEX,
        min: cluster.getMinZoom(),
        max: cluster.getMaxZoom(),
      });
    },
    getNoisePresentation(noisePoint) {
      const position = noisePoint.getPosition();
      const data = noisePoint.getData();
      const { ignitionState } = data.vehicle.lastPosition[0];
      const markerPosition = { coordinateLatitude: position.lat, coordinateLongitude: position.lng, ignitionState };
      return createPinMarker(markerPosition, { data: { ...data, clickable: true }, minZoom: noisePoint.getMinZoom() });
    },
  },
};

export const createClusteringProvider = (ui, t, fetchLocation, showLastTrace, infoBubble) => {
  const clusterProvider = new H.clustering.Provider([], clusteringConfig);
  const bubbleOpener = createBubbleOpener(ui, t, fetchLocation, showLastTrace, infoBubble);
  clusterProvider.addEventListener('tap', (evt) => {
    if (!evt.target.getData().marker) {
      zoomInGeoOneStep(ui, evt.target.b);
    } else {
      bubbleOpener(evt.target.getData(), evt.target.b);
    }
  });

  return clusterProvider;
};

export const createDataPoints = (vehicles) => vehicles.length
  && vehicles.filter((item) => isValidPosition(item.vehicle.lastPosition[0]))
    .map((item) => new H.clustering.DataPoint(
      item.vehicle.lastPosition[0].coordinateLatitude,
      item.vehicle.lastPosition[0].coordinateLongitude,
      1,
      item,
    ));
