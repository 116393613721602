import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterTag, ParagraphNoBottom } from '../Styled';
import { getTypeIcon } from './TableConfigurationComponent.constants';
import { getConfigurationType, getFilterValues } from '../../types/mappers';
import { ConfigurationType } from '../../types';

const CurrentViewTooltip = ({ type, values, name }) => {
  const { t } = useTranslation();

  const tooltip = {
    [ConfigurationType.Vehicles]: 'PERSONALIZATION.VEHICLES',
    [ConfigurationType.Companies]: 'PERSONALIZATION.COMPANIES',
  };

  return (
    type && values && values.length
    && (
      <>
        <ParagraphNoBottom style={{ color: 'white' }}>
          <Typography.Text strong style={{ color: 'white' }}>
            {t('PERSONALIZATION.CONFIGURATION_NAME')}
            {': '}
          </Typography.Text>
          {name}
        </ParagraphNoBottom>
        <ParagraphNoBottom style={{ color: 'white' }}>
          <Typography.Text strong style={{ color: 'white' }}>
            {t(tooltip[type])}
            {': '}
          </Typography.Text>
          {values.map(({ label }) => label).join(', ')}
        </ParagraphNoBottom>
      </>
    )
  );
};

CurrentViewTooltip.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

CurrentViewTooltip.defaultProps = {
  type: null,
  values: null,
  name: null,
};

function CurrentViewTag({ view }) {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState([]);
  const [type, setType] = useState(null);
  useEffect(() => {
    if (view) {
      setFilterValues(getFilterValues(view.filters));
      setType(getConfigurationType(view.filters));
    }
  }, [view]);

  return (
    filterValues && view && type ? (
      <Tooltip placement="top" title={<CurrentViewTooltip values={filterValues} type={type} name={view.name} />}>
        <FilterTag color="default">
          {getTypeIcon(view.filters)}
          <b>
            {t('PERSONALIZATION.SELECTED_VIEW')}
            {': '}
          </b>
          {view.name}
        </FilterTag>
      </Tooltip>
    ) : null
  );
}

CurrentViewTag.propTypes = {
  view: PropTypes.shape({
    name: PropTypes.string,
    filters: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })),
    ),
  }),
};

CurrentViewTag.defaultProps = {
  view: null,
};

export default CurrentViewTag;
