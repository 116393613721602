import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Form, message, Typography } from 'antd';
import { useHistory } from 'react-router';
import { error } from '../../../services/Logging';
import {
  getMeId,
  getRequestCompanyVehicles,
  getRequestCreationData,
  getSelectedCompany,
} from '../../../store/selectors';
import { createRequest, fetchAllCompanies } from '../../../store/actions/requests/create-request.actions';
import RequestCreationForm from './Components/RequestCreationForm';
import RequestConfirmationDialog from './Components/RequestConfirmationDialog';
import { MonitoringRequestType } from '../../../types/request.type';
import { findSelectedCompany, RequestMapper } from '../../../types/mappers/request.mapper';
import { enhanceRequestStopsWithGeocodes } from '../../../services/GeocodesService';
import { isSuccess } from '../../../utils/responseUtils';

const RequestCreationPage = () => {
  const { companies, creation } = useSelector(getRequestCreationData);
  const [confirmed, setConfirmed] = useState(false);
  const { vehicles } = useSelector(getRequestCompanyVehicles);
  const contextCompanyId = useSelector(getSelectedCompany);
  const loggedUserId = useSelector(getMeId);

  const [confirmationDialog, setConfirmationDialog] = useState(null);

  const { t } = useTranslation();
  const [commonForm] = Form.useForm();
  const [timeMonitoringForm] = Form.useForm();
  const [tourMonitoringForm] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCompanies());
  }, [dispatch]);

  const SuccessMessageContent = () => (
    <>
      <Typography.Text>
        {t('REQUEST.CREATION_SUCCESSFUL')}
      </Typography.Text>
    </>
  );

  const dispatchCreateRequest = (request, requestType) => {
    if (request) {
      const company = findSelectedCompany(request, companies.data);
      const vehicle = request.carrierVehicle;
      setConfirmationDialog({
        request,
        requestType,
        company,
        vehicle,
        onSubmit: () => {
          setConfirmed(true);
          dispatch(createRequest(request))
            .then((response) => {
              setConfirmationDialog(null);
              if (isSuccess(response)) {
                message.success(<SuccessMessageContent />, 7);
                history.push({ pathname: '/requests/outgoing' });
              } else {
                setConfirmed(false);
              }
            })
            .catch(() => setConfirmed(false));
        },
      });
    }
  };

  const checkGeocoding = (geocodingResult) => {
    if (geocodingResult.error) {
      const {
        houseNumber, localNumber, city, street, postcode, countryCode,
      } = geocodingResult.error.stops[0].address;
      const location = `${houseNumber}${localNumber ? ` ${localNumber}` : ''} ${street},
       ${postcode} ${city}, ${countryCode}`;
      message.error(
        <span style={{ whiteSpace: 'pre-wrap' }}>{t('REQUEST.VALIDATION.MISSING_GEOCODES', { location })}</span>,
      );
      return null;
    }
    return geocodingResult.tour;
  };

  const getFormToValidate = (requestType) => (requestType === MonitoringRequestType.TIME
    ? timeMonitoringForm
    : tourMonitoringForm
  );

  const emailRegexp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  const filterCompaniesToSelect = () => ({
    loading: companies.loading,
    data: companies.data.filter((company) => company.id !== contextCompanyId && !emailRegexp.test(company.name)),
  });

  const handleSubmit = (requestType) => {
    Promise.all([commonForm.validateFields(), getFormToValidate(requestType).validateFields()])
      .then((request) => RequestMapper.formToApi(request,
        { loggedUserId, contextCompanyId },
        vehicles.data, requestType), error)
      .then(enhanceRequestStopsWithGeocodes, error)
      .then(checkGeocoding)
      .then((request) => dispatchCreateRequest(request, requestType), error);
  };

  const handleCancel = () => {
    commonForm.resetFields();
    timeMonitoringForm.resetFields();
    tourMonitoringForm.resetFields();
    history.goBack();
  };

  return (
    <>
      <BreadcrumbsItem to="/requests">{t('BREADCRUMB.REQUESTS')}</BreadcrumbsItem>
      <BreadcrumbsItem to="/requests/outgoing">{t('BREADCRUMB.OUTGOING')}</BreadcrumbsItem>
      <BreadcrumbsItem to="/requests/outgoing/new">{t('BREADCRUMB.MONITORING_NEW')}</BreadcrumbsItem>
      <RequestCreationForm
        commonForm={commonForm}
        timeMonitoringForm={timeMonitoringForm}
        tourMonitoringForm={tourMonitoringForm}
        companies={filterCompaniesToSelect()}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        creation={creation}
      />
      <RequestConfirmationDialog
        open={!!confirmationDialog}
        onClose={() => setConfirmationDialog(null)}
        confirmed={confirmed}
        onSubmit={confirmationDialog && confirmationDialog.onSubmit}
        request={confirmationDialog && confirmationDialog.request}
        company={confirmationDialog && confirmationDialog.company}
        vehicle={confirmationDialog && confirmationDialog.vehicle}
        requestType={confirmationDialog && confirmationDialog.requestType}
      />
    </>
  );
};

export default RequestCreationPage;
