import { combineReducers } from 'redux';
import { FLEET_TOGGLE_AUTO_REFRESH } from '../../actions';

const autoRefreshReducer = (state = true, { type, payload }) => {
  if (type === FLEET_TOGGLE_AUTO_REFRESH) {
    return payload;
  }
  return state;
};

export default combineReducers({
  autoRefresh: autoRefreshReducer,
});
