import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Checkbox, Col, Modal, Row,
} from 'antd';

const mergeColumnsToCheck = (visibleColumns, lockedColumns) => {
  const basicChecked = Object.keys(visibleColumns).filter((key) => visibleColumns[key]);
  lockedColumns.forEach((col) => {
    if (!basicChecked.includes(col)) {
      basicChecked.push(col);
    }
  });

  return basicChecked;
};

const ColumnsConfigModal = ({
  open, onOk, onClose, allColumns, visibleColumns, lockedColumns,
}) => {
  const { t } = useTranslation();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [checked, setChecked] = useState(mergeColumnsToCheck(visibleColumns, lockedColumns));

  useEffect(() => {
    setChecked(mergeColumnsToCheck(visibleColumns, lockedColumns));
  }, [visibleColumns, lockedColumns]);

  const onChange = (checkedList) => {
    setIndeterminate(!!checkedList.length && checkedList.length < allColumns.length);
    setCheckAll(checkedList.length === allColumns.length);
    setChecked(checkedList);
  };

  const onCheckAllChange = (e) => {
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setChecked(e.target.checked ? allColumns : []);
  };

  const getCheckedPerColumn = () => {
    const visibility = {};
    allColumns.forEach((column) => {
      visibility[column] = checked.includes(column);
    });
    lockedColumns.forEach((column) => {
      visibility[column] = true;
    });
    return visibility;
  };

  const applySettings = () => {
    const visibilityPerColumn = getCheckedPerColumn();
    return onOk(visibilityPerColumn);
  };

  return (
    <Modal
      title={t('COMMON.CHOOSE_COLUMNS')}
      visible={open}
      onOk={() => applySettings()}
      onCancel={() => onClose()}
    >
      <div>
        <div>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {t('COMMON.CHECK_ALL')}
          </Checkbox>
        </div>
        <br />
        <Checkbox.Group
          value={checked}
          onChange={onChange}
        >
          <Row>
            {allColumns.filter((col) => !lockedColumns.includes(col)).map((col) => (
              <Col
                span={8}
                key={col}
              >
                <Checkbox
                  value={col}
                  disabled={lockedColumns.includes(col)}
                >
                  {t(`PROPERTIES.${col}`)}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    </Modal>
  );
};

ColumnsConfigModal.propTypes = {
  open: PropTypes.bool.isRequired,
  allColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  visibleColumns: PropTypes.objectOf(PropTypes.bool),
  lockedColumns: PropTypes.arrayOf(PropTypes.string),
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ColumnsConfigModal.defaultProps = {
  visibleColumns: {},
  lockedColumns: [],
};

export default ColumnsConfigModal;
