import LoadingBarContainer, { LoadingBar } from './LoadingBar';
import loadingBarMiddleware from './loadingBarMiddleware';
import {
  hideLoading,
  loadingBarReducer,
  resetLoading,
  showLoading,
} from './loadingBarActions';

export {
  hideLoading,
  LoadingBar,
  loadingBarMiddleware,
  loadingBarReducer,
  resetLoading,
  showLoading,
};
export default LoadingBarContainer;
