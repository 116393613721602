import PropTypes from 'prop-types';

const positionPropTypes = PropTypes.shape({
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  serverTimestamp: PropTypes.string,
  positionTimestamp: PropTypes.string,
  speed: PropTypes.string,
  ignitionState: PropTypes.string,
});

export const eventsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['START', 'END', 'BREAK']),
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  location: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    position: positionPropTypes,
  }),
}));

export const tripsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  ordinalNumber: PropTypes.number.isRequired,
  startEventId: PropTypes.number.isRequired,
  endEventId: PropTypes.number.isRequired,
  details: PropTypes.shape({
    startTimestamp: PropTypes.string,
    endTimestamp: PropTypes.string,
    distance: PropTypes.string,
    duration: PropTypes.string,
    avgSpeed: PropTypes.string,
  }),
  positions: PropTypes.arrayOf(positionPropTypes),
}));

export const analysedTracePropTypes = PropTypes.shape({
  events: eventsPropTypes,
  trips: tripsPropTypes,
});
