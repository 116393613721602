import PropTypes from 'prop-types';
import { ownershipTypeProp, vehicleTypeProp } from './dictionaries';
import { lastPosition, mappedLastPosition } from './positions.type';
import { owningCompany, shortCompany } from './company.type';

export const sharedVehicle = PropTypes.shape({
  vehicleId: PropTypes.string,
  licencePlateNumber: PropTypes.string,
  vehicleType: PropTypes.oneOfType([vehicleTypeProp, PropTypes.string]),
  make: PropTypes.string,
  ownershipType: ownershipTypeProp,
  vehicleCompany: owningCompany,
  lastPosition: PropTypes.arrayOf(lastPosition),
  useDateFrom: PropTypes.number,
  useDateTo: PropTypes.number,
  remark: PropTypes.string,
});

export const vehiclesTableRow = PropTypes.shape({
  vehicleId: PropTypes.string,
  licencePlateNumber: PropTypes.string,
  vehicleCompany: shortCompany,
  vehicleType: PropTypes.oneOfType([vehicleTypeProp, PropTypes.string]),
  make: PropTypes.string,
  ownershipType: ownershipTypeProp,
  lastPosition: mappedLastPosition,
  useDateFrom: PropTypes.number,
  useDateTo: PropTypes.number,
});

export const vehicleRemark = PropTypes.shape({
  remarkId: PropTypes.string,
  companyId: PropTypes.string,
  vehicleId: PropTypes.string,
  remark: PropTypes.string,
});
