import moment from 'moment';
import { FleetTabs } from '../../../types/enums/fleet.enum';
import axiosActions from '../axiosHelpers';
import UrlService from '../../../services/UrlService';
import { vehiclesWithDistinctLicence } from '../../../types/mappers';
import { getSelectedCompany, getCompleteSharing, getVehiclesArray } from '../../selectors';
import { ShareDirection } from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';
import { SharingComputedStatus } from '../../../types/sharing.type';

export const SUBMIT_SHARE_VEHICLES = axiosActions('SUBMIT_SHARE_VEHICLES_LOAD');
export const EDIT_SHARING = axiosActions('EDIT_SHARING_LOAD');
export const STOP_SHARING = axiosActions('STOP_SHARING_LOAD');
export const OPEN_SHARE_VEHICLES = 'OPEN_SHARE_VEHICLES';
export const CLOSE_SHARE_VEHICLES = 'CLOSE_SHARE_VEHICLES';
export const ShareVehicleMode = {
  EDIT: 'EDIT',
  NEW: 'NEW',
  RESTORE: 'RESTORE',
};

const executeOpenShareVehicles = (vehicles, sharing, mode = ShareVehicleMode.NEW, fixed = false, callback) => ({
  type: OPEN_SHARE_VEHICLES,
  payload: {
    vehicles, sharing, fixed, mode, callback,
  },
});

const isNotSharing = (vehicleOwnershipType) => vehicleOwnershipType !== 'SHARING';

const isOngoingSharing = (status) => status === SharingComputedStatus.Ongoing;

const getVehiclesFromSource = (dataSource, getState) => {
  switch (dataSource) {
    case FleetTabs.VEHICLES: {
      const { vehicles } = getVehiclesArray(getState());
      return vehicles.filter(({ ownershipType }) => isNotSharing(ownershipType)).map(({ vehicle }) => ({
        vehicleId: vehicle.id,
        licencePlateNumber: vehicle.licencePlateNumber,
      }));
    }
    case FleetTabs.SHARING: {
      const { shares, pageState } = getCompleteSharing(getState());
      const vehicleIdToLicence = {};
      shares.data.sharings.forEach((sharing) => {
        const { vehicle, ownershipType, activeStatus } = sharing;
        if (pageState.activeSharingsType === ShareDirection.OUT) {
          vehicleIdToLicence[vehicle.id] = vehicle.licencePlateNumber;
        }
        if (pageState.activeSharingsType === ShareDirection.IN
          && isNotSharing(ownershipType)
          && isOngoingSharing(activeStatus)) {
          vehicleIdToLicence[vehicle.id] = vehicle.licencePlateNumber;
        }
      });
      return Object.entries(vehicleIdToLicence)
        .map(([vehicleId, licencePlateNumber]) => ({ vehicleId, licencePlateNumber }));
    }
    default:
      return [];
  }
};

export const openShareVehicles = (
  selectedVehicles, dataSource, mode = ShareVehicleMode.NEW,
) => (dispatch, getState) => {
  const vehicles = getVehiclesFromSource(dataSource, getState);
  dispatch(
    executeOpenShareVehicles(vehicles, { selectedVehicles: vehiclesWithDistinctLicence(selectedVehicles) }, mode),
  );
};

export const openShareExactVehicle = (vehicleId, licencePlateNumber) => executeOpenShareVehicles(
  [{ vehicleId, licencePlateNumber }],
  { selectedVehicles: [{ vehicleId, licencePlateNumber }] },
  ShareVehicleMode.NEW,
  true,
);

export const closeShareVehicles = () => ({
  type: CLOSE_SHARE_VEHICLES,
});

const executeSubmitShareVehicles = (payload) => ({
  type: SUBMIT_SHARE_VEHICLES.START,
  payload: {
    request: {
      method: 'post',
      url: UrlService.shareVehicle(),
      data: { ...payload },
    },
  },
});

export const submitShareVehicles = (sharingForm) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  const {
    additionalInfo, vehicleIds, startDateTime, endDateTime, sharingType, emailAddress,
  } = sharingForm;
  return dispatch(
    executeSubmitShareVehicles({
      additionalInfo,
      companyId: selectedCompany,
      vehicleIds,
      shareFrom: startDateTime.unix(),
      shareTo: endDateTime.unix(),
      emailAddress,
      sharingType,
    }),
  );
};

export const openRestoreSharing = ({
  vehicle, useDateFrom, useDateTo, company, ownershipType, createdDate,
}, callback) => executeOpenShareVehicles(
  [{ vehicleId: vehicle.id, licencePlateNumber: vehicle.licencePlateNumber }],
  {
    selectedVehicles: [{ vehicleId: vehicle.id, licencePlateNumber: vehicle.licencePlateNumber }],
    useDateFrom: moment().unix(),
    useDateTo: moment().unix() + useDateTo - useDateFrom,
    personEmail: (company.emailAddresses && company.emailAddresses.length && company.emailAddresses[0]) || null,
    sharedToCompanyId: company.id,
    ownershipType,
    createdDate,
  },
  ShareVehicleMode.RESTORE,
  true,
  callback,
);

export const openEditSharing = ({
  vehicle, useDateFrom, useDateTo, company, ownershipType, createdDate,
}, callback) => executeOpenShareVehicles(
  [{ vehicleId: vehicle.id, licencePlateNumber: vehicle.licencePlateNumber }],
  {
    selectedVehicles: [{ vehicleId: vehicle.id, licencePlateNumber: vehicle.licencePlateNumber }],
    useDateFrom,
    useDateTo,
    personEmail: (company.emailAddresses && company.emailAddresses.length && company.emailAddresses[0]) || null,
    sharedToCompanyId: company.id,
    ownershipType,
    createdDate,
  },
  ShareVehicleMode.EDIT,
  true,
  callback,
);

export const stopSharing = ({ createdDate, company, vehicleId }) => ({
  type: STOP_SHARING.START,
  payload: {
    request: {
      method: 'post',
      url: UrlService.stopSharingVehicle(),
      data: {
        creationDate: createdDate,
        companyId: company.id,
        vehicleId,
      },
    },
  },
});

export const submitEditSharing = (form, creationDate, companyId) => {
  const {
    vehicleIds, startDateTime, endDateTime, sharingType,
  } = form;
  return {
    type: EDIT_SHARING.START,
    payload: {
      request: {
        method: 'post',
        url: UrlService.editSharingVehicle(),
        data: {
          companyId,
          creationDate,
          vehicleId: vehicleIds[0],
          useDateFrom: startDateTime.unix(),
          useDateTo: endDateTime.unix(),
          ownershipType: sharingType,
        },
      },
    },
  };
};
