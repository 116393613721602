import styled from 'styled-components';
import { Layout, Select } from 'antd';

export const StyledSelect = styled(Select)`
  min-width: 250px;
`;

export const LayoutHeader = styled(Layout.Header)`
  padding: 0 20px;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BreadcrumbsWrapper = styled.span`
  font-size: 16px;
`;
