import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import {
  Col, message, Radio, Row,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CurrentViewTag, FiltersTags, FleetHeaderComponent, RefreshComponent,
} from '../../../../components/Table';
import { FlexRight, FlexRightSpaced, HeaderButtonsDivider } from '../../../../components';
import {
  FILTERS, SharedByCompany, ShareDirection, SKIPPED_FILTERS,
} from './SharesTableComponent.constants';
import * as personalizationActionCreators from '../../../../store/actions/personalization';
import {
  getCurrentTableConfiguration, getFleetPageState, getRefreshIntervalSeconds, getShares,
  isCurrentInterfaceConfigurationLoading,
  isTableConfigurationsLoading, TableName,
} from '../../../../store/selectors';
import TableConfigurationComponent from '../../../../components/Table/TableConfigurationComponent';
import {
  openGenerateReport, openShareVehicles, resetSharingsPagination, resetVehiclesPagination, setFleetSelectionMode,
} from '../../../../store/actions';
import { FleetTabs, SelectionMode, SelectionType } from '../../../../types/enums/fleet.enum';

const SharesTableHeader = ({
  size, onRefresh, filters, updateFilters, clearFilters, updateRefreshInterval, refreshIntervalSeconds,
  isTableConfigLoading, shares, isInterfaceConfigLoading, currentTableConfig, pageState,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (filterName) => {
    updateFilters({ [filterName]: [] });
  };

  function getSideFilterOrDefault(sideFilter) {
    return (sideFilter && sideFilter.length && sideFilter[0]) || ShareDirection.IN;
  }

  function getSharedByCompanyFilterOrDefault(filter) {
    const filterValue = filter && filter.length && filter[0];
    if (filterValue === 'true' || filterValue === SharedByCompany.ALL || filterValue === true) {
      return SharedByCompany.ALL;
    }
    return SharedByCompany.MINE;
  }

  const onConfigurationSelected = () => {
    dispatch(resetSharingsPagination());
    dispatch(resetVehiclesPagination());
    onRefresh();
  };

  const handleMineChange = ({ target }) => updateFilters({ [FILTERS.SHARED_BY_COMPANY.name]: [target.value] });

  const handleUpdateInterval = (val) => updateRefreshInterval(TableName.Sharings, val);

  const onGenerateReport = () => {
    dispatch(openGenerateReport(null, FleetTabs.SHARING));
  };

  const onShareVehicles = () => {
    const { selection, selectedRows } = pageState;
    if (!selection || selection.mode === null) {
      message.info(t('SHARE_VEHICLES.SHARE_VEHICLES_INFO'), 4);
      dispatch(setFleetSelectionMode({
        mode: SelectionMode.SHARE,
        type: SelectionType.CHECKBOX,
      }));
    } else if (selection.mode === SelectionMode.SHARE) {
      dispatch(openShareVehicles(selectedRows, FleetTabs.SHARING));
    }
  };

  return (
    <Row gutter={[0, 8]}>
      <Col sm={10}>
        <CurrentViewTag view={currentTableConfig} />
      </Col>
      <Col sm={14}>
        <FlexRight>
          <FleetHeaderComponent
            loading={false}
            onGenerateReport={onGenerateReport}
            onShareVehicles={onShareVehicles}
          />
          <HeaderButtonsDivider />
          <TableConfigurationComponent
            tableName={TableName.Fleet}
            disabled={isTableConfigLoading || shares.loading || isInterfaceConfigLoading}
            size={size}
            onRefresh={onRefresh}
            onConfigurationSelected={onConfigurationSelected}
          />
        </FlexRight>
      </Col>
      <Col sm={24} md={18}>
        <FiltersTags
          filters={{ ...filters }}
          onRemoveFilter={handleClose}
          onClearFilters={clearFilters}
          skipFilters={SKIPPED_FILTERS}
        />
      </Col>
      <Col sm={24} md={6}>
        <FlexRightSpaced>
          <Radio.Group
            size={size}
            disabled={getSideFilterOrDefault(filters[FILTERS.SIDE.name]) !== ShareDirection.OUT}
            value={getSharedByCompanyFilterOrDefault(filters[FILTERS.SHARED_BY_COMPANY.name])}
            onChange={handleMineChange}
          >
            <Radio.Button value={SharedByCompany.MINE}>{t('DICTIONARY.SHARED_BY_COMPANY.MINE')}</Radio.Button>
            <Radio.Button value={SharedByCompany.ALL}>{t('DICTIONARY.SHARED_BY_COMPANY.ALL')}</Radio.Button>
          </Radio.Group>
          <HeaderButtonsDivider />
          <RefreshComponent
            onRefresh={onRefresh}
            onUpdateRefreshIntervalSeconds={handleUpdateInterval}
            size={size}
            refreshIntervalSeconds={refreshIntervalSeconds}
            loading={shares.loading}
            disabled={isTableConfigLoading || shares.loading || isInterfaceConfigLoading}
            lastRefreshed={shares.lastUpdated}
            compact
          />
        </FlexRightSpaced>
      </Col>
    </Row>
  );
};

SharesTableHeader.propTypes = {
  size: PropTypes.string,
  refreshIntervalSeconds: PropTypes.number,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.bool, PropTypes.number, MomentPropTypes.momentObj,
    ])),
  ),
  isTableConfigLoading: PropTypes.bool.isRequired,
  isInterfaceConfigLoading: PropTypes.bool.isRequired,
  shares: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    lastUpdated: MomentPropTypes.momentObj.isRequired,
  }).isRequired,
  currentTableConfig: PropTypes.shape({
    name: PropTypes.string,
    filters: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })),
    ),
  }),
  pageState: PropTypes.shape({
    selection: PropTypes.string,
    selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onRefresh: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateRefreshInterval: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

SharesTableHeader.defaultProps = {
  size: 'small',
  refreshIntervalSeconds: 120,
  filters: {},
  currentTableConfig: null,
};

const mapStateToProps = (state) => ({
  refreshIntervalSeconds: getRefreshIntervalSeconds(TableName.Sharings)(state),
  isTableConfigLoading: isTableConfigurationsLoading(state),
  isInterfaceConfigLoading: isCurrentInterfaceConfigurationLoading(state),
  currentTableConfig: getCurrentTableConfiguration(TableName.Fleet)(state),
  shares: getShares(state),
  pageState: getFleetPageState(state),
});

const mapActionCreators = {
  updateRefreshInterval: personalizationActionCreators.updateRefreshInterval,
};

export default connect(mapStateToProps, mapActionCreators)(SharesTableHeader);
