import UrlService from '../../../services/UrlService';
import axiosActions, { extractApiFilters } from '../axiosHelpers';
import {
  getCurrentTableConfiguration,
  getSelectedCompany,
  getSharingFilters,
  getSharingPagination, getSharingSorting, TableName,
} from '../../selectors';
import { filtersFromConfiguration } from '../../../types/mappers';
import { uuidv4 } from '../../../utils/uuid';
import { FILTERS, isApiFilter } from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';

export const FETCH_SHARES = axiosActions('SHARES_LOAD');

const executeSearchShares = (companyId, search, silentRefresh = false) => ({
  type: FETCH_SHARES.START,
  payload: {
    actionId: uuidv4(),
    silentRefresh,
    request: {
      method: 'post',
      url: UrlService.sharingsSearch(companyId),
      data: search,
    },
  },
});

const buildSearchBody = ({
  pagination, filters, configuration, sorting,
}) => {
  const apiFilters = extractApiFilters(filters, FILTERS, isApiFilter);
  const configFilters = configuration ? filtersFromConfiguration(configuration.filters) : {};
  const searchBody = {
    ...pagination, ...apiFilters, companiesIds: configFilters.companyIds, vehiclesIds: configFilters.vehicleIds,
  };
  if (sorting && sorting.order && sorting.field) {
    searchBody.sortBy = sorting.field;
    searchBody.sortDirection = sorting.order === 'ascend' ? 'ASC' : 'DESC';
  }
  return searchBody;
};

export const fetchShares = (silentRefresh = false) => (dispatch, getState) => {
  const state = getState();
  const pagination = getSharingPagination(state);
  const selectedCompany = getSelectedCompany(state);
  const filters = getSharingFilters(state);
  const sorting = getSharingSorting(state);
  const configuration = getCurrentTableConfiguration(TableName.Fleet)(state);

  const search = buildSearchBody({
    pagination, filters, configuration, sorting,
  });
  return dispatch(executeSearchShares(selectedCompany, search, silentRefresh));
};

export const SHARINGS_SWITCH_TAB = 'SHARINGS_SWITCH_TAB';
export const switchSharingsTab = (nextTab) => ({
  type: SHARINGS_SWITCH_TAB,
  payload: {
    nextTab,
  },
});
