import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getFullPrecision = (lat, lng) => `${+parseFloat(lat)},${+parseFloat(lng)}`;

const Text = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`;

const LatLng = ({
  latitude, longitude, precision, copy, hideEmpty,
}) => {
  const { t } = useTranslation();
  const renderComponent = () => (
    <Text copyable={copy && { text: getFullPrecision(latitude, longitude) }}>
      {+parseFloat(latitude).toFixed(precision)}
      {', '}
      {+parseFloat(longitude).toFixed(precision)}
    </Text>
  );

  const renderEmpty = () => (hideEmpty ? '' : <span>{t('COMMON.MISSING')}</span>);

  return ((latitude && longitude) ? renderComponent() : renderEmpty());
};

LatLng.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  precision: PropTypes.number,
  copy: PropTypes.bool,
  hideEmpty: PropTypes.bool,
};

LatLng.defaultProps = {
  latitude: null,
  longitude: null,
  precision: 5,
  copy: false,
  hideEmpty: false,
};

export default LatLng;
