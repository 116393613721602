import {
  Button, Col, message, Row,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColumnsConfigModal, CurrentViewTag, FiltersTags, FleetHeaderComponent, FlexRight, FlexRightSpaced,
  HeaderButtonsDivider,
  RefreshComponent,
} from '../../../../components';
import { ALL_COLS, MANDATORY_COLS } from './VehiclesTable.constants';
import {
  getCurrentInterfaceConfiguration, getCurrentTableConfiguration, getFleetPageState, getVehicles,
  isCurrentInterfaceConfigurationLoading, isTableConfigurationsLoading, TableName,
} from '../../../../store/selectors';
import TableConfigurationComponent from '../../../../components/Table/TableConfigurationComponent';
import {
  openGenerateReport, openShareVehicles, resetSharingsPagination, resetVehiclesPagination, setFleetSelectionMode,
  updateRefreshInterval,
} from '../../../../store/actions';
import { FleetTabs, SelectionMode, SelectionType } from '../../../../types/enums/fleet.enum';

const VehiclesTableHeader = ({
  size, onRefresh, columns, updateColumns, clearFilters, filters, updateFilters,
}) => {
  const [columnsModalVisible, setColumnsModalVisible] = useState(false);
  const interfaceConfigLoading = useSelector(isCurrentInterfaceConfigurationLoading);
  const { selection, selectedRows } = useSelector(getFleetPageState);
  const tableConfigLoading = useSelector(isTableConfigurationsLoading);
  const currentTableConfiguration = useSelector(getCurrentTableConfiguration(TableName.Fleet));
  const { refreshIntervalSeconds } = useSelector(getCurrentInterfaceConfiguration(TableName.Vehicles));
  const { vehicles: { loading: vehiclesLoading, lastUpdated } } = useSelector(getVehicles);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (filterName) => {
    updateFilters({ [filterName]: null });
  };

  const openColumnsModal = () => {
    setColumnsModalVisible(true);
  };

  const saveColumnsConfig = (newColumnsVisibility) => {
    updateColumns(newColumnsVisibility);
    setColumnsModalVisible(false);
  };

  const dispatchUpdateInterval = (val) => {
    dispatch(updateRefreshInterval(TableName.Vehicles, val));
    onRefresh();
  };

  const onTableConfigurationSelected = () => {
    dispatch(resetVehiclesPagination());
    dispatch(resetSharingsPagination());
    onRefresh();
  };

  const onGenerateReport = () => {
    dispatch(openGenerateReport(null, FleetTabs.VEHICLES));
  };

  const onShareVehicles = () => {
    if (!selection || selection.mode === null) {
      message.info(t('SHARE_VEHICLES.SHARE_VEHICLES_INFO'), 4);
      dispatch(setFleetSelectionMode({
        mode: SelectionMode.SHARE,
        type: SelectionType.CHECKBOX,
      }));
    } else if (selection.mode === SelectionMode.SHARE) {
      dispatch(openShareVehicles(selectedRows, FleetTabs.VEHICLES));
    }
  };

  return (
    <>
      <Row gutter={[0, 8]}>
        <Col sm={12}>
          <CurrentViewTag view={currentTableConfiguration} />
        </Col>
        <Col sm={12}>
          <FlexRight>
            <FleetHeaderComponent
              loading={false}
              onGenerateReport={onGenerateReport}
              onShareVehicles={onShareVehicles}
            />
            <HeaderButtonsDivider />
            <TableConfigurationComponent
              tableName={TableName.Fleet}
              disabled={tableConfigLoading || vehiclesLoading || interfaceConfigLoading}
              size={size}
              onRefresh={onRefresh}
              onConfigurationSelected={onTableConfigurationSelected}
            />
          </FlexRight>
        </Col>
        <Col sm={24} md={14}>
          <FiltersTags
            filters={filters}
            onRemoveFilter={handleClose}
            onClearFilters={clearFilters}
          />
        </Col>
        <Col sm={24} md={10}>
          <FlexRightSpaced>
            <Button
              onClick={() => openColumnsModal()}
              icon={<SettingOutlined />}
              size={size}
            >
              {t('COMMON.COLUMNS')}
            </Button>
            <HeaderButtonsDivider />
            <RefreshComponent
              lastRefreshed={lastUpdated}
              size={size}
              loading={vehiclesLoading}
              disabled={tableConfigLoading || interfaceConfigLoading || vehiclesLoading}
              onRefresh={onRefresh}
              onUpdateRefreshIntervalSeconds={dispatchUpdateInterval}
              refreshIntervalSeconds={refreshIntervalSeconds}
              compact
            />
          </FlexRightSpaced>
        </Col>
      </Row>
      <ColumnsConfigModal
        allColumns={ALL_COLS}
        lockedColumns={MANDATORY_COLS}
        visibleColumns={columns}
        open={columnsModalVisible}
        onOk={saveColumnsConfig}
        onClose={() => setColumnsModalVisible(false)}
      />
    </>
  );
};

VehiclesTableHeader.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  columns: PropTypes.objectOf(PropTypes.bool).isRequired,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
  ),
  onRefresh: PropTypes.func.isRequired,
  updateColumns: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

VehiclesTableHeader.defaultProps = {
  size: 'small',
  filters: {},
};

export default VehiclesTableHeader;
