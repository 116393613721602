import log from 'loglevel';
import moment from 'moment';
import config from '../config';
import { DEFAULT_DATE_TIME } from '../utils/constants/timeFormats';

log.setLevel(config.logLevel);

export const info = (msg) => {
  log.info(`${moment().format(DEFAULT_DATE_TIME)}: ${msg}`);
};

export const debug = (msg) => {
  log.debug(`${moment().format(DEFAULT_DATE_TIME)}: ${msg}`);
};

export const error = (msg) => {
  log.error(`${moment().format(DEFAULT_DATE_TIME)}: ${msg}`);
};
