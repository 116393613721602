import { combineReducers } from 'redux';
import ordersReducerCreator from './orders.reducerCreator';
import ordersPaginationReducerCreator from './pagination.reducerCreator';
import ordersSortingReducerCreator from './sorting.reducerCreator';
import pageStateReducer from './pageState.reducer';
import createOrderReducer from './createOrder.reducer';
import orderDetailsReducer from './details.reducer';
import statisticsReducer from './statistics.reducer';
import monitoringOrderSnapshotReducer from './snapshot/snapshot.reducer';
import monitoringOrderRoutesReducer from './routes/routes.reducer';
import { OrderContext } from '../../../types';

const createOrdersReducer = (orderType) => combineReducers({
  orders: ordersReducerCreator(orderType),
  routes: monitoringOrderRoutesReducer(orderType),
  pagination: ordersPaginationReducerCreator(orderType),
  sorting: ordersSortingReducerCreator(orderType),
});

export default combineReducers({
  [OrderContext.IN]: createOrdersReducer(OrderContext.IN),
  [OrderContext.OUT]: createOrdersReducer(OrderContext.OUT),
  pageState: pageStateReducer,
  details: orderDetailsReducer,
  createOrder: createOrderReducer,
  statistics: statisticsReducer,
  snapshot: monitoringOrderSnapshotReducer,
});
