/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import {
  Timeline, Typography,
} from 'antd';
import styled from 'styled-components';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../../utils/constants/timeFormats';
import {
  Duration, LoadingIndexedIcon, LoadingUnloadingIndexedIcon, UnloadingIndexedIcon,
} from '../../../components';
import HereMapsLocation from '../../../components/Vehicle/Data/HereMapsLocation';
import StopLocation from './StopLocation';
import ParkingIndexedIcon from '../../../components/Styled/Icons/ParkingIndexed';
import BreakIndexedIcon from '../../../components/Styled/Icons/BreakIndexedIcon';
import UnknownIndexedIcon from '../../../components/Styled/Icons/UnknownIndexedIcon';
import eventBus from '../../../services/EventBus';
import { stopPlacePropTypes } from '../../../types/stop.type';

const DetailsLine = styled(Typography.Paragraph)`
  margin-bottom: 3px !important;
  
  .greyed {
    color: ${({ theme }) => theme.color.gray2}
  }
`;

const Header = styled(DetailsLine)`
  font-size: 14px;
  margin-bottom: 3px !important;
`;

const EventLabel = styled.span`
  font-weight: 500;
`;

function determineEndDateString(t, { start, end }) {
  if (start.isSame(end)) {
    return '';
  }

  if (start.clone().startOf('d').isSame(end.clone().startOf('d'))) {
    return ` – ${end.format(DEFAULT_TIME)}`;
  }

  return ` – ${end.format(DEFAULT_DATE_TIME)}`;
}

const dispatchEventSelected = ({ eventType, eventId }) => eventBus.dispatch('event-selected', { eventType, eventId });

const DotByType = {
  break: (index, eventId) => (
    <BreakIndexedIcon
      index={index}
      onClick={() => dispatchEventSelected({ eventType: 'break', eventId })}
    />
  ),
  parking: (index, eventId) => (
    <ParkingIndexedIcon
      index={index}
      onClick={() => dispatchEventSelected({ eventType: 'break', eventId })}
    />
  ),
  loading: (index, eventId) => (
    <LoadingIndexedIcon
      style={{ paddingTop: '7px', paddingLeft: '7px' }}
      onClick={() => dispatchEventSelected({ eventType: 'stop', eventId })}
      index={index}
    />
  ),
  unloading: (index, eventId) => (
    <UnloadingIndexedIcon
      style={{ paddingTop: '7px', paddingLeft: '7px' }}
      onClick={() => dispatchEventSelected({ eventType: 'stop', eventId })}
      index={index}
    />
  ),
  unknown: (index, eventId) => (
    <UnknownIndexedIcon
      style={{ paddingTop: '7px', paddingLeft: '7px' }}
      onClick={() => dispatchEventSelected({ eventType: 'stop', eventId })}
      index={index}
    />
  ),
  loadingUnloading: (index, eventId) => (
    <LoadingUnloadingIndexedIcon
      style={{ paddingTop: '7px', paddingLeft: '7px' }}
      onClick={() => dispatchEventSelected({ eventType: 'stop', eventId })}
      index={index}
    />
  ),
};

const EventToStopType = {
  loading: 'LOAD',
  unloading: 'UNLOAD',
  loadingUnloading: 'LOAD_UNLOAD',
  unknown: 'UNKNOWN',
};

const BreakEvent = ({ event }) => {
  const { t } = useTranslation();

  return (
    <Timeline.Item dot={DotByType[event.type](event.index, event.id)} key={event.id}>
      <Header>
        <EventLabel>
          {t(`ORDER.BREAKS.TYPE.${event.type.toUpperCase()}`)}
        </EventLabel>
      </Header>
      <DetailsLine>
        <EventLabel>
          {t('ORDER.BREAKS.TIME')}
          {': '}
        </EventLabel>
        {`${event.time.start.format(DEFAULT_DATE_TIME)}${determineEndDateString(t, event.time)}`}
        <br />
        <EventLabel>
          {t('ORDER.BREAKS.DURATION')}
          {': '}
        </EventLabel>
        <Duration value={event.time.duration} />
      </DetailsLine>
      <DetailsLine>
        <HereMapsLocation hereLocation={{ items: [event.location] }} withCoordinates />
      </DetailsLine>
    </Timeline.Item>
  );
};

BreakEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.oneOf(['break', 'parking', 'loading', 'unloading', 'loadingUnloading', 'unknown']).isRequired,
    index: PropTypes.number.isRequired,
    time: PropTypes.shape({
      start: MomentPropTypes.momentObj.isRequired,
      end: MomentPropTypes.momentObj.isRequired,
      duration: MomentPropTypes.momentDurationObj.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      title: PropTypes.string.isRequired,
      position: PropTypes.shape({
        lat: PropTypes.string.isRequired,
        lng: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const StopEvent = ({ event }) => {
  const { t } = useTranslation();

  return (
    <Timeline.Item dot={DotByType[event.type](event.index, event.id)} key={event.id}>
      <DetailsLine>
        <EventLabel>
          {t(`ORDER.STOP_TYPES.${EventToStopType[event.type]}`)}
          {': '}
        </EventLabel>
        <StopLocation place={event.location} inline />
      </DetailsLine>
    </Timeline.Item>
  );
};

StopEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['loading', 'unloading', 'loadingUnloading', 'unknown']).isRequired,
    location: stopPlacePropTypes.isRequired,
  }).isRequired,
};

export default {
  Break: BreakEvent,
  Stop: StopEvent,
};
