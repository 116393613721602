import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, message, Typography } from 'antd';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useHistory } from 'react-router';
import { fetchAvailableCompanies, fetchOrderDetails, updateOrder } from '../../../store/actions';
import { getOrderCreationModalData, getOrderDetails, getSelectedCompany } from '../../../store/selectors';
import OrderConfirmationDialog from '../OrderCreation/Components/OrderConfirmationDialog';
import OrderEditingForm from './Components/OrderEditingForm';
import { OrderType } from '../../../types';
import { OrderMapper } from '../../../types/mappers';
import { error } from '../../../services/Logging';
// import { checkOrderGeocoding, enhanceOrdersStopsWithGeocodes } from '../../../services/GeocodesService';
import { isSuccess } from '../../../utils/responseUtils';

const OrderEditingPage = ({ match: { params: { shipperId, orderId } } }) => {
  const { companies, creation } = useSelector(getOrderCreationModalData);
  const contextCompanyId = useSelector(getSelectedCompany);

  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const { order } = useSelector(getOrderDetails);

  const { t } = useTranslation();
  const [commonForm] = Form.useForm();
  const [timeMonitoringForm] = Form.useForm();
  const [tourMonitoringForm] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderDetails(shipperId, orderId));
    dispatch(fetchAvailableCompanies());
  }, [dispatch, orderId, shipperId]);

  const SuccessMessageContent = ({ internalId }) => (
    <>
      <Typography.Text>
        {t('ORDER.EDITION_SUCCESSFUL', { internalId })}
      </Typography.Text>
    </>
  );

  SuccessMessageContent.propTypes = {
    internalId: PropTypes.string.isRequired,
  };

  const getFormToValidate = (orderType) => (orderType === OrderType.TIME
    ? timeMonitoringForm
    : tourMonitoringForm
  );

  const defineConfirmationTitle = (orderType) => (orderType === OrderType.TIME
    ? t('ORDER.CONFIRMATION.EDIT_TIME_TITLE')
    : t('ORDER.CONFIRMATION.EDIT_TOUR_TITLE'));

  const dispatchUpdateOrder = (internalId, updateRequest, orderType) => {
    if (updateRequest) {
      setConfirmationDialog({
        order: updateRequest,
        orderType,
        title: defineConfirmationTitle(orderType),
        onSubmit: () => {
          setSubmitInProgress(true);
          dispatch(updateOrder(internalId, contextCompanyId, updateRequest))
            .then((response) => {
              setConfirmationDialog(null);
              if (isSuccess(response)) {
                message.success(<SuccessMessageContent internalId={response.payload.data.internalId} />, 7);
                history.push(
                  {
                    pathname: `/orders/details/out/${updateRequest.shipperCompanyId}/${updateRequest.internalId}`,
                  },
                );
              }
              setSubmitInProgress(false);
            });
        },
      });
    }
  };

  const handleSubmit = () => {
    const orderType = order.data.type === 'TIME' ? OrderType.TIME : OrderType.TOUR;
    Promise.all([commonForm.validateFields(), getFormToValidate(orderType).validateFields()])
      .then((orderForms) => OrderMapper.formToUpdateRequest(orderForms, order.data, orderType), error)
      .then((updateRequest) => dispatchUpdateOrder(order.data.internalId, updateRequest, orderType), error);
  };

  const handleCancel = () => {
    commonForm.resetFields();
    timeMonitoringForm.resetFields();
    tourMonitoringForm.resetFields();
    history.goBack();
  };

  return (
    <>
      <BreadcrumbsItem to={`/orders/edit/${shipperId}/${orderId}`}>
        {t('BREADCRUMB.MONITORING_EDIT', { internalId: order.data.internalId })}
      </BreadcrumbsItem>
      <OrderEditingForm
        order={order}
        commonForm={commonForm}
        timeMonitoringForm={timeMonitoringForm}
        tourMonitoringForm={tourMonitoringForm}
        companies={companies}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        creation={creation}
      />
      <OrderConfirmationDialog
        open={!!confirmationDialog}
        title={confirmationDialog && confirmationDialog.title}
        onClose={() => setConfirmationDialog(null)}
        onSubmit={confirmationDialog && confirmationDialog.onSubmit}
        order={confirmationDialog && confirmationDialog.order}
        companies={companies && companies.data}
        orderType={confirmationDialog && confirmationDialog.orderType}
        disabled={submitInProgress}
      />
    </>
  );
};

OrderEditingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
      shipperId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrderEditingPage;
