import { error } from '../Logging';
import HereMapsService from './HereMapsService';

const fetchLocationForCoordinates = async ({ lat, lng }, onSuccess, onError = (e) => error(e)) => {
  if (lat && lng) {
    const searchService = HereMapsService.getSearchService();
    searchService.reverseGeocode({ at: `${lat},${lng}`, lang: 'en' }, onSuccess, onError);
  }
};

const fetchCoordinatesForOrderStopLocation = ({
  street, houseNumber, postcode, country, city,
}, onSuccess, onError = (e) => error(e)) => {
  const searchService = HereMapsService.getSearchService();
  searchService.geocode({
    qq: `houseNumber=${houseNumber};street=${street};postalCode=${postcode};country=${country};city=${city}`,
    limit: 1,
  }, onSuccess, onError);
};

const fetchCoordinatesForRequestStopLocation = ({
  street, houseNumber, postcode, countryCode, city,
}, onSuccess, onError = (e) => error(e)) => {
  const searchService = HereMapsService.getSearchService();
  searchService.geocode({
    qq: `houseNumber=${houseNumber};street=${street};postalCode=${postcode};country=${countryCode};city=${city}`,
    limit: 1,
  }, onSuccess, onError);
};

export default {
  fetchLocationForCoordinates,
  fetchCoordinatesForOrderStopLocation,
  fetchCoordinatesForRequestStopLocation,
};
