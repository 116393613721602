import ApiService from './ApiService';
import UrlService from './UrlService';

const getLocationDetails = (locationId) => ApiService.defaultInstance.get(UrlService.locationDetailsApi(locationId))
  .then((response) => response.data);

/* eslint-disable max-len */
const searchAddress = (phrase) => ApiService.defaultInstance.get(UrlService.locationSuggesterSearchApi(), { params: { query: phrase } })
  .then((response) => response.data);

export default {
  getLocationDetails,
  searchAddress,
};
