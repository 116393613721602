import localforage from 'localforage';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import env from '../config';
import UserService from './UserService';

const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
  ],
  name: 'co3-axios-cache',
});

const cacheFilterRegexRoutePoints = new RegExp('^/v0/monitoring-orders/[^/]+/route-points$');
const cache = setupCache({
  maxAge: 3 * 60 * 1000,
  store: forageStore,
  exclude: {
    query: false,
    filter: (req) => {
      if (cacheFilterRegexRoutePoints.test(req.url)) {
        return false;
      }
      return true;
    },
  },
});

const defaultInstance = axios.create({
  baseURL: `${env.api.url}${env.api.basePath || ''}`,
  responseType: 'json',
  adapter: cache.adapter,
});

const injectToken = (config) => {
  const cb = () => {
    const token = UserService.getToken();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `bearer ${token}` : '';

    return Promise.resolve(config);
  };
  return UserService.updateToken(cb);
};

defaultInstance.interceptors.request.use(injectToken);

export default {
  defaultInstance,
};
