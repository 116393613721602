/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'antd';
import { info } from '../../services/Logging';
import * as sharingActionCreators from '../../store/actions/sharing';
import * as fleetActionCreators from '../../store/actions/fleet';
import * as personalizationActionCreators from '../../store/actions/personalization';
import SharesTableComponent from './Components/SharesTable/SharesTableComponent';
import {
  getSelectedCompany, getSharingRefreshInterval, TableName,
} from '../../store/selectors';
import { FullHeightRow } from '../../components';

class SharingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { intervalId: 0 };
    this.bind();
  }

  componentDidMount() {
    this.fetchViewSetUp().then(() => this.startFetchingLoop());
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedCompany, refreshIntervalSeconds } = this.props;
    const { selectedCompany: prevCompany, refreshIntervalSeconds: prevInterval } = prevProps;
    const { intervalId } = this.state;
    if (this.shouldRestartFetchingLoop(prevProps)) {
      const auto = !prevInterval && refreshIntervalSeconds;
      clearInterval(intervalId);
      if (selectedCompany !== prevCompany) {
        this.fetchTableConfiguration()
          .then(() => this.startFetchingLoop(auto));
      } else {
        this.startFetchingLoop(auto);
      }
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    const { resetFleetSelectionMode } = this.props;
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
    resetFleetSelectionMode();
  }

  bind() {
    this.refresh = this.refresh.bind(this);
  }

  shouldRestartFetchingLoop(prevProps) {
    const { selectedCompany } = this.props;
    const { selectedCompany: prevCompany } = prevProps;
    return selectedCompany !== prevCompany;
  }

  fetchViewSetUp() {
    const { fetchLastUsedInterfaceConfiguration } = this.props;
    return Promise.all([
      this.fetchTableConfiguration(),
      fetchLastUsedInterfaceConfiguration(TableName.Sharings),
    ]);
  }

  fetchTableConfiguration() {
    const { fetchLastUsedTableConfigurationOrCached, fetchTableConfigurationsOrCached } = this.props;
    return Promise.all([
      fetchTableConfigurationsOrCached(TableName.Fleet),
      fetchLastUsedTableConfigurationOrCached(TableName.Fleet),
    ]);
  }

  fetchInterfaceConfiguration() {
    const { fetchLastUsedInterfaceConfiguration } = this.props;
    fetchLastUsedInterfaceConfiguration(TableName.Sharings);
  }

  startFetchingLoop(isAutomatic = false) {
    const {
      fetchShares, selectedCompany, refreshIntervalSeconds,
    } = this.props;
    const { intervalId } = this.state;

    if (!selectedCompany) {
      clearInterval(intervalId);
      return;
    }

    clearInterval(intervalId);
    if (refreshIntervalSeconds) {
      info(`Scheduling refresh of sharings in ${refreshIntervalSeconds}s`);
      const newIntervalId = setTimeout(() => {
        this.startFetchingLoop(true);
      }, refreshIntervalSeconds * 1000);

      this.setState({ intervalId: newIntervalId });
    }

    if (!isAutomatic || refreshIntervalSeconds) {
      fetchShares(isAutomatic);
    }
  }

  refresh() {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }

    this.startFetchingLoop();
  }

  render() {
    const { size } = this.props;
    return (
      <FullHeightRow gutter={[10, 10]} style={{ paddingTop: '10px' }}>
        <Col span={24}>
          <SharesTableComponent
            viewportHeight={700}
            size={size}
            onRefresh={this.refresh}
          />
        </Col>
      </FullHeightRow>
    );
  }
}

SharingComponent.propTypes = {
  refreshIntervalSeconds: PropTypes.number.isRequired,
  fetchShares: PropTypes.func.isRequired,
  fetchLastUsedInterfaceConfiguration: PropTypes.func.isRequired,
  fetchLastUsedTableConfigurationOrCached: PropTypes.func.isRequired,
  fetchTableConfigurationsOrCached: PropTypes.func.isRequired,
  resetFleetSelectionMode: PropTypes.func.isRequired,
  selectedCompany: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

SharingComponent.defaultProps = {
  size: 'small',
  selectedCompany: undefined,
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  refreshIntervalSeconds: getSharingRefreshInterval(state),
});

const actionCreators = {
  fetchShares: sharingActionCreators.fetchShares,
  fetchLastUsedInterfaceConfiguration: personalizationActionCreators.fetchLastUsedInterfaceConfiguration,
  fetchLastUsedTableConfigurationOrCached: personalizationActionCreators.fetchLastUsedTableConfigurationOrCached,
  fetchTableConfigurationsOrCached: personalizationActionCreators.fetchTableConfigurationsOrCached,
  resetFleetSelectionMode: fleetActionCreators.resetFleetSelectionMode,
};

export default connect(mapStateToProps, actionCreators)(SharingComponent);
