import { Row, Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CheckSquareOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { ownershipTypeProp } from '../../../types';
import { OwnerIcon } from '../../Styled/Icons';
import { Label, Wrapper } from './VehicleOwnership.styled';

const VehicleOwnership = ({
  type, showLabel, justify, size,
}) => {
  const { t } = useTranslation();
  const iconType = {
    OWNER: <OwnerIcon size={size} />,
    LEASE: <ShareAltOutlined />,
    SHARING: <EyeOutlined />,
    CONSENT: <CheckSquareOutlined />,
  }[type];
  return (
    <Tooltip
      placement="top"
      title={showLabel ? undefined : t(`DICTIONARY.STATUS.${type || 'UNKNOWN'}`)}
    >
      <Row justify={justify}>
        <Wrapper size={size}>{(iconType || <EyeInvisibleOutlined />)}</Wrapper>
        {showLabel && <Label>{t(`DICTIONARY.STATUS.${type || 'UNKNOWN'}`)}</Label>}
      </Row>
    </Tooltip>
  );
};

VehicleOwnership.propTypes = {
  type: ownershipTypeProp,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  showLabel: PropTypes.bool,
  justify: PropTypes.oneOf(['start', 'end', 'center', 'space-around', 'space-between']),
};

VehicleOwnership.defaultProps = {
  type: 'UNKNOWN',
  showLabel: false,
  justify: 'center',
  size: 'm',
};

export default VehicleOwnership;
