import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cleanSpeed, getSpeedUnitKey } from '../../../utils/speed';

const isPresent = (value) => value !== null && value !== undefined && value !== '';

const SpeedComponent = ({ value, precision, unit }) => {
  const { t } = useTranslation();
  return (
    isPresent(value)
      ? (
        <span>
          {cleanSpeed(value, precision)}
          {' '}
          {isPresent(value) && t(getSpeedUnitKey(unit))}
        </span>
      )
      : <span>{t('COMMON.MISSING')}</span>
  );
};

SpeedComponent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  unit: PropTypes.oneOf(['metric', 'imperial']),
  precision: PropTypes.number,
};

SpeedComponent.defaultProps = {
  unit: 'metric',
  precision: 0,
  value: null,
};

export default SpeedComponent;
