import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  min-width: 28px;
  max-width: 28px;
  margin-top: -7px;
`;

export default () => (
  <Icon
    src="./images/fleet-icon.svg"
    alt="Fleet page"
  />
);
