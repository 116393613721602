import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTable, TableLoadingComponent } from '../../../../components';
import {
  executeEmailSharesDelete, fetchEmailShares,
  setEmailSharingsPagination,
} from '../../../../store/actions';
import {
  getCompleteEmailSharing,
} from '../../../../store/selectors';
import { DEFAULT_DATE_TIME } from '../../../../utils/constants/timeFormats';

const EmailSharesTableComponent = ({
  size, pageSizeOptions,
}) => {
  const { t } = useTranslation();
  const { pagination, shares } = useSelector(getCompleteEmailSharing);

  const dispatch = useDispatch();

  const changePageAndRefresh = useCallback((
    page, pageSize,
  ) => {
    dispatch(setEmailSharingsPagination({ page, size: pageSize }));
  }, [dispatch]);

  const showDeleteConfirmationModal = (orderId, secret) => {
    const onConfirmDelete = () => {
      dispatch(executeEmailSharesDelete(orderId, secret)).then(() => {
        dispatch(fetchEmailShares());
      });
    };

    Modal.confirm({
      title: t('EMAIL_SHARES.DELETE_CONFIRM_POPUP.TITLE'),
      content: (
        <>
          <strong>{ t('EMAIL_SHARES.DELETE_CONFIRM_POPUP.FIRST_LINE') }</strong>
          <br />
          <p>{ t('EMAIL_SHARES.DELETE_CONFIRM_POPUP.DESCRIPTION') }</p>
        </>
      ),
      onOk: () => onConfirmDelete(),
      okText: t('COMMON.OK'),
      cancelText: t('COMMON.CANCEL'),
    });
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const columns = [
    {
      title: t('EMAIL_SHARES.ORDER_NUMBER'),
      dataIndex: 'transportOrderNumber',
      key: 'transportOrderNumber',
      render: (_, { transportOrderNumber }) => <span>{transportOrderNumber}</span>,
    },
    {
      title: t('EMAIL_SHARES.SHARED_TO'),
      dataIndex: 'sharedTo',
      key: 'sharedTo',
      render: (_, { sharedTo }) => <span>{sharedTo}</span>,
    },
    {
      title: t('EMAIL_SHARES.LINK'),
      dataIndex: 'link',
      key: 'link',
      render: (_, { link }) => link && (
        <>
          <a
            style={{
              display: 'inline-block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: 'calc(100% - 20px)',
              whiteSpace: 'nowrap',
            }}
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >
            {link}
          </a>
          <CopyOutlined
            onClick={() => copyToClipboard(link)}
            style={{
              color: '#1890ff',
              fontSize: '14px',
              transform: 'translateY(-7px)',
            }}
          />
        </>
      ),
    },
    {
      title: t('EMAIL_SHARES.START_DATE'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, { startDate }) => <span>{startDate.format(DEFAULT_DATE_TIME)}</span>,
    },
    {
      title: t('EMAIL_SHARES.END_DATE'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, { endDate }) => <span>{endDate.format(DEFAULT_DATE_TIME)}</span>,
    },
    {
      title: t('EMAIL_SHARES.CREATE_DATE'),
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (_, { creationDate }) => <span>{creationDate.format(DEFAULT_DATE_TIME)}</span>,
    },
    {
      title: t('EMAIL_SHARES.ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      width: 70,
      render: (_, { orderId, secret }) => (
        <Button
          type="link"
          onClick={() => showDeleteConfirmationModal(orderId, secret)}
          icon={<CloseCircleOutlined />}
        />
      ),
    },
  ];

  const renderTotal = useCallback((total) => (<span>{t('COMMON.TOTAL', { total })}</span>), [t]);

  return (
    <>
      <StyledTable
        showSorterTooltip={false}
        dataSource={shares.data.sharings}
        rowKey={(record) => `${record.sharedTo}-${record.orderId}`}
        size={size}
        bordered
        pagination={{
          showSizeChanger: true,
          showTotal: renderTotal,
          pageSizeOptions,
          total: shares.data.totalVehicles,
          disabled: shares.loading,
          current: pagination.page,
          pageSize: pagination.size,
          onChange: changePageAndRefresh,
        }}
        columns={columns}
        loading={TableLoadingComponent(shares.majorReload)}
      />
    </>
  );
};

EmailSharesTableComponent.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
};

EmailSharesTableComponent.defaultProps = {
  size: 'small',
  pageSizeOptions: ['10', '20', '50', '100', '200'],
};

export default EmailSharesTableComponent;
