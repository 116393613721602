import { TeamOutlined } from '@ant-design/icons';
import React from 'react';
import { ConfigurationType } from '../../types';
import { TruckIcon } from '../Styled/Icons';
import { getConfigurationType } from '../../types/mappers';

export const ViewType = {
  Vehicle: 'vehicle',
  Company: 'company',
};

export const typeIcons = {
  [ConfigurationType.Vehicles]: <TruckIcon style={{ marginTop: '4px', marginRight: '4px' }} />,
  [ConfigurationType.Companies]: <TeamOutlined style={{ marginRight: '4px' }} />,
};

export const getTypeIcon = (filters) => {
  const configType = getConfigurationType(filters);
  return configType ? typeIcons[configType] : null;
};
