import PropTypes from 'prop-types';

export const OwnershipType = {
  OWNER: 'OWNER',
  LEASE: 'LEASE',
  SHARING: 'SHARING',
  CONSENT: 'CONSENT',
  UNKNOWN: 'UNKNOWN',
};

export const OWNERSHIP_TYPES = Object.values(OwnershipType);
export const VEHICLE_TYPES = [
  'DOUBLE_TRAILER', 'PASSENGER', 'SEMI_TRAILER', 'SET', 'TRACTOR', 'TRAILER', 'TRUCK', 'VAN', 'N/A',
];
export const LOADING_TYPES = ['BACK', 'OTHER', 'SIDE', 'TOP', 'N/A'];
export const VEHICLE_BODY_TYPES = [
  'BOX_TRUCK', 'BUS', 'CHASSIS_CONTAINER', 'CHEMICAL_TANKER', 'COILMULDE', 'COOLER', 'CURTAINSIDER', 'DOUBLE_DECK',
  'DOUBLE_TRAILER', 'DUMP_TRUCK', 'FLATBED', 'FOOD_TANKER', 'GAS_TANKER', 'HOOK_LIFT', 'ISOTHERM', 'JOLODE', 'JUMBO',
  'LOG_TRAILER', 'MEGA', 'OTHER', 'OVERSIZED_CARGO', 'PETROLEUM_TANKER', 'REMOVAL_TRUCK', 'SILOS', 'SWAP_BODY', 'TENT',
  'TOW_TRUCK', 'TRUCK_TRANSPORTER', 'WALKING_FLOOR', 'OTHER', 'N/A',
];
export const HERE_LOCATION_TYPES = ['houseNumber', 'addressBlock', 'street', 'locality', 'administrativeArea', 'place'];

export const ownershipTypeProp = PropTypes.oneOf(OWNERSHIP_TYPES);
export const vehicleTypeProp = PropTypes.oneOf(VEHICLE_TYPES);
export const loadingTypeProp = PropTypes.oneOf(LOADING_TYPES);
export const vehicleBodyTypeProp = PropTypes.oneOf(VEHICLE_BODY_TYPES);
export const hereLocationTypeProp = PropTypes.oneOf(HERE_LOCATION_TYPES);
