/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Collapse, Empty, Switch, Timeline, Typography,
} from 'antd';
import moment from 'moment';
import { eventsPropTypes } from '../../../types';
import TimelineEvent from './TimelineEvent';
import { getCleanDuration, getCleanMoment } from '../../../utils/dateTimeUtils';
import { Loader } from '../../../components';
import { stopsPropTypes } from '../../../types/stop.type';

const toLocationDescription = (location) => {
  const title = (location.street ? `${location.street}, ` : '')
    + (location.city ? `${location.city} ` : '')
    + (location.postcode ? `${location.postcode}, ` : '')
    + (location.country || '');

  return {
    title,
    position: { lat: location.position.latitude, lng: location.position.longitude },
  };
};

const determineStopEventType = ({ isLoad, isUnload }) => {
  if (isLoad && isUnload) {
    return 'loadingUnloading';
  }

  if (isLoad) {
    return 'loading';
  }

  if (isUnload) {
    return 'unloading';
  }
  return 'unknown';
};

const NINE_HOURS = 9 * 60 * 60;

const BreaksDescriptionsCard = ({
  breaks, stops, renderBreaks, onRenderBreaksChange, loading,
}) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const breaksTableData = (breaks || []).filter((stop) => stop.duration > moment.duration(1, 'm').asSeconds())
      .map((stop, index) => ({
        id: stop.id,
        index: index + 1,
        location: toLocationDescription(stop.location),
        time: {
          start: getCleanMoment(stop.startTimestamp),
          end: getCleanMoment(stop.endTimestamp),
          duration: getCleanDuration(stop.duration),
        },
        type: stop.duration < NINE_HOURS ? 'parking' : 'break',
      }));

    const stopsData = (stops || []).map((stop, index) => ({
      time: {
        start: stop.timeSlot.startMoment,
      },
      index: index + 1,
      id: stop.id,
      location: stop.place,
      type: determineStopEventType(stop),
    }));

    // update only if breaks are present
    if (breaksTableData.length) {
      const mergedData = [...breaksTableData, ...stopsData];
      mergedData.sort((a, b) => a.time.start.diff(b.time.start));
      setDataSource(mergedData);
    } else {
      setDataSource([]);
    }
  }, [breaks, stops]);

  const renderBreaksToggle = () => (
    <>
      <Typography.Text>{t('ORDER.BREAKS.RENDER_LABEL')}</Typography.Text>
      {' '}
      <Switch
        size="small"
        checked={renderBreaks}
        onChange={(checked, e) => {
          e.stopPropagation();
          onRenderBreaksChange(checked);
        }}
      />
    </>
  );

  const renderTimeline = (events) => (
    <Timeline>
      {events.map((event) => (['break', 'parking'].includes(event.type)
        ? <TimelineEvent.Break event={event} key={`break-${event.id}`} />
        : <TimelineEvent.Stop event={event} key={`stop-${event.id}`} />))}
    </Timeline>
  );

  return (
    <Collapse>
      <Collapse.Panel
        key="1"
        header={t('ORDER.BREAKS.TITLE')}
        extra={renderBreaksToggle()}
      >
        {loading && <Loader height="100px" text="" />}
        {(!dataSource || !dataSource.length) && !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {(dataSource && dataSource.length && !loading && renderTimeline(dataSource)) || ''}
      </Collapse.Panel>
    </Collapse>
  );
};

BreaksDescriptionsCard.propTypes = {
  breaks: eventsPropTypes,
  stops: stopsPropTypes,
  renderBreaks: PropTypes.bool.isRequired,
  onRenderBreaksChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

BreaksDescriptionsCard.defaultProps = {
  breaks: [],
  stops: [],
  loading: false,
};

export default BreaksDescriptionsCard;
