import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const GET_TELEMATIC_ACCOUNT = axiosActionsFactory('GET_TELEMATIC_ACCOUNT');

export const getTelematicAccount = (accountId, contextCompanyId) => (dispatch) => dispatch({
  type: GET_TELEMATIC_ACCOUNT.START,
  payload: {
    request: {
      url: UrlService.telematicAccountById(accountId),
      method: 'get',
      params: { contextCompanyId },
    },
  },
});
