import H from '@here/maps-api-for-javascript';
import TripStartContent from './TripStartContent';

const createTripStartInfoBubble = (event, t) => {
  const point = { lat: event.location.position.latitude, lng: event.location.position.longitude };
  const infoBubble = new H.ui.InfoBubble(point, {
    content: TripStartContent({
      location: event.location, t,
    }),
  });
  infoBubble.addClass('trip-info-infobubble');
  infoBubble.addClass('above-infobubble');
  infoBubble.close();
  return infoBubble;
};

export default createTripStartInfoBubble;
