import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AimOutlined from '@ant-design/icons/lib/icons/AimOutlined';
import { CellButton } from '../../Styled';
import HereMapsLocation from './HereMapsLocation';
import { fetchLocation } from '../../../store/actions';
import { getVehicleLocation } from '../../../store/selectors';

const GetLocation = ({
  coordinates, fetchOnStart, vehicleId, hideRefresh,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicleLocation = useSelector(getVehicleLocation(vehicleId));

  const onFetchLocation = () => {
    if (!vehicleLocation
      || !vehicleLocation.coordinates
      || vehicleLocation.coordinates.lng !== coordinates.lng
      || vehicleLocation.coordinates.lat !== coordinates.lat) {
      dispatch(fetchLocation(vehicleId, coordinates));
    }
  };

  // fetch location immediately on render
  useEffect(() => {
    if (fetchOnStart && coordinates.lat && coordinates.lng) {
      onFetchLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnStart, coordinates, vehicleId]);

  const renderGetLocationPrompt = () => (
    coordinates && coordinates.lat && coordinates.lng ? (
      <CellButton
        type="link"
        icon={<AimOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          onFetchLocation();
        }}
      >
        {t('COMMON.GET_LOCATION')}
      </CellButton>
    ) : t('COMMON.MISSING'));

  return (
    vehicleLocation && vehicleLocation.location ? (
      <HereMapsLocation
        hereLocation={vehicleLocation.location}
        onRefresh={hideRefresh ? undefined : () => onFetchLocation()}
      />
    ) : renderGetLocationPrompt()
  );
};

GetLocation.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  fetchOnStart: PropTypes.bool,
  vehicleId: PropTypes.string,
  hideRefresh: PropTypes.bool,
};

GetLocation.defaultProps = {
  coordinates: {
    lat: null,
    lng: null,
  },
  fetchOnStart: false,
  hideRefresh: false,
  vehicleId: null,
};

export default GetLocation;
