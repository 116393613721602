import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Empty, Modal,
} from 'antd';
import { orderPropTypes, OrderType } from '../../../../types';
import { DateTime, FlexColumnLeft, FormSectionDivider } from '../../../../components';
import NOP from '../../../../utils/NOP';
import {
  Description, Label,
  StopDetailLineMinor, StopsTable, Subtitle, Title, Value,
} from '../../../../components/Styled/Containers/ConfirmationDialog';
import { renderOrderPlace, renderOrderStopType, renderTimeSlot } from '../../../../components/Stop/stopRenderer';

const OrderConfirmationDialog = ({
  onSubmit, onClose, open, order, companies, orderType, title, disabled,
}) => {
  const { t } = useTranslation();
  const [selectedCompanyVehicle, setSelectedCompanyVehicle] = useState({});

  useEffect(() => {
    if (!companies || !order) {
      setSelectedCompanyVehicle({});
      return;
    }

    const company = companies.find(({ companyId }) => companyId === order.carrierCompanyId);
    const vehicle = company.vehicles && company.vehicles.find(({ vehicleId }) => vehicleId === order.vehicleIds[0]);
    setSelectedCompanyVehicle({
      companyName: company && company.companyName,
      vehicleLicence: vehicle && vehicle.licencePlateNumber,
    });
  }, [order, companies]);
  const columns = [
    {
      title: t('ORDER.STOPS'),
      dataIndex: 'sequence',
      className: 'stops-table_column',
      width: 30,
      render: (sequence) => (
        <StopDetailLineMinor>
          {Number.parseInt(sequence, 10) + 1}
          {'.'}
        </StopDetailLineMinor>
      ),
    }, {
      title: t('ORDER.PLACE'),
      dataIndex: 'place',
      className: 'stops-table_column',
      render: (_, row) => renderOrderPlace(row),
    }, {
      title: t('ORDER.TIMESLOT'),
      dataIndex: 'timeslot',
      className: 'stops-table_column',
      render: (_, row) => renderTimeSlot(t, row.timeSlot.start, row.timeSlot.end),
    }, {
      title: t('ORDER.STOP_TYPE'),
      dataIndex: 'stopType',
      className: 'stops-table_column',
      width: 160,
      render: (_, row) => renderOrderStopType(t, row),
    },
  ];

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      width="750px"
      closable={false}
      footer={(
        <>
          <Button type="link" disabled={disabled} onClick={onClose}>{t('COMMON.CANCEL')}</Button>
          <Button type="primary" disabled={disabled} onClick={onSubmit}>{t('COMMON.CONFIRM')}</Button>
        </>
      )}
    >
      { order ? (
        <>
          <Title>
            {title}
          </Title>
          <FormSectionDivider />
          <FlexColumnLeft>
            <Description>
              <Label>
                {t('ORDER.ORDER_NUMBER')}
                :
              </Label>
              <Value>{order.internalId}</Value>
            </Description>
            <Description>
              <Label>
                {t('ORDER.ADDITIONAL_INFO')}
                :
              </Label>
              <Value>{order.name}</Value>
            </Description>
            <FormSectionDivider />
            <Description>
              <Label>
                {t('ORDER.CARRIERS_COMPANY')}
                :
              </Label>
              <Value>{selectedCompanyVehicle.companyName}</Value>
            </Description>
            <Description>
              <Label>
                {t('VEHICLE.VEHICLE_LICENSE_PLATE')}
                :
              </Label>
              <Value>{selectedCompanyVehicle.vehicleLicence}</Value>
            </Description>
          </FlexColumnLeft>
          <FormSectionDivider />
          <Subtitle>
            {orderType === OrderType.TIME
              ? t('ORDER.TIME_MONITORING_DETAILS')
              : t('ORDER.TOUR_MONITORING_DETAILS')}
          </Subtitle>
          <Card align="baseline">
            {orderType === OrderType.TIME
              ? (
                <>
                  <Description>
                    <Label>
                      {t('ORDER.START_DATE')}
                      :
                    </Label>
                    <Value><DateTime value={order.startDate} elapsed={{ layout: 'horizontal' }} /></Value>
                  </Description>
                  <Description>
                    <Label>
                      {t('ORDER.END_DATE')}
                      :
                    </Label>
                    <Value><DateTime value={order.endDate} elapsed={{ layout: 'horizontal' }} /></Value>
                  </Description>
                </>
              )
              : (
                <>
                  <StopsTable
                    size="small"
                    columns={columns}
                    dataSource={order.stops}
                    bordered={false}
                    pagination={false}
                    rowKey="sequence"
                  />
                </>
              )}
          </Card>
        </>
      ) : <Empty />}
    </Modal>
  );
};

OrderConfirmationDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  order: orderPropTypes,
  companies: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({
      vehicleId: PropTypes.string.isRequired,
      licencePlateNumber: PropTypes.string.isRequired,
    })).isRequired,
  })),
  orderType: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

OrderConfirmationDialog.defaultProps = {
  onSubmit: NOP,
  companies: [],
  order: {
    stops: [],
  },
  orderType: 'TIME',
  title: '',
};

export default OrderConfirmationDialog;
