import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card } from 'antd';
import DistanceBetweenChart from './charts/DistanceBetweenChart';
import PositionTimestampsChart from './charts/PositionTimestampsChart';
import EstimatedWorkingTimeWorkingChart from './charts/EstimatedWorkingTimeWorkingChart';
import PositionSpeedChart from './charts/PositionSpeedChart';
import CalculatedSpeedChart from './charts/CalculatedSpeedChart';
import IgnitionStateChart from './charts/IgnitionStateChart';
import IsUniqueInQuarterChart from './charts/IsUniqueInQuarterChart';
import DistanceBetweenInQuarterChart from './charts/DistanceBetweenInQuarterChart';
import TimeBetweenInQuarterChart from './charts/TimeBetweenInQuarterChart';
import MeanSpeedBetweenInQuarterChart from './charts/MeanSpeedBetweenInQuarterChart';

class TourChartsComponent extends React.Component {
  getChartsList() {
    const { t } = this.props;
    return [
      {
        title: t('Statistics.TourChartsComponent.DistanceBetweenChart'),
        chart: () => <DistanceBetweenChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.PositionTimestampsChart'),
        chart: () => <PositionTimestampsChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.EstimatedWorkingTimeWorkingChart'),
        chart: () => <EstimatedWorkingTimeWorkingChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.PositionSpeedChart'),
        chart: () => <PositionSpeedChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.CalculatedSpeedChart'),
        chart: () => <CalculatedSpeedChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.IgnitionStateChart'),
        chart: () => <IgnitionStateChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.IsUniqueInQuarterChart'),
        chart: () => <IsUniqueInQuarterChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.DistanceBetweenInQuarterChart'),
        chart: () => <DistanceBetweenInQuarterChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.TimeBetweenInQuarterChart'),
        chart: () => <TimeBetweenInQuarterChart />,
      },
      {
        title: t('Statistics.TourChartsComponent.MeanSpeedBetweenInQuarterChart'),
        chart: () => <MeanSpeedBetweenInQuarterChart />,
      },
    ];
  }

  render() {
    const chartList = this.getChartsList();
    return (
      <div>
        {chartList.map((chart) => (
          <Card
            title={chart.title}
            style={{ width: 800 }}
            key={chart.title}
          >
            <div style={{ height: 400 }}>
              {chart.chart()}
            </div>
          </Card>
        ))}
      </div>
    );
  }
}

TourChartsComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(TourChartsComponent));
