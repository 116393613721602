import { getSelectedCompany } from '../../selectors';
import axiosActionsFactory from '../axiosHelpers';
import { uuidv4 } from '../../../utils/uuid';
import { UrlService } from '../../../services';

export const SELECT_VEHICLE = 'SELECT_VEHICLE';
export const UNSELECT_VEHICLE = 'UNSELECT_VEHICLE';
export const FETCH_LAST_TRACE_LOAD = axiosActionsFactory('FETCH_LAST_TRACE_LOAD');
export const OPEN_LAST_TRACE_MODE = 'OPEN_LAST_TRACE_MODE';
export const CLOSE_LAST_TRACE_MODE = 'CLOSE_LAST_TRACE_MODE';

export const selectVehicle = (vehicle, findOnMap = true) => ({
  type: SELECT_VEHICLE,
  payload: {
    vehicle,
    findOnMap,
  },
});

export const fetchLastTrace = (vehicleId) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  return dispatch({
    type: FETCH_LAST_TRACE_LOAD.START,
    payload: {
      actionId: uuidv4(),
      request: {
        method: 'get',
        url: UrlService.vehicleLastTrace(vehicleId),
        params: {
          contextCompanyId,
        },
      },
    },
  });
};

export const openLastTraceMode = (vehicleId) => ({
  type: OPEN_LAST_TRACE_MODE,
  payload: {
    vehicleId,
  },
});

export const closeLastTraceMode = () => ({
  type: CLOSE_LAST_TRACE_MODE,
});

export const unselectVehicle = () => ({
  type: UNSELECT_VEHICLE,
});
