import { RESET_COMPANY_CONTEXT, RESET_SHARING_PAGINATION, SET_SHARING_PAGINATION } from '../../actions';

export default function paginationReducer(state = { page: 1, size: 200 }, { type, payload }) {
  switch (type) {
    case SET_SHARING_PAGINATION:
      return { ...payload };
    case RESET_COMPANY_CONTEXT:
    case RESET_SHARING_PAGINATION:
      return { ...state, page: 1 };
    default:
      return state;
  }
}
