import { OrderContext } from '../orders.type';
import { TableName } from '../../store/selectors';

export const toTableName = (orderType) => {
  if (orderType === OrderContext.IN) {
    return TableName.OrdersReceived;
  }

  if (orderType === OrderContext.OUT) {
    return TableName.OrdersSent;
  }

  return null;
};
