export const SET_EMAIL_SHARING_PAGINATION = 'SET_SHARING_PAGINATION';
export const RESET_EMAIL_SHARING_PAGINATION = 'RESET_SHARING_PAGINATION';

export const setEmailSharingsPagination = ({ page, size }) => ({
  type: SET_EMAIL_SHARING_PAGINATION,
  payload: {
    page,
    size,
  },
});

export const resetEmailSharingsPagination = () => ({
  type: RESET_EMAIL_SHARING_PAGINATION,
});
