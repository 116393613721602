export const stringSorter = (a, b, direction) => {
  const isAscend = direction === 'ascend';

  if ((a === null || a === undefined) && b) {
    return isAscend ? 1 : -1;
  }

  if ((b === null || b === undefined) && a) {
    return isAscend ? -1 : 1;
  }

  return `${a}`.localeCompare(b) * (isAscend ? 1 : -1);
};

export const stringPropSorter = (prop, direction = 'ascend') => (
  a, b,
) => stringSorter(a[prop].toUpperCase(), b[prop].toUpperCase(), direction);
