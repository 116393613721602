export const COUNTRIES = [
  { isoCode: 'AF' },
  { isoCode: 'AL' },
  { isoCode: 'DZ' },
  { isoCode: 'AS' },
  { isoCode: 'AD' },
  { isoCode: 'AO' },
  { isoCode: 'AI' },
  { isoCode: 'AQ' },
  { isoCode: 'AG' },
  { isoCode: 'AR' },
  { isoCode: 'AM' },
  { isoCode: 'AW' },
  { isoCode: 'AU' },
  { isoCode: 'AT' },
  { isoCode: 'AZ' },
  { isoCode: 'BS' },
  { isoCode: 'BH' },
  { isoCode: 'BD' },
  { isoCode: 'BB' },
  { isoCode: 'BY' },
  { isoCode: 'BE' },
  { isoCode: 'BZ' },
  { isoCode: 'BJ' },
  { isoCode: 'BM' },
  { isoCode: 'BT' },
  { isoCode: 'BO' },
  { isoCode: 'BQ' },
  { isoCode: 'BA' },
  { isoCode: 'BW' },
  { isoCode: 'BV' },
  { isoCode: 'BR' },
  { isoCode: 'IO' },
  { isoCode: 'BN' },
  { isoCode: 'BG' },
  { isoCode: 'BF' },
  { isoCode: 'BI' },
  { isoCode: 'CV' },
  { isoCode: 'KH' },
  { isoCode: 'CM' },
  { isoCode: 'CA' },
  { isoCode: 'KY' },
  { isoCode: 'CF' },
  { isoCode: 'TD' },
  { isoCode: 'CL' },
  { isoCode: 'CN' },
  { isoCode: 'CX' },
  { isoCode: 'CC' },
  { isoCode: 'CO' },
  { isoCode: 'KM' },
  { isoCode: 'CD' },
  { isoCode: 'CG' },
  { isoCode: 'CK' },
  { isoCode: 'CR' },
  { isoCode: 'HR' },
  { isoCode: 'CU' },
  { isoCode: 'CW' },
  { isoCode: 'CY' },
  { isoCode: 'CZ' },
  { isoCode: 'CI' },
  { isoCode: 'DK' },
  { isoCode: 'DJ' },
  { isoCode: 'DM' },
  { isoCode: 'DO' },
  { isoCode: 'EC' },
  { isoCode: 'EG' },
  { isoCode: 'SV' },
  { isoCode: 'GQ' },
  { isoCode: 'ER' },
  { isoCode: 'EE' },
  { isoCode: 'SZ' },
  { isoCode: 'ET' },
  { isoCode: 'FK' },
  { isoCode: 'FO' },
  { isoCode: 'FJ' },
  { isoCode: 'FI' },
  { isoCode: 'FR' },
  { isoCode: 'GF' },
  { isoCode: 'PF' },
  { isoCode: 'TF' },
  { isoCode: 'GA' },
  { isoCode: 'GM' },
  { isoCode: 'GE' },
  { isoCode: 'DE' },
  { isoCode: 'GH' },
  { isoCode: 'GI' },
  { isoCode: 'GR' },
  { isoCode: 'GL' },
  { isoCode: 'GD' },
  { isoCode: 'GP' },
  { isoCode: 'GU' },
  { isoCode: 'GT' },
  { isoCode: 'GG' },
  { isoCode: 'GN' },
  { isoCode: 'GW' },
  { isoCode: 'GY' },
  { isoCode: 'HT' },
  { isoCode: 'HM' },
  { isoCode: 'VA' },
  { isoCode: 'HN' },
  { isoCode: 'HK' },
  { isoCode: 'HU' },
  { isoCode: 'IS' },
  { isoCode: 'IN' },
  { isoCode: 'ID' },
  { isoCode: 'IR' },
  { isoCode: 'IQ' },
  { isoCode: 'IE' },
  { isoCode: 'IM' },
  { isoCode: 'IL' },
  { isoCode: 'IT' },
  { isoCode: 'JM' },
  { isoCode: 'JP' },
  { isoCode: 'JE' },
  { isoCode: 'JO' },
  { isoCode: 'KZ' },
  { isoCode: 'KE' },
  { isoCode: 'KI' },
  { isoCode: 'KP' },
  { isoCode: 'KR' },
  { isoCode: 'KW' },
  { isoCode: 'KG' },
  { isoCode: 'LA' },
  { isoCode: 'LV' },
  { isoCode: 'LB' },
  { isoCode: 'LS' },
  { isoCode: 'LR' },
  { isoCode: 'LY' },
  { isoCode: 'LI' },
  { isoCode: 'LT' },
  { isoCode: 'LU' },
  { isoCode: 'MO' },
  { isoCode: 'MG' },
  { isoCode: 'MW' },
  { isoCode: 'MY' },
  { isoCode: 'MV' },
  { isoCode: 'ML' },
  { isoCode: 'MT' },
  { isoCode: 'MH' },
  { isoCode: 'MQ' },
  { isoCode: 'MR' },
  { isoCode: 'MU' },
  { isoCode: 'YT' },
  { isoCode: 'MX' },
  { isoCode: 'FM' },
  { isoCode: 'MD' },
  { isoCode: 'MC' },
  { isoCode: 'MN' },
  { isoCode: 'ME' },
  { isoCode: 'MS' },
  { isoCode: 'MA' },
  { isoCode: 'MZ' },
  { isoCode: 'MM' },
  { isoCode: 'NA' },
  { isoCode: 'NR' },
  { isoCode: 'NP' },
  { isoCode: 'NL' },
  { isoCode: 'NC' },
  { isoCode: 'NZ' },
  { isoCode: 'NI' },
  { isoCode: 'NE' },
  { isoCode: 'NG' },
  { isoCode: 'NU' },
  { isoCode: 'NF' },
  { isoCode: 'MP' },
  { isoCode: 'NO' },
  { isoCode: 'OM' },
  { isoCode: 'PK' },
  { isoCode: 'PW' },
  { isoCode: 'PS' },
  { isoCode: 'PA' },
  { isoCode: 'PG' },
  { isoCode: 'PY' },
  { isoCode: 'PE' },
  { isoCode: 'PH' },
  { isoCode: 'PN' },
  { isoCode: 'PL' },
  { isoCode: 'PT' },
  { isoCode: 'PR' },
  { isoCode: 'QA' },
  { isoCode: 'MK' },
  { isoCode: 'RO' },
  { isoCode: 'RU' },
  { isoCode: 'RW' },
  { isoCode: 'RE' },
  { isoCode: 'BL' },
  { isoCode: 'SH' },
  { isoCode: 'KN' },
  { isoCode: 'LC' },
  { isoCode: 'MF' },
  { isoCode: 'PM' },
  { isoCode: 'VC' },
  { isoCode: 'WS' },
  { isoCode: 'SM' },
  { isoCode: 'ST' },
  { isoCode: 'SA' },
  { isoCode: 'SN' },
  { isoCode: 'RS' },
  { isoCode: 'SC' },
  { isoCode: 'SL' },
  { isoCode: 'SG' },
  { isoCode: 'SX' },
  { isoCode: 'SK' },
  { isoCode: 'SI' },
  { isoCode: 'SB' },
  { isoCode: 'SO' },
  { isoCode: 'ZA' },
  { isoCode: 'GS' },
  { isoCode: 'SS' },
  { isoCode: 'ES' },
  { isoCode: 'LK' },
  { isoCode: 'SD' },
  { isoCode: 'SR' },
  { isoCode: 'SJ' },
  { isoCode: 'SE' },
  { isoCode: 'CH' },
  { isoCode: 'SY' },
  { isoCode: 'TW' },
  { isoCode: 'TJ' },
  { isoCode: 'TZ' },
  { isoCode: 'TH' },
  { isoCode: 'TL' },
  { isoCode: 'TG' },
  { isoCode: 'TK' },
  { isoCode: 'TO' },
  { isoCode: 'TT' },
  { isoCode: 'TN' },
  { isoCode: 'TR' },
  { isoCode: 'TM' },
  { isoCode: 'TC' },
  { isoCode: 'TV' },
  { isoCode: 'UG' },
  { isoCode: 'UA' },
  { isoCode: 'AE' },
  { isoCode: 'GB' },
  { isoCode: 'UM' },
  { isoCode: 'US' },
  { isoCode: 'UY' },
  { isoCode: 'UZ' },
  { isoCode: 'VU' },
  { isoCode: 'VE' },
  { isoCode: 'VN' },
  { isoCode: 'VG' },
  { isoCode: 'VI' },
  { isoCode: 'WF' },
  { isoCode: 'EH' },
  { isoCode: 'YE' },
  { isoCode: 'ZM' },
  { isoCode: 'ZW' },
  { isoCode: 'AX' },
];
