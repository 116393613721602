import styled from 'styled-components';
import theme from '../../../theme';

export const StopStatus = {
  ON_TIME: 'ON_TIME',
  DELAYED_PLANT: 'DELAYED_PLANT',
  DELAYED_CARRIER: 'DELAYED_CARRIER',
  DELAYED: 'DELAYED',
  EMPTY: 'EMPTY',
  EARLY: 'EARLY',
};

const StopStatusColor = {
  ON_TIME: theme.color.ignition.on,
  DELAYED_PLANT: theme.color.danger,
  DELAYED_CARRIER: theme.color.danger,
  DELAYED: theme.color.danger,
  EMPTY: theme.color.danger,
  EARLY: theme.color.primary,
};

export const StopStatusDisplay = styled.span`
  font-weight: 500;
  color: ${({ status }) => StopStatusColor[status]};
`;
