import H from '@here/maps-api-for-javascript';
import MapManager from '../../../../services/map/MapManager';
import TourService from '../../../../services/TourService';
import { FIT_GROUP } from '../../../../services/map/drawing';
import { MapEventType } from '../../../../services/map/mapEventDispatcher';

const LINE_CONFIG = {
  style: {
    ...H.map.SpatialStyle.DEFAULT_STYLE,
    fillColor: 'rgba(28, 67, 124, 0.7)',
    strokeColor: 'rgba(28, 67, 124, 0.7)',
    lineWidth: 4,
    lineJoin: 'round',
  },
  visibility: true,
};
const ACTIVE_LINE_STYLE = {
  ...LINE_CONFIG.style,
  fillColor: 'rgba(255, 100, 0, 1)',
  strokeColor: 'rgba(255, 100, 0, 1)',
  lineWidth: 10,
};

class ToursMapManager extends MapManager {
  constructor({ refId, t }) {
    super({
      refId, legend: false, fullscreen: false, t,
    });
    this.vehiclesMarkers = null;
    this.initCenterAtLocation = true;
    this.singlePositionBacklight = null;
    this.previousBacklightedStepLine = null;
    this.stepsGroup = [];
    this.stepPoints = [];
    TourService.setDrawTourRouteFcn(this.drawTourRoute.bind(this));
    TourService.setDrawPositionBacklightFcn(this.drawSinglePositionBacklight.bind(this));
    TourService.setCenterOnActivePoint(this.centerOnActivePoint.bind(this));
    this.cancelTourRouteEventListeners = this.cancelTourRouteEventListeners.bind(this);
  }

  resetPreVstepLineStyle() {
    this.removeSinglePositionBacklight();
    if (this.previousBacklightedStepLine) {
      this.previousBacklightedStepLine.setStyle(LINE_CONFIG.style);
    }
  }

  drawTourRoute(positions) {
    this.clearMap();
    const points = TourService.convertPositionsToMapPoints(positions);
    const steps = [];
    for (let i = 0; i < points.length - 1; i += 1) {
      const lineString = new H.geo.LineString();
      lineString.pushPoint(points[i]);
      lineString.pushPoint(points[i + 1]);
      const line = new H.map.Polyline(lineString, LINE_CONFIG);
      line.setData(points[i]);
      steps.push(line);
    }
    const stepsGroup = new H.map.Group({
      objects: steps,
    });
    this.addToMap(stepsGroup);
    const continuousLine = new H.geo.LineString();
    points.forEach((point) => continuousLine.pushPoint(point));
    this.stepPoints = points;
    const entireRoute = new H.map.Polyline(continuousLine);
    if (entireRoute) {
      this.mapEventDispatcher.dispatch({
        type: MapEventType.NEW_BOUNDING,
        payload: {
          bounding: entireRoute.getBoundingBox(),
          triggerCentering: true,
          type: FIT_GROUP,
        },
      });
    }

    stepsGroup.addEventListener('pointermove', (evt) => {
      if (this.previousBacklightedStepLine !== evt.target) {
        this.resetPreVstepLineStyle();
        this.previousBacklightedStepLine = evt.target;
        evt.target.setStyle(ACTIVE_LINE_STYLE);
        TourService.setActivePositionById(evt.target.getData().position.id);
      }
    });

    this.stepsGroup = stepsGroup;
    TourService.map.stepsGroup = stepsGroup;
  }

  centerOnActivePoint(index) {
    const neighbourhood = new H.geo.LineString();
    const startIndex = index > 3 ? index - 3 : 0;
    const endIndex = index + 2 > this.stepPoints.length ? this.stepPoints.length - 1 : index + 2;

    for (let i = startIndex; i < endIndex; i += 1) {
      neighbourhood.pushPoint(this.stepPoints[i]);
    }
    this.lookAtData(neighbourhood.getBoundingBox());
  }

  drawSinglePositionBacklight(position) {
    this.resetPreVstepLineStyle();
    this.singlePositionBacklight = new H.map.Marker(position);
    this.addToMap(this.singlePositionBacklight);
  }

  removeSinglePositionBacklight() {
    if (this.singlePositionBacklight) this.removeFromMap(this.singlePositionBacklight);
    this.singlePositionBacklight = null;
  }

  cancelTourRouteEventListeners() {
    this.stepsGroup.dispose();
  }
}

export default ToursMapManager;
