import moment from 'moment';

export function normalizeSharing(sharing) {
  return {
    ...sharing,
    isActive: sharing.ownershipStatus === 'ACTIVE' && sharing.useDateTo >= moment().unix(),
    computedId: `${sharing.vehicleId}${sharing.companyId}${sharing.createdDate}`,
  };
}

export function normalizeSharings(sharings) {
  return sharings.map(normalizeSharing);
}
