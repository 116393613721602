import { combineReducers } from 'redux';
import { SHARINGS_SWITCH_TAB } from '../../actions';
import { ShareDirection } from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';

const activeSharingsTypeReducer = (state = ShareDirection.IN, { type, payload }) => {
  if (type === SHARINGS_SWITCH_TAB) {
    return payload.nextTab;
  }
  return state;
};

export default combineReducers({
  activeSharingsType: activeSharingsTypeReducer,
});
