import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import App from './App';
import './styles/style.scss';
import { Loader } from './components';
import { LanguageService, UserService } from './services';

const portalQuery = new QueryClient();

const renderApp = () => {
  ReactDOM.render(
    <Suspense
      fallback={(
        <Loader
          size="m"
          height="100vh"
        />
      )}
    >
      <QueryClientProvider client={portalQuery}>
        <App />
      </QueryClientProvider>
    </Suspense>,
    document.getElementById('root'),
  );
};

Promise.all([
  UserService.initKeycloak(),
  LanguageService.init(),
]).then(renderApp);
