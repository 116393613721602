import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Tabs, Button } from 'antd';
import { StyledTabs, FlexRightSpaced } from '../../components';
import { RequestType } from '../../types/enums/requestType.enum';
import OutgoingRequest from './OutgoingRequest';
import IncomingRequest from './IncomingRequest';

const RequestsContentHolder = ({ context }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [activeTab] = useState(context === 'outgoing' ? RequestType.OUTGOING : RequestType.INCOMING);

  const openMonitoringCreationForm = () => {
    history.push('/requests/outgoing/new');
  };

  return (
    <>
      <BreadcrumbsItem to={`/requests/${activeTab}`}>{t(`BREADCRUMB.${activeTab.toUpperCase()}`)}</BreadcrumbsItem>
      <BreadcrumbsItem to="/requests">{t('BREADCRUMB.REQUESTS')}</BreadcrumbsItem>
      <StyledTabs
        defaultActiveKey={RequestType.OUTGOING}
        activeKey={activeTab}
        size="small"
        onChange={(tab) => {
          history.push({
            pathname: `/requests/${tab}`,
          });
        }}
        tabBarExtraContent={activeTab === RequestType.OUTGOING ? (
          <FlexRightSpaced>
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={openMonitoringCreationForm}
            >
              {t('REQUEST.CREATE_NEW_REQUEST')}
            </Button>
          </FlexRightSpaced>
        ) : (<></>)}
      >
        <Tabs.TabPane
          tab={t('BREADCRUMB.OUTGOING')}
          key={RequestType.OUTGOING}
        >
          <OutgoingRequest />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t('BREADCRUMB.INCOMING')}
          key={RequestType.INCOMING}
        >
          <IncomingRequest />
        </Tabs.TabPane>
      </StyledTabs>
    </>
  );
};

RequestsContentHolder.propTypes = {
  context: PropTypes.string.isRequired,
};

export default RequestsContentHolder;
