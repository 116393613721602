/* eslint-disable no-case-declarations */

import moment from 'moment';
import { FETCH_REQUESTS_OUTGOING } from '../../actions/requests';
import { RESET_COMPANY_CONTEXT } from '../../actions';

const requestsOutgoingInitState = {
  loading: false,
  majorReload: false,
  error: null,
  lastUpdated: moment(),
  data: {
    page: 0,
    size: 0,
    total: 0,
    requestsOutgoing: [],
  },
};

export default function requestsOutgoingReducer(state = null, { type, payload, meta }) {
  switch (type) {
    case FETCH_REQUESTS_OUTGOING.START:
      return {
        ...state, loading: true, majorReload: !payload.silentRefresh, actionId: payload.actionId, data: {},
      };
    case FETCH_REQUESTS_OUTGOING.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }

      return {
        ...state,
        data: {
          page: payload.data.number,
          size: payload.data.size,
          total: payload.data.totalElements,
          requestsOutgoing: payload.data.content,
        },
        loading: false,
        majorReload: false,
        error: undefined,
        lastUpdated: moment(),
      };
    }
    case FETCH_REQUESTS_OUTGOING.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        majorReload: false,
        error: payload,
        data: requestsOutgoingInitState.data,
        lastUpdated: moment(),
      };
    case RESET_COMPANY_CONTEXT:
      return { ...requestsOutgoingInitState };
    default:
      return state;
  }
}
