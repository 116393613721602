/* eslint-disable class-methods-use-this */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ChartsService } from '../../../../services';
import BaseChart from './BaseChart';

class MeanSpeedBetweenInQuarterChart extends React.Component {
  constructor() {
    super();
    this.mapEventsActive = false;
  }

  generateChartPoints() {
    const points = ChartsService.getMeanSpeedBetweenInQuarterChartPoints();

    const base = points;
    const selected = points.map((x) => ({ x: x.x, y: x.data.active ? x.y : null }));
    return {
      base,
      selected,
    };
  }

  render() {
    return (
      <BaseChart
        chartId="MeanSpeedBetweenInQuarterChart"
        getPoints={this.generateChartPoints}
        indexDivider="2"
        unit="km/h"
        mapEventsActive={false}
      />
    );
  }
}

export default withTranslation()(MeanSpeedBetweenInQuarterChart);
