import axiosActionsFactory from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import UrlService from '../../../services/UrlService';
import { uuidv4 } from '../../../utils/uuid';

export const CALCULATE_ETA = axiosActionsFactory('CALCULATE_ETA_LOAD');
export const RESET_ETA = 'RESET_ETA';

export const calculateEta = ({ orderId, shipperCompanyId, stopId }) => (dispatch, getState) => {
  const contextCompanyId = getSelectedCompany(getState());
  return dispatch({
    type: CALCULATE_ETA.START,
    payload: {
      actionId: uuidv4(),
      request: {
        url: UrlService.calculateEta(orderId),
        params: {
          contextCompanyId,
          shipperCompanyId,
          stopId,
        },
      },
    },
  });
};

export const resetEta = () => ({
  type: RESET_ETA,
});
