import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HStretched } from '../../Styled';
import {
  fetchFeatureToggles,
  fetchLoggedUser,
  clearFleetVehicles,
  changeCompanyContext,
} from '../../../store/actions';
import { BreadcrumbsWrapper, LayoutHeader, StyledSelect } from './Header.styled';
import { stringSorter } from '../../../utils/comparators';
import {
  getRelatedCompanies, getSelectedCompany, isMeFetched, isSelectedCompanyFetched,
} from '../../../store/selectors';

const FormItem = styled(Form.Item)`
  margin-right: 0 !important;
`;

export default () => {
  const meFetched = useSelector(isMeFetched);
  const storedSelectedCompany = useSelector(getSelectedCompany);
  const selectedCompanyFetched = useSelector(isSelectedCompanyFetched);
  const { loading, data = [] } = useSelector(getRelatedCompanies);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchLoggedUser());
  }, [dispatch]);

  const onCompanySelected = (companyId) => {
    dispatch(changeCompanyContext(companyId));
  };

  useEffect(() => {
    // set selected company based on query param
    const searchParams = new URLSearchParams(location.search);
    const contextCompanyIdParamName = 'contextCompanyId';
    const contextCompanyId = searchParams.get(contextCompanyIdParamName);
    if (contextCompanyId) {
      searchParams.delete(contextCompanyIdParamName);
      history.replace({
        search: searchParams.toString(),
      });
      onCompanySelected(contextCompanyId);
    } else if ((!selectedCompany || selectedCompany !== storedSelectedCompany)
      && selectedCompanyFetched
      && data && data.length) {
      dispatch(clearFleetVehicles());
      if (data.some((company) => company.id === storedSelectedCompany)) {
        setSelectedCompany(storedSelectedCompany);
        dispatch(fetchFeatureToggles());
      } else {
        onCompanySelected(data[0].id);
      }
    } else if (selectedCompanyFetched && meFetched && !data.some((company) => company.id === selectedCompany)) {
      onCompanySelected(null);
    }
    // meFetched is updated at the same time as data, thus it's not required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    storedSelectedCompany,
    dispatch,
    selectedCompanyFetched,
    onCompanySelected,
    selectedCompany,
    history,
    location.search,
  ]);

  const renderCompanies = () => (
    data.sort((a, b) => stringSorter(a.name.toUpperCase(), b.name.toUpperCase(), 'ascend'))
      .map(({ name, id }) => (
        <Select.Option
          key={id}
          value={id}
        >
          {name}
        </Select.Option>
      ))
  );

  return (
    <LayoutHeader>
      <HStretched>
        <BreadcrumbsWrapper>
          {' '}
          <Breadcrumbs
            separator={<b>{' > '}</b>}
            item={Link}
          />
        </BreadcrumbsWrapper>
        <Form
          layout="inline"
        >
          <FormItem
            label={t('COMMON.SELECTED_COMPANY')}
          >
            <StyledSelect
              placeholder={t('USER.SELECT_COMPANY')}
              loading={loading}
              onChange={onCompanySelected}
              value={selectedCompany}
            >
              {renderCompanies()}
            </StyledSelect>
          </FormItem>
        </Form>
      </HStretched>
    </LayoutHeader>
  );
};
