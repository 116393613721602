import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FlexRightSpaced } from '../../components/Styled/Containers';
import OrderTypeContentHeaderComponent from './OrderTypeContentHeaderComponent';
import {
  ordersResponsePropTypes, OrderContext, OrderType, sortingPropTypes,
} from '../../types';

const OrdersHeaderComponent = ({
  size, orderContext, orders, sorting, interfaceLoading, refreshIntervalSeconds, activeType, filters, orderType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const openOrderCreationForm = () => {
    history.push('/orders/new');
  };

  return (
    <FlexRightSpaced>
      <Button
        type="primary"
        size={size}
        icon={<PlusOutlined />}
        onClick={openOrderCreationForm}
      >
        {t('ORDER.CREATE_MONITORING')}
      </Button>
      <OrderTypeContentHeaderComponent
        orderContext={orderContext}
        orders={orders}
        sorting={sorting}
        interfaceLoading={interfaceLoading}
        refreshIntervalSeconds={refreshIntervalSeconds}
        activeType={activeType}
        filters={filters}
        orderType={orderType}
      />
    </FlexRightSpaced>
  );
};

OrdersHeaderComponent.propTypes = {
  size: PropTypes.string,
  orderContext: PropTypes.oneOf(Object.values(OrderContext)).isRequired,
  orderType: PropTypes.oneOf(Object.values(OrderType)),
  orders: ordersResponsePropTypes.isRequired,
  sorting: sortingPropTypes.isRequired,
  interfaceLoading: PropTypes.bool.isRequired,
  refreshIntervalSeconds: PropTypes.number,
  activeType: PropTypes.string.isRequired,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
};

OrdersHeaderComponent.defaultProps = {
  size: 'small',
  orderType: OrderType.TIME,
  refreshIntervalSeconds: 0,
  filters: {},
};

export default OrdersHeaderComponent;
