import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import styled from 'styled-components';

const CustomTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-tail {
    border-left: 1px solid #52c41a;
  }
`;

const LastTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-tail {
    border-left: 0px;
  }
`;

const ItemHolder = styled.div`
`;

const RequestStatusTimelineItem = ({ item }) => (
  item && !item.last
    ? (
      <CustomTimelineItem color={item.color}>
        <ItemHolder className={item.disabled ? 'disabled' : ''}>{item.renderDetail()}</ItemHolder>
      </CustomTimelineItem>
    )
    : (
      <LastTimelineItem color={item.color}>
        <ItemHolder className={item.disabled ? 'disabled' : ''}>{item.renderDetail()}</ItemHolder>
      </LastTimelineItem>
    )
);

RequestStatusTimelineItem.propTypes = {
  item: PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    renderDetail: PropTypes.func.isRequired,
  }).isRequired,
};

export default RequestStatusTimelineItem;
