import {
  CLOSE_SHARE_VEHICLES,
  EDIT_SHARING,
  OPEN_SHARE_VEHICLES,
  ShareVehicleMode,
  SUBMIT_SHARE_VEHICLES,
} from '../../actions/vehicleDetails';
import NOP from '../../../utils/NOP';

const initialGenerateReportState = {
  visible: false,
  vehicles: [],
  error: undefined,
  loading: false,
  fixed: false,
  mode: ShareVehicleMode.NEW,
  sharing: {
    selectedVehicles: [],
    useDateFrom: undefined,
    useDateTo: undefined,
    personEmail: undefined,
    ownershipType: 'LEASE',
    createdDate: undefined,
  },
  callback: NOP,
};

export default function shareVehiclesReducer(state = initialGenerateReportState, { type, payload }) {
  switch (type) {
    case OPEN_SHARE_VEHICLES:
      return {
        ...state, ...payload, sharing: { ...state.sharing, ...payload.sharing }, visible: true,
      };
    case SUBMIT_SHARE_VEHICLES.START:
    case EDIT_SHARING.START:
      return { ...state, loading: true };
    case SUBMIT_SHARE_VEHICLES.SUCCESS:
    case EDIT_SHARING.SUCCESS:
    case CLOSE_SHARE_VEHICLES:
      return { ...initialGenerateReportState };
    case SUBMIT_SHARE_VEHICLES.FAIL:
    case EDIT_SHARING.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}
