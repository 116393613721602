import { createMapOrderCreation, createBehavior, addDraggableMarker } from '../../../../services/map/drawing';

class OrderCreationMapManager {
  constructor({
    refId,
  }) {
    this.map = createMapOrderCreation(refId);
    this.behavior = createBehavior(this.map);
  }

  drawMarker(point, saveCordinate) {
    this.removeExistMarker();
    this.map.setCenter(point);
    this.map.setZoom(16);
    addDraggableMarker(point, this.map, this.behavior, saveCordinate);
  }

  removeExistMarker() {
    this.map.removeObjects(this.map.getObjects());
  }
}

export default OrderCreationMapManager;
