export const getFleetPageState = (state) => state.fleet.selection;
export const getFleetSelectedRows = (state) => state.fleet.selection.selectedRows;
export const getFleetAutoRefresh = (state) => state.fleet.pageState.autoRefresh;
export const getFleetSelectionMode = (state) => (state.fleet.selection.selection
  ? state.fleet.selection.selection.mode
  : null);
export const getFleetPageSelectedRowsKeys = (state) => state.fleet.selection.selectedRows.map((v) => v.computedId);
export const getFleetSelectionType = (state) => (state.fleet.selection.selection
  ? state.fleet.selection.selection.type
  : null);
export const getFleetVehicles = (state) => state.fleet.vehicles.data;
