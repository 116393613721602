import H from '@here/maps-api-for-javascript';
import StopInfoContent from './StopInfoContent';

export default (stop, position, type, t) => {
  const { sequence, place, timeSlot } = stop;
  const infoBubble = new H.ui.InfoBubble(position, {
    content: StopInfoContent({
      sequence,
      timeSlot,
      position,
      place,
      type,
    }, t),
  });
  infoBubble.addClass('trip-info-infobubble');
  infoBubble.addClass('above-circle-infobubble');
  infoBubble.close();
  return infoBubble;
};
