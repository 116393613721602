import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { fetchLastUsedCompany } from '../company';

export const FETCH_ME = axiosActions('ME_LOAD');

function fetchMe() {
  return {
    type: FETCH_ME.START,
    payload: {
      request: {
        url: UrlService.personsMe(),
      },
    },
  };
}

export const fetchLoggedUser = () => (dispatch) => Promise.all([dispatch(fetchMe()), dispatch(fetchLastUsedCompany())]);
