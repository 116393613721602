import { info } from './Logging';

const eventBus = {
  subscribe(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
    return () => this.unsubscribe(event, callback);
  },
  dispatch(event, data) {
    info(`dispatched ${event}`);
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  unsubscribe(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
