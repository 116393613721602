/* eslint-disable max-len */
const indexTag = (index) => (index > 9
  ? '<g id="g9" transform="translate(-1,-6)">'
  + '<path id="circle4-3" style="fill:#4d4d4d;stroke:#ffffff;stroke-width:0.6632;stroke-miterlimit:2.126" d="m 23.2,20.1 c 3.5,0 6.3,2.8 6.3,6.3 0,3.5 -2.8,6.3 -6.3,6.3 0,0 -5.1,0 -5.1,0 -3.4,-0.1 -6.2,-2.9 -6.2,-6.3 0,-3.4 2.7,-6.1 6,-6.3 0.1,0 5.2,0 5.3,0 z" />'
  + `<text transform="translate(15.4056,29.735)" style="font-size:9.567px;font-family:Helvetica;fill:#ffffff" id="text7">${index}</text>`
  + '</g>'
  : '<g style="fill:none" id="g855" transform="matrix(0.53150159,0,0,0.53150159,10.045604,11.965535)">'
  + '<circle cx="14.927561" cy="15.220095" r="11.854009" id="circle4-3" style="fill:#4d4d4d;stroke:#ffffff;stroke-width:1.24779" />'
  + '<text style="font-style:normal;font-weight:normal;font-size:20.0689px;line-height:1.25;font-family:sans-serif;fill:#000000;fill-opacity:1;stroke:none" x="8.366951" y="22.535248" id="text843">'
  + `    <tspan id="tspan841" x="9.9512281" y="21.511269" style="font-size:18px;fill:#ffffff;stroke:none;stroke-opacity:1">${index}</tspan>`
  + '</text>'
  + '</g>');

const loading = '<svg width="__SIZE__" height="__SIZE__" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '    <circle cx="15" cy="14" r="10" fill="#F2994A"/>'
  + '    <circle cx="15" cy="14" r="9.5" stroke="#D47119"/>'
  + '    <path d="M10 15V17.9C10 17.9552 10.0448 18 10.1 18H19.9C19.9552 18 20 17.9552 20 17.9V15" stroke="white"/>'
  + '    <path d="M15.5 14.2544V9H14.5V14.2544H13L15 16L17 14.2544H15.5Z" fill="white"/>'
  + '</svg>';

const loadingIndexed = (index) => '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">'
  + '<circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />'
  + '<circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />'
  + '<path d="m 5,11 v 2.9 C 5,13.9552 5.0448,14 5.1,14 h 9.8 C 14.9552,14 15,13.9552 15,13.9 V 11" stroke="#ffffff" id="path6" />'
  + '<path d="M 10.5,10.2544 V 5 h -1 v 5.2544 H 8 L 10,12 12,10.2544 Z" fill="#ffffff" id="path8" />'
  + `${indexTag(index)}`
  + '</svg>';

const unloading = '<svg width="__SIZE__" height="__SIZE__" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '  <circle cx="15" cy="14" r="10" fill="#F2994A" />'
  + '  <circle cx="15" cy="14" r="9.5" stroke="#D47119" />'
  + '  <path d="M10 15V17.9C10 17.9552 10.0448 18 10.1 18H19.9C19.9552 18 20 17.9552 20 17.9V15" stroke="white" />'
  + '  <path d="m 14.522429,10.762167 v 5.2544 h 1 v -5.2544 h 1.5 l -2,-1.7456 -2,1.7456 z" fill="#ffffff" />'
  + '</svg>';

const loadingUnloadingIndexed = (index) => '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">'
  + '<circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />'
  + '<circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />'
  + '<g id="g840" transform="translate(-0.2408249,0.19361925)">'
  + '  <path d="m 5.2408248,11.056381 v 2.9 c 0,0.0552 0.0448,0.1 0.1,0.1 h 9.8000002 c 0.0552,0 0.1,-0.0448 0.1,-0.1 v -2.9" stroke="#ffffff" id="path6" />'
  + '  <path d="m 11.736455,6.5526805 v 4.5037005 h 0.75 V 6.5526805 h 1.125 l -1.5,-1.4963 -1.5,1.4963 z" fill="#ffffff" id="path8" />'
  + '  <path d="M 8.7822178,9.5957175 V 5.0920179 h -0.75 v 4.5036996 h -1.125 l 1.5,1.4963005 1.5,-1.4963005 z" fill="#ffffff" id="path10" />'
  + '</g>'
  + `${indexTag(index)}`
  + '</svg>';

const loadingUnloading = '<svg width="__SIZE__" height="__SIZE__" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">'
  + '  <circle cx="15" cy="14" r="10" fill="#F2994A" />'
  + '  <circle cx="15" cy="14" r="9.5" stroke="__FILL_COLOR__" />'
  + '  <path d="M10 15V17.9C10 17.9552 10.0448 18 10.1 18H19.9C19.9552 18 20 17.9552 20 17.9V15" stroke="white" />'
  + '  <path d="M 16.49563,10.4963 V 15 h 0.75 v -4.5037 h 1.125 l -1.5,-1.4963 -1.5,1.4963 z" fill="#ffffff" />'
  + '  <path d="M 13.541393,13.539337 V 9.0356374 h -0.75 v 4.5036996 h -1.125 l 1.5,1.4963 1.5,-1.4963 z" fill="#ffffff" />'
  + '</svg>';

const unloadingIndexed = (index) => '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">'
+ '<circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />'
+ '<circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />'
+ '<g id="g840" transform="translate(-5,-3.7582835)">'
+ '<path d="m 10,15 v 2.9 c 0,0.0552 0.0448,0.1 0.1,0.1 h 9.8 C 19.9552,18 20,17.9552 20,17.9 V 15" stroke="#ffffff" id="path7" />'
+ '<path d="m 14.522429,10.762167 v 5.2544 h 1 v -5.2544 h 1.5 l -2,-1.7456 -2,1.7456 z" fill="#ffffff" id="path9" />'
+ '</g>'
+ `${indexTag(index)}`
+ '</svg>';

const unknown = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">'
  + '<circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />'
  + '<circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />'
  + '<g id="g840" transform="translate(-5,-3.7582835)">'
  + '<path d="" stroke="#ffffff" id="path7" />'
  + '<path d="" fill="#ffffff" id="path9" />'
  + '</g>'
  + '</svg>';

const unknownIndexed = (index) => '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.127065" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">'
  + '<circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />'
  + '<circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />'
  + '<g id="g840" transform="translate(-5,-3.7582835)">'
  + '<path d="" stroke="#ffffff" id="path7" />'
  + '<path d="" fill="#ffffff" id="path9" />'
  + '</g>'
  + `${indexTag(index)}`
  + '</svg>';

const stopTypeToIcon = {
  LOAD_UNLOAD: loadingUnloading,
  LOAD: loading,
  UNKNOWN: unknown,
  UNLOAD: unloading,
};

const stopTypeToIndexedIcon = {
  LOAD_UNLOAD: loadingUnloadingIndexed,
  LOAD: loadingIndexed,
  UNLOAD: unloadingIndexed,
  UNKNOWN: unknownIndexed,
};

export default {
  loading,
  unloading,
  loadingUnloading,
  stopTypeToIcon: (type) => stopTypeToIcon[type],
  stopTypeToIndexedIcon: (type) => stopTypeToIndexedIcon[type],
};
