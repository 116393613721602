import { combineReducers } from 'redux';
import {
  CLOSE_CREATE_REQUEST,
  CREATE_REQUEST,
  FETCH_ALL_COMPANIES, FETCH_COMPANY_VEHICLES,
  OPEN_CREATE_REQUEST,
} from '../../actions/requests/create-request.actions';

function visibilityReducer(state = false, { type }) {
  switch (type) {
    case OPEN_CREATE_REQUEST:
      return true;
    case CLOSE_CREATE_REQUEST:
      return false;
    default:
      return state;
  }
}

const initialState = {
  loading: false,
  data: [],
};

function allCompaniesReducer(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case FETCH_ALL_COMPANIES.START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_COMPANIES.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case FETCH_ALL_COMPANIES.FAIL:
    case CLOSE_CREATE_REQUEST:
      return {
        ...state,
        data: [],
        loading: false,
      };
    default:
      return state;
  }
}

function companyVehicles(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case FETCH_COMPANY_VEHICLES.START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANY_VEHICLES.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case FETCH_COMPANY_VEHICLES.FAIL:
    case CLOSE_CREATE_REQUEST:
      return {
        ...state,
        data: [],
        loading: false,
      };
    default:
      return state;
  }
}

const creationInitialState = { loading: false };

function creationReducer(state = { ...creationInitialState }, { type }) {
  switch (type) {
    case CREATE_REQUEST.START:
      return { ...state, loading: true };
    case CREATE_REQUEST.SUCCESS:
    case CREATE_REQUEST.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default combineReducers({
  visible: visibilityReducer,
  companies: allCompaniesReducer,
  creation: creationReducer,
  vehicles: companyVehicles,
});
