import UrlService from '../../../services/UrlService';
import axiosActions from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';
import { isSuccess } from '../../../utils/responseUtils';
import { fetchLocation } from '../location.actions';

export const FETCH_ORDER_DETAILS = axiosActions('ORDER_DETAILS_LOAD');
export const FETCH_ORDER_POSITIONS = axiosActions('ORDER_POSITIONS_LOAD');
export const FETCH_VEHICLE_TOUR_ANALYSIS = axiosActions('FETCH_VEHICLE_TOUR_ANALYSIS_LOAD');
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const CLEAN_ORDER_DETAILS = 'CLEAN_ORDER_DETAILS';

const executeFetchOrderDetails = (shipperCompanyId, internalId, contextCompanyId) => ({
  type: FETCH_ORDER_DETAILS.START,
  payload: {
    fetchingAsCompany: contextCompanyId,
    actionId: uuidv4(),
    request: {
      url: UrlService.orderDetails(internalId),
      params: {
        contextCompanyId,
        shipperCompanyId,
      },
    },
  },
});

export const fetchOrderDetails = (shipperId, orderId) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  return dispatch(executeFetchOrderDetails(shipperId, orderId, selectedCompany));
};

const executeFetchOrderPositions = (shipperCompanyId, orderId, exclusiveStartKey, contextCompanyId) => {
  const params = {
    contextCompanyId, shipperCompanyId, sortOrder: 'ASC', withPreciseRoute: true,
  };
  if (exclusiveStartKey) {
    params.exclusiveStartKey = exclusiveStartKey;
  }

  return {
    type: FETCH_ORDER_POSITIONS.START,
    actionId: uuidv4(),
    payload: {
      request: {
        url: UrlService.orderPositions(orderId),
        params,
      },
    },
  };
};

export const fetchOrderPositions = (shipperId, orderId, lastPositionId = undefined) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  return dispatch(executeFetchOrderPositions(shipperId, orderId, lastPositionId, selectedCompany));
};

const executeFetchOrderLastPositions = (shipperCompanyId, orderId, contextCompanyId) => {
  const params = {
    contextCompanyId,
    shipperCompanyId,
    limit: 1,
    sortOrder: 'desc',
  };
  return {
    type: FETCH_ORDER_POSITIONS.START,
    actionId: uuidv4(),
    payload: {
      request: {
        url: UrlService.orderPositions(orderId),
        params,
      },
    },
  };
};

const findVehiclePosition = (givenVehicleId, receivedVehicles = []) => {
  const vehicle = receivedVehicles.find(({ vehicleId }) => vehicleId === givenVehicleId);
  if (vehicle && vehicle.positions && vehicle.positions.length) {
    const position = vehicle.positions[0];
    return { lat: position.coordinateLatitude, lng: position.coordinateLongitude, timestamp: position.serverTimestamp };
  }
  return null;
};

export const fetchOrderLastPositions = (vehicleId, shipperId, orderId) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  return dispatch(executeFetchOrderLastPositions(shipperId, orderId, selectedCompany))
    .then((action) => {
      if (isSuccess(action)) {
        const locationKey = ['orders', vehicleId, shipperId, orderId].join('_');
        const vehiclePosition = findVehiclePosition(vehicleId, action.payload.data);
        if (!vehiclePosition) {
          return Promise.reject();
        }
        return dispatch(fetchLocation(locationKey, vehiclePosition));
      }
      return Promise.reject();
    });
};

export const cleanOrderDetails = () => (dispatch) => dispatch({
  type: CLEAN_ORDER_DETAILS,
  payload: null,
});

export const setOrderDetails = ({ detailsAction, positionsAction }) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());

  return dispatch({
    type: SET_ORDER_DETAILS,
    payload: {
      fetchingAsCompany: selectedCompany,
      details: detailsAction.payload && detailsAction.payload.data,
      positions: positionsAction.payload && positionsAction.payload.data,
      probableRoute: positionsAction.payload && positionsAction.payload.data,
    },
  });
};

export const fetchPositionsAnalysis = ({ positions }) => {
  const apiPositions = positions.map((position) => ({
    coordinates: {
      lat: position.coordinateLatitude,
      lng: position.coordinateLongitude,
    },
    id: position.id,
    heading: position.heading,
    ignitionState: position.ignitionState,
    positionTimestamp: position.positionTimestamp,
    serverTimestamp: position.serverTimestamp,
    speed: position.speed || 0,
  }));

  return {
    type: FETCH_VEHICLE_TOUR_ANALYSIS.START,
    payload: {
      actionId: uuidv4(),
      request: {
        method: 'post',
        url: UrlService.positionsAnalysis(),
        data: {
          positions: apiPositions,
        },
      },
    },
  };
};
