import styled from 'styled-components';
import theme from '../../../theme';

export const StopTimeStatus = {
  EARLY: 'EARLY',
  ON_TIME: 'ON_TIME',
  TOO_LATE: 'TOO_LATE',
  DELAYED: 'DELAYED',
};

const StopTimeStatusColor = {
  EARLY: theme.color.primary,
  ON_TIME: theme.color.ignition.on,
  TOO_LATE: theme.color.danger,
  DELAYED: theme.color.danger,
};

export const StopTimeStatusDisplay = styled.span`
  font-weight: 500;
  color: ${({ status }) => StopTimeStatusColor[status]};
`;
