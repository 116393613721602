import React from 'react';
import { withTranslation } from 'react-i18next';
import { ChartsService } from '../../../../services';
import BaseChart from './BaseChart';

class IsUniqueInQuarterChart extends React.Component {
  generateChartPoints() {
    const points = ChartsService.getIsUniqueQuarterChartPoints();

    const base = points;
    const selected = points.map((x) => ({ x: x.x, y: x.data.active ? x.y : null }));
    return {
      base,
      selected,
    };
  }

  render() {
    return (
      <BaseChart
        chartId="IsUniqueInQuarterChart"
        getPoints={this.generateChartPoints}
        indexDivider="2"
        mapEventsActive={false}
      />
    );
  }
}

export default withTranslation()(IsUniqueInQuarterChart);
