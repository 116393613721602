/* eslint-disable max-len */
import { message } from 'antd';
import { locationDetailsSection, pauseDetailsSection } from './eventInfoSections';
import ParkingIcons from '../../assets/parkingIcons';
import BreakIcons from '../../assets/breakIcons';
import theme from '../../../../theme';

export default (vehicle, event, type, t, config) => {
  const {
    duration, location, startTimestamp, endTimestamp,
  } = event;
  const { id: vehicleId } = vehicle;
  const vehicleDetailsUrl = `#/vehicles/${vehicleId}`;

  window.copyAndNotify = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success(t('COMMON.COPIED_TO_CLIPBOARD')));
  };

  const vehicleDetailsLink = () => '<p class="H_bubble_content--label" style="margin:2px 2px 5px 2px">'
    + `  <a class="H_bubble_content--link" href="${vehicleDetailsUrl}" target="_blank">${t('VEHICLE.SHOW_VEHICLE_DETAILS')}</a>`
    + '</p>';

  const headerIcon = (type === 'PARKING' ? ParkingIcons.basic() : BreakIcons.basic())
    .replace(/__FILL_COLOR__/g, theme.color.lightText)
    .replace(/__TEXT_COLOR__/g, theme.color.routeStroke);

  return '<div class="H_bubble_root">'
    + '  <div class="H_bubble_wrapper">'
    + '    <div class="H_bubble_content">'
    + '      <div class="H_bubble_header">'
    + `        ${headerIcon}`
    + '        <span class="H_bubble_header--title">'
    + `          ${t(`PROPERTIES.${type === 'PARKING' ? 'PARKING_DETAILS' : 'BREAK_DETAILS'}`)}`
    + '        </span>'
    + '      </div>'
    + '      <div class="horizontal-line"></div>'
    + `      ${pauseDetailsSection(t, {
      duration, startTimestamp, endTimestamp, type,
    })}`
    + `      ${locationDetailsSection(t, { location })}`
    + `      ${config.vehicleLink ? vehicleDetailsLink() : ''}`
    + '    </div>'
    + '    <div class="triangle-with-shadow"></div>'
    + '  </div>'
    + '</div>';
};
