import H from '@here/maps-api-for-javascript';
import ignitionStateToColor from '../../../utils/constants/ignitionStateToColor';
import vehicleDetailsPopUp from './infobubbles/VehicleDetailsContent';
import vehicleDetailsOrderPopUp from './infobubbles/VehicleDetailsContentOrder';
import {
  circleIcon, markerIcon, markerIconWithText,
} from '../assets/icons';
import { isValidPosition } from '../../../store/normalizers';
import { MAP_CIRCLES_Z_INDEX, MAP_MARKERS_Z_INDEX } from './mapConsts';
import { zoomTo } from './utils';
import PinIcons from '../assets/pinIcons';
import ParkingIcons from '../assets/parkingIcons';
import BreakIcons from '../assets/breakIcons';
import StopTypeIcons from '../assets/stopTypeIcons';
import theme from '../../../theme';

const createIcon = (icon, {
  color = '#489ed7', stroke = 'white', width = '15px', height = '15px', text,
}) => (
  icon.replace(/__FILL__/g, color)
    .replace(/__STROKE__/g, stroke)
    .replace(/__WIDTH__/g, width)
    .replace(/__HEIGHT__/g, height)
    .replace(/__TEXT__/g, text)
);

const createIgnitionIcon = (icon, { ignitionState, text, anchor }) => {
  const color = ignitionStateToColor(ignitionState);
  const options = {};
  if (anchor) {
    options.anchor = anchor;
  }
  return new H.map.Icon(createIcon(icon, { color, text }), options);
};

const createCircleIcon = (color, sizePixels) => {
  const size = `${sizePixels}px`;
  const icon = createIcon(circleIcon, {
    color, stroke: 'white', width: size, height: size,
  });
  return new H.map.Icon(icon, {
    anchor: {
      x: sizePixels / 2,
      y: sizePixels / 2,
    },
  });
};

const anchorByType = {
  active: { x: 17, y: 16 },
  done: { x: 15, y: 18 },
  indexed: { x: 15, y: 18 },
  hover: { x: 15, y: 14 },
  basic: { x: 15, y: 14 },
};

const createBreakIcon = (type, { fillColor, textColor } = {}, { index } = {}) => {
  const icon = BreakIcons[type](index)
    .replace(/__FILL_COLOR__/g, fillColor)
    .replace(/__TEXT_COLOR__/g, textColor)
    .replace(/__SIZE__/g, '30');
  return new H.map.Icon(icon, {
    anchor: anchorByType[type],
  });
};

const createParkingIcon = (type, { fillColor, textColor } = {}, { index } = {}) => {
  const icon = ParkingIcons[type](index)
    .replace(/__FILL_COLOR__/g, fillColor)
    .replace(/__TEXT_COLOR__/g, textColor)
    .replace(/__SIZE__/g, '30');
  return new H.map.Icon(icon, {
    anchor: anchorByType[type],
  });
};

const createStopTypeIcon = (type, index, { fillColor, textColor } = {}) => {
  const icon = StopTypeIcons.stopTypeToIndexedIcon(type)(index)
    .replace(/__FILL_COLOR__/g, fillColor)
    .replace(/__TEXT_COLOR__/g, textColor)
    .replace(/__SIZE__/g, '30');
  return new H.map.Icon(icon, {
    anchor: { x: 15, y: 18 },
  });
};

const createStartIcon = ({ color }) => {
  const icon = PinIcons.startPin.replace(/__FILL_COLOR__/g, color);
  return new H.map.Icon(icon, {
    anchor: {
      x: 10,
      y: 10,
    },
  });
};

export const createMarker = (icon, position, {
  data = {}, zIndex = 0, minZoom, volatility = false, visibility = true, onTap, onPointerMove, onPointerLeave,
} = {}) => {
  if (!isValidPosition(position)) {
    return null;
  }
  const conf = {
    data: { clickable: !!onTap, ...data, marker: true }, icon, zIndex, volatility, visibility,
  };
  if (minZoom !== undefined && minZoom !== null) {
    conf.min = minZoom;
  }

  const marker = new H.map.Marker({
    lat: position.coordinateLatitude || position.lat,
    lng: position.coordinateLongitude || position.lng,
  }, conf);

  if (onTap) {
    marker.addEventListener('tap', (event) => onTap(event));
  }

  if (onPointerMove) {
    marker.addEventListener('pointermove', () => onPointerMove(marker));
  }

  if (onPointerLeave) {
    marker.addEventListener('pointerleave', () => onPointerLeave(marker));
  }

  return marker;
};

export const createDraaggableMarker = () => {
  const marker = new H.map.Marker({ lat: 52.030994, lng: 23.099141 }, {
    volatility: true,
  });
  return marker;
};

export const createBreakMarker = (type, position, iconStyle, config) => createMarker(
  createBreakIcon(type, iconStyle, config), position, config,
);

export const createParkingMarker = (type, position, iconStyle, config) => createMarker(
  createParkingIcon(type, iconStyle, config), position, config,
);

export const createStopTypeMarker = (type, index, position, iconStyle, config) => createMarker(
  createStopTypeIcon(type, index, iconStyle), position, config,
);

export const createPinMarker = (position, config = {}) => {
  const text = config.data && config.data.vehicle && (
    config.data.vehicle.licencePlateNumber || config.data.vehicle.vehiclePlateNumber
  );
  let icon;
  const anchor = position.break ? { x: 30, y: 55 } : null;
  if (text) {
    icon = createIgnitionIcon(markerIconWithText, {
      ignitionState: position.ignitionState, text, anchor,
    });
  } else {
    icon = createIgnitionIcon(markerIcon, {
      ignitionState: position.ignitionState, anchor,
    });
  }

  return createMarker(icon, position, {
    visibility: true, data: {}, ...config, zIndex: config.zIndex || MAP_MARKERS_Z_INDEX,
  });
};

export const createCircleMarker = (position, { color, size, zIndex }, {
  data, visibility, volatility, onTap, onPointerMove, onPointerLeave,
} = {}) => {
  const icon = createCircleIcon(color, size);
  const marker = createMarker(icon, position, {
    data, zIndex: zIndex || MAP_CIRCLES_Z_INDEX, visibility, volatility,
  });

  if (onTap) {
    marker.addEventListener('tap', () => onTap(marker));
  }

  if (onPointerMove) {
    marker.addEventListener('pointermove', () => onPointerMove(marker));
  }

  if (onPointerLeave) {
    marker.addEventListener('pointerleave', () => onPointerLeave(marker));
  }

  return marker;
};

export const createStartMarker = (position, { color, zIndex }, {
  data, visibility, volatility, onTap, onPointerMove, onPointerLeave,
} = {}) => {
  const icon = createStartIcon({ color });
  return createMarker(icon, position, {
    data,
    zIndex: zIndex || MAP_CIRCLES_Z_INDEX,
    visibility,
    volatility,
    onTap,
    onPointerMove,
    onPointerLeave,
  });
};

export const createBubbleOpener = (ui, t, fetchLocation, showLastTrace, infoBubble, zoomIn = false) => (
  data, location,
) => {
  if (data) {
    ui.getBubbles().forEach((existingBubble) => existingBubble.close());
    const { vehicleId, vehicle } = data;
    infoBubble.setContent(vehicleDetailsPopUp(data, t, showLastTrace));
    infoBubble.setPosition(location);
    infoBubble.open();
    const lat = vehicle.lastPosition[0].coordinateLatitude;
    const lng = vehicle.lastPosition[0].coordinateLongitude;
    const location$ = fetchLocation(vehicleId, { lat, lng });
    const zoom$ = zoomTo(ui, location, zoomIn && 19, true);
    Promise.all([location$, zoom$])
      .then(([loc]) => {
        const locName = loc && loc.items && loc.items.length && loc.items[0];
        const dataWithLocation = {
          ...data,
          locName,
        };
        const content = vehicleDetailsPopUp(dataWithLocation, t, showLastTrace);
        infoBubble.setContent(content);
      });
  }
};

export const createBubbleContent = (ui, t, fetchLocation, showLastTrace, infoBubble) => (
  data,
) => {
  if (data) {
    ui.getBubbles().forEach((existingBubble) => existingBubble.close());
    const {
      lat, lng, vehicleId, serverTimestamp, positionTimestamp, speed, vehicle, ignitionState,
    } = data;
    const { licencePlateNumber, id } = vehicle;
    const detailContent = {
      ...data,
      vehicleId: id,
      vehicle: {
        lastPosition: [{
          serverTimestamp,
          positionTimestamp,
          speed,
          ignitionState,
        }],
        vehicleCompany: undefined,
        licencePlateNumber,
      },
    };
    infoBubble.setContent(vehicleDetailsOrderPopUp(detailContent, t, showLastTrace));
    const location$ = fetchLocation(vehicleId, { lat, lng });
    Promise.all([location$])
      .then(([loc]) => {
        const locName = loc && loc.items && loc.items.length && loc.items[0];
        const dataWithLocation = {
          ...detailContent,
          locName,
        };
        const content = vehicleDetailsOrderPopUp(dataWithLocation, t, showLastTrace);
        infoBubble.setContent(content);
      });
  }
};

export const drawHistoryBoundaryMarkers = (start, end) => {
  const startMarker = start && createStartMarker({
    coordinateLatitude: start.lat,
    coordinateLongitude: start.lng,
  }, {
    zIndex: 4000,
    color: theme.color.lastRouteStroke,
  }, {
    volatility: true,
  });
  const endMarker = end && createPinMarker(end, { data: { vehicle: end.vehicle }, zIndex: 3999, volatility: true });
  return [startMarker, endMarker].filter((marker) => marker);
};

export const addDraggableMarker = (point, map, behavior, callback) => {
  const marker = new H.map.Marker(point, {
    volatility: true,
  });
  marker.draggable = true;
  map.addObject(marker);

  map.addEventListener('dragstart', (ev) => {
    const { target } = ev;
    const pointer = ev.currentPointer;
    if (target instanceof H.map.Marker) {
      const targetPosition = map.geoToScreen(target.getGeometry());
      target.offset = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
      behavior.disable();
    }
  }, false);

  map.addEventListener('dragend', (ev) => {
    const { target } = ev;
    if (target instanceof H.map.Marker) {
      callback(target.b);
      behavior.enable();
    }
  }, false);

  map.addEventListener('drag', (ev) => {
    const { target } = ev;
    const pointer = ev.currentPointer;
    if (target instanceof H.map.Marker) {
      target.setGeometry(map.screenToGeo(pointer.viewportX - target.offset.x, pointer.viewportY - target.offset.y));
    }
  }, false);
};
