import axiosActionsFactory from '../axiosHelpers';
import UrlService from '../../../services/UrlService';

export const OPEN_CREATE_REQUEST = 'OPEN_CREATE_REQUEST';
export const CLOSE_CREATE_REQUEST = 'CLOSE_CREATE_REQUEST';
export const FETCH_ALL_COMPANIES = axiosActionsFactory('FETCH_ALL_COMPANIES');
export const FETCH_COMPANY_VEHICLES = axiosActionsFactory('FETCH_COMPANY_VEHICLES');
export const CREATE_REQUEST = axiosActionsFactory('CREATE_REQUEST');

export const fetchAllCompanies = () => (dispatch) => dispatch({
  type: FETCH_ALL_COMPANIES.START,
  payload: {
    request: {
      method: 'get',
      url: UrlService.allCompanies(),
    },
  },
});

export const fetchCompanyVehicles = (companyId) => (dispatch) => dispatch({
  type: FETCH_COMPANY_VEHICLES.START,
  payload: {
    request: {
      url: UrlService.companyVehicles(companyId),
    },
  },
});

export const createRequest = (request) => (dispatch) => dispatch({
  type: CREATE_REQUEST.START,
  payload: {
    request: {
      url: UrlService.creatMonitoringRequest(),
      method: 'post',
      data: request,
    },
  },
});
