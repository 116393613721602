/* eslint no-param-reassign:0 */
import { normalizePosition } from './position.normalizer';

export function normalizeVehicleInPlace(vehicle) {
  const lastPosition = vehicle && vehicle.vehicle
    && vehicle.vehicle.lastPosition && vehicle.vehicle.lastPosition.length && vehicle.vehicle.lastPosition[0];

  if (lastPosition) {
    vehicle.vehicle.lastPosition[0] = normalizePosition(lastPosition);
  }

  vehicle.computedId = `${vehicle.companyId}_${vehicle.vehicleId}_${vehicle.vehicle?.vehicleCompany?.id}`;

  return vehicle;
}

export function normalizeVehiclesInPlace(vehicles) {
  return vehicles.map((vehicle) => normalizeVehicleInPlace(vehicle));
}
