/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len:0 */
import React from 'react';
import PropTypes from 'prop-types';
import StopIndexTag from './StopIconTag';

const LoadingIndexedIcon = ({ index, onClick, style }) => (
  <div onClick={onClick} style={{ ...style, cursor: onClick ? 'pointer' : 'default' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="28.8316" height="27.031601" viewBox="0 0 28.8316 27.031601" fill="none" version="1.1" id="svg10">
      <circle cx="10" cy="10" r="10" fill="#f2994a" id="circle2" />
      <circle cx="10" cy="10" r="9.5" stroke="#d47119" id="circle4" />
      <path d="m 5,11 v 2.9 C 5,13.9552 5.0448,14 5.1,14 h 9.8 C 14.9552,14 15,13.9552 15,13.9 V 11" stroke="#ffffff" id="path6" />
      <path d="M 10.5,10.2544 V 5 h -1 v 5.2544 H 8 L 10,12 12,10.2544 Z" fill="#ffffff" id="path8" />
      <StopIndexTag index={index} />
    </svg>
  </div>
);

LoadingIndexedIcon.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
};

LoadingIndexedIcon.defaultProps = {
  onClick: null,
  style: {},
};

export default LoadingIndexedIcon;
