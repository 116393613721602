import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailsDescriptions from '../../../../components/Styled/Custom/DetailsDescriptions';
import { FullWidthStyledCard } from '../../../../components';

const OrderDetailsDescription = ({ requestDetails }) => {
  const { t } = useTranslation();
  const orderDetails = [
    {
      title: 'REQUEST.ORDER_NUMBER',
      renderDetail: () => requestDetails.orderNumber,
      loading: () => requestDetails.loading,
    },
    {
      title: 'REQUEST.ADDITIONAL_INFO',
      renderDetail: () => requestDetails.additionalInfo,
      loading: () => requestDetails.loading,
    },
  ];

  return (
    <FullWidthStyledCard title={t('REQUEST.ORDER_DETAILS')}>
      <DetailsDescriptions
        detailsSize="small"
        detailsConfig={orderDetails}
      />
    </FullWidthStyledCard>
  );
};

OrderDetailsDescription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default OrderDetailsDescription;
