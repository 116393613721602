import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

export const OrderStopType = {
  LoadUnload: 'LOAD_UNLOAD',
  Load: 'LOAD',
  Unload: 'UNLOAD',
  Unknown: 'UNKNOWN',
};

export const RequestStopType = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
};

export const stopTimeSlotPropTypes = PropTypes.shape({
  id: PropTypes.string,
  startMoment: MomentPropTypes.momentObj,
  endMoment: MomentPropTypes.momentObj,
});

export const stopPlacePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  companyOwnerId: PropTypes.string,
  personOwnerId: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  localNumber: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
});

export const stopPropTypes = PropTypes.shape({
  id: PropTypes.string,
  sequence: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isLoad: PropTypes.bool,
  isUnload: PropTypes.bool,
  timeSlot: stopTimeSlotPropTypes,
  place: stopPlacePropTypes,
});

export const stopsPropTypes = PropTypes.arrayOf(stopPropTypes);
