import { normalizeThreeStateBoolean } from './primitive.normalizer';

export function normalizePosition(position) {
  const newSpeed = parseFloat(position.speed) || null;
  const newLatitude = parseFloat(position.coordinateLatitude) || null;
  const newLongitude = parseFloat(position.coordinateLongitude) || null;
  const newIgnition = normalizeThreeStateBoolean(position.ignitionState);
  return {
    ...position,
    coordinateLatitude: newLatitude,
    coordinateLongitude: newLongitude,
    ignitionState: newIgnition,
    speed: newSpeed,
  };
}

export function normalizePositions(positions) {
  return positions.map(normalizePosition);
}

export function isValidPosition(position) {
  return position && (position.coordinateLongitude || position.lng) && (position.coordinateLatitude || position.lat);
}
