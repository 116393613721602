import axiosActions from '../axiosHelpers';
import {
  getCurrentTableConfigurationId, getSelectedCompany, getTableConfigurations, isLastUsedTableConfigurationCached,
  isTableConfigurationsCached,
} from '../../selectors';
import UrlService from '../../../services/UrlService';
import { toApiConfigurationModel } from '../../normalizers';
import { vehiclesWithDistinctLicence } from '../../../types/mappers';
import { isSuccess } from '../../../utils/responseUtils';
import { uuidv4 } from '../../../utils/uuid';

export const FETCH_TABLE_CONFIGURATIONS = axiosActions('TABLE_CONFIGURATIONS_LOAD');
export const FETCH_LAST_USED_TABLE_CONFIGURATION = axiosActions('LAST_USED_TABLE_CONFIGURATION_LOAD');
export const CREATE_TABLE_CONFIGURATION = axiosActions('CREATE_TABLE_CONFIGURATION_LOAD');
export const UPDATE_LAST_USED_TABLE_CONFIGURATION = axiosActions('UPDATE_LAST_USED_TABLE_CONFIGURATION_LOAD');
export const UPDATE_TABLE_CONFIGURATION = axiosActions('UPDATE_TABLE_CONFIGURATION_LOAD');
export const SET_CURRENT_TABLE_CONFIGURATION = 'SET_CURRENT_TABLE_CONFIGURATION';
export const OPEN_VEHICLES_FILTER_CREATION = 'OPEN_VEHICLES_FILTER_CREATION';
export const CLOSE_VEHICLES_FILTER_CREATION = 'CLOSE_VEHICLES_FILTER_CREATION';
export const DELETE_CONFIGURATION = axiosActions('DELETE_CONFIGURATION_LOAD');
export const ERASE_CONFIGURATION_DATA = 'ERASE_CONFIGURATION_DATA';

const fetchTableConfigurations = (tableName, companyId) => ({
  type: FETCH_TABLE_CONFIGURATIONS.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.tableConfigurations(tableName),
      params: {
        contextCompanyId: companyId,
      },
    },
  },
});

export const fetchTableConfigurationsOrCached = (tableName) => (dispatch, getState) => {
  const state = getState();
  const cached = isTableConfigurationsCached(tableName)(state);
  if (!cached) {
    const company = getSelectedCompany(state);
    return dispatch(fetchTableConfigurations(tableName, company));
  }

  const configurations = getTableConfigurations(tableName)(state);
  const cachedPayload = { data: configurations };
  return Promise.resolve({ type: FETCH_TABLE_CONFIGURATIONS.SUCCESS, payload: cachedPayload });
};

const fetchLastUsedTableConfiguration = (tableName, contextCompanyId) => ({
  type: FETCH_LAST_USED_TABLE_CONFIGURATION.START,
  payload: {
    actionId: uuidv4(),
    request: {
      url: UrlService.lastUsedConfiguration(tableName),
      params: {
        contextCompanyId,
      },
    },
  },
});

export const fetchLastUsedTableConfigurationOrCached = (tableName) => (dispatch, getState) => {
  const state = getState();
  const cached = isLastUsedTableConfigurationCached(tableName)(state);
  if (!cached) {
    const company = getSelectedCompany(state);
    return dispatch(fetchLastUsedTableConfiguration(tableName, company));
  }
  const configurationId = getCurrentTableConfigurationId(tableName)(state);
  const cachedBody = { data: { tableName, configurationId } };
  return Promise.resolve({ type: FETCH_LAST_USED_TABLE_CONFIGURATION.SUCCESS, payload: cachedBody });
};

export const setLastUsedTableConfiguration = (tableName, configurationId) => ({
  type: SET_CURRENT_TABLE_CONFIGURATION,
  payload: {
    data: {
      configurationId,
      tableName,
    },
  },
});

export const updateLastUsedTableConfiguration = (tableName, configurationId) => (dispatch, getState) => {
  dispatch(setLastUsedTableConfiguration(tableName, configurationId));
  const selectedCompany = getSelectedCompany(getState());
  return dispatch({
    type: UPDATE_LAST_USED_TABLE_CONFIGURATION.START,
    payload: {
      request: {
        method: 'put',
        url: UrlService.lastUsedConfiguration(tableName),
        params: {
          contextCompanyId: selectedCompany,
        },
        data: {
          configurationId,
        },
      },
    },
  });
};

export const createTableConfiguration = (tableName, tableConfiguration) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());
  return dispatch({
    type: CREATE_TABLE_CONFIGURATION.START,
    payload: {
      request: {
        method: 'post',
        url: UrlService.tableConfigurations(tableName),
        params: {
          contextCompanyId: selectedCompany,
        },
        data: toApiConfigurationModel(tableConfiguration),
      },
    },
  });
};

export const updateTableConfiguration = (tableName, tableConfiguration) => (dispatch, getState) => {
  const selectedCompany = getSelectedCompany(getState());

  return dispatch({
    type: UPDATE_TABLE_CONFIGURATION.START,
    payload: {
      request: {
        method: 'put',
        url: UrlService.tableConfiguration(tableName, tableConfiguration.id),
        params: {
          contextCompanyId: selectedCompany,
        },
        data: toApiConfigurationModel(tableConfiguration),
      },
    },
  });
};

export const openNewVehiclesFilter = (selectedVehicles, editConfiguration, setAsCurrent) => ({
  type: OPEN_VEHICLES_FILTER_CREATION,
  payload: {
    selectedVehicles: vehiclesWithDistinctLicence(selectedVehicles),
    editConfiguration,
    setAsCurrent,
  },
});

export const closeNewVehiclesFilter = ({ resetSelection } = { resetSelection: false }) => ({
  type: CLOSE_VEHICLES_FILTER_CREATION,
  payload: {
    resetSelection,
  },
});

const eraseConfigurationData = (tableName, configurationId) => (dispatch, getState) => {
  const state = getState();
  const currentConfigurationId = getCurrentTableConfigurationId(tableName)(state);

  dispatch({
    type: ERASE_CONFIGURATION_DATA,
    payload: { tableName, configurationId },
  });
  if (configurationId === currentConfigurationId) {
    dispatch(updateLastUsedTableConfiguration(tableName, null));
  }
};

export const deleteConfiguration = (tableName, configurationId) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  return dispatch({
    type: DELETE_CONFIGURATION.START,
    payload: {
      request: {
        method: 'delete',
        url: UrlService.tableConfiguration(tableName, configurationId),
        params: {
          contextCompanyId,
        },
      },
    },
  }).then((response) => {
    if (isSuccess(response)) {
      dispatch(eraseConfigurationData(tableName, configurationId));
    }
  });
};
