import LocationService from '../../services/map/LocationService';
import axiosActionsFactory from './axiosHelpers';

export const FETCH_LOCATION = axiosActionsFactory('LOCATION_LOAD');
export const RESET_LOCATION = 'RESET_LOCATION';
export const RESET_ALL_LOCATIONS = 'RESET_ALL_LOCATIONS';

function startFetchLocation(vehicleId) {
  return {
    type: FETCH_LOCATION.START,
    payload: {
      vehicleId,
    },
  };
}

function failFetchLocation(vehicleId) {
  return {
    type: FETCH_LOCATION.FAIL,
    payload: {
      vehicleId,
    },
  };
}

function successFetchLocation(vehicleId, coordinates, location) {
  return {
    type: FETCH_LOCATION.SUCCESS,
    payload: {
      vehicleId,
      coordinates,
      location,
    },
  };
}

export const fetchLocation = (vehicleId, coordinates) => (dispatch) => {
  dispatch(startFetchLocation(vehicleId));

  return new Promise((res, rej) => LocationService.fetchLocationForCoordinates(coordinates,
    (location) => {
      dispatch(successFetchLocation(vehicleId, coordinates, location));
      res(location);
    },
    () => {
      dispatch(failFetchLocation(vehicleId));
      rej();
    }));
};

export const resetLocation = (vehicleId) => ({
  type: RESET_LOCATION,
  payload: {
    vehicleId,
  },
});

export const resetAllLocations = () => ({
  type: RESET_ALL_LOCATIONS,
});
