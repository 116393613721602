/* eslint-disable max-len */
import * as moment from 'moment';
import { copyIcon } from '../../assets/icons';
import BreakIcons from '../../assets/breakIcons';
import ParkingIcons from '../../assets/parkingIcons';
import PinIcons from '../../assets/pinIcons';
import StopIcons from '../../assets/stopTypeIcons';
import TimeIcons from '../../assets/timeIcons';
import theme from '../../../../theme';
import { toElapsedFriendlyString } from '../../../../utils/dateTimeUtils';
import { cleanSpeed, getSpeedUnitKey } from '../../../../utils/speed';
import nth from '../../../../utils/nth';

const addressLine1 = (content) => `<p class="H_bubble_content--label" style="margin: 0">${content}</p>`;
const addressLine2 = (content) => `<p class="H_bubble_content--label H_bubble_content--highlighted-value" style="margin: 0">${content}</p>`;

const copyButton = ({ lat, lng }) => `<button class="H_bubble_copy_button ant-btn ant-btn-sm ant-btn-icon-only ant-btn-link" onclick="window.copyAndNotify('${lat},${lng}')">`
+ `  <span class="anticon">${copyIcon}</span>`
+ '</button>';

export const lastUpdateSection = (t, { lastUpdate }) => {
  const lastUpdateDateTime = moment.unix(lastUpdate);
  return '<div style="display:flex;margin-top:8px;line-height:12px">'
    + `  <span class="H_bubble_content--section-icon">${TimeIcons.lastUpdate}</span>`
    + '  <div class="H_bubble_content--info-data">'
    + `    <p class="H_bubble_content--label H_bubble_content--highlighted-value" style="margin:0">${t('PROPERTIES.LAST_UPDATE')}:</p>`
    + `    <span class="H_bubble_content--data">${lastUpdateDateTime ? lastUpdateDateTime.format('DD-MM-YYYY, HH:mm') : '–'}</span>`
    + `    <span class="H_bubble_content--data">${lastUpdateDateTime ? toElapsedFriendlyString(lastUpdateDateTime, t) : ''}</span>`
    + '  </div>'
    + '</div>';
};

export const speedSection = (t, { speed }) => '<div style="display:flex;margin-top:8px;line-height:12px">'
    + `  <span class="H_bubble_content--section-icon">${TimeIcons.speed}</span>`
    + '  <div class="H_bubble_content--info-data" style="margin-top:0">'
    + `    <span class="H_bubble_content--data">${cleanSpeed(speed)} ${t(getSpeedUnitKey())}</span>`
    + '  </div>'
    + '</div>';

export const locationDetailsSection = (t, { location, noCopy = false }) => {
  const { lat, lng } = { lat: location.position.latitude, lng: location.position.longitude };
  const street = location.street ? location.street : '';
  const postalCode = (location.postcode || location.city || location.countryCode)
    ? `${`${location.countryCode},` || ''} ${location.city || ''} ${location.postcode || ''}` : t('PROPERTIES.GPS_ADDRESS_NOT_AVAILABLE');

  return '<div style="display:flex;margin-top:8px">'
  + `  <span class="H_bubble_content--section-icon">${PinIcons.pinThin.replace(/__FILL_COLOR__/g, theme.color.routeStroke)}</span>`
  + '  <div class="H_bubble_content--info-data">'
  + `    ${addressLine1(street)}`
  + `    ${addressLine2(postalCode)}`
  + '    <div class="H_bubble_content--coords">'
  + `      <p class="H_bubble_content--label">${+parseFloat(lat)},<br>${+parseFloat(lng)}</p>`
  + `      ${noCopy ? '' : copyButton({ lat, lng })}`
  + '    </div>'
  + '  </div>'
  + '</div>';
};

export const pauseDetailsSection = (t, {
  duration, startTimestamp, endTimestamp, type,
}) => {
  const durationMoment = moment.duration(duration, 's');
  const asMinutes = Math.floor(durationMoment.asMinutes());
  const minutes = asMinutes % 60;
  const hours = (asMinutes - minutes) / 60;
  const durationString = `${hours}h ${minutes}min`;
  const from = moment.unix(startTimestamp);
  const to = moment.unix(endTimestamp);
  const icon = (type === 'BREAK' ? BreakIcons.basic() : ParkingIcons.basic()).replace(/__SIZE__/g, '21');

  return '<div style="display:flex;margin-top:4px">'
    + `  ${icon}`
    + '  <div class="H_bubble_content--info-data">'
    + '    <p class="H_bubble_content--label" style="margin-top:0">'
    + `      ${t(type === 'PARKING' ? 'PROPERTIES.PAUSE_DURATION' : 'PROPERTIES.BREAK_DURATION')}`
    + `      <span class="H_bubble_content--highlighted-value">${durationString}</span>`
    + '    </p>'
    + '    <div style="display:flex;margin-top:4px">'
    + '      <div>'
    + `        <p class="H_bubble_content--data">${t('PROPERTIES.FROM')}</p>`
    + `        <p class="H_bubble_content--data">${t('PROPERTIES.TO')}</p>`
    + '      </div>'
    + '      <div style="margin-left:8px">'
    + '        <p class="H_bubble_content--data">'
    + `          ${from ? from.format('DD-MM-YYYY') : '–'} <b>${from ? from.format('HH:mm') : ''}</b>`
    + '        </p>'
    + '        <p class="H_bubble_content--data">'
    + `          ${to ? to.format('DD-MM-YYYY') : '–'} <b>${to ? to.format('HH:mm') : ''}</b>`
    + '        </p>'
    + '      </div>'
    + '    </div>'
    + '  </div>'
    + '</div>';
};

export const stopDetailsSection = (t, {
  sequence, from, to, type,
}) => {
  const icon = StopIcons.stopTypeToIcon(type).replace(/__FILL_COLOR__/g, theme.color.stop.normal)
    .replace(/__SIZE__/g, 21);
  const index = sequence + nth(sequence);
  return '<div style="display:flex;margin-top:4px">'
    + `  ${icon}`
    + '  <div class="H_bubble_content--info-data">'
    + '    <p class="H_bubble_content--label" style="margin-top:0">'
    + `      <span class="H_bubble_content--highlighted-value">${t('ORDER.STOP_INDEX', { index })}</span>`
    + '    </p>'
    + '    <div style="display:flex;margin-top:4px">'
    + '      <div>'
    + `        <p class="H_bubble_content--data">${t('PROPERTIES.FROM')}</p>`
    + `        <p class="H_bubble_content--data">${t('PROPERTIES.TO')}</p>`
    + '      </div>'
    + '      <div style="margin-left:8px">'
    + '        <p class="H_bubble_content--data">'
    + `          ${from ? from.format('DD-MM-YYYY') : '–'} <b>${from ? from.format('HH:mm') : ''}</b>`
    + '        </p>'
    + '        <p class="H_bubble_content--data">'
    + `          ${to ? to.format('DD-MM-YYYY') : '–'} <b>${to ? to.format('HH:mm') : ''}</b>`
    + '        </p>'
    + '      </div>'
    + '    </div>'
    + '  </div>'
    + '</div>';
};
