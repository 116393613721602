import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Header, SidePanel } from '../components';
import Home from './HomeContent';

const FullScreenLayout = styled(Layout)`
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
`;

const CoreLayout = ({ theme }) => (
  <FullScreenLayout>
    <SidePanel theme={theme} />
    <Layout style={{ minWidth: '1280px' }}>
      <Header />
      <Home />
    </Layout>
  </FullScreenLayout>
);

CoreLayout.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

CoreLayout.defaultProps = {
  theme: 'light',
};

export default CoreLayout;
