import styled from 'styled-components';
import { Popover } from 'antd';
import { ActionButton } from '../../../../components/Styled/Custom';

export const MoreButton = styled(ActionButton)`
  font-size: 20px !important;
`;

export const ShowOnMapButton = styled(ActionButton)`
  font-size: 18px !important;
`;

export const MoreActionsPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 8px;
  }
`;
