import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { info } from '../../services/Logging';
import { getActiveOrdersType, getSelectedCompany, TableName } from '../../store/selectors';
import * as orderActionCreators from '../../store/actions/orders';
import * as personalizationActionCreators from '../../store/actions/personalization';
import {
  ordersResponsePropTypes, OrderContext, OrderType, sortingPropTypes,
} from '../../types';
import OrdersTableHeader from './Components/OrdersTableHeader';
import { toTableName } from '../../types/mappers/orders.mapper';

class OrderTypeContentHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { intervalId: 0 };
    this.windowResizeListener = () => this.setState({ });
    this.bind();
  }

  componentDidMount() {
    this.fetchViewSetUp();
    window.addEventListener('resize', this.windowResizeListener);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intervalId } = this.state;
    const prevCompany = prevProps.selectedCompany;

    if (this.shouldRestartFetchingLoop(prevProps)) {
      clearInterval(intervalId);
      if (this.isNotPrevCompany(prevCompany)) {
        this.fetchTableConfiguration();
      }
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    window.removeEventListener('resize', this.windowResizeListener);
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
  }

  onUpdateRefreshInterval(val) {
    const { orderContext, updateRefreshIntervalSeconds } = this.props;
    updateRefreshIntervalSeconds(toTableName(orderContext), val);
  }

  shouldRestartFetchingLoop(prevProps) {
    const {
      activeType, sorting,
    } = this.props;
    const prevCompany = prevProps.selectedCompany;
    const prevRefreshIntervalSeconds = prevProps.refreshIntervalSeconds;
    const prevActiveType = prevProps.activeType;
    const prevSorting = prevProps.sorting;
    return (this.isNotPrevCompany(prevCompany)
      || prevSorting !== sorting
      || (this.isAutomatic(prevRefreshIntervalSeconds))
      || prevActiveType !== activeType);
  }

  isNotPrevCompany(prevCompany) {
    const {
      selectedCompany,
    } = this.props;
    return selectedCompany !== prevCompany;
  }

  isAutomatic(prevRefreshIntervalSeconds) {
    const {
      refreshIntervalSeconds,
    } = this.props;
    return refreshIntervalSeconds && !prevRefreshIntervalSeconds;
  }

  bind() {
    this.clearFiltersAndRefresh = this.clearFiltersAndRefresh.bind(this);
    this.updateFiltersAndRefresh = this.updateFiltersAndRefresh.bind(this);
    this.startFetchingLoop = this.startFetchingLoop.bind(this);
    this.onUpdateRefreshInterval = this.onUpdateRefreshInterval.bind(this);
  }

  fetchViewSetUp() {
    const {
      orderContext, fetchLastUsedInterfaceConfiguration,
    } = this.props;

    return fetchLastUsedInterfaceConfiguration(toTableName(orderContext));
  }

  startFetchingLoop(isAutomatic = false) {
    const {
      fetchOrders, orderContext, orderType, refreshIntervalSeconds, selectedCompany,
    } = this.props;
    const { intervalId } = this.state;

    if (!selectedCompany) {
      clearInterval(intervalId);
      return;
    }

    clearInterval(intervalId);
    if (refreshIntervalSeconds) {
      info(`Scheduling orders refresh in: ${refreshIntervalSeconds * 1000}ms`);
      const newIntervalId = setTimeout(() => {
        this.startFetchingLoop(true);
      }, refreshIntervalSeconds * 1000);
      this.setState({ intervalId: newIntervalId });
    }

    if (!isAutomatic || refreshIntervalSeconds) {
      fetchOrders(isAutomatic, orderContext, orderType);
    }
  }

  updateFilters(filter) {
    const { mergeUpdateInterfaceFilters, orderContext } = this.props;
    mergeUpdateInterfaceFilters(orderContext === OrderContext.IN
      ? TableName.OrdersReceived : TableName.OrdersSent, filter);
  }

  updateFiltersAndRefresh(filter) {
    const { resetOrdersPagination } = this.props;
    this.updateFilters(filter);
    resetOrdersPagination();
    this.startFetchingLoop();
  }

  clearFiltersAndRefresh() {
    const { clearInterfaceFiltersExcept, resetOrdersPagination, orderContext } = this.props;
    clearInterfaceFiltersExcept(orderContext === OrderContext.IN ? TableName.OrdersReceived : TableName.OrdersSent);
    resetOrdersPagination();
    this.startFetchingLoop();
  }

  render() {
    const {
      filters, orders, interfaceLoading, refreshIntervalSeconds,
    } = this.props;

    return (
      <>
        <OrdersTableHeader
          lastUpdated={orders.lastUpdated}
          interfaceLoading={interfaceLoading}
          ordersLoading={orders.loading}
          refreshIntervalSeconds={refreshIntervalSeconds}
          filters={filters}
          clearFilters={this.clearFiltersAndRefresh}
          updateFilters={this.updateFiltersAndRefresh}
          onRefresh={this.startFetchingLoop}
          onUpdateInterval={this.onUpdateRefreshInterval}
        />
      </>
    );
  }
}

OrderTypeContentHeaderComponent.propTypes = {
  orderContext: PropTypes.oneOf(Object.values(OrderContext)).isRequired,
  orderType: PropTypes.oneOf(Object.values(OrderType)),
  sorting: sortingPropTypes.isRequired,
  orders: ordersResponsePropTypes.isRequired,
  interfaceLoading: PropTypes.bool.isRequired,
  refreshIntervalSeconds: PropTypes.number,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),

  activeType: PropTypes.string.isRequired,
  selectedCompany: PropTypes.string.isRequired,

  resetOrdersPagination: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  fetchLastUsedInterfaceConfiguration: PropTypes.func.isRequired,
  updateRefreshIntervalSeconds: PropTypes.func.isRequired,
  mergeUpdateInterfaceFilters: PropTypes.func.isRequired,
  clearInterfaceFiltersExcept: PropTypes.func.isRequired,
};

OrderTypeContentHeaderComponent.defaultProps = {
  orderType: OrderType.TIME,
  refreshIntervalSeconds: 0,
  filters: {},
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  activeType: getActiveOrdersType(state),
});

const mapDispatchToProps = {
  fetchLastUsedInterfaceConfiguration: personalizationActionCreators.fetchLastUsedInterfaceConfiguration,
  updateRefreshIntervalSeconds: personalizationActionCreators.updateRefreshInterval,
  mergeUpdateInterfaceFilters: personalizationActionCreators.mergeUpdateInterfaceFilters,
  clearInterfaceFiltersExcept: personalizationActionCreators.clearInterfaceFiltersExcept,
  resetOrdersPagination: orderActionCreators.resetOrdersPagination,
  setOrdersSorting: orderActionCreators.setOrdersSorting,
  fetchOrders: orderActionCreators.fetchOrders,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderTypeContentHeaderComponent));
