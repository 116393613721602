export const tableConfigsById = (configs) => {
  const map = {};
  if (configs) {
    configs.forEach((config) => {
      map[config.id] = config;
    });
  }
  return map;
};

const fromApiTableConfigurationFilters = (filters) => {
  const result = {};
  if (filters) {
    filters.forEach(({ fieldName, values }) => {
      result[fieldName] = values;
    });
  }
  return result;
};

export const fromApiTableConfigurationModel = (config) => ({
  ...config,
  filters: fromApiTableConfigurationFilters(config.filters),
});

const toApiConfigurationFilters = (
  filters,
) => filters && Object.entries(filters).map(([fieldName, values]) => ({ fieldName, values }));

export const toApiConfigurationModel = (config) => ({
  ...config,
  tableConfigurationId: config.id,
  filters: toApiConfigurationFilters(config.filters),
});
