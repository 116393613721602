import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import { areFeatureTogglesFetched, getSelectedCompany } from '../../store/selectors';
import { Loader } from '../../components';
import Sharing from './Sharing';

const renderContent = (selectedCompany, match) => (
  !selectedCompany ? <SelectCompanyFirstComponent /> : (
    <>
      <Route
        exact
        path={`${match.url}`}
        component={Sharing}
      />
    </>
  )
);

export default () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const selectedCompany = useSelector(getSelectedCompany);
  const featureTogglesFetched = useSelector(areFeatureTogglesFetched);

  return (
    <>
      <BreadcrumbsItem to="/">
        {t('BREADCRUMB.FLEET')}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="/sharings">
        {t('BREADCRUMB.FLEET_SHARINGS')}
      </BreadcrumbsItem>
      {!featureTogglesFetched ? <Loader /> : renderContent(selectedCompany, match)}
    </>
  );
};
