import {
  FETCH_LAST_USED_TABLE_CONFIGURATION, setLastUsedTableConfiguration, UPDATE_LAST_USED_TABLE_CONFIGURATION,
} from '../personalization';
import { UrlService } from '../../../services';
import {
  getSelectedCompany,
  TableName,
} from '../../selectors';
import { uuidv4 } from '../../../utils/uuid';

export const COMPANY_INITIALIZED = 'COMPANY_INITIALIZED';
export const RESET_COMPANY_CONTEXT = 'RESET_COMPANY_CONTEXT';

export const fetchLastUsedCompany = () => async (dispatch) => {
  await dispatch({
    type: FETCH_LAST_USED_TABLE_CONFIGURATION.START,
    payload: {
      actionId: uuidv4(),
      request: {
        url: UrlService.lastUsedConfiguration(TableName.SelectedCompany),
      },
    },
  });
  dispatch({
    type: COMPANY_INITIALIZED,
  });
};

export const changeCompanyContext = (companyId) => async (dispatch, getState) => {
  const state = getState();
  const selectedCompany = getSelectedCompany(state);
  if (selectedCompany && selectedCompany.id === companyId) {
    return;
  }

  dispatch({
    type: RESET_COMPANY_CONTEXT,
  });
  await Promise.all([
    dispatch(setLastUsedTableConfiguration(TableName.SelectedCompany, companyId)),
    dispatch({
      type: UPDATE_LAST_USED_TABLE_CONFIGURATION.START,
      payload: {
        request: {
          method: 'put',
          url: UrlService.lastUsedConfiguration(TableName.SelectedCompany),
          data: {
            configurationId: companyId,
          },
        },
      },
    }),
  ]);
  dispatch(fetchLastUsedCompany());
};
