export const ERROR_CODE = {
  TELEMATIC_PROVIDER_ACCOUNT_EXISTS: 'TELEMATIC_PROVIDER_ACCOUNT_EXISTS',
  MONITORING_ORDER_SNAPSHOT_NOT_FOUND: 'MONITORING_ORDER_SNAPSHOT_NOT_FOUND',
  ROUTE_POINT_NOT_AVAILABLE_YET: 'ROUTE_POINT_NOT_AVAILABLE_YET',
  ROUTE_POINT_NOT_AVAILABLE: 'ROUTE_POINT_NOT_AVAILABLE',
  ROUTE_POINT_UNABLE_TO_CALCULATE: 'ROUTE_POINT_UNABLE_TO_CALCULATE',
  PROVIDER_ACCOUNT_BAD_CREDENTIALS: 'PROVIDER_ACCOUNT_BAD_CREDENTIALS',
  SCANIA_VAT_NUMBER_NOT_FOUND: 'SCANIA_VAT_NUMBER_NOT_FOUND',
  CONNECTIVITY_AGREEMENT_NOT_SIGNED: 'CONNECTIVITY_AGREEMENT_NOT_SIGNED',
  ESTIMATION_EXCEPTION: 'ESTIMATION_EXCEPTION',
};

const ERROR_STATUS = {
  GATEWAY_TIMEOUT: 504,
};

const IGNORED_ERROR_CODES = [
  ERROR_CODE.TELEMATIC_PROVIDER_ACCOUNT_EXISTS,
  ERROR_CODE.MONITORING_ORDER_SNAPSHOT_NOT_FOUND,
  ERROR_CODE.ROUTE_POINT_NOT_AVAILABLE_YET,
  ERROR_CODE.ROUTE_POINT_NOT_AVAILABLE,
  ERROR_CODE.PROVIDER_ACCOUNT_BAD_CREDENTIALS,
  ERROR_CODE.SCANIA_VAT_NUMBER_NOT_FOUND,
  ERROR_CODE.CONNECTIVITY_AGREEMENT_NOT_SIGNED,
  ERROR_CODE.ESTIMATION_EXCEPTION,
];

const IGNORED_ERROR_STATUSES = [ERROR_STATUS.GATEWAY_TIMEOUT];

export const isIgnoredErrorCode = (errorCode) => IGNORED_ERROR_CODES.includes(errorCode);

export const isIgnoredErrorStatus = (errorStatus) => IGNORED_ERROR_STATUSES.includes(errorStatus);
