import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const TELEMATIC_ACCOUNT_SUPPORT_REQUIRED = axiosActionsFactory('TELEMATIC_ACCOUNT_SUPPORT_REQUIRED');

export const submitTelematicAccountSupportRequired = (payload, contextCompanyId) => (dispatch) => dispatch({
  type: TELEMATIC_ACCOUNT_SUPPORT_REQUIRED.START,
  payload: {
    request: {
      url: UrlService.telematicAccountSupportRequired(),
      method: 'post',
      data: payload,
      params: { contextCompanyId },
    },
  },
});
