import PropTypes from 'prop-types';
import { hereLocationTypeProp } from './dictionaries';

const hereLocationAddressPropType = PropTypes.shape({
  city: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  county: PropTypes.string,
  houseNumber: PropTypes.string,
  label: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
});

const hereLocationMapViewPropType = PropTypes.shape({
  east: PropTypes.number,
  north: PropTypes.number,
  south: PropTypes.number,
  west: PropTypes.number,
});

const hereLocationPositionPropType = PropTypes.shape({
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const hereLocationPropType = PropTypes.shape({
  items: PropTypes.arrayOf(PropTypes.shape({
    access: PropTypes.arrayOf(hereLocationPositionPropType),
    address: hereLocationAddressPropType,
    distance: PropTypes.number,
    houseNumberType: PropTypes.string,
    mapView: hereLocationMapViewPropType,
    position: hereLocationPositionPropType,
    resultType: hereLocationTypeProp,
    title: PropTypes.string,
  })),
});
