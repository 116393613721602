import H from '@here/maps-api-for-javascript';
import config from '../../config';

const createPlatform = new H.service.Platform({
  apikey: config.hereMaps.apiKey,
});

export default {
  getSearchService: () => createPlatform.getSearchService(),
  createDefaultLayers: () => createPlatform.createDefaultLayers(),
};
