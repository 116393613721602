import Keycloak from 'keycloak-js';
import { error, info } from './Logging';
import config from '../config';

const queryParams = {
  SUCCESS_SCREEN: ['successScreen'],
};

const kc = new Keycloak(config.keycloak.core);

const doLogin = kc.login;

const updateToken = (successCallback) => kc.updateToken(30)
  .then(successCallback)
  .catch(doLogin);

const redirectToSuccessScreen = () => {
  const { hash, search } = window.location;
  const searchParams = new URLSearchParams(search === '' ? hash.substring(hash.indexOf('?')) : search);
  if (Object.values(queryParams).every((t) => searchParams.has(t))) {
    const successScreen = searchParams.get(queryParams.SUCCESS_SCREEN);
    window.history.pushState(
      null,
      null,
      `${config.keycloak.init.redirectUri}/#/vehicles?successScreen=${successScreen}`,
    );
  }
};

const initKeycloak = () => new Promise((resolve, reject) => {
  kc.init({
    ...config.keycloak.init,
  })
    .then((isAuthenticated) => {
      if (!isAuthenticated) {
        error('Not authenticated, redirecting');
        doLogin({ redirectUri: window.location });
        reject();
      } else {
        kc.onTokenExpired = () => updateToken((result) => info(`Token refresh status: ${result}`));
        redirectToSuccessScreen();
        resolve();
      }
    });
});

const doLogout = () => {
  kc.logout();
};

const getToken = () => kc.token;

export default {
  initKeycloak,
  doLogout,
  getToken,
  updateToken,
};
