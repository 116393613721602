import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FullSpace } from '../../Styled';

export const SpinnerIcon = styled(LoadingOutlined)`
  ${({ size }) => {
    switch (size) {
      case 'l':
        return 'font-size: 48px';
      case 'm':
        return 'font-size: 36px';
      default:
        return 'font-size: 24px';
    }
  }} 
`;

const LoadingText = styled.span`
  padding-left: 10px;
  ${({ size }) => {
    switch (size) {
      case 'l':
        return 'font-size: 24px';
      case 'm':
        return 'font-size: 20px';
      default:
        return 'font-size: 18px';
    }
  }} 
`;

const Loader = ({ size, text, height = '500px' }) => (
  <FullSpace height={height}>
    <Spin
      indicator={(
        <SpinnerIcon
          size={size}
          spin
        />
)}
    />
    <LoadingText size={size}>
      {text}
    </LoadingText>
  </FullSpace>
);

Loader.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  text: PropTypes.string,
  height: PropTypes.string,
};

Loader.defaultProps = {
  height: '500px',
  size: 'm',
  text: 'Loading',
};

export default Loader;
