import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

export const NotFoundPageResult = styled(Result)`
 .ant-result-extra {
    margin: 0 0 0 0;
    text-align: center;
  }
`;

export const NotFoundImg = styled.img`
`;

export default () => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToFleet = () => history.push('/vehicles');

  return (
    <NotFoundPageResult
      title={t('ERROR.404.MESSAGE')}
      extra={(
        <Button
          type="link"
          onClick={goToFleet}
        >
          {t('ERROR.BACK_HOME')}
        </Button>
      )}
      icon={<NotFoundImg src="/images/404.png" alt="404" />}
    />
  );
};
