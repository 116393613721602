import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import {
  Col, Radio, Row, Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { NodeIndexOutlined } from '@ant-design/icons';
import { MapModeSwitch } from '../VehicleDetails.styled';
import { HISTORY_MODE, LAST_LOCATION_MODE } from '../VehicleDetails.constants';
import HistoryRangePickerComponent from './HistoryRangePicker/HistoryRangePickerComponent';
import { GoBackButton, GoBackDivider } from '../../../components';

const VehicleDetailsExtrasBar = ({
  mapMode, historyRange, onMapModeChange, onHistoryRangeChange, size, changePropableRoute, propableRoute,
}) => {
  const { t } = useTranslation();

  return (
    <Row align="middle" gutter={[0, 8]} justify="flex-start">
      <Col>
        <GoBackButton size={size} />
        <GoBackDivider type="vertical" />
      </Col>
      <Col>
        <MapModeSwitch
          value={mapMode}
          onChange={onMapModeChange}
          size={size}
        >
          <Radio.Button value={LAST_LOCATION_MODE}>{t('PROPERTIES.LAST_LOCATION')}</Radio.Button>
          <Radio.Button value={HISTORY_MODE}>{t('PROPERTIES.HISTORY')}</Radio.Button>
        </MapModeSwitch>
      </Col>
      <Col>
        <HistoryRangePickerComponent
          loading={false}
          hidden={mapMode !== HISTORY_MODE}
          range={historyRange}
          onRangeChange={onHistoryRangeChange}
          size={size}
        />
      </Col>
      <Col>
        {t('PROPERTIES.SHOW_HIDE_ROUTE')}
        <Switch
          type="primary"
          disabled={mapMode === LAST_LOCATION_MODE}
          checked={mapMode === HISTORY_MODE && !propableRoute}
          size={size}
          onClick={() => {
            changePropableRoute();
          }}
          icon={<NodeIndexOutlined />}
        />
      </Col>
    </Row>
  );
};

VehicleDetailsExtrasBar.propTypes = {
  mapMode: PropTypes.oneOf([LAST_LOCATION_MODE, HISTORY_MODE]).isRequired,
  historyRange: PropTypes.arrayOf(MomentPropTypes.momentObj),
  onHistoryRangeChange: PropTypes.func.isRequired,
  onMapModeChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  changePropableRoute: PropTypes.func.isRequired,
  propableRoute: PropTypes.bool.isRequired,
};

VehicleDetailsExtrasBar.defaultProps = {
  size: 'small',
  historyRange: null,
};

export default VehicleDetailsExtrasBar;
