import React from 'react';
import { Button } from 'antd';
import { SearchDropdownContent, VerticalCheckboxGroup } from './FilterDropdown.styled';

const SelectFilterDropdown = (columnName, options, setFilters, t, highlight) => ({
  // eslint-disable-next-line react/prop-types
  setSelectedKeys, selectedKeys, confirm, clearFilters,
}) => {
  const setFilterValue = (value) => {
    setFilters({ [columnName]: value });
  };

  const handleSearch = () => {
    setFilterValue(selectedKeys);
    if (!highlight) {
      confirm();
    }
  };

  const handleReset = () => {
    setFilterValue(null);
    if (!highlight) {
      clearFilters();
    }
  };

  return (
    <SearchDropdownContent>
      <VerticalCheckboxGroup
        options={options.map((option) => ({ label: t(option.label), value: option.value }))}
        value={selectedKeys}
        onChange={(e) => setSelectedKeys(e)}
      />
      <div className="ant-table-filter-dropdown-btns">
        <Button
          onClick={() => handleReset(setFilterValue, clearFilters)}
          size="small"
          type="link"
          disabled={!selectedKeys[0]}
        >
          {t('COMMON.RESET')}
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch(setFilterValue, selectedKeys, confirm)}
          size="small"
        >
          {t('COMMON.OK')}
        </Button>
      </div>
    </SearchDropdownContent>
  );
};

export default SelectFilterDropdown;
