import React from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

export const ActionButton = styled(Button)`
  text-align: left;
  font-size: 20px;
  span {
    font-size: inherit !important;
  }
`;

export const CellButton = styled(Button)`
  padding: 0;
`;

export const StyledButtonGoBack = styled(Button)`
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 24px;
`;

export const GoBackDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.color.darkgray1};
  margin-right: 30px;
`;

const IntHeaderButtonsDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.color.darkgray2};
  margin: 0 0 0 10px;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const HeaderButtonsDivider = (props) => (<IntHeaderButtonsDivider type="vertical" {...props} />);

export const GoBackButton = ({ size, onClick, text }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <StyledButtonGoBack
      icon={<ArrowLeftOutlined />}
      type="link"
      onClick={onClick || (() => history.goBack())}
      size={size}
    >
      {t(text)}
    </StyledButtonGoBack>
  );
};

GoBackButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

GoBackButton.defaultProps = {
  size: 'small',
  onClick: null,
  text: 'COMMON.GO_BACK',
};

export const CloseSelectionButton = styled(Button)`
  margin-left: 11px !important;
`;
