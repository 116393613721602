import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FlexRightSpaced } from '../../components/Styled/Containers';
import { loadInvitedCarriers } from '../../store/actions/carriers';
import config from '../../config';
import { getMeId, getSelectedCompany } from '../../store/selectors';

const ReloadButton = styled(Button)`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: ${(props) => (props.compact ? '10px !important' : '12px')};
  padding-top: 0;
`;

const InvitationText = styled.p`
  /* p14 */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* Gray 2 */
  color: #4F4F4F;
  opacity: 0.8;
`;

// const invitationLink = config.invitation.link;

const CarrierHeaderComponent = ({
  size, withRefresh,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCompany = useSelector(getSelectedCompany);
  const userId = useSelector(getMeId);

  window.copyAndNotify = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success(t('COMMON.COPIED_TO_CLIPBOARD')));
  };

  const copyInvitationLinkPernament = () => {
    window.copyAndNotify(`${config.invitation.link}/perm-invite?companyId=${selectedCompany}&userId=${userId}`);
  };

  const copyInvitationLinkOrders = () => {
    window.copyAndNotify(`${config.invitation.link}/invite?companyId=${selectedCompany}&userId=${userId}`);
  };

  const onRefresh = () => {
    dispatch(loadInvitedCarriers(false));
  };

  return (
    <>
      <FlexRightSpaced>
        <InvitationText style={{ marginTop: '15px' }}>{t('PROPERTIES.INVITATION_HELP_TEXT')}</InvitationText>
        <Button
          type="primary"
          size={size}
          icon={<CopyOutlined />}
          onClick={copyInvitationLinkOrders}
        >
          {t('PROPERTIES.INVITATION_LINK_ORDERS')}
        </Button>
        <Button
          type="primary"
          size={size}
          icon={<CopyOutlined />}
          onClick={copyInvitationLinkPernament}
        >
          {t('PROPERTIES.INVITATION_LINK_PERNAMENT')}
        </Button>
        {withRefresh?.enabled
          ? (
            <ReloadButton
              size={withRefresh.compact ? 'medium' : size}
              type={withRefresh.compact ? 'link' : null}
              icon={<ReloadOutlined />}
              onClick={onRefresh}
              compact={withRefresh.compact ? 'compact' : null}
            />
          ) : null}
      </FlexRightSpaced>
    </>
  );
};

CarrierHeaderComponent.propTypes = {
  size: PropTypes.string,
  withRefresh: PropTypes.shape({
    onRefresh: PropTypes.func.isRequired,
    enabled: PropTypes.bool,
    loading: PropTypes.bool,
    compact: PropTypes.bool,
  }),
};

CarrierHeaderComponent.defaultProps = {
  size: 'small',
  withRefresh: null,
};

export default CarrierHeaderComponent;
