import React from 'react';
import {
  Row, Col, Steps, Divider,
} from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CircleStatus } from './CircleStatus';

export const Header = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    color: #333333;
`;

export const Description = styled.span`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;

/* or 133% */

/* Gray 1 */
color: #333333;

opacity: 0.5;
`;

const StatusInfo = styled.span`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;

/* identical to box height */

/* Gray 1 */
color: #333333;
`;

const { Step } = Steps;

export const StatusLegend = () => {
  const { t } = useTranslation();

  const title = (key) => (<><Header>{t(key)}</Header></>);

  const description = (key) => (<><Description>{t(key)}</Description></>);

  return (
    <>
      <Row>
        <Col sm={4}>
          <Description>{t('ONB_STATUS.UPDATE_OF_STATUSES_LABEL')}</Description>
        </Col>
        <Col sm={20}>
          <StatusInfo>{t('ONB_STATUS.UPDATE_OF_STATUSES_INFO')}</StatusInfo>
        </Col>
      </Row>
      <Row>
        <Col sm={24}><Divider /></Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Description>{t('ONB_STATUS.CUSTOMER_CARE_LABEL')}</Description>
        </Col>
        <Col sm={20}>
          <a href={`mailto: ${t('SUCCESS_MODAL_INVITATION.EMAIL')}`}>{t('SUCCESS_MODAL_INVITATION.EMAIL')}</a>
        </Col>
      </Row>
      <Row>
        <Col sm={24}><Divider /></Col>
      </Row>
      <Row>
        <Col>
          <Steps direction="vertical" current={-1}>
            <Step
              icon={<><CircleStatus text="1" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.REGISTRATION_IN_PROGRESS')}
              description={description('ONB_STATUS.REGISTRATION_IN_PROGRESS_LABEL')}
            />

            <Step
              icon={<><CircleStatus text="2" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.REGISTERED')}
              description={description('ONB_STATUS.REGISTERED_LABEL')}
            />

            <Step
              icon={<><CircleStatus text="3" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.API_CREDENTIALS_GATHERING')}
              description={description('ONB_STATUS.API_CREDENTIALS_GATHERING_LABEL')}
            />

            <Step
              icon={<><CircleStatus text="4" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.CO3_PROVIDER_INTEGRATION')}
              description={description('ONB_STATUS.CO3_PROVIDER_INTEGRATION_LABEL')}
            />

            <Step
              icon={<><CircleStatus text="5" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.ONBOARDING_FLEET')}
              description={description('ONB_STATUS.ONBOARDING_FLEET_LABEL')}
            />

            <Step
              icon={<><CircleStatus text="6" disabled={false} /></>}
              status="process"
              title={title('ONB_STATUS.COMPLETED')}
              description={description('ONB_STATUS.COMPLETED_LABEL')}
            />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col sm={24}><Divider /></Col>
      </Row>
      <Row>
        <Col>
          <Steps direction="vertical" current={0}>
            <Step
              icon={<><CircleStatus text="P" disabled /></>}
              title={title('ONB_STATUS.PAUSED')}
              description={description('ONB_STATUS.PAUSED_LABEL')}
            />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Steps direction="vertical" current={0}>
            <Step
              icon={<><CircleStatus text="F1" disabled /></>}
              title={title('ONB_STATUS.FAILED_CARRIER_UNRESPONSIVE')}
              description={description('ONB_STATUS.FAILED_CARRIER_UNRESPONSIVE_LABEL')}
            />
          </Steps>
        </Col>
        <Col sm={12}>
          <Steps direction="vertical" current={0}>
            <Step
              icon={<><CircleStatus text="F2" disabled /></>}
              title={title('ONB_STATUS.FAILED_NO_GPS')}
              description={description('ONB_STATUS.FAILED_NO_GPS_LABEL')}
            />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Steps direction="vertical" current={0}>
            <Step
              icon={<><CircleStatus text="F3" disabled /></>}
              title={title('ONB_STATUS.FAILED_STOPPED_COOPERATION')}
              description={description('ONB_STATUS.FAILED_STOPPED_COOPERATION_LABEL')}
            />
          </Steps>
        </Col>
        <Col sm={12}>
          <Steps direction="vertical" current={0}>
            <Step
              icon={<><CircleStatus text="F4" disabled /></>}
              title={title('ONB_STATUS.FAILED_OTHER')}
              description={description('ONB_STATUS.FAILED_OTHER_LABEL')}
            />
          </Steps>
        </Col>
      </Row>
    </>
  );
};
