import { combineReducers } from 'redux';
import generateReportReducer from './generateReport.reducer';
import { detailsReducer } from './details.reducer';
import positionsReducer from './positions.reducer';
import { remarkReducer } from './remark.reducer';
import shareVehiclesReducer from './shareVehicles.reducer';
import stopSharingReducer from './stopSharing.reducer';

export default combineReducers({
  details: detailsReducer,
  positions: positionsReducer,
  generateReport: generateReportReducer,
  remark: remarkReducer,
  shareVehicles: shareVehiclesReducer,
  stopSharing: stopSharingReducer,
});
