import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Badge, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, FileTextOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseSelectionButton, FlexRightSpaced, HeaderButtonsDivider, CenteredRow,
} from '../Styled';
import { getFleetSelectedRows, getFleetSelectionMode } from '../../store/selectors';
import { SelectionMode } from '../../types/enums/fleet.enum';
import { resetFleetSelectionMode } from '../../store/actions';
import RefreshComponent from './RefreshComponent';

const FleetHeaderComponent = ({
  loading, renderInfo, size, onGenerateReport, onShareVehicles, withRefresh,
}) => {
  const { t } = useTranslation();
  const [countBadgeTitle, setCountBadgeTitle] = useState();
  const selectionMode = useSelector(getFleetSelectionMode);
  const selectedRows = useSelector(getFleetSelectedRows);
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedLicences = selectedRows.map((row) => row.licencePlateNumber)
      .join(', ');
    setCountBadgeTitle(selectedLicences);
  }, [selectedRows]);

  const handleShareVehiclesClick = useCallback(
    (vehicles) => {
      onShareVehicles(vehicles);
    },
    [onShareVehicles],
  );

  const handleGenerateReportClick = useCallback(
    (vehicles) => {
      onGenerateReport(vehicles);
    },
    [onGenerateReport],
  );

  const isSelectReportMode = () => selectionMode === SelectionMode.REPORT;

  const isSelectShareMode = () => selectionMode === SelectionMode.SHARE;

  const isOtherModeThan = (expected) => selectionMode && (selectionMode !== expected);

  const dispatchResetFleetSelectionMode = () => dispatch(resetFleetSelectionMode());

  const renderSharingButton = () => (
    <>
      <Badge
        count={isSelectShareMode() ? selectedRows.length : 0}
        title={countBadgeTitle}
        overflowCount={999}
      >
        <Button
          onClick={handleShareVehiclesClick}
          type={isOtherModeThan(SelectionMode.SHARE) ? 'default' : 'primary'}
          icon={<ShareAltOutlined />}
          disabled={loading || (selectionMode && !isSelectShareMode())}
          size={size}
        >
          {t(isSelectShareMode() ? 'COMMON.SELECTION_COMPLETE' : 'VEHICLE.SHARE_VEHICLES')}
        </Button>
      </Badge>
      {isSelectShareMode() && (
        <Tooltip
          placement="top"
          title={t('SHARE_VEHICLES.EXIT')}
        >
          <CloseSelectionButton
            icon={<CloseOutlined />}
            type="primary"
            ghost
            size={size}
            onClick={dispatchResetFleetSelectionMode}
          />
        </Tooltip>
      )}
    </>
  );

  const renderGenerateReportButton = () => (
    <>
      <Badge
        count={isSelectReportMode() ? selectedRows.length : 0}
        title={countBadgeTitle}
      >
        <Button
          type={isOtherModeThan(SelectionMode.REPORT) ? 'default' : 'primary'}
          onClick={handleGenerateReportClick}
          icon={<FileTextOutlined />}
          disabled={loading || (selectionMode && !isSelectReportMode())}
          size={size}
        >
          {t(isSelectReportMode() ? 'COMMON.SELECTION_COMPLETE' : 'VEHICLE.GENERATE_REPORT')}
        </Button>
      </Badge>
      {isSelectReportMode() && (
        <Tooltip
          placement="top"
          title={t('GENERATE_REPORT.EXIT')}
        >
          <CloseSelectionButton
            icon={<CloseOutlined />}
            type="primary"
            ghost
            size={size}
            onClick={dispatchResetFleetSelectionMode}
          />
        </Tooltip>
      )}
    </>
  );

  const renderRefresh = () => (
    <>
      <HeaderButtonsDivider />
      <RefreshComponent
        onRefresh={withRefresh.onRefresh}
        onUpdateRefreshIntervalSeconds={withRefresh.onUpdateRefreshIntervalSeconds}
        loading={withRefresh.loading}
        refreshIntervalSeconds={withRefresh.refreshIntervalSeconds}
        size={size}
        lastRefreshed={withRefresh.lastRefreshed}
        compact={withRefresh.compact}
      />
    </>
  );

  return (
    <CenteredRow _justify={renderInfo ? 'space-between' : 'flex-end'}>
      {renderInfo && renderInfo()}
      <FlexRightSpaced>
        {onShareVehicles && renderSharingButton()}
        {onGenerateReport && renderGenerateReportButton()}
        {withRefresh && renderRefresh()}
      </FlexRightSpaced>
    </CenteredRow>
  );
};

FleetHeaderComponent.propTypes = {
  loading: PropTypes.bool,
  onGenerateReport: PropTypes.func,
  onShareVehicles: PropTypes.func,
  renderInfo: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  withRefresh: PropTypes.shape({
    loading: PropTypes.bool,
    onRefresh: PropTypes.func.isRequired,
    onUpdateRefreshIntervalSeconds: PropTypes.func.isRequired,
    refreshIntervalSeconds: PropTypes.number,
    lastRefreshed: MomentPropTypes.momentObj,
    compact: PropTypes.bool,
  }),
};

FleetHeaderComponent.defaultProps = {
  loading: false,
  withRefresh: null,
  renderInfo: null,
  onGenerateReport: undefined,
  onShareVehicles: undefined,
  size: 'small',
};

export default FleetHeaderComponent;
