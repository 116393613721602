import React from 'react';
import PropTypes from 'prop-types';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { PlayButton } from './HistoryPlayComponent.styled';

class PlayPauseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intervalId } = this.state;
    const { playing } = this.props;
    const prevPlaying = prevProps.playing;

    if (playing && !prevPlaying) {
      clearInterval(intervalId);
      this.startPlaying();
    }

    if (!playing) {
      clearInterval(intervalId);
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
  }

  startPlaying() {
    const {
      playbackSpeed, onTick, defaultTickInterval,
    } = this.props;
    const { intervalId } = this.state;

    clearInterval(intervalId);
    const newIntervalId = setTimeout(() => {
      this.startPlaying();
    }, defaultTickInterval / playbackSpeed);

    onTick();
    this.setState({ intervalId: newIntervalId });
  }

  render() {
    const { playing, onPlayingChange, disabled } = this.props;

    return (
      <PlayButton
        type="link"
        icon={playing ? <PauseOutlined /> : <CaretRightOutlined />}
        size="large"
        disabled={disabled}
        onClick={() => onPlayingChange(!playing)}
      />
    );
  }
}

PlayPauseComponent.propTypes = {
  playing: PropTypes.bool.isRequired,
  onPlayingChange: PropTypes.func.isRequired,
  onTick: PropTypes.func.isRequired,
  playbackSpeed: PropTypes.number.isRequired,
  defaultTickInterval: PropTypes.number,
  disabled: PropTypes.bool,
};

PlayPauseComponent.defaultProps = {
  defaultTickInterval: 500,
  disabled: false,
};

export default PlayPauseComponent;
