export const FleetTabs = {
  VEHICLES: 'vehicles',
  SHARING: 'sharing',
};

export const SelectionMode = {
  REPORT: 'REPORT',
  SHARE: 'SHARE',
  VEHICLES_FILTER: 'VEHICLES_FILTER',
};

export const SelectionType = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};
