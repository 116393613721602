import { FETCH_LOCATION, RESET_ALL_LOCATIONS, RESET_LOCATION } from '../actions';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_LOCATION.START:
      return { ...state, [payload.vehicleId]: { ...state[payload.vehicleId], loading: true } };
    case FETCH_LOCATION.SUCCESS:
      return {
        ...state,
        [payload.vehicleId]: {
          location: payload.location,
          coordinates: payload.coordinates,
          loading: false,
        },
      };
    case FETCH_LOCATION.FAIL:
      return { ...state, [payload.vehicleId]: { error: payload.error, loading: false } };
    case RESET_LOCATION:
      return { ...state, [payload.vehicleId]: undefined };
    case RESET_ALL_LOCATIONS:
      return {};
    default:
      return state;
  }
};
