import { combineReducers } from 'redux';
import {
  CLOSE_CREATE_ORDER, CREATE_ORDER, FETCH_ORDER_COMPANIES, OPEN_CREATE_ORDER, UPDATE_ORDER,
} from '../../actions';

function visibilityReducer(state = false, { type }) {
  switch (type) {
    case OPEN_CREATE_ORDER:
      return true;
    case CLOSE_CREATE_ORDER:
      return false;
    default:
      return state;
  }
}

const initialState = {
  loading: false,
  data: [],
};

function availableCompaniesReducer(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case FETCH_ORDER_COMPANIES.START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDER_COMPANIES.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case FETCH_ORDER_COMPANIES.FAIL:
    case CLOSE_CREATE_ORDER:
      return {
        ...state,
        data: [],
        loading: false,
      };
    default:
      return state;
  }
}

const creationInitialState = { loading: false };

function creationReducer(state = { ...creationInitialState }, { type }) {
  switch (type) {
    case CREATE_ORDER.START:
      return { ...state, loading: true };
    case CREATE_ORDER.SUCCESS:
    case CREATE_ORDER.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

function editionReducer(state = { ...creationInitialState }, { type }) {
  switch (type) {
    case UPDATE_ORDER.START:
      return { ...state, loading: true };
    case UPDATE_ORDER.SUCCESS:
    case UPDATE_ORDER.FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default combineReducers({
  visible: visibilityReducer,
  companies: availableCompaniesReducer,
  creation: creationReducer,
  edition: editionReducer,
});
