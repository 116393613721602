import { RESET_COMPANY_CONTEXT, SET_ORDERS_PAGINATION } from '../../actions';
import { OrderContext } from '../../../types';

const INIT_PAGINATION = { page: 1, size: 50 };

export default (orderType = OrderContext.UNKNOWN) => (state = { ...INIT_PAGINATION }, { type, payload }) => {
  switch (type) {
    case SET_ORDERS_PAGINATION[orderType]:
      return { page: payload.page || state.page, size: payload.size || state.size };
    case RESET_COMPANY_CONTEXT:
      return { ...state, page: 1 };
    default:
      return state;
  }
};
