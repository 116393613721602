import styled, { css } from 'styled-components';
import { Typography } from 'antd';

export const Wrapper = styled.span`
 ${(props) => {
    switch (props.size) {
      case 's':
        return css`
          font-size: 21px;
          line-height: 26px;
        `;
      case 'l':
        return css`
          font-size: 32px;
          line-height: 40px;
        `;
      default:
        return css`
          font-size: 24px;
          line-height: 32px;
        `;
    }
  }}
`;

export const Label = styled(Typography.Text)`
  margin-left: 10px;
  line-height: 26px;
`;
