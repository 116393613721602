import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { geofencingStopPropTypes } from '../../../types';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { FlexRight } from '../../../components';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';

const ArrivingTime = ({ geofencingData }) => {
  const { t } = useTranslation();
  const show = geofencingData !== {};
  const [details, setDetails] = useState(false);

  const onClick = () => {
    setDetails(true);
  };

  const Time = styled.span`
    margin-left: 6px;
  `;

  const arrived = geofencingData && geofencingData.in
    ? getCleanMoment(geofencingData.in).format(DEFAULT_DATE_TIME)
    : null;

  const departed = geofencingData && geofencingData.out
    ? getCleanMoment(geofencingData.out).format(DEFAULT_DATE_TIME)
    : null;

  return show && (
    <Row justify="end">
      {details && (
        <Col style={{ textAlign: 'right' }}>
          {arrived && (
            <FlexRight>
              <span style={{ whiteSpace: 'nowrap' }}>
                {t('ORDER.GEOFENCING_ARRIVED')}
                :
              </span>
              <Time>
                {arrived}
              </Time>
            </FlexRight>
          )}
          {departed && (
            <FlexRight>
              <span style={{ whiteSpace: 'nowrap' }}>
                {t('ORDER.GEOFENCING_DEPARTED')}
                :
              </span>
              <Time>
                {departed}
              </Time>
            </FlexRight>
          )}
        </Col>
      )}
      {!details && (
        <Button
          size="small"
          style={{ padding: 0, margin: 0 }}
          type="link"
          onClick={() => onClick()}
        >
          {t('ORDER.GEOFENCING_ARRIVAL_TIME')}
        </Button>
      )}
    </Row>
  );
};

ArrivingTime.propTypes = {
  geofencingData: geofencingStopPropTypes,
};

ArrivingTime.defaultProps = {
  geofencingData: {},
};

export default ArrivingTime;
