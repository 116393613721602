import { STOP_SHARING, SUBMIT_SHARE_VEHICLES } from '../../actions/vehicleDetails';

const initialState = {
  loading: false,
  error: undefined,
};

export default function shareVehiclesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case STOP_SHARING.START:
      return { ...state, loading: true };
    case SUBMIT_SHARE_VEHICLES.FAIL:
      return { loading: false, error: payload };
    case STOP_SHARING.SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
}
