import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Diacritic from 'diacritic';

const Text = styled(Typography.Text)`
  color: inherit !important;
`;

const Highlighteable = ({ search, content, color }) => {
  const { t } = useTranslation();
  if (search && content) {
    const normalizedSearch = Diacritic.clean(search);
    const normalizedContent = Diacritic.clean(content);
    const index = normalizedContent.toLowerCase().indexOf(normalizedSearch.toLowerCase());

    if (index !== -1) {
      const { length } = search;
      const prefix = content.substring(0, index);
      const suffix = content.substring(index + length);
      const match = content.substring(index, index + length);

      return (
        <span>
          {prefix}
          <Text strong style={{ color: `${color}` }}>{match}</Text>
          {suffix}
        </span>
      );
    }
  }

  return (
    <span style={{ color: `${color}` }}>
      {content || t('COMMON.MISSING')}
    </span>
  );
};

Highlighteable.propTypes = {
  search: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string,
};

Highlighteable.defaultProps = {
  search: null,
  content: null,
  color: 'black',
};

export default Highlighteable;
