import { combineReducers } from 'redux';
import moment from 'moment';
import {
  CALCULATE_ETA, FETCH_ORDER_DETAILS, FETCH_ORDER_POSITIONS, FETCH_VEHICLE_TOUR_ANALYSIS, RESET_COMPANY_CONTEXT,
  RESET_ETA, SET_ORDER_DETAILS, CLEAN_ORDER_DETAILS,
} from '../../actions';
import { normalizeEta, normalizeOrder, normalizeOrdersPositions } from '../../normalizers/orders.normalizer';

const orderDetailsInitialState = {
  data: {},
  fetchingAsCompany: null,
  loading: false,
  lastUpdated: moment(),
  error: null,
};

const orderDetailsReducer = (state = { ...orderDetailsInitialState }, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ORDER_DETAILS.SUCCESS:
      if (state.actionId === meta.previousAction.payload.actionId) {
        return {
          ...state,
          data: normalizeOrder(payload.data, state.fetchingAsCompany),
          loading: false,
          error: null,
          lastUpdated: moment(),
        };
      }
      return state;

    case FETCH_ORDER_DETAILS.FAIL:
      return {
        ...state, loading: false, error: payload, data: {}, lastUpdated: moment(), fetchingAsCompany: null,
      };
    case FETCH_ORDER_DETAILS.START:
      return {
        ...state, loading: true, fetchingAsCompany: payload.fetchingAsCompany, actionId: payload.actionId,
      };
    case RESET_COMPANY_CONTEXT:
      return { ...orderDetailsInitialState };
    case CLEAN_ORDER_DETAILS:
      return { ...state };
    default:
      return state;
  }
};

const positionsInitialState = {
  data: { },
  loading: false,
  error: null,
};

const orderPositionsReducer = (state = positionsInitialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ORDER_POSITIONS.SUCCESS:
      if (state.actionId === meta.previousAction.payload.actionId) {
        return {
          ...state, data: normalizeOrdersPositions(payload.data), loading: false, error: null,
        };
      }
      return state;
    case FETCH_ORDER_POSITIONS.FAIL:
      return {
        ...positionsInitialState, error: payload,
      };
    case FETCH_ORDER_POSITIONS.START:
      return { ...positionsInitialState, loading: true, actionId: payload.actionId };
    case RESET_COMPANY_CONTEXT:
      return { ...positionsInitialState };
    case CLEAN_ORDER_DETAILS:
      return { ...positionsInitialState };
    default:
      return state;
  }
};

const etaInitialState = {
  loading: false,
  data: {},
  error: null,
};

function calculateEtaReducer(state = etaInitialState, { payload, type, meta }) {
  switch (type) {
    case CALCULATE_ETA.START: {
      const { stopId } = payload.request.params;
      return {
        ...state,
        loading: true,
        actionId: payload.actionId,
        data: {
          ...state.data,
          [stopId]: {
            ...(state.data[stopId] || {}),
            loading: true,
          },
        },
      };
    }
    case CALCULATE_ETA.FAIL:
      if (state.actionId === meta.previousAction.payload.actionId) {
        return {
          ...etaInitialState,
          error: payload && payload.data,
          loading: false,
        };
      }
      return state;
    case CALCULATE_ETA.SUCCESS:
      if (state.actionId === meta.previousAction.payload.actionId) {
        const { stopId } = payload.config.params;
        return {
          ...etaInitialState,
          loading: false,
          data: {
            ...state.data,
            latest: stopId,
            [stopId]: normalizeEta(payload.data),
          },
        };
      }
      return state;
    case RESET_ETA:
      return { ...state, data: { ...state.data, latest: null } };
    case RESET_COMPANY_CONTEXT:
      return etaInitialState;
    default:
      return state;
  }
}

const detailsInitialState = { details: null, positions: {} };

const fullOrderDetailsViewReducer = (state = detailsInitialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER_DETAILS:
      return {
        order: normalizeOrder(payload.details, payload.fetchingAsCompany),
        positions: normalizeOrdersPositions(payload.positions),
      };
    case CLEAN_ORDER_DETAILS:
      return detailsInitialState;
    case RESET_COMPANY_CONTEXT:
      return detailsInitialState;
    default:
      return state;
  }
};

const vehicleAnalysisInitState = { data: null, loading: false, error: null };
const vehicleAnalysisReducer = (state = vehicleAnalysisInitState, { type, payload }) => {
  switch (type) {
    case FETCH_VEHICLE_TOUR_ANALYSIS.START:
      return { ...state, loading: true };
    case FETCH_VEHICLE_TOUR_ANALYSIS.SUCCESS:
      return { data: payload.data, loading: false, error: null };
    case FETCH_VEHICLE_TOUR_ANALYSIS.FAIL:
      return { data: null, loading: false, error: null };
    case CLEAN_ORDER_DETAILS:
      return vehicleAnalysisInitState;
    default:
      return state;
  }
};

export default combineReducers({
  order: orderDetailsReducer,
  positions: orderPositionsReducer,
  eta: calculateEtaReducer,
  analysis: vehicleAnalysisReducer,
  details: fullOrderDetailsViewReducer,
});
