import React from 'react';
import {
  Modal, Form, Col, Row, Select, Input, notification,
} from 'antd';
import theme from '../../../theme';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';
import { shareOrderToEmails } from '../../../store/actions';

function openShareComponent(order, contextCompanyId, t, form, dispatch) {
  const startDate = order.startDate && getCleanMoment(order.startDate).format(DEFAULT_DATE_TIME);
  const endDate = order.endDate && getCleanMoment(order.endDate).format(DEFAULT_DATE_TIME);

  const validateEmails = () => {
    const emails = form.getFieldValue('emails');
    // eslint-disable-next-line
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const filteredEmails = emails.filter((email) => regex.test(email));
    if (emails.length !== filteredEmails.length) {
      form.setFields([
        {
          name: 'emails',
          errors: [t('EMAIL_SHARES.SHARE_POPUP.PLEASE_PROVIDE_VALID_EMAIL')],
        },
      ]);
    } else {
      form.setFieldsValue({
        emails,
      });
    }
  };

  const popupShareForm = (
    <Form form={form} style={{ paddingTop: '24px' }}>
      {t('EMAIL_SHARES.SHARE_POPUP.SHARING_DATE_RANGE')}
      <Row
        style={{
          height: '32px',
          background: theme.color.backgroundLight3,
          border: `1px solid ${theme.color.border}`,
          color: theme.color.darkgray3,
          padding: '5px',
          marginBottom: '24px',
        }}
      >
        <Col span={12}>{startDate}</Col>
        <Col span={12}>{endDate}</Col>
      </Row>

      <span style={{ color: 'red' }}>*</span>
      &nbsp;
      {t('COMMON.EMAIL')}
      <Form.Item name="emails">
        <Select
          onChange={validateEmails}
          style={{ width: '100%' }}
          dropdownStyle={{ display: 'none' }}
          mode="tags"
          tokenSeparators={[',']}
        />
      </Form.Item>
      {t('EMAIL_SHARES.ADDITIONAL_INFO')}
      <Form.Item name="additionalInfo">
        <Input.TextArea rows={5} />
      </Form.Item>
    </Form>
  );

  const openShareSuccessModalContent = (link) => (
    <>
      <p>
        {t('EMAIL_SHARES.SUCCESS_POPUP.INFO')}
        <br />
        <a target="_blank" rel="noreferrer" href={link}>{link}</a>
      </p>
      <small style={{ color: theme.color.gray3, lineHeight: '14px', fontSize: '10px' }}>
        {t('EMAIL_SHARES.SUCCESS_POPUP.NOTE')}
      </small>
    </>
  );

  const openShareSuccessModal = (link) => {
    const showCopySuccessNotification = () => {
      notification.open({
        icon: null,
        closeIcon: null,
        duration: 5,
        description: (
          <>
            {t('EMAIL_SHARES.SHARE_POPUP.LINK')}
            &nbsp;
            <a target="_blank" rel="noreferrer" href={link}>{link}</a>
            &nbsp;
            {t('EMAIL_SHARES.SHARE_POPUP.HAS_BEEN_COPIED')}
          </>
        ),
        placement: 'bottomRight',
        style: {
          color: theme.color.white,
          background: theme.color.backgroundDark3,
        },
      });
    };

    const shareSuccessModal = Modal.confirm({
      icon: (
        <span className="anticon anticon-exclamation-circle">
          <img
            src="./images/green-tick.svg"
            alt="tick icon"
          />
        </span>
      ),
      title: t('COMMON.DONE!'),
      visible: true,
      closable: true,
      width: 500,
      content: openShareSuccessModalContent(link),
      okText: t('COMMON.OK'),
      cancelText: t('EMAIL_SHARES.SUCCESS_POPUP.COPY_LINK'),
      onCancel: () => {
        shareSuccessModal.update({
          cancelText: t('EMAIL_SHARES.SUCCESS_POPUP.COPIED'),
          cancelButtonProps: { disabled: true },
        });
        navigator.clipboard.writeText(link)
          .then(showCopySuccessNotification(link));
      },
    });
  };

  form.setFields([
    { name: 'additionalInfo', value: '' },
    { name: 'emails', value: [] },
  ]);

  form.setFieldsValue({
    emails: [],
    additionalInfo: '',
  });

  Modal.confirm({
    closable: true,
    icon: null,
    title: t('EMAIL_SHARES.SHARE_FOR_ORDER', { internalId: order.internalId }),
    content: popupShareForm,
    width: 500,
    onOk: (close) => {
      const emails = form.getFieldValue('emails');
      const additionalInfo = form.getFieldValue('additionalInfo');

      if (emails.length > 0 && form.getFieldError('emails').length === 0) {
        dispatch(shareOrderToEmails(order.id, emails, additionalInfo, contextCompanyId))
          .then((response) => openShareSuccessModal(response.payload.data.link));
        close();
      }
    },
    okText: t('EMAIL_SHARES.SHARE'),
    cancelText: t('COMMON.CANCEL'),
  });
}

export default openShareComponent;
