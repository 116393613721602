export const ORDERS_TOGGLE_AUTO_REFRESH = 'ORDERS_TOGGLE_AUTO_REFRESH';
export const ORDERS_SWITCH_TAB = 'ORDERS_SWITCH_TAB';

export const toggleOrdersAutoRefresh = (checked) => ({
  type: ORDERS_TOGGLE_AUTO_REFRESH,
  payload: checked,
});

export const switchOrdersTab = (nextTab) => ({
  type: ORDERS_SWITCH_TAB,
  payload: {
    nextTab,
  },
});
