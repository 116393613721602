/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card, Table } from 'antd';

const { Column } = Table;

class TourStatisticsTablesComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      table: [],
      uniqueAndNonUniqueTable: [],
    };
  }

  componentDidMount() {
    this.createGeneralStatsTable();
    this.createUniqueAndNonUniqueTable();
  }

  createGeneralStatsTable() {
    const table = [];
    const { stats } = this.props;
    table.push({
      realTrackingDuration: stats.realTrackingDuration,
      realTrackingEndTime: this.convertToDateString(stats.realTrackingEndTime),
      realTrackingStartTime: this.convertToDateString(stats.realTrackingStartTime),
      scheduledTrackingDuration: stats.scheduledTrackingDuration,
      scheduledTrackingStartTime: this.convertToDateString(stats.tour.startDate),
      scheduledTrackingEndTime: this.convertToDateString(stats.tour.endDate),
      workingTime: Number(stats.workingTime).toFixed(2),
      rowKey: 'key',
    });
    this.setState({ table });
  }

  convertToDateString(value) {
    return new Date(value * 1000).toLocaleString();
  }

  createUniqueAndNonUniqueTable() {
    const { t, stats } = this.props;
    const uniqueAndNonUniqueTable = [];

    const nonUnique = stats.nonUniquePositionStatistics;
    nonUnique.countPerHour = Number(nonUnique.countPerHour).toFixed(2);
    nonUnique.uniqueType = t('Statistics.TourStatisticTablesComponent.nonUnique');
    uniqueAndNonUniqueTable.push(nonUnique);

    const unique = stats.uniquePositionStatistics;
    unique.uniqueType = t('Statistics.TourStatisticTablesComponent.unique');
    unique.countPerHour = Number(unique.countPerHour).toFixed(2);
    uniqueAndNonUniqueTable.push(unique);

    this.setState({ uniqueAndNonUniqueTable });
  }

  renderTourDetailsTable() {
    const { t, stats } = this.props;
    const activeStateTrue = t('Statistics.TourStatisticTablesComponent.activeStateTrue');
    const activeStateFalse = t('Statistics.TourStatisticTablesComponent.activeStateFalse');
    return (
      <Card
        title={t('Statistics.TourStatisticTablesComponent.tourDetailsCardTitle')}
        style={{ width: 500 }}
        className="tour-details-card"
      >
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourName')}
          :
          {' '}
          {stats.tour.name}
        </p>
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourInternalId')}
          :
          {' '}
          {stats.tour.internalId}
          {' '}
        </p>
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourStartDate')}
          :
          {' '}
          {new Date(stats.tour.startDate * 1000).toLocaleString()}
          {' '}
        </p>
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourEndDate')}
          :
          {' '}
          {new Date(stats.tour.endDate * 1000).toLocaleString()}
          {' '}
        </p>
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourActiveState')}
          :
          {' '}
          {stats.tour.active ? activeStateTrue : activeStateFalse}
          {' '}
        </p>
        <p>
          {t('Statistics.TourStatisticTablesComponent.tourNotTrackedQuartersCount')}
          :
          {' '}
          {stats.quarterStatistics.numberOfSpeedGreaterThanGiven || 0}
        </p>
      </Card>
    );
  }

  renderTourStatisticTable() {
    const { t } = this.props;
    const { table } = this.state;
    return (
      <Card
        title={t('Statistics.TourStatisticTablesComponent.tourStatisticsCardTitle')}
        style={{ width: 800 }}
      >
        <div style={{ height: 300 }}>
          <Table
            dataSource={table}
            pagination={{ position: ['none', 'none'] }}
            rowKey="rowKey"
          >
            <Column
              title={t('Statistics.TourStatisticTablesComponent.realTrackingStartTime')}
              dataIndex="realTrackingStartTime"
              key="realTrackingStartTime"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.realTrackingEndTime')}
              dataIndex="realTrackingEndTime"
              key="realTrackingEndTime"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.realTrackingDuration')}
              dataIndex="realTrackingDuration"
              key="realTrackingDuration"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.scheduledTrackingStartTime')}
              dataIndex="scheduledTrackingStartTime"
              key="scheduledTrackingStartTime"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.scheduledTrackingEndTime')}
              dataIndex="scheduledTrackingEndTime"
              key="scheduledTrackingEndTime"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.scheduledTrackingDuration')}
              dataIndex="scheduledTrackingDuration"
              key="scheduledTrackingDuration"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.workingTime')}
              dataIndex="workingTime"
              key="workingTime"
            />
          </Table>
        </div>
      </Card>
    );
  }

  renderPositionStatisticsTable() {
    const { t } = this.props;
    const { uniqueAndNonUniqueTable } = this.state;
    return (
      <Card
        title={t('Statistics.TourStatisticTablesComponent.positionStatisticsCardTitle')}
        style={{ width: 800 }}
      >
        <div style={{ height: 300 }}>
          <Table
            dataSource={uniqueAndNonUniqueTable}
            pagination={{ position: ['none', 'none'] }}
            rowKey="uniqueType"
          >
            <Column
              title={t('Statistics.TourStatisticTablesComponent.uniqueType')}
              dataIndex="uniqueType"
              key="uniqueType"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.count')}
              dataIndex="count"
              key="count"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.countPerHour')}
              dataIndex="countPerHour"
              key="countPerHour"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.maxTimeBetween')}
              dataIndex="maxTimeBetween"
              key="maxTimeBetween"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.minTimeBetween')}
              dataIndex="minTimeBetween"
              key="minTimeBetween"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.meanTimeBetween')}
              dataIndex="meanTimeBetween"
              key="meanTimeBetween"
            />
            <Column
              title={t('Statistics.TourStatisticTablesComponent.medianTimeBetween')}
              dataIndex="medianTimeBetween"
              key="medianTimeBetween"
            />

          </Table>
        </div>
      </Card>
    );
  }

  render() {
    return (
      <div>
        {this.renderTourDetailsTable()}
        {this.renderTourStatisticTable()}
        {this.renderPositionStatisticsTable()}
      </div>
    );
  }
}

TourStatisticsTablesComponent.propTypes = {
  t: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    realTrackingDuration: PropTypes.string,
    realTrackingEndTime: PropTypes.number,
    realTrackingStartTime: PropTypes.number,
    scheduledTrackingDuration: PropTypes.string,
    tour: PropTypes.shape({
      name: PropTypes.string,
      internalId: PropTypes.string,
      active: PropTypes.bool,
      startDate: PropTypes.number,
      endDate: PropTypes.number,
    }),
    workingTime: PropTypes.number,
    quarterStatistics: PropTypes.shape({
      numberOfSpeedGreaterThanGiven: PropTypes.number,
    }),
    nonUniquePositionStatistics: PropTypes.shape({
      countPerHour: PropTypes.number,
      uniqueType: PropTypes.string,
    }),
    uniquePositionStatistics: PropTypes.shape({
      countPerHour: PropTypes.number,
      uniqueType: PropTypes.string,
    }),
  }),
};

TourStatisticsTablesComponent.defaultProps = {
  stats: {},
};

export default withRouter(withTranslation()(TourStatisticsTablesComponent));
