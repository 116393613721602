import { RESET_COMPANY_CONTEXT, RESET_VEHICLES_PAGINATION, SET_VEHICLES_PAGINATION } from '../../actions';

const INIT_PAGINATION = { page: 1, size: 200 };

export default function paginationReducer(state = { ...INIT_PAGINATION }, { type, payload }) {
  switch (type) {
    case SET_VEHICLES_PAGINATION:
      return { ...payload };
    case RESET_COMPANY_CONTEXT:
    case RESET_VEHICLES_PAGINATION:
      return { ...state, page: 1 };
    default:
      return state;
  }
}
