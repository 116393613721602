/* eslint-disable no-case-declarations */
import { OrderContext } from '../../../../types';
import { ERROR_CODE } from '../../../../utils/apiError';
import {
  FETCH_MONITORING_ORDER_ROUTE_POINTS, FETCH_ORDERS, RESET_COMPANY_CONTEXT, SET_ORDERS_PAGINATION,
} from '../../../actions';

const initState = [];

const isFirstRoutePointRequested = (filter) => !filter || filter === 'FIRST';

const isNextRoutePointRequested = (filter) => !filter || filter === 'NEXT';

const buildUpdatedRoutePoint = (routePoint, loading, error) => ({
  stopId: routePoint.stopId,
  actualArrival: routePoint.actualArrival,
  estimatedArrival: routePoint.estimatedArrival,
  loading,
  error,
});

const resolveFirstRoutePoint = (point, newPoint, loading, error, filter) => (isFirstRoutePointRequested(filter)
  ? buildUpdatedRoutePoint(newPoint || point, loading, error)
  : point);

const resolveNextRoutePoint = (point, newPoint, loading, error, filter) => (isNextRoutePointRequested(filter)
  ? buildUpdatedRoutePoint(newPoint || point, loading, error)
  : point);

const buildUpdatedAsset = (asset, newAsset, loading, error, filter) => ({
  vehicleId: asset.vehicleId,
  firstRoutePoint: resolveFirstRoutePoint(asset.firstRoutePoint, newAsset?.firstRoutePoint, loading, error, filter),
  nextRoutePoint: resolveNextRoutePoint(asset.nextRoutePoint, newAsset?.nextRoutePoint, loading, error, filter),
});

const buildUpdatedRoute = (monitoringOrderId, asset, newAsset, loading, error, filter) => ({
  orderId: monitoringOrderId,
  assetsRoutes: [buildUpdatedAsset(asset, newAsset, loading, error, filter)],
});

const buildNewRoutePoint = (loading, error) => ({
  loading,
  error,
});

const buildNewAsset = (loading, error) => ({
  firstRoutePoint: buildNewRoutePoint(loading, error),
  nextRoutePoint: buildNewRoutePoint(loading, error),
});

const buildNewRoute = (monitoringOrderId, loading, error) => ({
  orderId: monitoringOrderId,
  assetsRoutes: [buildNewAsset(loading, error)],
});

const mapErrorStatus = (status) => {
  switch (status) {
    case 504:
      return ERROR_CODE.ROUTE_POINT_UNABLE_TO_CALCULATE;
    default:
      return null;
  }
};

const handleStart = (action, state) => {
  const { routePointFilter, monitoringOrderId } = action;
  let isNew = true;
  const newRoutes = state.map((route) => {
    if (route.orderId === monitoringOrderId) {
      isNew = false;
      const asset = route.assetsRoutes[0];
      return buildUpdatedRoute(monitoringOrderId, asset, null, true, null, routePointFilter);
    }
    return route;
  });
  if (isNew) {
    return state.concat(buildNewRoute(monitoringOrderId, true, null));
  }
  return newRoutes;
};

const handleSuccess = (action, state) => {
  const responseData = action.payload.data;
  const { meta: { previousAction: { routePointFilter } } } = action;
  const newRoutes = state.map((route) => {
    const responseOrderId = responseData.orderId;
    if (route.orderId === responseOrderId) {
      const asset = route.assetsRoutes[0];
      const responseAsset = responseData.assetsRoutes[0];
      return buildUpdatedRoute(responseOrderId, asset, responseAsset, false, null, routePointFilter);
    }
    return route;
  });
  return newRoutes;
};

const handleError = (action, state) => {
  const {
    meta: { previousAction: { monitoringOrderId, routePointFilter } },
    error: { response: { data: { errorCode }, status } },
  } = action;
  const error = errorCode || mapErrorStatus(status);
  const newRoutes = state.map((route) => {
    if (route.orderId === monitoringOrderId) {
      const asset = route.assetsRoutes[0];
      return buildUpdatedRoute(monitoringOrderId, asset, null, false, error, routePointFilter);
    }
    return route;
  });
  return newRoutes;
};

export default (orderType = OrderContext.UNKNOWN) => (state = initState, action) => {
  switch (action.type) {
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].START:
      return handleStart(action, state);
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].SUCCESS:
      return handleSuccess(action, state);
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].FAIL:
      return handleError(action, state);
    case FETCH_ORDERS[orderType].START:
      return initState;
    case RESET_COMPANY_CONTEXT:
      return initState;
    case SET_ORDERS_PAGINATION[orderType]:
      return initState;
    default:
      return state;
  }
};
