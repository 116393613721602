import theme from '../../theme';
import { normalizeThreeStateBoolean } from '../../store/normalizers';

export default (ignitionState) => {
  if (typeof ignitionState === 'string') {
    // eslint-disable-next-line no-param-reassign
    ignitionState = normalizeThreeStateBoolean(ignitionState);
  }
  if (ignitionState) {
    return theme.color.ignition.on;
  }

  if (ignitionState === false) {
    return theme.color.ignition.off;
  }

  return theme.color.ignition.unknown;
};
