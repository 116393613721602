const validBooleanTrue = [
  'YES', '1', 'TRUE', 'ON', 1, true,
];

const validBooleanFalse = [
  'NO', '0', 'FALSE', 'OFF', 0, false,
];

const validBooleanUnknown = [
  'UNKNOWN', null, undefined, 'null', 'undefined',
];

export function normalizeThreeStateBoolean(b) {
  if (validBooleanUnknown.includes(b)) {
    return null;
  }
  if (validBooleanTrue.includes(typeof b === 'string' ? b.toUpperCase() : b)) {
    return true;
  }
  if (validBooleanFalse.includes(typeof b === 'string' ? b.toUpperCase() : b)) {
    return false;
  }

  return null;
}

export function normalizeFloat(f) {
  return parseFloat(f);
}
