/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Form, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { HStretched } from '../../../../components';
import { EditionMode, orderPropTypes } from '../../../../types';
import TimeDetails from '../../../../components/Time/TimeDetails';
import StopSection from '../../../../components/Stop/StopSection';
import FormSection from '../../../../components/Form/FormSection';
import OrderDetailsSection from '../../Components/OrderDetailsSection';
import OrderCarrierDetails from '../../Components/OrderCarrierDetails';
import { getRequestCompanyVehicles, getSelectedCompany } from '../../../../store/selectors';
import { fetchCompanyVehicles } from '../../../../store/actions/requests/create-request.actions';
import ErrorMessageContent from '../../../../components/Structure/Header/ErrorMessageContent';
import { stopsByTimeSlotAndSequence } from '../../../../types/mappers';

const OrderEditingForm = ({
  order,
  companies,
  commonForm,
  timeMonitoringForm,
  tourMonitoringForm,
  handleSubmit,
  handleCancel,
}) => {
  const contextCompanyId = useSelector(getSelectedCompany);
  const { vehicles } = useSelector(getRequestCompanyVehicles);

  const dispatch = useDispatch();

  const [companyVehicles, setCompanyVehicles] = useState([]);
  const [companyLoaded, setCompanyLoaded] = useState(false);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [vehiclesLoaded, setVehiclesLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [tourStops, setTourStops] = useState([]);
  const [mode, setMode] = useState(EditionMode.UNKNOWN);

  const history = useHistory();

  const { t } = useTranslation();

  const hasCarrierCompany = () => {
    if (companies.data && order.data.carrierCompany) {
      return companies.data.map((company) => company.companyId)
        .indexOf(order.data.carrierCompany.id) !== -1;
    }
    return false;
  };

  const hasOrderVehicle = (carrierVehicles, orderVehicle) => carrierVehicles.map((vehicle) => vehicle.vehicleId)
    .indexOf(orderVehicle.id) !== -1;

  const prepareCarrierVehicles = () => {
    const carrierVehicles = [];
    if (vehicles && !vehicles.loading && vehicles.data && mode === EditionMode.CARRIER) {
      vehicles.data.forEach((vehicle) => {
        carrierVehicles.push({
          vehicleId: vehicle.id,
          licencePlateNumber: vehicle.licencePlateNumber,
        });
      });
    }

    order.data.vehicles.forEach((orderVehicle) => {
      if (!hasOrderVehicle(carrierVehicles, orderVehicle)) {
        carrierVehicles.push({
          vehicleId: orderVehicle.id,
          licencePlateNumber: orderVehicle.licencePlateNumber,
        });
      }
    });

    return carrierVehicles;
  };

  const addCarrierCompanyAndVehicles = () => {
    const carrierVehicles = prepareCarrierVehicles();
    if (order.data.carrierCompany) {
      companies.data.push({
        companyId: order.data.carrierCompany.id,
        companyName: order.data.carrierCompany.name,
        vehicles: carrierVehicles,
      });
    }
  };

  const addCarrierVehicles = () => {
    companies.data.forEach((company) => {
      if (mode === EditionMode.CARRIER && company.companyId === order.data.carrierCompany.id) {
        vehicles.data.forEach((vehicle) => {
          if (!hasOrderVehicle(company.vehicles, vehicle)) {
            company.vehicles.push({
              vehicleId: vehicle.id,
              licencePlateNumber: vehicle.licencePlateNumber,
            });
          }
        });
      }
    });
  };

  const findAvailableVehicles = (companyId) => {
    const selectedCarrier = companies.data.find((company) => company.companyId === companyId);
    if (selectedCarrier) {
      setCompanyVehicles(selectedCarrier.vehicles || []);
      commonForm.setFields([{
        name: 'vehicleId',
        value: null,
      }]);
    }
  };

  const orderAvailable = () => order && !order.loading && order.fetchingAsCompany;

  const companiesAvailable = () => companies && !companies.loading && companies.data;

  const vehiclesAvailable = () => vehicles && !vehicles.loading && vehicles.data;

  const checkCompanyAccess = () => {
    if (mode === EditionMode.UNKNOWN) {
      message.error(<ErrorMessageContent status={403} />, 7);
      history.push({ pathname: '/orders/outgoing/time' });
    }
  };

  const setCompanyAndVehicle = () => {
    if (companiesAvailable() && vehiclesAvailable() && orderAvailable()) {
      if (hasCarrierCompany()) {
        addCarrierVehicles();
      } else {
        addCarrierCompanyAndVehicles();
      }

      if (order.data.carrierCompany) {
        findAvailableVehicles(order.data.carrierCompany.id);
      }
      checkCompanyAccess();
      setFormReady(true);
    }
  };

  const defineMode = () => {
    if (order.data.shipperCompany.id === contextCompanyId) {
      setMode(EditionMode.SHIPPER);
    }

    if (order.data.carrierCompany && order.data.carrierCompany.id === contextCompanyId) {
      setMode(EditionMode.CARRIER);
    }
  };

  useEffect(() => {
    if (orderAvailable()) {
      defineMode();
      setOrderLoaded(true);
    } else {
      setOrderLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (vehiclesAvailable()) {
      setVehiclesLoaded(true);
    } else {
      setVehiclesLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  useEffect(() => {
    switch (mode) {
      case EditionMode.SHIPPER: {
        setVehiclesLoaded(true);
        break;
      }
      case EditionMode.CARRIER: {
        dispatch(fetchCompanyVehicles(order.data.carrierCompany.id));
        break;
      }
      default: {
        /* NOP */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (companiesAvailable()) {
      setCompanyLoaded(true);
    } else {
      setCompanyLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    if (companyLoaded && orderLoaded && vehiclesLoaded) {
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
  }, [companyLoaded, orderLoaded, vehiclesLoaded]);

  const setCommonFields = () => {
    commonForm.setFields([
      {
        name: 'internalId',
        value: order.data.internalId,
      },
      {
        name: 'shipperName',
        value: order.data.name,
      },
      {
        name: 'carrierCompanyId',
        value: order.data.carrierCompany?.id,
      },
      {
        name: 'vehicleId',
        value: order.data.vehicles[0]?.id,
      },
    ]);
  };

  const extractStopTypes = (stop) => {
    const stopTypes = [];
    if (stop.isLoad) {
      stopTypes.push('loading');
    }
    if (stop.isUnload) {
      stopTypes.push('unloading');
    }
    return stopTypes;
  };

  const setTimeOrderFields = () => {
    timeMonitoringForm.setFields([
      {
        name: 'startDateTime',
        value: order.data.startMoment,
      },
      {
        name: 'endDateTime',
        value: order.data.endMoment,
      },
    ]);
  };

  const setTourOrderFields = () => {
    const orderStops = [];
    (order.data.stops || [])
      .sort(stopsByTimeSlotAndSequence)
      .forEach((stop) => {
        orderStops.push({
          id: stop.id,
          city: stop.place.city,
          endDateTime: stop.timeSlot.endMoment,
          fixed: stop.timeSlot.start === stop.timeSlot.end,
          cordinate: { lat: stop.latitude, lng: stop.longitude },
          localNumber: stop.place.localNumber,
          postcode: stop.place.postcode,
          sequence: stop.sequence,
          startDateTime: stop.timeSlot.startMoment,
          stopCtry: stop.place.country,
          stopType: extractStopTypes(stop),
          street: stop.place.street,
          streetNumber: stop.place.houseNumber,
          name: stop.name,
          additionalInfo: stop.additionalInfo,
        });
      });
    setTourStops(orderStops);
  };

  const setOrderTypeFields = () => {
    if (order.data.type === 'TIME') {
      setTimeOrderFields();
    } else {
      setTourOrderFields();
    }
  };

  useEffect(() => {
    if (dataLoaded) {
      setCompanyAndVehicle();
      setCommonFields();
      setOrderTypeFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  const addNextStop = useCallback((addRow) => {
    const stops = tourMonitoringForm.getFieldValue('stops');
    if (!stops || stops.length === 0) {
      addRow();
    }
    addRow();
  }, [tourMonitoringForm]);

  return (
    <>
      {formReady && (
        <div style={{ display: 'inline-block' }}>
          <Form
            style={{ marginTop: '12px', marginBottom: '40px' }}
            form={commonForm}
            layout="vertical"
          >
            <FormSection title={t('ORDER.EDIT_ORDER')}>
              <OrderDetailsSection />
            </FormSection>
            <FormSection title={t('ORDER.CARRIER_DETAILS')}>
              <OrderCarrierDetails
                disableCompanies={mode === EditionMode.CARRIER}
                form={commonForm}
                companies={companies}
                findAvailableVehicles={findAvailableVehicles}
                companyVehicles={companyVehicles}
              />
            </FormSection>
          </Form>

          <Form
            style={{ marginTop: '12px', marginBottom: '40px' }}
            form={commonForm}
            layout="vertical"
          >

            <FormSection title={t('ORDER.MONITORING_DETAILS')}>
              {order.data.type === 'TIME'
                ? (
                  <Form
                    form={timeMonitoringForm}
                    layout="vertical"
                  >
                    <FormSection subtitle={t('ORDER.TRIP_TIME_DESCRIPTION')} noDivider>
                      <TimeDetails
                        form={timeMonitoringForm}
                        fromDateLabel={t('COMMON.START_DATE')}
                        fromDateRequiredMessage={t('COMMON.START_DATE_REQUIRED')}
                        toDateLabel={t('COMMON.END_DATE')}
                        toDateRequiredMessage={t('COMMON.END_DATE_REQUIRED')}
                        toDateAfterStartMessage={t('COMMON.END_DATE_MUST_NOT_AFTER_START')}
                      />
                    </FormSection>
                  </Form>
                )
                : (
                  <Form
                    form={tourMonitoringForm}
                    layout="vertical"
                  >
                    <FormSection subtitle={t('ORDER.TRIP_DETAILS_DESCRIPTION')} noDivider>
                      <Form.List name="stops" initialValue={tourStops}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, index) => (
                              <StopSection
                                field={field}
                                remove={remove}
                                form={tourMonitoringForm}
                                possibleRemove={index !== 0 && index !== 1}
                                index={index}
                                key={index}
                              />
                            ))}
                            <Divider style={{ marginBottom: '10px' }} />
                            <HStretched _justify="flex-start">
                              <Button
                                type="link"
                                size="large"
                                onClick={() => addNextStop(add)}
                                icon={<PlusCircleOutlined />}
                              >
                                {t('ORDER.ADD_NEXT_STOP')}
                              </Button>
                            </HStretched>
                            <Divider style={{ marginTop: '10px' }} />
                          </>
                        )}
                      </Form.List>
                    </FormSection>
                  </Form>
                )}

            </FormSection>

            <HStretched _justify="flex-end">
              <Button type="link" size="large" style={{ marginRight: '12px' }} onClick={handleCancel}>
                {t('COMMON.CANCEL')}
              </Button>
              <Button type="primary" size="large" onClick={handleSubmit}>
                {t('COMMON.CHANGE')}
              </Button>
            </HStretched>
          </Form>
        </div>
      )}
    </>
  );
};

OrderEditingForm.propTypes = {
  order: orderPropTypes.isRequired,
  creation: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  companies: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      companyName: PropTypes.string,
      companyId: PropTypes.string,
      vehicles: PropTypes.arrayOf(PropTypes.shape({
        vehicleId: PropTypes.string,
        licencePlateNumber: PropTypes.string,
      })),
    })).isRequired,
  }).isRequired,
  commonForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  timeMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  tourMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
export default OrderEditingForm;
