import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Card, Col, Form, Input, Row, Button,
} from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  findCompanies, findVehicles, checkCompanyVehicleRelationExistence, FeatureToggle,
} from '../../store/actions';
import { isFeatureEnabled } from '../../store/selectors';
import {
  FlexColumn, InlineFlex, SuccessCheckOutlined, FailureCloseOutlined,
} from '../../components';

const SearchResult = {
  Found: 'FOUND',
  Searching: 'SEARCHING',
  NotFound: 'NOT_FOUND',
};

const StyledForm = styled(Form)`
  margin-top: 8px;
  margin-bottom: 8px;
  .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
`;

const SearchSection = styled.div`
  margin-bottom: 20px;
`;

const ResultIcons = {
  [SearchResult.Searching]: <LoadingOutlined />,
  [SearchResult.Found]: <SuccessCheckOutlined />,
  [SearchResult.NotFound]: <FailureCloseOutlined />,
};

const SearchResultComponent = ({ textConfig, result }) => (
  <span style={{ fontSize: '14px' }}>
    {ResultIcons[result]}
    <span style={{ marginLeft: '5px' }}>{textConfig[result]}</span>
  </span>
);

SearchResultComponent.propTypes = {
  textConfig: PropTypes.objectOf(PropTypes.string).isRequired,
  result: PropTypes.oneOf(Object.values(SearchResult)),
};

SearchResultComponent.defaultProps = {
  result: null,
};

const CarrierSearch = () => {
  const [vehicleSearchForm] = Form.useForm();
  const [companySearchForm] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [foundVehicles, setFoundVehicles] = useState({});
  const [foundCompanies, setFoundCompanies] = useState({});
  const [foundRelation, setFoundRelation] = useState({});
  const assetsSearchEnabled = useSelector(isFeatureEnabled(FeatureToggle.SEARCH_ASSETS));

  useEffect(() => {
    if (!assetsSearchEnabled) {
      history.replace('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsSearchEnabled]);

  const searchCompany = (values) => {
    setFoundCompanies({ result: SearchResult.Searching, args: values });
    return dispatch(findCompanies(values))
      .then((action) => {
        const company = action.payload && action.payload.data;
        setFoundCompanies({
          result: company ? SearchResult.Found : SearchResult.NotFound,
          args: values,
        });
        return company;
      });
  };

  const searchVehicle = (values) => {
    setFoundVehicles({ result: SearchResult.Searching, args: values });
    return dispatch(findVehicles(values))
      .then((action) => {
        const vehicles = action.payload && action.payload.data.exist;
        setFoundVehicles({
          result: vehicles ? SearchResult.Found : SearchResult.NotFound,
          args: values,
        });
        return vehicles;
      });
  };

  const searchVehiclesAndCompanies = (vehicleValues, companyValues) => {
    const args = {
      vehicle: vehicleValues.registrationNumber,
      company: `${companyValues.taxCountryCode || ''}${companyValues.taxNumber}`,
    };
    const company = {
      taxNumber: companyValues.taxNumber,
      taxCountryCode: companyValues.taxCountryCode,
    };
    const vehicle = {
      licencePlateNumber: vehicleValues.registrationNumber,
    };
    setFoundRelation({ result: SearchResult.Searching, args });
    dispatch(checkCompanyVehicleRelationExistence(company, vehicle))
      .then((action) => {
        if (action.payload.data && action.payload.data.exist) {
          setFoundRelation({ result: SearchResult.Found, args });
        } else {
          setFoundRelation({ result: SearchResult.NotFound, args });
        }
      });
  };

  const searchRelation = () => {
    Promise.all([
      vehicleSearchForm.validateFields(),
      companySearchForm.validateFields(),
    ]).then(
      ([vehicleValues, companyValues]) => searchVehiclesAndCompanies(vehicleValues, companyValues),
      () => {},
    );
  };

  const clearForms = () => {
    vehicleSearchForm.resetFields();
    companySearchForm.resetFields();
  };

  const renderVehiclesSearchResult = () => {
    if (!foundVehicles.result) {
      return (<span />);
    }
    const vehicle = foundVehicles.args.registrationNumber;
    return (
      <SearchResultComponent
        result={foundVehicles.result}
        textConfig={{
          [SearchResult.Found]: t('ASSETS.VEHICLE_FOUND', { vehicle }),
          [SearchResult.NotFound]: t('ASSETS.VEHICLE_NOT_FOUND', { vehicle }),
          [SearchResult.Searching]: t('ASSETS.CHECKING_VEHICLE', { vehicle }),
        }}
      />
    );
  };
  const renderCompaniesSearchResult = () => {
    if (!foundCompanies.result) {
      return (<span />);
    }
    const company = `${foundCompanies.args.taxCountryCode || ''}${foundCompanies.args.taxNumber}`;
    return (
      <SearchResultComponent
        result={foundCompanies.result}
        textConfig={{
          [SearchResult.Found]: t('ASSETS.COMPANY_FOUND', { company }),
          [SearchResult.NotFound]: t('ASSETS.COMPANY_NOT_FOUND', { company }),
          [SearchResult.Searching]: t('ASSETS.CHECKING_COMPANY', { company }),
        }}
      />
    );
  };
  const renderRelationSearchResult = () => {
    if (!foundRelation.result) {
      return (<span />);
    }
    const { company, vehicle } = foundRelation.args;
    return (
      <SearchResultComponent
        result={foundRelation.result}
        textConfig={{
          [SearchResult.Found]: t('ASSETS.RELATION_FOUND', { vehicle, company }),
          [SearchResult.NotFound]: t('ASSETS.RELATION_NOT_FOUND', { vehicle, company }),
          [SearchResult.Searching]: t('ASSETS.CHECKING_RELATION', { vehicle, company }),
        }}
      />
    );
  };

  return (
    <>
      <Row>
        <Card size="small" title={t('ASSETS.FIND_CARRIERS')} style={{ marginTop: '20px' }}>
          <Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
            <Col style={{ padding: '0 12px' }}>
              <SearchSection>
                <span>
                  {t('ASSETS.SEARCH_VEHICLE')}
                </span>
                <StyledForm form={vehicleSearchForm} layout="inline" onFinish={searchVehicle}>
                  <Form.Item
                    name="registrationNumber"
                    rules={[{
                      required: true,
                      transform: (value) => (value ? value.trim() : value),
                      message: t('ASSETS.REQUIRED.LICENSE_PLATE'),
                    }]}
                  >
                    <Input placeholder={t('PROPERTIES.LICENSE_PLATE_NUMBER')} />
                  </Form.Item>
                  <Form.Item style={{ marginRight: '0' }}>
                    <Button type="primary" htmlType="submit">
                      {t('ASSETS.FIND')}
                    </Button>
                  </Form.Item>
                </StyledForm>
                {renderVehiclesSearchResult()}
              </SearchSection>
              <SearchSection>
                <span>
                  {t('ASSETS.SEARCH_COMPANY')}
                </span>
                <StyledForm form={companySearchForm} layout="inline" onFinish={searchCompany}>
                  <Form.Item name="taxCountryCode">
                    <Input placeholder={t('PROPERTIES.COUNTRY_CODE')} />
                  </Form.Item>
                  <Form.Item
                    name="taxNumber"
                    rules={[{
                      required: true,
                      transform: (value) => (value ? value.trim() : value),
                      message: t('ASSETS.REQUIRED.TAX_NUMBER'),
                    }]}
                  >
                    <Input placeholder={t('PROPERTIES.VAT_NUMBER')} />
                  </Form.Item>
                  <Form.Item style={{ marginRight: '0' }}>
                    <Button type="primary" htmlType="submit">
                      {t('ASSETS.FIND')}
                    </Button>
                  </Form.Item>
                </StyledForm>
                {renderCompaniesSearchResult()}
              </SearchSection>
            </Col>
          </Row>
          <FlexColumn>
            <InlineFlex style={{ marginBottom: '8px' }}>
              <Button type="primary" onClick={searchRelation}>
                {t('ASSETS.CHECK_RELATION')}
              </Button>
              <Button onClick={clearForms} style={{ marginLeft: '16px' }}>
                {t('COMMON.CLEAR_FORM')}
              </Button>
            </InlineFlex>
            {renderRelationSearchResult()}
          </FlexColumn>
        </Card>
      </Row>
    </>
  );
};

export default CarrierSearch;
