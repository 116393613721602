import React from 'react';
import { Row, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { EmptyStyledTable, StyledTable } from '../../../../components';
import TelematicAccountListTableActions from './TelematicAccountListTableActions';
import TableLoadingComponent from '../../../../components/Table/TableLoadingComponent';

export default function TelematicAccountListTable({ accounts, isLoading }) {
  const { t } = useTranslation();

  const getStatusLabel = (status) => t(`TELEMATICS.ACCOUNT.OVERVIEW.STATUSES.${status}.LABEL`);
  const getStatusColor = (status) => t(`TELEMATICS.ACCOUNT.OVERVIEW.STATUSES.${status}.COLOR`);
  const renderAccountNameWithDescription = (_, { accountDisplayName, accountDescription }) => (
    <>
      {accountDisplayName}
      <br />
      <small>{accountDescription}</small>
    </>
  );
  const renderStatus = (_, { workingStatus }) => (
    <Row justify="center">
      <Tag
        key={workingStatus}
        color={getStatusColor(workingStatus)}
      >
        {getStatusLabel(workingStatus)}
      </Tag>
    </Row>
  );
  const renderActions = (_, { accountId, hasFormConfiguration }) => (
    <>
      <TelematicAccountListTableActions accountId={accountId} editDisabled={!hasFormConfiguration} />
    </>
  );
  const renderProviderName = (_, { providerName }) => (
    <>
      {providerName || t('TELEMATICS.ACCOUNT.OVERVIEW.NO_PROVIDER')}
    </>
  );

  const compareIgnoreCase = (a, b) => (
    // eslint-disable-next-line no-nested-ternary
    a ? b ? a.toLowerCase().trim().localeCompare(b.toLowerCase().trim()) : -1 : 1
  );

  const getColumnName = (key) => t(`TELEMATICS.ACCOUNT.OVERVIEW.COLUMNS.${key}`);
  const columns = [
    {
      title: getColumnName('ACCOUNT_NAME'),
      dataIndex: 'accountDisplayName',
      key: 'accountDisplayName',
      width: 200,
      filterIcon: <SearchOutlined />,
      filterSearch: true,
      filters: accounts
        .map(({ accountDisplayName }) => accountDisplayName)
        .filter((value) => value && value.trim().length > 0)
        .filter((value, index, self) => self.indexOf(value) === index) // unique
        .sort(compareIgnoreCase)
        .map((accountDisplayName) => ({ text: accountDisplayName, value: accountDisplayName })),
      onFilter: (value, record) => record.accountDisplayName === value,
      sorter: (rowA, rowB) => compareIgnoreCase(rowA.accountDisplayName, rowB.accountDisplayName),
      render: renderAccountNameWithDescription,
    },
    {
      title: getColumnName('PROVIDER'),
      dataIndex: 'providerName',
      key: 'providerName',
      width: 200,
      filterIcon: <SearchOutlined />,
      filterSearch: true,
      filters: accounts
        .map(({ providerName }) => providerName)
        .filter((value) => value && value.trim().length > 0)
        .filter((value, index, self) => self.indexOf(value) === index) // unique
        .sort(compareIgnoreCase)
        .map((providerName) => ({ text: providerName, value: providerName })),
      onFilter: (value, record) => record.providerName === value,
      sorter: (rowA, rowB) => compareIgnoreCase(rowA.providerName, rowB.providerName),
      render: renderProviderName,
    },
    {
      title: getColumnName('DEVICE_COUNT'),
      dataIndex: 'deviceCount',
      key: 'deviceCount',
      width: 200,
    },
    {
      title: getColumnName('WORKING_STATUS'),
      dataIndex: 'workingStatus',
      key: 'workingStatus',
      width: 200,
      render: renderStatus,
    },
    {
      title: getColumnName('ACTIONS'),
      key: 'actions',
      width: 50,
      render: renderActions,
    },
  ];

  if (accounts?.length > 0) {
    return (
      <StyledTable
        dataSource={accounts}
        columns={columns}
        rowKey={(record) => `${record.accountId}`}
        size="small"
        bordered
        scroll={{ y: window.innerHeight }}
        loading={TableLoadingComponent(isLoading)}
      />
    );
  }
  return (<EmptyStyledTable columns={columns} size="small" bordered />);
}

TelematicAccountListTable.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      accountDisplayName: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
};

TelematicAccountListTable.defaultProps = {
  accounts: [],
};
