import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const EMAIL_SHARE_ORDERS = axiosActionsFactory('EMAIL_SHARE_ORDERS');

export const shareOrderToEmails = (internalId, emails, additionalInfo, contextCompanyId) => (dispatch) => dispatch({
  type: EMAIL_SHARE_ORDERS.START,
  payload: {
    request: {
      url: UrlService.orderShareToEmail(internalId),
      method: 'post',
      data: {
        emails,
        additionalInfo,
        contextCompanyId,
      },
    },
  },
});
