import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { OwnershipType } from '../../../../types';
import { StyledTable, TableLoadingComponent } from '../../../../components';
import { setVehiclesPagination } from '../../../../store/actions';
import { toVehiclesTableProps } from '../../../../types/mappers';
import { COL_WIDTHS } from './VehiclesTable.constants';
import {
  getFleetPageSelectedRowsKeys, getFleetPageState, getLastTraceMode, getVehicles,
} from '../../../../store/selectors';

const VehiclesTableComponent = ({
  columns, size, pageSizeOptions, viewportHeight, onRefresh, onChange,
}) => {
  const {
    selectedVehicle, pagination, vehicles,
  } = useSelector(getVehicles);
  const { selection } = useSelector(getFleetPageState);
  const selectedRowsKeys = useSelector(getFleetPageSelectedRowsKeys);
  const lastTraceVehicleId = useSelector(getLastTraceMode);
  const [items, setItems] = useState([]);
  const [width, setWidth] = useState(1000);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const newItems = vehicles.data.vehicles.map(toVehiclesTableProps);
    setItems(newItems);
  }, [vehicles.data.vehicles]);

  useEffect(() => {
    const columnsWidth = columns.map((a) => a.width).reduce((a, b) => a + b, 0);
    setWidth(columnsWidth);
  }, [columns]);

  const onPageChange = (page, pageSize) => {
    dispatch(setVehiclesPagination({ page, size: pageSize }));
    onRefresh();
  };

  const getRowStyle = (row) => {
    const classes = [];

    if ((selectedVehicle && (selectedVehicle.computedId === row.computedId)) || lastTraceVehicleId === row.vehicleId) {
      classes.push('ant-table-row-selected');
    }

    if (!selection) {
      classes.push('ant-table-row-nohover');
    } else {
      classes.push('ant-table-row-selectable');
      if (row.ownershipType === OwnershipType.SHARING) {
        classes.push('ant-table-row-not-selectable');
      }
    }

    return classes.join(' ');
  };

  const tableOnRow = useCallback((row) => ({
    onClick: () => {
      if (selection && row.ownershipType !== OwnershipType.SHARING) {
        selection.onSelect(row);
      }
    },
  }), [selection]);

  const renderTotal = (total) => (<span>{t('COMMON.TOTAL', { total })}</span>);

  const tableRowSelection = (selection && {
    ...selection,
    selectedRowKeys: selectedRowsKeys,
    columnWidth: `${COL_WIDTHS.SELECTION}px`,
    getCheckboxProps: ({ ownershipType }) => ({
      disabled: ownershipType === OwnershipType.SHARING,
    }),
  }) || undefined;

  const paginationOnChange = (page, pageSize) => onPageChange(page, pageSize);

  const tablePagination = {
    showSizeChanger: true,
    showTotal: renderTotal,
    pageSizeOptions,
    total: vehicles.data.totalVehicles,
    disabled: vehicles.loading,
    current: pagination.page,
    pageSize: pagination.size,
    onChange: paginationOnChange,
  };

  const tableScroll = { x: width, y: viewportHeight };

  return (
    <StyledTable
      onRow={tableOnRow}
      rowSelection={tableRowSelection}
      rowClassName={getRowStyle}
      dataSource={items}
      scroll={tableScroll}
      rowKey="computedId"
      size={size}
      bordered
      pagination={tablePagination}
      columns={columns}
      loading={TableLoadingComponent(vehicles.majorReload)}
      onChange={onChange}
    />
  );
};
VehiclesTableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  viewportHeight: PropTypes.number.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

VehiclesTableComponent.defaultProps = {
  size: 'default',
  pageSizeOptions: ['2', '10', '20', '50', '100', '200', '400'],
};

export default VehiclesTableComponent;
