import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VEHICLE_TYPES, vehicleTypeProp } from '../../../types';

const DEFAULT_TYPE = 'UNKNOWN';
const VehicleType = ({ type }) => {
  const { t } = useTranslation();
  const renderComponent = () => (
    <span>{VEHICLE_TYPES.includes(type) ? t(`DICTIONARY.VEHICLE_TYPE.${type}`) : type}</span>
  );
  const renderDefault = () => (<span>{t('DICTIONARY.VEHICLE_TYPE.UNKNOWN')}</span>);

  return (type ? renderComponent() : renderDefault());
};

VehicleType.propTypes = {
  type: PropTypes.oneOfType([vehicleTypeProp, PropTypes.string]),
};
VehicleType.defaultProps = {
  type: DEFAULT_TYPE,
};

export default VehicleType;
