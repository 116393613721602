import { combineReducers } from 'redux';
import moment from 'moment';
import { FETCH_TOUR_STATISTICS, RESET_COMPANY_CONTEXT } from '../../actions';

const INIT_DETAILS_STATE = {
  data: {},
  loading: false,
  lastUpdated: moment(),
  error: null,
};

const statisticsReducer = (state = { ...INIT_DETAILS_STATE }, { type, payload, meta }) => {
  switch (type) {
    case FETCH_TOUR_STATISTICS.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case FETCH_TOUR_STATISTICS.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state, loading: false, error: payload, data: null, lastUpdated: moment(),
      };
    case FETCH_TOUR_STATISTICS.START:
      return { ...state, loading: true, actionId: payload.actionId };
    case RESET_COMPANY_CONTEXT:
      return { ...INIT_DETAILS_STATE };
    default:
      return state;
  }
};

export default combineReducers({
  statistics: statisticsReducer,
});
