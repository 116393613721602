import { FETCH_FLEET_VEHICLES_REDUCED, CLEAR_FLEET_VEHICLES } from '../../actions/fleet/fleet-vehicles.actions';

const initialState = [];
function vehicles(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case FETCH_FLEET_VEHICLES_REDUCED.START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FLEET_VEHICLES_REDUCED.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case FETCH_FLEET_VEHICLES_REDUCED.FAIL:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_FLEET_VEHICLES:
      return {
        ...state,
        data: undefined,
        loading: false,
      };
    default:
      return state;
  }
}

export default vehicles;
