import React from 'react';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { getSelectedCompany } from '../../store/selectors';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import TelematicAccountCreationPage from './TelematicAccountCreation/TelematicAccountCreationPage';
import TelematicAccountListPage from './TelematicAccountList/TelematicAccountListPage';
import TelematicAccountEditPage from './TelematicAccountEdit/TelematicAccountEditPage';

export default () => {
  const selectedCompany = useSelector(getSelectedCompany);
  const match = useRouteMatch();

  return (
    <>
      {
        !selectedCompany ? <SelectCompanyFirstComponent /> : (
          <>
            <Route
              exact
              path={`${match.url}`}
              component={TelematicAccountListPage}
            />
            <Route
              exact
              path={`${match.url}/accounts/create`}
              component={TelematicAccountCreationPage}
            />
            <Route
              path={`${match.url}/accounts/edit/:accountId`}
              component={TelematicAccountEditPage}
            />
          </>
        )
      }
    </>
  );
};
