import styled, { css } from 'styled-components';
import { Row } from 'antd';

export const StyleFormElementNarrow = { width: '225px', maxWidth: '225px' };
export const StyleFormElementWide = { width: '478px', maxWidth: '478px' };
export const StyleFormElementExtraWide = { width: '678px', maxWidth: '678px' };
export const StyleFormElementWideWithMinHeight = { width: '478px', maxWidth: '478px', minHeight: '150px' };

export const Styles = {
// because styled components seem to ignore styling...
  narrow: StyleFormElementNarrow,
  wide: StyleFormElementWide,
  extraWide: StyleFormElementExtraWide,
  wideWithMinHeight: StyleFormElementWideWithMinHeight,
};

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  padding: 30px 50px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

export const Wrapper = styled(Row)`
  ${(props) => props.hidden && css`
    display: none;
  `}
`;
