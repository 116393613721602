import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  FormItem, FormSelect, HStretched, SectionDescription,
} from '../../../../components';

const MINIMUM_SYMBOLS_TO_SEARCH = 2;

const getVehiclesPickerPlaceholder = (form, t) => (
  form.getFieldValue('carrierCompanyId') ? t('REQUEST.VEHICLES_PROMPT') : t('REQUEST.COMPANY_BEFORE_VEHICLE')
);

const RequestCarrierDetails = ({
  form, findAvailableVehicles, companies, companyVehicles = { data: [] },
}) => {
  const { t } = useTranslation();

  const [companySearchRequest, setCompanySearchRequest] = useState('');
  const [vehicleSearchRequest, setVehicleSearchRequest] = useState('');

  const filterCompanies = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const filterVehicles = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onCompanySearch = (input) => {
    setCompanySearchRequest(input);
  };

  const onVehicleSearch = (input) => {
    setVehicleSearchRequest(input);
  };

  const onCompanySelected = (companyId) => {
    setVehicleSearchRequest('');
    findAvailableVehicles(companyId);
  };

  const onCompanySelectionBlur = () => {
    setCompanySearchRequest('');
  };

  const onVehicleSelectionBlur = () => {
    setVehicleSearchRequest('');
  };

  return (
    <>
      <HStretched _justify="flex-start">
        <FormItem
          label={t('REQUEST.SEARCH_COMPANY')}
          name="carrierCompanyId"
          rules={[{ required: true, message: t('REQUEST.COMPANY_MISSING') }]}
        >

          <FormSelect
            showSearch
            placeholder={t('REQUEST.COMPANY_PROMPT')}
            optionFilterProp="children"
            onSelect={onCompanySelected}
            loading={companies.loading}
            onBlur={() => onCompanySelectionBlur()}
            filterOption={filterCompanies}
            onSearch={(input) => onCompanySearch(input)}
          >
            {companySearchRequest.length >= MINIMUM_SYMBOLS_TO_SEARCH
              ? (
                companies.data
                  .filter((company) => company.id)
                  .map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {`${company.name} (${company.taxNumber})`}
                    </Select.Option>
                  ))
              )
              : (
                <Select.Option disabled>
                  {t('REQUEST.COMPANY_PROMPT')}
                </Select.Option>
              )}
          </FormSelect>
        </FormItem>
        <FormItem
          label={t('REQUEST.SEARCH_VEHICLE')}
          name="vehicleId"
          rules={[{ required: true, message: t('REQUEST.VEHICLES_MISSING') }]}
        >
          <FormSelect
            loading={companyVehicles.loading}
            showSearch
            disabled={!form.getFieldValue('carrierCompanyId')}
            placeholder={getVehiclesPickerPlaceholder(form, t)}
            optionFilterProp="children"
            onBlur={() => onVehicleSelectionBlur()}
            filterOption={filterVehicles}
            onSearch={(input) => onVehicleSearch(input)}
          >
            {vehicleSearchRequest.length >= MINIMUM_SYMBOLS_TO_SEARCH
              ? (
                companyVehicles.data
                  .filter((vehicle) => vehicle.id)
                  .map(({ id, licencePlateNumber }) => (
                    <Select.Option
                      key={id}
                      value={id}
                    >
                      {licencePlateNumber}
                    </Select.Option>
                  ))
              )
              : (
                <Select.Option disabled>
                  {t('REQUEST.SEARCH_VEHICLE')}
                </Select.Option>
              )}
          </FormSelect>
        </FormItem>
      </HStretched>
      <SectionDescription>{t('REQUEST.CARRIER_NOTE')}</SectionDescription>
    </>
  );
};

RequestCarrierDetails.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
  }).isRequired,
  findAvailableVehicles: PropTypes.func.isRequired,
  companies: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      taxNumber: PropTypes.string.isRequired,
    })),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  companyVehicles: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      licencePlateNumber: PropTypes.string.isRequired,
    })),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default RequestCarrierDetails;
