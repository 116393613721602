import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import {
  Col, Modal, Row, Switch,
} from 'antd';
import { NodeIndexOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { info } from '../../services/Logging';
import * as vehiclesActionCreators from '../../store/actions/vehicles';
import * as fleetActionCreators from '../../store/actions/fleet';
import * as interfaceConfigurationActionCreators
from '../../store/actions/personalization/interfaceConfiguration.actions';
import * as tableConfigurationActionCreators
from '../../store/actions/personalization/tableConfiguration.actions';
import VehiclesTable from './Components/VehicleTable/VehiclesTable';
import VehiclesMap from './Components/Map/VehiclesMap';
import { sharedVehicle } from '../../types';
import {
  getRefreshIntervalSeconds, getSelectedCompany, getSelectedVehicle, getVehiclesData, getVehiclesFilters,
  getVehiclesPagination, TableName, getLastTraceMode,
} from '../../store/selectors';
import { FullHeightRow } from '../../components';
import SuccessScreenInvitation from './SuccessScreenInvitation';
import SuccessScreenRegistration from './SuccessScreenRegistration';
import * as personalizationActionCreators from '../../store/actions/personalization';

class VehiclesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0, windowHeight: window.innerHeight, propableRoute: true,
    };
    this.windowResizeListener = (ev) => this.setState({ windowHeight: ev.currentTarget.innerHeight });
    this.bind();
  }

  componentDidMount() {
    this.fetchViewSetUp()
      .then(() => this.startFetchingLoop());
    window.addEventListener('resize', this.windowResizeListener);
    this.viewSuccessScreen();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intervalId } = this.state;
    const { selectedCompany, refreshIntervalSeconds, lastModeTrace } = this.props;
    const { selectedCompany: prevCompany, refreshIntervalSeconds: prevInterval } = prevProps;
    const { lastModeTrace: prevLastModeTrace } = prevProps;

    if (this.shouldRestartFetchingLoop(prevProps)) {
      const auto = !prevInterval && refreshIntervalSeconds;
      clearInterval(intervalId);
      if (selectedCompany !== prevCompany) {
        this.fetchTableConfiguration()
          .then(() => this.startFetchingLoop(auto));
      } else {
        this.startFetchingLoop(auto);
      }
    }

    if (prevLastModeTrace !== null && lastModeTrace === null) {
      this.setState({ propableRoute: true });
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    const { unselectVehicle, resetFleetSelectionMode } = this.props;
    clearInterval(intervalId);
    this.setState({ intervalId: 0 });
    window.removeEventListener('resize', this.windowResizeListener);
    unselectVehicle();
    resetFleetSelectionMode();
  }

  handleVehicleSelection(vehicle) {
    const { selectVehicle, unselectVehicle } = this.props;

    if (vehicle) {
      selectVehicle(vehicle);
    } else {
      unselectVehicle();
    }
  }

  viewSuccessScreen() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    if (query.get('successScreen') === 'invitation') {
      Modal.success({
        width: 686,
        height: 455,
        centered: true,
        mask: true,
        content: <SuccessScreenInvitation />,
        icon: (<></>),
        closable: true,
        okButtonProps: { style: { display: 'none' } },
        onCancel: () => {
          window.history.pushState(null, null, '/#/vehicles');
        },
        maskClosable: true,
      });
    } else if (query.get('successScreen') === 'registration') {
      Modal.success({
        width: 686,
        height: 455,
        centered: true,
        mask: true,
        content: <SuccessScreenRegistration />,
        icon: (<></>),
        closable: true,
        okButtonProps: { style: { display: 'none' } },
        onCancel: () => {
          window.history.pushState(null, null, '/#/vehicles');
        },
        maskClosable: true,
      });
    }
  }

  bind() {
    this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
    this.startFetchingLoop = this.startFetchingLoop.bind(this);
    this.updateFiltersAndRefresh = this.updateFiltersAndRefresh.bind(this);
    this.clearFiltersAndRefresh = this.clearFiltersAndRefresh.bind(this);
  }

  refresh() {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }

    this.startFetchingLoop();
  }

  startFetchingLoop(isAutomatic = false) {
    const {
      fetchVehicles, selectedCompany, refreshIntervalSeconds,
    } = this.props;
    const { intervalId } = this.state;
    clearInterval(intervalId);

    if (!selectedCompany) {
      return;
    }

    if (refreshIntervalSeconds) {
      info(`Scheduling vehicles refresh in: ${refreshIntervalSeconds * 1000}ms`);
      const newIntervalId = setTimeout(() => {
        this.startFetchingLoop(true);
      }, refreshIntervalSeconds * 1000);
      this.setState({ intervalId: newIntervalId });
    }

    if (!isAutomatic || refreshIntervalSeconds) {
      fetchVehicles(isAutomatic);
    }
  }

  fetchTableConfiguration() {
    const {
      fetchLastUsedTableConfigurationOrCached,
      fetchTableConfigurationsOrCached,
    } = this.props;

    return Promise.all([
      fetchLastUsedTableConfigurationOrCached(TableName.Fleet),
      fetchTableConfigurationsOrCached(TableName.Fleet),
    ]);
  }

  fetchViewSetUp() {
    const {
      fetchLastUsedInterfaceConfiguration,
    } = this.props;

    return Promise.all([
      fetchLastUsedInterfaceConfiguration(TableName.Vehicles),
      this.fetchTableConfiguration(),
    ]);
  }

  shouldRestartFetchingLoop(prevProps) {
    const { selectedCompany } = this.props;
    const { selectedCompany: prevCompany } = prevProps;
    return selectedCompany !== prevCompany;
  }

  updateFilters(filter) {
    const { mergeUpdateInterfaceFilters } = this.props;
    mergeUpdateInterfaceFilters(TableName.Vehicles, filter);
  }

  updateFiltersAndRefresh(filter) {
    const { resetVehiclesPagination } = this.props;
    this.updateFilters(filter);
    resetVehiclesPagination();
    this.startFetchingLoop();
  }

  clearFiltersAndRefresh() {
    const { clearInterfaceFilters, resetVehiclesPagination } = this.props;
    clearInterfaceFilters(TableName.Vehicles);
    resetVehiclesPagination();
    this.startFetchingLoop();
  }

  render() {
    const { windowHeight } = this.state;
    const { filters } = this.props;
    const { propableRoute } = this.state;
    const { t } = this.props;
    const { lastModeTrace } = this.props;

    return (
      <FullHeightRow gutter={[10, 10]}>
        <Col span={8}>
          <Row align="middle" justify="end" style={{ marginTop: '10px' }}>
            <Col>
              {t('PROPERTIES.SHOW_HIDE_ROUTE')}
              <Switch
                disabled={lastModeTrace === null}
                checked={!propableRoute}
                icon={<NodeIndexOutlined />}
                type="primary"
                size="small"
                onClick={() => { this.setState({ propableRoute: !propableRoute }); }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <VehiclesMap viewportHeight={windowHeight - 120} tooglePropableRoute={propableRoute} />
          </Row>
        </Col>
        <Col span={16}>
          <VehiclesTable
            viewportHeight={windowHeight - 290}
            filters={filters}
            size="small"
            onVehicleSelected={this.handleVehicleSelection}
            onRefresh={this.startFetchingLoop}
            updateFilters={this.updateFiltersAndRefresh}
            clearFilters={this.clearFiltersAndRefresh}
          />
        </Col>
      </FullHeightRow>
    );
  }
}

VehiclesComponent.propTypes = {
  vehicles: PropTypes.shape({
    data: PropTypes.shape({
      vehicles: PropTypes.arrayOf(sharedVehicle),
      totalVehicles: PropTypes.number,
    }),
    loading: PropTypes.bool,
    lastUpdated: MomentPropTypes.momentObj,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  resetVehiclesPagination: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  selectVehicle: PropTypes.func.isRequired,
  unselectVehicle: PropTypes.func.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  refreshIntervalSeconds: PropTypes.number,
  fetchLastUsedInterfaceConfiguration: PropTypes.func.isRequired,
  fetchLastUsedTableConfigurationOrCached: PropTypes.func.isRequired,
  fetchTableConfigurationsOrCached: PropTypes.func.isRequired,
  resetFleetSelectionMode: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  mergeUpdateInterfaceFilters: PropTypes.func.isRequired,
  clearInterfaceFilters: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  lastModeTrace: PropTypes.string,
};

VehiclesComponent.defaultProps = {
  filters: {},
  refreshIntervalSeconds: 120,
  lastModeTrace: null,
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  vehicles: getVehiclesData(state),
  pagination: getVehiclesPagination(state),
  selectedVehicle: getSelectedVehicle(state),
  refreshIntervalSeconds: getRefreshIntervalSeconds(TableName.Vehicles)(state),
  filters: getVehiclesFilters(state),
  lastModeTrace: getLastTraceMode(state),
});

const actionCreators = {
  fetchVehicles: vehiclesActionCreators.fetchVehicles,
  selectVehicle: vehiclesActionCreators.selectVehicle,
  unselectVehicle: vehiclesActionCreators.unselectVehicle,
  resetVehiclesPagination: vehiclesActionCreators.resetVehiclesPagination,
  fetchLastUsedInterfaceConfiguration: interfaceConfigurationActionCreators.fetchLastUsedInterfaceConfiguration,
  fetchLastUsedTableConfigurationOrCached: tableConfigurationActionCreators.fetchLastUsedTableConfigurationOrCached,
  fetchTableConfigurationsOrCached: tableConfigurationActionCreators.fetchTableConfigurationsOrCached,
  resetFleetSelectionMode: fleetActionCreators.resetFleetSelectionMode,
  mergeUpdateInterfaceFilters: personalizationActionCreators.mergeUpdateInterfaceFilters,
  clearInterfaceFilters: personalizationActionCreators.clearInterfaceFilters,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(VehiclesComponent));
