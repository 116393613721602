import styled from 'styled-components';
import { Col, Radio, Row } from 'antd';
import { FlexColumn } from '../../components';

export const VehicleDetailsWrapper = styled(FlexColumn)`
  padding-top: 7px;
  padding-bottom: 10px;
`;

export const DetailsPaneCol = styled(Col)`
  overflow-y: auto;
`;

export const DetailsRow = styled(Row)`
  padding-top: 5px;
  flex-flow: nowrap;
  height: calc(100% - 25px);
  ${VehicleDetailsWrapper} & {
    flex: 1 1 auto;
  }
`;

export const MapExtrasRow = styled(Row)`
  height: 24px;
`;

export const MapModeSwitch = styled(Radio.Group)`
  margin-right: 30px;
`;
