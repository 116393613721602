import { SET_VEHICLE_SORTING } from '../../actions';

const INIT_SORTING = {
  order: 'ascend',
  field: 'vehicleCompany.name',
};

export default (state = { ...INIT_SORTING }, { type, payload }) => {
  switch (type) {
    case SET_VEHICLE_SORTING:
      return { ...payload };
    default:
      return state;
  }
};
