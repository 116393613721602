import { combineReducers } from 'redux';
import { FETCH_REQUEST_DETAILS } from '../../actions/requests/request-details.actions';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

function requestDetailsReducer(state = { ...initialState }, { type, payload }) {
  switch (type) {
    case FETCH_REQUEST_DETAILS.START:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case FETCH_REQUEST_DETAILS.SUCCESS:
      return {
        ...state,
        data: payload.data,
        error: false,
        loading: false,
      };
    case FETCH_REQUEST_DETAILS.FAIL:
    case FETCH_REQUEST_DETAILS:
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  requestDetails: requestDetailsReducer,
});
