import PropTypes from 'prop-types';
import {
  loadingTypeProp, ownershipTypeProp, vehicleBodyTypeProp, vehicleTypeProp,
} from './dictionaries';

const sharingVehicleType = PropTypes.shape({
  id: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  chassisNumber: PropTypes.string,
  licencePlateNumber: PropTypes.string,
  licencePlateCountryCode: PropTypes.string,
  manufactureDate: PropTypes.string,
  vehicleType: PropTypes.oneOfType([vehicleTypeProp, PropTypes.string]),
  bodyType: vehicleBodyTypeProp,
  loadingType: loadingTypeProp,
  emissionStandard: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  createdDate: PropTypes.number,
  version: PropTypes.number,
  versionTimestamp: PropTypes.number,
  userId: PropTypes.string,
  system: PropTypes.string,
});

const sharingCompanyType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  taxNumber: PropTypes.string,
  taxCountryCode: PropTypes.string,
  registrationNumber: PropTypes.string,
  businessDescription: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  localNumber: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phoneNumbers: PropTypes.arrayOf(PropTypes.string),
  emailAddresses: PropTypes.arrayOf(PropTypes.string),
  faxNumbers: PropTypes.arrayOf(PropTypes.string),
});

export const sharingType = PropTypes.shape({
  companyId: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
  ownershipType: ownershipTypeProp,
  useDateFrom: PropTypes.number,
  useDateTo: PropTypes.number,
  ownershipStatus: PropTypes.string,
  createdDate: PropTypes.number,
  personFirstName: PropTypes.string,
  personLastName: PropTypes.string,
  personEmail: PropTypes.string,
  vehicle: sharingVehicleType,
  company: sharingCompanyType,
});

export const SharingComputedStatus = {
  Expired: 'EXPIRED',
  Ongoing: 'ONGOING',
  Future: 'FUTURE',
};
