import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Highlighteable } from '../../Text';

const CompanyInfo = ({
  company, highlight, showLocation, showEmail,
}) => {
  const { t } = useTranslation();
  const darkgray = 'darkgray';
  return (
    (company && (
      <>
        <Highlighteable content={company.name} search={highlight} />
        <br />
        <Highlighteable
          content={`${company.taxCountryCode}${company.taxNumber}`}
          search={highlight}
          color={darkgray}
        />
        {showEmail && company && company.emailAddresses && company.emailAddresses.length > 0 && (
          <>
            <br />
            <Highlighteable content={company.emailAddresses[0]} search={highlight} />
          </>
        )}
        {showLocation && (company.country || company.city)
        && (
          <>
            <br />
            <span>
              <Highlighteable content={company.country} search={highlight} />
              {' '}
              /
              {' '}
              <Highlighteable content={company.city} search={highlight} />
            </span>
          </>
        )}
      </>
    )) || t('COMMON.MISSING')
  );
};

CompanyInfo.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    taxNumber: PropTypes.string.isRequired,
    taxCountryCode: PropTypes.string.isRequired,
    country: PropTypes.string,
    city: PropTypes.string,
    emailAddresses: PropTypes.arrayOf(PropTypes.string),
  }),
  showLocation: PropTypes.bool,
  showEmail: PropTypes.bool,
  highlight: PropTypes.string,
};

CompanyInfo.defaultProps = {
  showLocation: true,
  showEmail: false,
  highlight: null,
  company: null,
};

export default CompanyInfo;
