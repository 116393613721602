/* eslint-disable no-case-declarations */

import moment from 'moment';
import { FETCH_CARRIERS } from '../../actions/carriers';
import { RESET_COMPANY_CONTEXT } from '../../actions';

const carriersInitState = {
  loading: false,
  majorReload: false,
  error: null,
  lastUpdated: moment(),
  data: {
    page: 0,
    size: 10,
    totalCarriers: 10,
    carriers: [],
  },
};

export default function carriersReducer(state = null, { type, payload, meta }) {
  switch (type) {
    case FETCH_CARRIERS.START:
      return {
        ...state, loading: true, majorReload: !payload.silentRefresh, actionId: payload.actionId,
      };
    case FETCH_CARRIERS.SUCCESS: {
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }

      const carriers = payload.data.invitedCarriers.map((carrier) => ({
        companyName: carrier.companyName,
        vatNumber: carrier.vatNumber,
        email: carrier.email,
        stage: carrier.stage,
        cooperationType: carrier.cooperationType,
        gpsProvider: carrier.gpsProvider,
      }));

      return {
        ...state,
        data: {
          page: payload.data.page,
          size: payload.data.size,
          totalCarriers: payload.data.totalElements,
          carriers,
        },
        loading: false,
        majorReload: false,
        error: undefined,
        lastUpdated: moment(),
      };
    }
    case FETCH_CARRIERS.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        majorReload: false,
        error: payload,
        data: carriersInitState.data,
        lastUpdated: moment(),
      };
    case RESET_COMPANY_CONTEXT:
      return { ...carriersInitState };
    default:
      return state;
  }
}
