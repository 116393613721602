import { SelectionType } from '../../../types/enums/fleet.enum';
import {
  getFleetPageSelectedRowsKeys,
  getFleetSelectionType,
  getSelectedCompany,
  getFleetVehicles,
} from '../../selectors';
import { fetchFleetVehicles } from './fleet-vehicles.actions';

export const FLEET_APPEND_SELECTED_ROW = 'FLEET_APPEND_SELECTED_ROW';
export const FLEET_SELECT_ALL = 'FLEET_SELECT_ALL';
export const FLEET_UNSELECT_ALL = 'FLEET_UNSELECT_ALL';
export const FLEET_SELECT_SINGLE_ROW = 'FLEET_SELECT_SINGLE_ROW';
export const FLEET_UNSELECT_ROW = 'FLEET_UNSELECT_ROW';
export const FLEET_SET_SELECTION_MODE = 'FLEET_SET_SELECTION_MODE';
export const FLEET_RESET_SELECTION_MODE = 'FLEET_RESET_SELECTION_MODE';
export const FLEET_TOGGLE_AUTO_REFRESH = 'FLEET_TOGGLE_AUTO_REFRESH';

export const toggleFleetAutoRefresh = (checked) => ({
  type: FLEET_TOGGLE_AUTO_REFRESH,
  payload: checked,
});

const appendSelectedRow = (row) => ({
  type: FLEET_APPEND_SELECTED_ROW,
  payload: {
    row,
  },
});

const mapToRows = (vehicles) => vehicles.map(({
  vehicleId, licencePlateNumber, companyId, sharingCompanyId,
}) => (
  {
    computedId: `${companyId}_${vehicleId}_${sharingCompanyId}`,
    vehicleId,
    licencePlateNumber,
  }));

const selectRows = (rows) => ({
  type: FLEET_SELECT_ALL,
  payload: {
    rows,
  },
});

const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const vehicles = getFleetVehicles(state);
  if (vehicles) {
    const rows = mapToRows(vehicles);
    dispatch(selectRows(rows));
  } else {
    const selectedCompany = getSelectedCompany(state);
    dispatch(fetchFleetVehicles(selectedCompany)).then((response) => {
      const rows = mapToRows(response.payload.data);
      dispatch(selectRows(rows));
    });
  }
};

const unselectAll = () => ({
  type: FLEET_UNSELECT_ALL,
});

const selectSingleRow = (row) => ({
  type: FLEET_SELECT_SINGLE_ROW,
  payload: {
    row,
  },
});

const unselectRow = (row) => ({
  type: FLEET_UNSELECT_ROW,
  payload: {
    row,
  },
});

const rowSelectionChange = (row) => (dispatch, getState) => {
  const state = getState();
  const type = getFleetSelectionType(state);
  const selectedRowsKeys = getFleetPageSelectedRowsKeys(state);

  if (!selectedRowsKeys.includes(row.computedId)) {
    if (type === SelectionType.RADIO) {
      dispatch(selectSingleRow(row));
    } else {
      dispatch(appendSelectedRow(row));
    }
  } else {
    dispatch(unselectRow(row));
  }
};

export const setFleetSelectionMode = (mode, preselected) => (dispatch) => dispatch({
  type: FLEET_SET_SELECTION_MODE,
  payload: {
    mode: {
      ...mode,
      fixed: true,
      preselected,
      onSelect: (row) => dispatch(rowSelectionChange(row)),
      onSelectAll: (isSelect) => dispatch(isSelect ? selectAll() : unselectAll()),
    },
  },
});

export const resetFleetSelectionMode = () => ({
  type: FLEET_RESET_SELECTION_MODE,
});
