import moment from 'moment';
import { isValidPosition, normalizePositions } from './position.normalizer';
import { OrderContext } from '../../types';
import { getCleanMoment } from '../../utils/dateTimeUtils';

export const normalizeOrdersPositions = (vehiclesPositions) => {
  if (!vehiclesPositions) {
    return [];
  }
  const positionsPerVehicle = vehiclesPositions
    .map(({
      vehicleId, licencePlateNumber, positions, routeSummary,
    }) => ({
      vehicleId,
      licencePlateNumber,
      probableRoute: routeSummary?.points,
      positions: normalizePositions(positions)
        .filter(isValidPosition)
        .sort((a, b) => a.serverTimestamp - b.serverTimestamp),
    }))
    .map((vehicle) => [vehicle.vehicleId, vehicle]);

  return Object.fromEntries(positionsPerVehicle);
};

function deductOrderType(order, fetchingAsCompany) {
  if (order && order.carrierCompany && order.carrierCompany.id === fetchingAsCompany) {
    return OrderContext.IN;
  }
  if (order && order.shipperCompany && order.shipperCompany.id === fetchingAsCompany) {
    return OrderContext.OUT;
  }
  return OrderContext.UNKNOWN;
}

function getOnlyValidVehicles(order) {
  return order && order.vehicles && order.vehicles.filter((vehicle) => !!vehicle);
}

function normalizeTimeSlot(timeSlot) {
  return {
    ...timeSlot,
    startMoment: getCleanMoment(timeSlot.start),
    endMoment: getCleanMoment(timeSlot.end),
  };
}

export const StopStatus = {
  PASSED: 'PASSED',
  ONGOING: 'ONGOING',
  FUTURE: 'FUTURE',
};

function getStopStatus({ timeSlot: { start, end } }) {
  const now = moment().unix();

  if (now < start) {
    return StopStatus.FUTURE;
  }
  if (now > end) {
    return StopStatus.PASSED;
  }

  return StopStatus.ONGOING;
}

function normalizeStops(stops) {
  if (!stops) {
    return [];
  }

  return stops.map((stop) => ({
    ...stop,
    status: getStopStatus(stop),
    timeSlot: normalizeTimeSlot(stop.timeSlot),
  }));
}

export const normalizeOrder = (order, fetchingAsCompany) => {
  if (!order) {
    return null;
  }
  const orderType = deductOrderType(order, fetchingAsCompany);
  const validVehicles = getOnlyValidVehicles(order);
  const stops = normalizeStops(order.stops);
  const endMoment = getCleanMoment(order.endDate);
  const startMoment = getCleanMoment(order.startDate);

  return {
    ...order, _type: orderType, vehicles: validVehicles, stops, startMoment, endMoment,
  };
};

function enhanceOrder(order) {
  const endMoment = getCleanMoment(order.endDate);
  const startMoment = getCleanMoment(order.startDate);

  return {
    ...order, endMoment, startMoment,
  };
}

export const normalizeOrders = (orders) => {
  const tours = orders && orders.tours && orders.tours.map((order) => enhanceOrder(order));
  return { ...orders, tours };
};

export const normalizeEta = (eta) => {
  const estimated = {};
  if (eta && eta.estimated) {
    eta.estimated.forEach((estimation) => {
      estimated[estimation.vehicleId] = estimation;
    });
  }

  const unableToEstimate = {};
  if (eta && eta.unableToEstimate) {
    eta.unableToEstimate.forEach((failure) => {
      unableToEstimate[failure.vehicleId] = failure;
    });
  }

  return { estimated, unableToEstimate };
};
