import H from '@here/maps-api-for-javascript';
import PauseInfoContent from './PauseInfoContent';

export default (position, vehicle, event, type, t, config) => {
  const infoBubble = new H.ui.InfoBubble(position, {
    content: PauseInfoContent(vehicle.vehicle, event, type, t, config),
  });
  infoBubble.addClass('trip-info-infobubble');
  infoBubble.addClass('above-circle-infobubble');
  infoBubble.close();
  return infoBubble;
};
