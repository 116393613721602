/* eslint-disable no-param-reassign */
import axiosActionsFactory from '../axiosHelpers';
import { getSelectedCompany } from '../../selectors';
import UrlService from '../../../services/UrlService';

export const OPEN_CREATE_ORDER = 'OPEN_CREATE_ORDER';
export const CLOSE_CREATE_ORDER = 'CLOSE_CREATE_ORDER';
export const FETCH_ORDER_COMPANIES = axiosActionsFactory('FETCH_ORDER_COMPANIES_LOAD');
export const CREATE_ORDER = axiosActionsFactory('CREATE_ORDER_LOAD');
export const UPDATE_ORDER = axiosActionsFactory('EDIT_ORDER_LOAD');

export const fetchAvailableCompanies = () => (dispatch, getState) => {
  const contextCompanyId = getSelectedCompany(getState());
  return dispatch({
    type: FETCH_ORDER_COMPANIES.START,
    payload: {
      request: {
        url: UrlService.orderCarriers(),
        params: {
          contextCompanyId,
        },
      },
    },
  });
};

export const createOrder = (order, contextCompanyId) => (dispatch) => dispatch({
  type: CREATE_ORDER.START,
  payload: {
    request: {
      url: UrlService.orders(),
      method: 'post',
      data: order,
      params: { contextCompanyId },
    },
  },
});

export const updateOrder = (internalId, contextCompanyId, updateRequest) => (dispatch) => dispatch({
  type: UPDATE_ORDER.START,
  payload: {
    request: {
      url: UrlService.updateOrder(internalId),
      method: 'put',
      data: updateRequest,
      params: { contextCompanyId },
    },
  },
});
