import PropTypes from 'prop-types';

export const ConfigurationType = {
  Vehicles: 'vehicles',
  Companies: 'companies',
};

export const tableConfigurationFilterType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const tableConfigurationType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  filters: PropTypes.objectOf(tableConfigurationFilterType),
});
