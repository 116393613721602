/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { EventType } from '../../../../types/enums/eventType.enum';
import {
  lastUpdateSection, locationDetailsSection, pauseDetailsSection, speedSection,
} from './eventInfoSections';

const EndEventInfoContent = ({
  type, startTimestamp, endTimestamp, duration, avgSpeed, licencePlateNumber, companyName, location, vehicleId, onClose, config,
}, t) => {
  const vehicleDetailsUrl = `#/vehicles/${vehicleId}`;
  window.closeLastTrace = onClose;

  const closeButton = () => `<button type="button" class="ant-btn ant-btn-primary ant-btn-dangerous ant-btn-sm close_last" onclick="window.closeLastTrace('${vehicleId}')">`
    + `  ${t('VEHICLE.CLOSE_LAST_TRACE')}`
    + '</button>';

  const vehicleDetailsLink = () => '<p class="H_bubble_content--label H_bubble_content--close-last">'
    + `  <a href="${vehicleDetailsUrl}" target="_blank">${t('VEHICLE.SHOW_VEHICLE_DETAILS')}</a>`
    + '</p>';

  return '<div class="H_bubble_root">'
    + '  <div class="H_bubble_wrapper">'
    + '    <div class="H_bubble_content">'
    + '      <span class="H_bubble_content--company">'
    + `        ${(companyName || '').trim()}`
    + '      </span>'
    + '      <div class="H_bubble_content--vehicle-id">'
    + '        <span>'
    + `          ${licencePlateNumber}`
    + '        </span>'
    + '      </div>'
    + '      <div class="horizontal-line"></div>'
    + `      ${type === EventType.BREAK ? pauseDetailsSection(t, { duration, startTimestamp, endTimestamp }) : ''}`
    + `      ${locationDetailsSection(t, { location })}`
    + `      ${type === EventType.BREAK ? '' : speedSection(t, { speed: avgSpeed })}`
    + `      ${lastUpdateSection(t, { lastUpdate: endTimestamp })}`
    + `      ${config.vehicleLink ? vehicleDetailsLink() : ''}`
    + `      ${config.closeButton ? closeButton() : ''}`
    + '    </div>'
    + '    <div class="triangle-with-shadow"></div>'
    + '  </div>'
    + '</div>';
};

EndEventInfoContent.propTypes = {
  startTimestamp: PropTypes.string,
  endTimestamp: PropTypes.string,
  duration: PropTypes.string,
  durationAfterPause: PropTypes.string,
  avgSpeed: PropTypes.string,
  licencePlateNumber: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
};

EndEventInfoContent.defaultProps = {
  startTimestamp: null,
  endTimestamp: null,
  duration: null,
  durationAfterPause: null,
  avgSpeed: null,
};

export default EndEventInfoContent;
