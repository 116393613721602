import moment from 'moment';
import {
  FETCH_VEHICLE_REMARK, ADD_VEHICLE_REMARK, EDIT_VEHICLE_REMARK, REMOVE_VEHICLE_REMARK,
  RESET_COMPANY_CONTEXT, OPEN_EDIT_VEHICLE_REMARK, EDIT_VEHICLE_REMARK_FORM, CLOSE_EDIT_VEHICLE_REMARK,
} from '../../actions';

const remarkInitState = {
  loading: false,
  error: null,
  lastUpdated: moment(),
  data: null,
};

export function remarkReducer(state = remarkInitState, { type, payload }) {
  switch (type) {
    case FETCH_VEHICLE_REMARK.START:
      return { ...state, loading: true };
    case ADD_VEHICLE_REMARK.START:
      return { ...state, loading: true };
    case EDIT_VEHICLE_REMARK.START:
      return { ...state, loading: true };
    case REMOVE_VEHICLE_REMARK.START:
      return { ...state, loading: true };
    case FETCH_VEHICLE_REMARK.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case ADD_VEHICLE_REMARK.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case EDIT_VEHICLE_REMARK.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case REMOVE_VEHICLE_REMARK.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: null,
        lastUpdated: moment(),
      };
    case FETCH_VEHICLE_REMARK.FAIL:
      return {
        ...state, loading: false, error: null, data: null, lastUpdated: moment(),
      };
    case ADD_VEHICLE_REMARK.FAIL:
      return {
        ...state, loading: false, error: null, data: null, lastUpdated: moment(),
      };
    case EDIT_VEHICLE_REMARK.FAIL:
      return {
        ...state, loading: false, error: null, data: null, lastUpdated: moment(),
      };
    case REMOVE_VEHICLE_REMARK.FAIL:
      return {
        ...state, loading: false, error: null, data: null, lastUpdated: moment(),
      };
    case OPEN_EDIT_VEHICLE_REMARK:
      return {
        ...state, editViewVehicleRemark: true,
      };
    case EDIT_VEHICLE_REMARK_FORM:
      return {
        ...state, formValue: payload.editedValue,
      };
    case CLOSE_EDIT_VEHICLE_REMARK:
      return {
        ...state, editViewVehicleRemark: false, formValue: null,
      };
    case RESET_COMPANY_CONTEXT:
      return { ...remarkInitState };
    default:
      return state;
  }
}
