import { saveAs } from 'file-saver';
import { CLOSE_GENERATE_REPORT, OPEN_GENERATE_REPORT, SUBMIT_GENERATE_REPORT } from '../../actions';

const initialGenerateReportState = {
  visible: false,
  vehicles: [],
  error: undefined,
  loading: false,
  fixed: false,
  selectedVehicle: undefined,
};

export default function generateReportReducer(state = initialGenerateReportState, { type, payload }) {
  switch (type) {
    case OPEN_GENERATE_REPORT:
      return { ...state, ...payload, visible: true };
    case SUBMIT_GENERATE_REPORT.START:
      return { ...state, loading: true };
    case SUBMIT_GENERATE_REPORT.SUCCESS: {
      const blob = new Blob([payload.data], { type: payload.data.type });
      const disposition = payload.headers['content-disposition'];
      const match = disposition.match(/filename=(.*)/);
      const filename = decodeURI(match[1]);
      saveAs(blob, filename.replace(/["]+/g, ''));
      return { ...initialGenerateReportState };
    }
    case CLOSE_GENERATE_REPORT:
      return { ...initialGenerateReportState };
    default:
      return state;
  }
}
