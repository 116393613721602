import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const GET_TELEMATIC_ACCOUNT_FORM_CONFIG = axiosActionsFactory('FETCH_TELEMATIC_ACCOUNT_FORM_CONFIG');

export const fetchTelematicAccountFormConfig = () => ({
  type: GET_TELEMATIC_ACCOUNT_FORM_CONFIG.START,
  payload: {
    request: {
      url: UrlService.telematicAccountFormConfig(),
    },
  },
});
