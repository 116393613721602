import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getSize = (size) => {
  switch (size) {
    case 's':
      return '26px';
    case 'l':
      return '40px';
    default:
      return '32px';
  }
};

export const Svg = styled.svg`
  fill: ${(props) => (props.fill ? props.fill : props.theme.color.defaultText)};
`;

const OwnerIcon = ({ size, fill }) => (
  <Svg
    fill={fill}
    width={getSize(size)}
    height={getSize(size)}
  >
    <use xlinkHref="./images/fleet-icon-current.svg#truck" />
  </Svg>
);

OwnerIcon.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  fill: PropTypes.string,
};

OwnerIcon.defaultProps = {
  size: 'm',
  fill: undefined,
};

export default OwnerIcon;
