import * as moment from 'moment';
import { locationDetailsSection } from './eventInfoSections';
import { DEFAULT_DATE_TIME } from '../../../../utils/constants/timeFormats';
import PinIcons from '../../assets/pinIcons';
import theme from '../../../../theme';

export const dateTimeSection = (t, { serverTimestamp }) => {
  const positionDateTime = moment.unix(serverTimestamp).format(DEFAULT_DATE_TIME);
  return '<div style="display:flex;margin-top:8px">'
    + '  <span class="H_bubble_content--section-icon" style="line-height:1px">'
    + `    ${PinIcons.startPin.replace(/__FILL_COLOR__/g, theme.color.lastRouteStroke)}`
    + '  </span>'
    + '  <div style="margin-left:4px">'
    + `    <p class="H_bubble_content--data" style="margin-top:5px">${positionDateTime}</p>`
    + '  </div>'
    + '</div>';
};

export const TripStartContext = ({
  location, t,
}) => '<div class="H_bubble_root">'
  + '  <div class="H_bubble_wrapper">'
  + '    <div class="H_bubble_content">'
  + '      <div class="H_bubble_header">'
  + '        <span class="H_bubble_header--title">'
  + `          ${t('COMMON.TRIP_START')}`
  + '        </span>'
  + '      </div>'
  + '      <div class="horizontal-line"></div>'
  + `      ${dateTimeSection(t, { serverTimestamp: location.position.serverTimestamp })}`
  + `      ${locationDetailsSection(t, { location })}`
  + '    </div>'
  + '    <div class="triangle-with-shadow"></div>'
  + '  </div>'
  + '</div>';

export default TripStartContext;
