import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const CREATE_TELEMATIC_ACCOUNT = axiosActionsFactory('CREATE_TELEMATIC_ACCOUNT');
export const UPDATE_TELEMATIC_ACCOUNT = axiosActionsFactory('UPDATE_TELEMATIC_ACCOUNT');
export const SUBMIT_UNKNOWN_TELEMATIC_PROVIDER = axiosActionsFactory('SUBMIT_UNKNOWN_TELEMATIC_PROVIDER');

export const createTelematicAccount = (accountDetails, contextCompanyId) => (dispatch) => dispatch({
  type: CREATE_TELEMATIC_ACCOUNT.START,
  payload: {
    request: {
      url: UrlService.telematicAccountBase(),
      method: 'post',
      data: accountDetails,
      params: { contextCompanyId },
    },
  },
});
export const updateTelematicAccount = (accountId, accountDetails, contextCompanyId) => (dispatch) => dispatch({
  type: UPDATE_TELEMATIC_ACCOUNT.START,
  payload: {
    request: {
      url: UrlService.telematicAccountById(accountId),
      method: 'put',
      data: accountDetails,
      params: { contextCompanyId },
    },
  },
});

export const submitUnknownTelematicProvider = (unknownProviderDetails, contextCompanyId) => (dispatch) => dispatch({
  type: SUBMIT_UNKNOWN_TELEMATIC_PROVIDER.START,
  payload: {
    request: {
      url: UrlService.telematicAccountUnknownProvider(),
      method: 'post',
      data: unknownProviderDetails,
      params: { contextCompanyId },
    },
  },
});
