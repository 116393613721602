/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const StopIndexTag = ({ index }) => (
  index > 9 ? (
    <g id="g9" transform="translate(-1,-6)">
      <path
        id="circle4-3"
        style={{
          fill: ' #4d4d4d', stroke: '#ffffff', strokeWidth: '0.6632', strokeMiterlimit: '2.126',
        }}
        d="m 23.2,20.1 c 3.5,0 6.3,2.8 6.3,6.3 0,3.5 -2.8,6.3 -6.3,6.3 0,0 -5.1,0 -5.1,0 -3.4,-0.1 -6.2,-2.9 -6.2,-6.3 0,-3.4 2.7,-6.1 6,-6.3 0.1,0 5.2,0 5.3,0 z"
      />
      <text transform="translate(15.4056,29.735)" style={{ fontSize: '9.567px', fontFamily: 'Helvetica', fill: '#ffffff' }} id="text7">{index}</text>
    </g>
  ) : (
    <g style={{ fill: 'none' }} id="g855" transform="matrix(0.53150159,0,0,0.53150159,10.045604,11.965535)">
      <circle cx="14.927561" cy="15.220095" r="11.854009" id="circle4-3" style={{ fill: '#4d4d4d', stroke: '#ffffff', strokeWidth: '1.24779' }} />
      <text
        style={{
          fontStyle: 'normal', fontWeight: 'normal', fontSize: '20.0689px', lineHeight: '1.25', fontFamily: 'sans-serif', fill: '#000000', fillOpacity: '1', stroke: 'none',
        }}
        x="8.366951"
        y="22.535248"
        id="text843"
      >
        <tspan
          id="tspan841"
          x="9.9512281"
          y="21.511269"
          style={{
            fontSize: '18px', fill: '#ffffff', stroke: 'none', strokeOpacity: '1',
          }}
        >
          {index}
        </tspan>
      </text>
    </g>
  )
);

StopIndexTag.propTypes = {
  index: PropTypes.number.isRequired,
};

export default StopIndexTag;
