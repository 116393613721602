import React from 'react';
import PropTypes from 'prop-types';
import { isTimeMonitoring } from '../../../../utils/monitoringRequests';
import TimeOrderDetailsDescription from './TimeMonitoringDetailsDescription';
import TourMonitoringDetailsDescription from './TourMonitoringDetailsDescription';

const MonitoringDetailsDescription = ({ requestDetails }) => (
  isTimeMonitoring(requestDetails)
    ? <TimeOrderDetailsDescription requestDetails={requestDetails} />
    : <TourMonitoringDetailsDescription requestDetails={requestDetails} />
);

MonitoringDetailsDescription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default MonitoringDetailsDescription;
