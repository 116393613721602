import PropTypes from 'prop-types';

export const owningCompany = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  taxNumber: PropTypes.string,
  taxCountryCode: PropTypes.string,
  registrationNumber: PropTypes.string,
  businessDescription: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  localNumber: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phoneNumbers: PropTypes.arrayOf(PropTypes.string),
  emailAddresses: PropTypes.arrayOf(PropTypes.string),
  faxNumbers: PropTypes.arrayOf(PropTypes.string),
});

export const shortCompany = PropTypes.shape({
  name: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
});

export const orderCompanyPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
});

export const requestCompanyPropTypes = orderCompanyPropTypes;
