import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const MONITORING_REQUEST_DECLINE = axiosActionsFactory('MONITORING_REQUEST_DECLINE');
export const MONITORING_REQUEST_ACCEPT = axiosActionsFactory('MONITORING_REQUEST_ACCEPT');
export const MONITORING_REQUEST_DEACTIVATE = axiosActionsFactory('MONITORING_REQUEST_DEACTIVATE');

export const declineMonitoringRequestCommand = (data) => (dispatch) => dispatch({
  type: MONITORING_REQUEST_DECLINE.START,
  payload: {
    request: {
      url: UrlService.requestsMonitoringDecline(),
      method: 'post',
      data,
    },
  },
});

export const acceptMonitoringRequestCommand = (data) => (dispatch) => dispatch({
  type: MONITORING_REQUEST_ACCEPT.START,
  payload: {
    request: {
      url: UrlService.requestsMonitoringAccept(),
      method: 'post',
      data,
    },
  },
});

export const deactivateMonitoringRequestCommand = (data) => (dispatch) => dispatch({
  type: MONITORING_REQUEST_DEACTIVATE.START,
  payload: {
    request: {
      url: UrlService.requestsMonitoringDeactivate(),
      method: 'post',
      data,
    },
  },
});
