import { combineReducers } from 'redux';
import { FETCH_VEHICLE_ANALYSED_POSITIONS, RESET_COMPANY_CONTEXT } from '../../actions';

const analysedPositionsInitState = { data: {}, loading: false, error: null };
function analysedPositionsReducer(state = analysedPositionsInitState, { type, payload, meta }) {
  switch (type) {
    case FETCH_VEHICLE_ANALYSED_POSITIONS.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return {
        ...state, data: payload.data, loading: false, error: null,
      };
    case FETCH_VEHICLE_ANALYSED_POSITIONS.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return { ...state, loading: false, error: payload };
    case FETCH_VEHICLE_ANALYSED_POSITIONS.START:
      return { ...state, loading: true, actionId: payload.actionId };
    case RESET_COMPANY_CONTEXT:
      return { ...analysedPositionsInitState };
    default:
      return state;
  }
}

export default combineReducers({
  analysed: analysedPositionsReducer,
});
