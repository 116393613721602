/* eslint-disable no-param-reassign,class-methods-use-this */
class TourService {
  constructor() {
    this.drawTourRouteFcn = null;
    this.centerAtActivePointFcn = null;
    this.drawPositionBacklight = null;
    this.tourStatisticsByPositionTimestamp = null;
    this.tourStatisticsByCoordinates = null;
    this.chartsUpdateFunctions = [];
    this.map = {};
    this.uniqueTypesDisplay = {
      byCoordinates: true,
      byPositionTimestamp: true,
    };
    this.prevPositionIndex = [];
    this.dateFormatted = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    this.byCoordinates = false;
  }

  changeUniqueTypesDisplay(name, value) {
    this.uniqueTypesDisplay[name] = value;
    this.updateCharts();
  }

  setTourStatistics(stats) {
    this.tourStatisticsByPositionTimestamp = this.convertPositionTimestampValues(stats);
    this.initMapAndCharts();
  }

  initMapAndCharts() {
    this.tourStatistics = this.byCoordinates
      ? this.tourStatisticsByCoordinates
      : this.tourStatisticsByPositionTimestamp;
    this.drawTourRoute();
    this.updateCharts();
  }

  convertPositionTimestampValues(statistics) {
    statistics.uniquePositions.forEach((e) => {
      e.positionTimestamp *= 1000;
    });
    statistics.quarterStatistics.timesForQuarters = statistics.quarterStatistics.timesForQuarters.map((e) => e * 1000);
    return statistics;
  }

  setDrawTourRouteFcn(fcn) {
    this.drawTourRouteFcn = fcn;
  }

  setDrawPositionBacklightFcn(fcn) {
    this.drawPositionBacklight = fcn;
  }

  drawTourRoute() {
    if (this.drawTourRouteFcn && this.tourStatistics) {
      this.drawTourRouteFcn(this.tourStatistics.uniquePositions);
    }
  }

  convertPositionsToMapPoints(positions) {
    return positions.map(this.convertPositionToMapPoint);
  }

  convertPositionToMapPoint(position) {
    return {
      lat: Number(position.coordinateLatitude),
      lng: Number(position.coordinateLongitude),
      position,
    };
  }

  setActivePositionById(positionId) {
    const index = this.tourStatistics.uniquePositions.map((up) => up.id).indexOf(positionId);
    if (index > 0) {
      this.resetActivePosition();
      this.prevPositionIndex.push(index, index + 1);
      this.tourStatistics.uniquePositions[index].active = true;
      this.tourStatistics.uniquePositions[index + 1].active = true;
      this.updateCharts();
    }
  }

  setActivePositionByIndex(positionIndex) {
    this.resetActivePosition();
    this.tourStatistics.uniquePositions[positionIndex].active = true;
    this.prevPositionIndex.push(positionIndex);
    this.drawPositionBacklight(this.convertPositionToMapPoint(this.tourStatistics.uniquePositions[positionIndex]));
    this.updateCharts();
  }

  resetActivePosition() {
    let index = this.prevPositionIndex.shift();
    while (index >= 0) {
      if (this.tourStatistics.uniquePositions[index]) this.tourStatistics.uniquePositions[index].active = false;
      index = this.prevPositionIndex.shift();
    }
  }

  addChartUpdateFunction(fcn) {
    this.chartsUpdateFunctions.push(fcn);
  }

  updateCharts() {
    this.chartsUpdateFunctions.forEach((fcn) => { fcn(); });
  }

  setCenterOnActivePoint(centerAtActivePointFcn) {
    this.centerAtActivePointFcn = centerAtActivePointFcn;
  }
}

export default new TourService();
