export const VEHICLE_FILTER = 'vehicleId';
export const COMPANY_FILTER = 'companyId';

const toFilterValues = (values) => {
  if (!values) {
    return [];
  }
  return values.map(({ value }) => value);
};

export const filtersFromConfiguration = (filters) => {
  if (!filters) {
    return {};
  }
  return {
    vehicleIds: toFilterValues(filters[VEHICLE_FILTER]),
    companyIds: toFilterValues(filters[COMPANY_FILTER]),
  };
};

export const getMappedFiltering = (filters) => {
  if (!filters) {
    return {};
  }
  return {
    vehicleLicencePlateNumber: filters.VEHICLE_ID && filters.VEHICLE_ID.length ? filters.VEHICLE_ID[0] : undefined,
    vehicleCompanyName: filters.COMPANY_NAME && filters.COMPANY_NAME.length ? filters.COMPANY_NAME[0] : undefined,
  };
};

export const toCompanyValueLabel = (filters) => {
  if (!filters) {
    return [];
  }
  return filters[COMPANY_FILTER] || [];
};

export const toRowSelection = (filters) => {
  if (!filters) {
    return [];
  }

  return filters[VEHICLE_FILTER].map(({ value, label }) => ({
    vehicleId: value,
    licencePlateNumber: label,
    computedId: value,
  }));
};
