/* eslint max-len:0 */
export const markerIcon = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-251.1 379 31.5 37.3" width="30" height="36">'
+ '<style type="text/css">'
+ 'svg {cursor:pointer}'
+ ' .st0{fill:white;}'
+ ' .st1{fill:__FILL__; cursor:pointer;}'
+ '</style>'
+ '<g>'
+ '<g>'
+ '<path class="st0" d="M-229.6,381.1c-0.6-0.3-1.3-0.5-1.9-0.6C-230.8,380.7-230.2,380.9-229.6,381.1z"/>'
+ '<path class="st0" d="M-220.8,388.4c-0.8-1.9-2-3.5-3.4-4.9c-1.4-1.4-3.1-2.5-5-3.3c-3.9-1.6-8.3-1.6-12.2,0'
+ 'c-1.9,0.8-3.6,1.9-5,3.3c-1.4,1.4-2.6,3.1-3.4,4.9c-0.8,1.9-1.3,3.9-1.3,6c0,3,0.7,5.9,2.1,8.7c1.1,2.3,2.6,4.4,4.6,6.5'
+ 'c3.3,3.5,6.7,5.7,7.7,6.3c0.4,0.3,0.9,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.4c1-0.6,4.4-2.7,7.7-6.3c1.9-2.1,3.5-4.3,4.6-6.5'
+ 'c1.4-2.8,2.1-5.8,2.1-8.7C-219.5,392.4-220,390.3-220.8,388.4z M-223,402.5c-1,2.1-2.5,4.2-4.3,6.2c-3.2,3.4-6.4,5.4-7.4,6'
+ 'c-0.4,0.2-0.9,0.2-1.3,0c-0.9-0.5-4.2-2.6-7.4-6c-1.8-2-3.3-4-4.3-6.2c-1.3-2.6-1.9-5.3-1.9-8.1c0-1.9,0.4-3.7,1.1-5.4'
+ 'c0.7-1.7,1.8-3.2,3.1-4.4c1.3-1.3,2.8-2.3,4.5-3c1.8-0.7,3.6-1.1,5.5-1.1c1.9,0,3.8,0.4,5.5,1.1c1.7,0.7,3.2,1.7,4.5,3'
+ 'c1.3,1.3,2.3,2.8,3.1,4.4c0.8,1.7,1.1,3.5,1.1,5.4C-221,397.2-221.7,399.9-223,402.5z"/>'
+ '<path class="st0" d="M-221,394.4c0,2.7-0.7,5.5-2,8.1c-1,2.1-2.5,4.2-4.3,6.2c-3.2,3.4-6.4,5.4-7.4,6c-0.4,0.2-0.9,0.2-1.3,0'
+ 'c-0.9-0.5-4.2-2.6-7.4-6c-1.8-2-3.3-4-4.3-6.2c-1.3-2.6-1.9-5.3-1.9-8.1c0-1.9,0.4-3.7,1.1-5.4c0.7-1.7,1.8-3.2,3.1-4.4'
+ 'c1.3-1.3,2.8-2.3,4.5-3c1.8-0.7,3.6-1.1,5.5-1.1c1.9,0,3.8,0.4,5.5,1.1c1.7,0.7,3.2,1.7,4.5,3c1.3,1.3,2.3,2.8,3.1,4.4'
+ 'C-221.4,390.7-221,392.6-221,394.4z"/>'
+ '</g>'
+ '<path class="st1" d="M-221,394.4c0,2.7-0.7,5.5-2,8.1c-1,2.1-2.5,4.2-4.3,6.2c-3.2,3.4-6.4,5.4-7.4,6c-0.4,0.2-0.9,0.2-1.3,0'
+ 'c-0.9-0.5-4.2-2.6-7.4-6c-1.8-2-3.3-4-4.3-6.2c-1.3-2.6-1.9-5.3-1.9-8.1c0-1.9,0.4-3.7,1.1-5.4c0.7-1.7,1.8-3.2,3.1-4.4'
+ 'c1.3-1.3,2.8-2.3,4.5-3c1.8-0.7,3.6-1.1,5.5-1.1c1.9,0,3.8,0.4,5.5,1.1c1.7,0.7,3.2,1.7,4.5,3c1.3,1.3,2.3,2.8,3.1,4.4'
+ 'C-221.4,390.7-221,392.6-221,394.4z"/>'
+ '<path class="st0" d="M-227.8,394.8c0,4.1-3.4,7.5-7.5,7.5c-4.1,0-7.5-3.3-7.5-7.5s3.4-7.5,7.5-7.5'
+ 'C-231.2,387.3-227.8,390.6-227.8,394.8z"/>'
+ '<path class="st1" d="M-235.3,390.8c-1.1,0-2,0.4-2.8,1.2c-0.7,0.7-1.2,1.7-1.2,2.8c0,1.1,0.4,2,1.2,2.8c0.7,0.7,1.7,1.2,2.8,1.2'
+ 'c0,0,0,0,0,0c1.1,0,2-0.4,2.8-1.2c0.7-0.7,1.2-1.7,1.2-2.8s-0.4-2-1.2-2.8C-233.3,391.2-234.3,390.8-235.3,390.8z"/>'
+ '</g>'
+ '</svg>';

export const circleIcon = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 4 4" height="__HEIGHT__" width="__WIDTH__">'
  + '    <circle r="1.7" cy="2" cx="2" style="fill:#ffffff;fill-rule:evenodd;stroke:#69c0ff;stroke-opacity:1;stroke-width:0.6;stroke-miterlimit:4;stroke-dasharray:none;fill-opacity:1" id="circle2" />'
  + '</svg>';

export const fullscreenIcon = (tooltip) => `<img class="H_fullscreen_icon" title="${tooltip}" src="./images/map/expand.svg" alt="Fullscreen"/>`;

export const markerIconWithText = '<svg width="60" height="50" xmlns="http://www.w3.org/2000/svg">'
  + ' <g>'
  + '  <title>Layer 1</title>'
  + '  <style type="text/css">.st0{fill:white;}'
  + ' .st1{fill:__FILL__;}</style>'
  + '  <rect rx="3" id="svg_15" height="14.23729" width="58.22077" y="0.27908" x="0.88961" stroke-width="0" stroke="#ffffff" fill="#fff"/>'
  + '  <g id="svg_13">'
  + '   <g transform="matrix(0.909961, 0, 0, 0.909961, 134.039, -435.898)" id="svg_5">'
  + '    <g id="svg_6">'
  + '     <path id="svg_7" d="m-108.63387,498.62432c-0.6,-0.3 -1.3,-0.5 -1.9,-0.6c0.7,0.2 1.3,0.4 1.9,0.6z" class="st0"/>'
  + '     <path id="svg_8" d="m-99.83387,505.92432c-0.8,-1.9 -2,-3.5 -3.4,-4.9c-1.4,-1.4 -3.1,-2.5 -5,-3.3c-3.9,-1.6 -8.3,-1.6 -12.2,0c-1.9,0.8 -3.6,1.9 -5,3.3c-1.4,1.4 -2.6,3.1 -3.4,4.9c-0.8,1.9 -1.3,3.9 -1.3,6c0,3 0.7,5.9 2.1,8.7c1.1,2.3 2.6,4.4 4.6,6.5c3.3,3.5 6.7,5.7 7.7,6.3c0.4,0.3 0.9,0.4 1.4,0.4c0.5,0 1,-0.1 1.4,-0.4c1,-0.6 4.4,-2.7 7.7,-6.3c1.9,-2.1 3.5,-4.3 4.6,-6.5c1.4,-2.8 2.1,-5.8 2.1,-8.7c0,-2 -0.5,-4.1 -1.3,-6zm-2.2,14.1c-1,2.1 -2.5,4.2 -4.3,6.2c-3.2,3.4 -6.4,5.4 -7.4,6c-0.4,0.2 -0.9,0.2 -1.3,0c-0.9,-0.5 -4.2,-2.6 -7.4,-6c-1.8,-2 -3.3,-4 -4.3,-6.2c-1.3,-2.6 -1.9,-5.3 -1.9,-8.1c0,-1.9 0.4,-3.7 1.1,-5.4c0.7,-1.7 1.8,-3.2 3.1,-4.4c1.3,-1.3 2.8,-2.3 4.5,-3c1.8,-0.7 3.6,-1.1 5.5,-1.1c1.9,0 3.8,0.4 5.5,1.1c1.7,0.7 3.2,1.7 4.5,3c1.3,1.3 2.3,2.8 3.1,4.4c0.8,1.7 1.1,3.5 1.1,5.4c0.2,2.8 -0.5,5.5 -1.8,8.1z" class="st0"/>'
  + '     <path id="svg_9" d="m-100.03387,511.92432c0,2.7 -0.7,5.5 -2,8.1c-1,2.1 -2.5,4.2 -4.3,6.2c-3.2,3.4 -6.4,5.4 -7.4,6c-0.4,0.2 -0.9,0.2 -1.3,0c-0.9,-0.5 -4.2,-2.6 -7.4,-6c-1.8,-2 -3.3,-4 -4.3,-6.2c-1.3,-2.6 -1.9,-5.3 -1.9,-8.1c0,-1.9 0.4,-3.7 1.1,-5.4c0.7,-1.7 1.8,-3.2 3.1,-4.4c1.3,-1.3 2.8,-2.3 4.5,-3c1.8,-0.7 3.6,-1.1 5.5,-1.1c1.9,0 3.8,0.4 5.5,1.1c1.7,0.7 3.2,1.7 4.5,3c1.3,1.3 2.3,2.8 3.1,4.4c0.9,1.7 1.3,3.6 1.3,5.4z" class="st0"/>'
  + '    </g>'
  + '    <path id="svg_10" d="m-100.03387,511.92432c0,2.7 -0.7,5.5 -2,8.1c-1,2.1 -2.5,4.2 -4.3,6.2c-3.2,3.4 -6.4,5.4 -7.4,6c-0.4,0.2 -0.9,0.2 -1.3,0c-0.9,-0.5 -4.2,-2.6 -7.4,-6c-1.8,-2 -3.3,-4 -4.3,-6.2c-1.3,-2.6 -1.9,-5.3 -1.9,-8.1c0,-1.9 0.4,-3.7 1.1,-5.4c0.7,-1.7 1.8,-3.2 3.1,-4.4c1.3,-1.3 2.8,-2.3 4.5,-3c1.8,-0.7 3.6,-1.1 5.5,-1.1c1.9,0 3.8,0.4 5.5,1.1c1.7,0.7 3.2,1.7 4.5,3c1.3,1.3 2.3,2.8 3.1,4.4c0.9,1.7 1.3,3.6 1.3,5.4z" class="st1"/>'
  + '    <path id="svg_11" d="m-106.83387,512.32432c0,4.1 -3.4,7.5 -7.5,7.5c-4.1,0 -7.5,-3.3 -7.5,-7.5s3.4,-7.5 7.5,-7.5c4.1,0 7.5,3.3 7.5,7.5z" class="st0"/>'
  + '    <path id="svg_12" d="m-114.33387,508.32432c-1.1,0 -2,0.4 -2.8,1.2c-0.7,0.7 -1.2,1.7 -1.2,2.8c0,1.1 0.4,2 1.2,2.8c0.7,0.7 1.7,1.2 2.8,1.2c0,0 0,0 0,0c1.1,0 2,-0.4 2.8,-1.2c0.7,-0.7 1.2,-1.7 1.2,-2.8s-0.4,-2 -1.2,-2.8c-0.8,-0.8 -1.8,-1.2 -2.8,-1.2z" class="st1"/>'
  + '   </g>'
  + '  </g>'
  + '  <text xml:space="preserve" text-anchor="middle" font-family="monospace" letter-spacing="1.1" font-size="11" id="svg_14" y="10.59216" x="31" stroke-width="0" stroke="#000" fill="#000000">__TEXT__</text>'
  + ' </g>'
  + '</svg>';

export const copyIcon = '<svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="1em" height="1em" fill="currentColor" aria-hidden="true">'
+ '<path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>'
+ '</svg>';
