import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FilterTagComponent from './FilterTagComponent';
import { InlineFlexFullHeight } from '../Styled/Containers';

function renderTags(filters, onRemoveFilter) {
  return Object.entries(filters)
    .filter(([, value]) => value && value.length)
    .map(([key, value]) => (
      <FilterTagComponent
        key={key}
        filterName={key}
        onRemove={onRemoveFilter}
        value={value}
      />
    ));
}

const FiltersTagsComponent = ({
  filters, onRemoveFilter, size, onClearFilters, skipFilters,
}) => {
  const [tags, setTags] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (filters) {
      const allTags = {};
      Object.entries(filters).filter(([key]) => !skipFilters.includes(key))
        .forEach(([key, value]) => {
          allTags[key] = value;
        });
      setTags(allTags);
    }
  }, [filters, skipFilters]);

  const isFilterPresent = tags && Object.entries(tags)
    .some(([, value]) => value && value.length);

  return (
    isFilterPresent ? (
      <InlineFlexFullHeight>
        {renderTags(tags, onRemoveFilter)}
        <Button
          type="link"
          onClick={onClearFilters}
          size={size}
        >
          {t('COMMON.RESET_FILTERS')}
        </Button>
      </InlineFlexFullHeight>
    ) : null
  );
};

FiltersTagsComponent.propTypes = {
  filters: PropTypes.shape({}),
  onRemoveFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  skipFilters: PropTypes.arrayOf(PropTypes.string),
};

FiltersTagsComponent.defaultProps = {
  size: 'small',
  skipFilters: [],
  filters: null,
  onRemoveFilter: null,
  onClearFilters: null,
};

export default FiltersTagsComponent;
