export default {
  isDark: true,
  color: {
    white: '#fff',
    backgroundDark1: '#1c437c',
    backgroundDark2: '#132d52',
    backgroundDark3: 'rgba(0,0,0,.75)',
    backgroundLight1: '#fff',
    backgroundLight2: '#fafafa',
    backgroundLight3: '#f0f2f5',
    backgroundLight4: '#e0e4eb',
    defaultLight: 'rgba(250,250,250, 0.9)',
    gray1: '#c4c4c4',
    gray2: '#777',
    gray3: '#828282',
    darkgray1: '#5E6069',
    darkgray2: '#4F4F4F',
    darkgray3: '#333',
    border: '#D9D9D9',
    defaultText: 'rgba(0, 0, 0, 0.65)',
    lightText: '#fff',
    primary: '#489ed7',
    primaryLowOpacity: 'rgba(72, 158, 215, 0.25)',
    secondary: '#086da0',
    inactive: 'rgba(0, 0, 0, 0.25)',
    highlight: '#fffbe7',
    highlightHover: '#f6f1d7',
    danger: '#FF4D4F',
    ignition: {
      on: '#3EA678',
      off: '#F2B246',
      unknown: 'rgb(85, 85, 85)',
    },
    lastRouteStroke: '#4CAF50',
    lastRouteShadow: 'rgba(58, 138, 61, 0.4)',
    routeStroke: '#2F80ED',
    lastRouteStrokeHighlight: 'rgba(35, 156, 6, 0.3)',
    routeStrokeHighlight: 'rgba(37, 102, 189, 0.3)',
    propableHistoricRoute: '#4CAF50',
    stop: {
      normal: '#F2994A',
      hover: '#D47119',
    },
  },
};
