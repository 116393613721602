import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';
import PlaceInfo from './PlaceInfo';
import { Highlighteable } from '../../Text';

const StopInfo = ({ stop }) => {
  const { t } = useTranslation();
  const startDate = stop && stop.timeSlot.start && getCleanMoment(stop.timeSlot.start);
  const endDate = stop && stop.timeSlot.end && getCleanMoment(stop.timeSlot.end);
  const formattedOrEmpty = (date) => (date ? date.format(DEFAULT_DATE_TIME) : t('COMMON.MISSING'));
  const darkgray = 'darkgray';

  return (
    (stop && (
      <>
        {stop.timeSlot.start && stop.timeSlot.end && (
          <>
            <div>
              {formattedOrEmpty(startDate)}
              {stop.timeSlot.start !== stop.timeSlot.end && ' - '.concat(formattedOrEmpty(endDate))}
            </div>
          </>
        )}
        {stop.name && (
          <>
            <Highlighteable
              content={stop.name}
              color={darkgray}
            />
            <br />
          </>
        )}
        <PlaceInfo
          place={stop.place}
          latitude={stop.latitude}
          longitude={stop.longitude}
          color={darkgray}
        />
      </>
    )) || t('COMMON.MISSING')
  );
};

StopInfo.propTypes = {
  stop: PropTypes.shape({
    timeSlot: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
    place: PropTypes.shape({
      country: PropTypes.string,
      postcode: PropTypes.string,
      city: PropTypes.string,
      street: PropTypes.string,
      houseNumber: PropTypes.string,
    }),
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
  }),
};

StopInfo.defaultProps = {
  stop: null,
};

export default StopInfo;
