import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { FilterTag } from '../Styled';
import { DEFAULT_DATE } from '../../utils/constants/timeFormats';

const toString = (value) => {
  if (value && moment.isMoment(value) && value.isValid()) {
    return value.format(DEFAULT_DATE);
  }

  return value;
};

const parseValues = (values) => values.map(toString).join(', ');

const FilterTagComponent = ({ filterName, value, onRemove }) => {
  const { t } = useTranslation();
  return (
    <FilterTag
      key={filterName}
      closable={!!onRemove}
      onClose={() => onRemove(filterName)}
      color="blue"
    >
      <b>
        {t(`PROPERTIES.${filterName}`)}
        :
        {' '}
      </b>
      {' '}
      {parseValues(value)}
    </FilterTag>
  );
};

FilterTagComponent.propTypes = {
  filterName: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, MomentPropTypes.momentObj])).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FilterTagComponent;
