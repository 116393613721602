import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, List, message } from 'antd';
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import {
  DateFilterDropdown,
  EllipsisSpan,
  EmptyStyledTable,
  InputFilterDropdown,
  SelectFilterDropdown,
  StyledTable,
} from '../../../components';
import { TableLoadingComponent } from '../../../components/Table';
import { CompanyInfo, DateTime, StopInfo } from '../../../components/Vehicle/Data';
import {
  OrderContext, ordersResponsePropTypes, monitoringOrderRoutePointsResponsePropTypes, OrderType, paginationPropTypes,
  sortingPropTypes,
} from '../../../types';
import OrderActionsComponent from './OrderActionsComponent';
import OrderStatus from './OrderStatusComponent';
import GetOrderVehicleLocation from '../../../components/Vehicle/Data/GetOrderVehicleLocation';
import { FILTERS, OrdersColumnWidth, statusOptions } from './OrdersTable.constants';
import { mergeUpdateInterfaceFilters, fetchMonitoringOrderRoutePoints, FeatureToggle } from '../../../store/actions';
import {
  getOrdersFilters, getSelectedCompany, TableName, isFeatureEnabled,
} from '../../../store/selectors';
import { asMoment, toOrdersTableProps } from '../../../types/mappers';
import { OrderStatus as OrderStatusEnum } from '../../../types/enums/orderType.enum';
import FirstRoutePoint from './FirstRoutePoint';
import NextRoutePoint from './NextRoutePoint';

const OrdersTable = ({
  viewportHeight,
  size,
  pageSizeOptions,
  orders,
  routes,
  pagination,
  sorting,
  onSetPagination,
  orderContext,
  orderType,
  onSetSorting,
  onShare,
  onDeactivate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const showRoutePointsFull = useSelector(isFeatureEnabled(FeatureToggle.SHOW_ROUTE_POINTS_FULL));
  const contextCompanyId = useSelector(getSelectedCompany);

  const history = useHistory();

  const filters = useSelector(getOrdersFilters(orderContext));
  const [form] = Form.useForm();

  const fetchRoutePoints = (orderId, routePointFilter) => dispatch(fetchMonitoringOrderRoutePoints(
    orderId, contextCompanyId, orderContext, routePointFilter,
  ));

  useEffect(() => {
    const newItems = orders.data.tours.map(toOrdersTableProps);
    setItems(newItems);
    if (showRoutePointsFull && orderType === OrderType.TOUR) {
      const nowInMillis = moment().valueOf();
      const newOrdersWithRoutes = newItems
        .filter(({ status, endDate, trackingToleranceWindowInMinutes }) => status === OrderStatusEnum.FUTURE
          || status === OrderStatusEnum.ACTIVE
          || (status === OrderStatusEnum.EXPIRED
            && nowInMillis < endDate + moment.duration(trackingToleranceWindowInMinutes.after, 'minutes').valueOf()));
      if (newOrdersWithRoutes.length) {
        for (let i = 0; i < newOrdersWithRoutes.length; i += 1) {
          setTimeout(() => {
            dispatch(
              fetchMonitoringOrderRoutePoints(newOrdersWithRoutes[i].id, contextCompanyId, orderContext, 'FIRST'),
            );
            dispatch(
              fetchMonitoringOrderRoutePoints(newOrdersWithRoutes[i].id, contextCompanyId, orderContext, 'NEXT'),
            );
          }, 100 * i);
        }
      }
    }
  }, [orders.data.tours, contextCompanyId, dispatch, orderContext, orderType, showRoutePointsFull]);

  const updateFilters = (filter) => dispatch(mergeUpdateInterfaceFilters(
    orderContext === OrderContext.IN ? TableName.OrdersReceived : TableName.OrdersSent,
    filter,
  ));

  const renderVehicleLink = ({ licencePlateNumber }) => (
    <List.Item>
      <EllipsisSpan title={licencePlateNumber}>{licencePlateNumber}</EllipsisSpan>
    </List.Item>
  );

  const renderVehicles = (vehicles) => {
    const validVehicles = vehicles && vehicles.filter((vehicle) => !!vehicle);

    return validVehicles && validVehicles.length ? (
      <List
        dataSource={validVehicles}
        renderItem={renderVehicleLink}
      />
    ) : t('COMMON.MISSING');
  };

  const onEdit = (order) => {
    history.push({ pathname: `/orders/edit/${order.shipperCompany.id}/${order.internalId}` });
  };

  const renderGetLastVehicleLocation = ({
    licencePlate, vehicleId, shipperId, orderId,
  }) => (
    <List.Item>
      <GetOrderVehicleLocation
        vehicleId={vehicleId}
        shipperId={shipperId}
        orderId={orderId}
        onError={() => message.error(t('ORDER.LOCATION_NOT_AVAILABLE', { vehicle: licencePlate, tour: orderId }))}
      />
    </List.Item>
  );

  const renderGetLastVehiclesLocations = ({ internalId, shipperId, vehicles }) => {
    const validVehicles = vehicles && vehicles.filter((vehicle) => !!vehicle)
      .map(({ id, licencePlateNumber }) => ({
        licencePlate: licencePlateNumber, vehicleId: id, shipperId, orderId: internalId,
      }));
    return validVehicles && validVehicles.length ? (
      <List
        dataSource={validVehicles}
        renderItem={renderGetLastVehicleLocation}
      />
    ) : t('COMMON.MISSING');
  };

  const getSortOrder = (sortBy) => (sorting && sorting.field === sortBy ? sorting.order : false);

  const renderTransportOrderNumber = (text, {
    internalId,
    shipperCompany,
  }) => (
    shipperCompany
      ? (
        <Link to={`/orders/details/${orderContext}/${shipperCompany.id}/${internalId}`}>
          {internalId}
        </Link>
      ) : { internalId }
  );

  const renderCompany = (_, { carrierCompany, shipperCompany }) => (
    <CompanyInfo company={OrderContext.OUT === orderContext ? carrierCompany : shipperCompany} />
  );

  const renderStartDate = (_, { startDate, firstStop }) => (orderType === OrderType.TIME
    ? (<DateTime value={startDate} />)
    : (<StopInfo stop={firstStop} />));

  const renderEndDate = (_, { endDate, lastStop }) => (orderType === OrderType.TIME
    ? (<DateTime value={endDate} />)
    : (<StopInfo stop={lastStop} />));

  const findRoute = (order) => routes.find((route) => route.orderId === order.id);

  const renderFirstRoutePoint = (_, order) => {
    const route = findRoute(order);
    const firstRoutePoint = route?.assetsRoutes[0]?.firstRoutePoint;
    return (
      <FirstRoutePoint
        order={order}
        routePoint={firstRoutePoint}
        fetchRoutePoints={fetchRoutePoints}
      />
    );
  };

  const renderNextRoutePoint = (_, order) => {
    const route = findRoute(order);
    const nextRoutePoint = route?.assetsRoutes[0]?.nextRoutePoint;
    return (
      <NextRoutePoint
        order={order}
        routePoint={nextRoutePoint}
        fetchRoutePoints={fetchRoutePoints}
      />
    );
  };

  const columns = [{
    title: t('ORDER.ORDER_NUMBER'),
    dataIndex: 'internalId',
    key: 'internalId',
    width: OrdersColumnWidth.transportOrderNumber,
    render: renderTransportOrderNumber,
    filteredValue: filters.INTERNAL_ID,
    filterDropdown: InputFilterDropdown(FILTERS.INTERNAL_ID, updateFilters, t),
    filterIcon: <SearchOutlined />,
  },
  {
    title: t('PROPERTIES.LICENSE_PLATE'),
    sorter: true,
    sortOrder: getSortOrder('vehicles.licencePlateNumber'),
    dataIndex: 'vehicles.licencePlateNumber',
    key: 'vehicles.licencePlateNumber',
    width: OrdersColumnWidth.licencePlate,
    render: (_, { vehicles }) => renderVehicles(vehicles),
    filteredValue: filters.VEHICLE,
    filterDropdown: InputFilterDropdown(FILTERS.VEHICLE, updateFilters, t),
    filterIcon: <SearchOutlined />,
  },
  {
    title: t('PROPERTIES.LAST_LOCATION'),
    dataIndex: 'vehicles',
    key: 'vehicles',
    width: OrdersColumnWidth.lastLocation,
    render: (_, { internalId, shipperCompany, vehicles }) => renderGetLastVehiclesLocations({
      internalId, shipperId: shipperCompany.id, vehicles,
    }),
  }, {
    title: t(`PROPERTIES.${orderContext === OrderContext.OUT ? 'CARRIER' : 'SHIPPER'}`),
    dataIndex: orderContext === OrderContext.OUT ? 'carrierCompanyId' : 'shipperCompanyId',
    key: orderContext === OrderContext.OUT ? 'carrierCompanyId' : 'shipperCompanyId',
    width: OrdersColumnWidth.company,
    render: renderCompany,
    filteredValue: filters.CARRIER,
    filterDropdown: InputFilterDropdown(FILTERS.CARRIER, updateFilters, t),
    filterIcon: <SearchOutlined />,
  }, {
    title: t('ORDER.ADDITIONAL_INFO'),
    dataIndex: 'name',
    key: 'name',
    width: OrdersColumnWidth.name,
    filteredValue: filters.ADDITIONAL_INFO,
    filterDropdown: InputFilterDropdown(FILTERS.ADDITIONAL_INFO, updateFilters, t),
    filterIcon: <SearchOutlined />,
  }, {
    title: t(`PROPERTIES.${orderType === OrderType.TIME ? 'START_DATE' : 'FROM'}`),
    sorter: true,
    dataIndex: orderType === OrderType.TIME ? 'startDate' : 'firstStop',
    sortOrder: getSortOrder('startDate'),
    key: orderType === OrderType.TIME ? 'startDate' : 'firstStop',
    width: OrdersColumnWidth.startDate,
    render: renderStartDate,
    filteredValue: asMoment(filters.START_DATE),
    filterDropdown: DateFilterDropdown(FILTERS.START_DATE.name, updateFilters, t),
    filterIcon: <CalendarOutlined />,
  }, {
    title: t(`PROPERTIES.${orderType === OrderType.TIME ? 'END_DATE' : 'TO'}`),
    sorter: true,
    sortOrder: getSortOrder('endDate'),
    dataIndex: orderType === OrderType.TIME ? 'endDate' : 'lastStop',
    key: orderType === OrderType.TIME ? 'endDate' : 'lastStop',
    width: OrdersColumnWidth.endDate,
    render: renderEndDate,
    filteredValue: asMoment(filters.END_DATE),
    filterDropdown: DateFilterDropdown(FILTERS.END_DATE.name, updateFilters, t),
    filterIcon: <CalendarOutlined />,
  }, {
    title: t('ORDER.CREATED_DATE'),
    sorter: true,
    sortOrder: getSortOrder('creationDate'),
    dataIndex: 'creationDate',
    key: 'creationDate',
    width: OrdersColumnWidth.createdDate,
    render: (_, { creationDate }) => (<DateTime value={creationDate} elapsed />),
  }, {
    title: t('PROPERTIES.STATUS'),
    className: 'ant-table-cell-centered',
    dataIndex: 'status',
    key: 'status',
    width: OrdersColumnWidth.status,
    render: (_, { status }) => (
      <OrderStatus status={status} />
    ),
    filteredValue: filters.STATUS,
    filterDropdown: SelectFilterDropdown(FILTERS.STATUS.name, statusOptions, updateFilters, t),
    filterIcon: () => <SearchOutlined />,
  }, {
    title: t('PROPERTIES.ACTIONS'),
    className: 'ant-table-cell-centered ant-table-cell-actions',
    key: 'actions',
    fixed: 'right',
    width: OrdersColumnWidth.actions,
    render: (record) => {
      const { internalId, status } = record;
      return (
        <OrderActionsComponent
          onDeactivate={() => onDeactivate(internalId)}
          onShare={() => onShare(record, contextCompanyId, form, dispatch)}
          onEdit={() => onEdit(record)}
          disabledShare={status === OrderStatusEnum.INACTIVE
            || status === OrderStatusEnum.EXPIRED}
          disabledDeactivate={orderContext === OrderContext.IN
            || status === OrderStatusEnum.INACTIVE
            || status === OrderStatusEnum.EXPIRED}
          editShown={status === OrderStatusEnum.ACTIVE || status === OrderStatusEnum.FUTURE}
        />
      );
    },
  }];

  if (orderType === OrderType.TOUR) {
    const index = columns.findIndex((column) => column.title === t('ORDER.CREATED_DATE'));
    const firstRoutePointColumn = {
      title: t('PROPERTIES.FIRST_ROUTE_POINT'),
      dataIndex: 'firstStop',
      key: 'firstStop',
      width: OrdersColumnWidth.firstRoutePoint,
      render: renderFirstRoutePoint,
    };
    columns.splice(index, 0, firstRoutePointColumn);
    const nextRoutePointColumn = {
      title: t('PROPERTIES.NEXT_ROUTE_POINT'),
      dataIndex: 'lastStop',
      key: 'lastStop',
      width: OrdersColumnWidth.nextRoutePoint,
      render: renderNextRoutePoint,
    };
    columns.splice(index + 1, 0, nextRoutePointColumn);
  }

  const onPageChange = (page, pageSize) => onSetPagination({ page, size: pageSize });

  const handleTableChange = (p, f, sorter, { action }) => {
    if (action === 'sort') {
      onSetSorting(sorter);
    }
  };

  return items && items.length ? (
    <>
      <StyledTable
        showSorterTooltip
        dataSource={items}
        scroll={{ y: viewportHeight - 50 }}
        rowKey="id"
        size={size}
        bordered
        locale={{ emptyText: t('ORDER.EMPTY_SHARINGS_TABLE_TEXT') }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => (<span>{t('COMMON.TOTAL', { total })}</span>),
          pageSizeOptions,
          total: orders.data.totalElements,
          disabled: orders.loading,
          current: pagination.page,
          pageSize: pagination.size,
          onChange: (page, pageSize) => onPageChange(page, pageSize),
          onShowSizeChange: (page, pageSize) => onPageChange(page, pageSize),
        }}
        onChange={handleTableChange}
        columns={columns}
        loading={TableLoadingComponent(orders.majorReload)}
      />
    </>
  ) : (
    <EmptyStyledTable
      showSorterTooltip
      dataSource={items}
      rowKey="id"
      size={size}
      bordered
      locale={{ emptyText: t('ORDER.EMPTY_SHARINGS_TABLE_TEXT') }}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => (<span>{t('COMMON.TOTAL', { total })}</span>),
        pageSizeOptions,
        total: orders.data.totalElements,
        disabled: orders.loading,
        current: pagination.page,
        pageSize: pagination.size,
        onChange: (page, pageSize) => onPageChange(page, pageSize),
        onShowSizeChange: (page, pageSize) => onPageChange(page, pageSize),
      }}
      onChange={handleTableChange}
      columns={columns}
      loading={TableLoadingComponent(orders.majorReload)}
    />
  );
};

OrdersTable.propTypes = {
  orderContext: PropTypes.oneOf(Object.values(OrderContext)).isRequired,
  orderType: PropTypes.oneOf(Object.values(OrderType)),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onSetPagination: PropTypes.func.isRequired,
  onSetSorting: PropTypes.func.isRequired,
  viewportHeight: PropTypes.number.isRequired,
  pagination: paginationPropTypes.isRequired,
  orders: ordersResponsePropTypes.isRequired,
  routes: PropTypes.arrayOf(monitoringOrderRoutePointsResponsePropTypes).isRequired,
  sorting: sortingPropTypes.isRequired,
  onShare: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

OrdersTable.defaultProps = {
  orderType: OrderType.TIME,
  size: 'small',
  pageSizeOptions: ['10', '20', '50', '100', '200'],
};

export default OrdersTable;
