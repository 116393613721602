import styled, { css } from 'styled-components';
import { Button, Col, Row } from 'antd';

export const Wrapper = styled(Row)`
  ${(props) => props.hidden && css`
    display: none;
  `}
`;

export const PresetButton = styled(Button)`
  margin: 0 4px;
`;

export const RangeSection = styled(Col)`
  display: inline-flex;
`;
