import React from 'react';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined, EnvironmentOutlined } from '@ant-design/icons';
import theme from '../../../theme';

export const MapIgnitionState = styled(EnvironmentOutlined)`
  color: ${({ color }) => color};
`;

export const SuccessCheckOutlined = () => <CheckOutlined style={{ color: theme.color.ignition.on }} />;
export const FailureCloseOutlined = () => <CloseOutlined style={{ color: theme.color.danger }} />;
export const CancelCloseOutlined = () => <CloseOutlined style={{ color: theme.color.primary }} />;
