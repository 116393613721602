import { combineReducers } from 'redux';
import paginationReducer from './pagination.reducer';
import sharesReducer from './shares.reducer';
import sortingReducer from './sorting.reducer';
import pageStateReducer from './pageState.reducer';

export default combineReducers({
  shares: sharesReducer,
  pagination: paginationReducer,
  sorting: sortingReducer,
  pageState: pageStateReducer,
});
