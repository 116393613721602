import styled from 'styled-components';
import { Checkbox, Input } from 'antd';
import { StyledDatePicker } from '../Styled/Custom';

export const SearchInput = styled(Input)`
  width: 188px;
  margin: 0 8px 8px 8px;
  display: block;
`;

export const SearchDropdownContent = styled.div`
  padding-top: 8px;
`;

export const SearchDatePicker = styled(StyledDatePicker)`
  margin: 0 8px 8px 8px;
`;

export const VerticalCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  margin: 0 8px 8px 8px;
`;
