/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import {
  createBubbleOpener, createCircleMarker, createClusteringProvider, createDataPoints, createDefaultMap,
  createFitGroupButton, createLegend, drawFullscreenButton, drawHistoryBoundaryMarkers, createRoute, resizeMap,
  createPinMarker, zoomTo, FIT_GROUP,
} from './drawing';
import { MapEventDispatcher, MapEventType } from './mapEventDispatcher';
import { error } from '../Logging';

class BoundingBoxChangeHandler {
  constructor(ui, centerAtButton) {
    this.centerAtButton = centerAtButton;
    this.ui = ui;
  }

  notify({ type, payload }) {
    if (type !== MapEventType.NEW_BOUNDING) {
      return;
    }

    this.centerAtButton.setData({
      ui: this.ui, boundingBox: payload.bounding, location: payload.location, type: payload.type || FIT_GROUP,
    });

    if (payload.triggerCentering) {
      this.centerAtButton.onButtonClick({ force: true });
    }
  }
}

class MapManager {
  constructor({
    refId, fullscreen = false, legend = false, t,
  }) {
    this.ui = createDefaultMap(refId);
    this.centerAtButton = null;
    this.t = t;
    this.mapEventDispatcher = new MapEventDispatcher();

    if (fullscreen) {
      this.drawFullscreenButton();
    }

    if (legend) {
      this.addLegend();
    }
  }

  disposeMap() {
    this.ui.getMap().dispose();
  }

  addCenterAtButton() {
    this.centerAtButton = createFitGroupButton();
    this.ui.addControl('centerAtLocationButton', this.centerAtButton);

    this.mapEventDispatcher.subscribe(new BoundingBoxChangeHandler(this.ui, this.centerAtButton));
    return this.centerAtButton;
  }

  removeCenterAtButton() {
    this.ui.removeControl('centerAtLocationButton');
  }

  drawFullscreenButton() {
    drawFullscreenButton(this.ui);
  }

  resize() {
    resizeMap(this.ui);
  }

  addLegend() {
    const legend = createLegend();
    this.ui.addControl('legend', legend);
  }

  clearMap() {
    const allObjects = this.ui.getMap().getObjects();
    this.ui.getMap().removeObjects(allObjects);
  }

  removeFromMap(obj) {
    this.ui.getMap().removeObject(obj);
  }

  removeAllFromMap(objs) {
    try {
      this.ui.getMap().removeObjects(objs);
    } catch (e) {
      error(e);
    }
  }

  addToMap(obj) {
    this.ui.getMap().addObject(obj);
  }

  addLayerToMap(layer) {
    this.ui.getMap().addLayer(layer, 1);
  }

  removeLayer(layer) {
    this.ui.getMap().removeLayer(layer);
  }

  addAllToMap(objs) {
    this.ui.getMap().addObjects(objs);
  }

  closeAllBubbles() {
    this.ui.getBubbles().forEach((bubble) => bubble.close());
  }

  createDataPoints(vehicles) {
    return createDataPoints(vehicles);
  }

  createClusteringProvider(fetchLocation, showLastTrace, infoBubble) {
    return createClusteringProvider(this.ui, this.t, fetchLocation, showLastTrace, infoBubble);
  }

  zoomTo(location, zoomFactor, animated) {
    return zoomTo(this.ui, location, zoomFactor, animated);
  }

  createHistoryBoundaryMarkers(a, b) {
    return drawHistoryBoundaryMarkers(a, b);
  }

  createCircleMarker(position, style, config) {
    return createCircleMarker(position, style, config);
  }

  createPinMarker(position, config) {
    return createPinMarker(position, config);
  }

  createRoute(points, config) {
    return createRoute(points, config);
  }

  createBubbleOpener(fetchLocation, selectVehicle, infoBubble, zoomIn) {
    return createBubbleOpener(this.ui, this.t, fetchLocation, selectVehicle, infoBubble, zoomIn);
  }

  applyRenderer(renderer) {
    renderer.apply(this.ui.getMap());
  }
}

export default MapManager;
