import styled from 'styled-components';
import { Divider } from 'antd';
import ignitionStateToColor from '../../../utils/constants/ignitionStateToColor';

export const StyledDivider = styled(Divider)`
  margin: ${({ size }) => {
    switch (size) {
      case 's':
        return '10px 0';
      case 'm':
        return '18px 0';
      default:
        return '24px 0';
    }
  }} 
`;

export const IgnitionStateIcon = styled.span`
  background-color: ${({ state }) => ignitionStateToColor(state)};
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 4px;
`;
