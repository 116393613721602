import { OrderType } from '../orders.type';
import { OrderStopType } from '../stop.type';
import { uuidv4 } from '../../utils/uuid';

const extractStartDate = (orderType, order, apiStops) => (orderType === OrderType.TIME
  ? order.startDateTime.startOf('minute').unix()
  : apiStops[0].timeSlot.start);

const extractEndDate = (orderType, order, apiStops) => (orderType === OrderType.TIME
  ? order.endDateTime.startOf('minute').unix()
  : apiStops[apiStops.length - 1].timeSlot.end);

const mapStop = (stop) => ({
  name: stop.name,
  additionalInfo: stop.additionalInfo,
  sequence: stop.sequence,
  latitude: stop.cordinate.lat.toFixed(4),
  longitude: stop.cordinate.lng.toFixed(4),
  isLoad: stop.stopType && stop.stopType.includes('loading'),
  isUnload: stop.stopType && stop.stopType.includes('unloading'),
  timeSlot: {
    start: stop.startDateTime.startOf('minute').unix(),
    end: stop.endDateTime.startOf('minute').unix(),
  },
  place: {
    street: stop.street,
    houseNumber: stop.streetNumber,
    city: stop.city,
    country: stop.stopCtry,
    postcode: stop.postcode,
    localNumber: stop.localNumber,
  },
});

const getOrCreateStopId = (id, originalStops) => {
  let stopId = uuidv4();
  originalStops.forEach((originalStop) => {
    if (originalStop.id === id) {
      stopId = originalStop.id;
    }
  });
  return stopId;
};

const getOrCreateTimeSlotId = (id, originalStops) => {
  let timeSlotId = uuidv4();
  originalStops.forEach((originalStop) => {
    if (originalStop.id === id) {
      timeSlotId = originalStop.timeSlot.id;
    }
  });
  return timeSlotId;
};

const getOrCreatePlaceId = (id, originalStops) => {
  let placeId = uuidv4();
  originalStops.forEach((originalStop) => {
    if (originalStop.id === id) {
      placeId = originalStop.place.id;
    }
  });
  return placeId;
};

const mapUpdatedStop = (stop, originalStops) => ({
  id: getOrCreateStopId(stop.id, originalStops),
  sequence: stop.sequence,
  latitude: stop.cordinate.lat.toFixed(4),
  longitude: stop.cordinate.lng.toFixed(4),
  isLoad: stop.stopType && stop.stopType.includes('loading'),
  isUnload: stop.stopType && stop.stopType.includes('unloading'),
  name: stop.name,
  additionalInfo: stop.additionalInfo,
  timeSlot: {
    id: getOrCreateTimeSlotId(stop.id, originalStops),
    start: stop.startDateTime.startOf('minute').unix(),
    end: stop.endDateTime.startOf('minute').unix(),
  },
  place: {
    id: getOrCreatePlaceId(stop.id, originalStops),
    street: stop.street,
    houseNumber: stop.streetNumber,
    city: stop.city,
    country: stop.stopCtry,
    postcode: stop.postcode,
    localNumber: stop.localNumber,
  },
});

const stopsBy = (func, nextLevel) => (a, b) => {
  if (func(a) < func(b)) {
    return -1;
  }

  if (func(a) > func(b)) {
    return 1;
  }

  return nextLevel ? nextLevel(a, b) : 0;
};

export const stopsByTimeSlotAndSequence = stopsBy((stop) => stop.sequence, stopsBy((stop) => stop.timeSlot.start));

const mapStops = (stops) => {
  if (!stops) {
    return [];
  }
  const apiStops = stops.map((stop) => mapStop(stop)).sort(stopsByTimeSlotAndSequence);
  return apiStops.map((stop, index) => ({ ...stop, sequence: index }));
};

const mapUpdatedStops = (updatedStops, originalStops) => {
  if (!updatedStops) {
    return [];
  }
  const apiStops = updatedStops.map((stop) => mapUpdatedStop(stop, originalStops)).sort(stopsByTimeSlotAndSequence);
  return apiStops.map((stop, index) => ({ ...stop, sequence: index }));
};

export const getStopType = ({ isLoad, isUnload }) => {
  if (isLoad && isUnload) {
    return OrderStopType.LoadUnload;
  }
  if (isLoad) {
    return OrderStopType.Load;
  }
  if (isUnload) {
    return OrderStopType.Unload;
  }
  return OrderStopType.Unknown;
};

const formToCreateRequest = (orderForms, { contextCompanyId }, orderType = OrderType.TIME) => {
  const apiStops = orderType === OrderType.TOUR ? mapStops(orderForms[1].stops) : [];
  return {
    internalId: orderForms[0].internalId,
    name: orderForms[0].shipperName,
    vehicleIds: [orderForms[0].vehicleId],
    shipperCompanyId: contextCompanyId,
    carrierCompanyId: orderForms[0].carrierCompanyId,
    stops: apiStops,
    startDate: extractStartDate(orderType, orderForms[1], apiStops),
    endDate: extractEndDate(orderType, orderForms[1], apiStops),
  };
};

const formToUpdateRequest = (orderForms, order, orderType = OrderType.TIME) => {
  const apiStops = orderType === OrderType.TOUR ? mapUpdatedStops(orderForms[1].stops, order.stops) : [];
  return {
    id: order.id,
    internalId: orderForms[0].internalId,
    name: orderForms[0].shipperName,
    vehicleIds: [orderForms[0].vehicleId],
    shipperCompanyId: order.shipperCompany.id,
    carrierCompanyId: orderForms[0].carrierCompanyId,
    stops: apiStops,
    startDate: extractStartDate(orderType, orderForms[1], apiStops),
    endDate: extractEndDate(orderType, orderForms[1], apiStops),
  };
};

export const OrderMapper = {
  formToCreateRequest,
  formToUpdateRequest,
};
