import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sharingType } from '../../../../../types';
import { openEditSharing, openRestoreSharing, stopSharing } from '../../../../../store/actions';
import { isSuccess } from '../../../../../utils/responseUtils';
import { getFleetSelectionMode } from '../../../../../store/selectors';
import InactiveSharingActionsComponent from './InactiveSharingActionsComponent';
import ActiveSharingActionsComponent from './ActiveSharingActionsComponent';

const INACTIVE_TOOLTIPS = {
  edit: 'SHARE_VEHICLES.ACTIONS_DISABLED',
  stop: 'SHARE_VEHICLES.ACTIONS_DISABLED',
  restore: 'SHARE_VEHICLES.ACTIONS_DISABLED',
};

const INITIAL_TOOLTIPS = {
  edit: 'SHARE_VEHICLES.EDIT_SHARING',
  stop: 'SHARE_VEHICLES.STOP_SHARING',
  restore: 'SHARE_VEHICLES.RESTORE_SHARING',
};

const SharesTableActionsComponent = ({ sharing, onRefresh, disabled }) => {
  const { t } = useTranslation();
  const selectionMode = useSelector(getFleetSelectionMode);
  const dispatch = useDispatch();
  const [tooltips, setTooltips] = useState(INITIAL_TOOLTIPS);
  useEffect(() => {
    if (disabled) {
      setTooltips(INACTIVE_TOOLTIPS);
    } else {
      setTooltips(INITIAL_TOOLTIPS);
    }
  }, [setTooltips, disabled]);

  const dispatchRestoreSharing = useCallback(
    () => {
      dispatch(openRestoreSharing(sharing, onRefresh));
    },
    [dispatch, sharing, onRefresh],
  );

  const dispatchOpenEditSharing = useCallback(
    () => {
      dispatch(openEditSharing(sharing, onRefresh));
    },
    [dispatch, sharing, onRefresh],
  );

  const dispatchStopSharing = useCallback(
    () => dispatch(stopSharing(sharing)).then((result) => {
      if (isSuccess(result)) {
        message.success(t('SHARE_VEHICLES.STOP_SUCCESS', { vehicle: sharing.vehicle.licencePlateNumber }));
      }
    }),
    [dispatch, sharing, t],
  );

  return (
    <Row justify="center">
      {sharing.isActive ? (
        <ActiveSharingActionsComponent
          tooltips={tooltips}
          onEditSharing={dispatchOpenEditSharing}
          onStopSharing={() => dispatchStopSharing().then(onRefresh)}
          disabled={disabled || !!selectionMode}
        />
      ) : (
        <InactiveSharingActionsComponent
          tooltips={tooltips}
          restoreSharing={dispatchRestoreSharing}
          disabled={disabled || !!selectionMode}
        />
      )}
    </Row>
  );
};

SharesTableActionsComponent.propTypes = {
  sharing: sharingType.isRequired,
  onRefresh: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SharesTableActionsComponent.defaultProps = {
  disabled: false,
};

export default SharesTableActionsComponent;
