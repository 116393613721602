import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import { getSelectedCompany } from '../../store/selectors';
import OrderDetails from './OrderDetails/OrderDetailsComponent';
import OrdersContentHolder from './OrdersContentHolder';
import TourStatisticsPage from '../TourStatistics/TourStatisticsPage';
import OrderCreationPage from './OrderCreation/OrderCreationPage';
import OrderEditingPage from './OrderEditing/OrderEditingPage';

export default () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const selectedCompany = useSelector(getSelectedCompany);

  return (
    <>
      <BreadcrumbsItem to="/orders">{t('BREADCRUMB.MONITORINGS')}</BreadcrumbsItem>
      {!selectedCompany ? <SelectCompanyFirstComponent /> : (
        <>
          <Route
            exact
            path={`${match.url}`}
            component={() => <OrdersContentHolder context="out" />}
          />
          <Route
            exact
            path={`${match.url}/outgoing`}
            component={() => <OrdersContentHolder context="out" />}
          />
          <Route
            exact
            path={`${match.url}/outgoing/:type`}
            component={() => <OrdersContentHolder context="out" />}
          />
          <Route
            exact
            path={`${match.url}/incoming`}
            component={() => <OrdersContentHolder context="in" />}
          />
          <Route
            exact
            path={`${match.url}/incoming/:type`}
            component={() => <OrdersContentHolder context="in" />}
          />
          <Route
            exact
            path={`${match.url}/new`}
            component={OrderCreationPage}
          />
          <Route
            exact
            path={`${match.url}/statistics/:side/:shipperId/:orderId`}
            component={TourStatisticsPage}
          />
          <Route
            exact
            path={`${match.url}/edit/:shipperId/:orderId`}
            component={OrderEditingPage}
          />
          <Route
            exact
            path={`${match.url}/details/:side/:shipperId/:orderId`}
            component={OrderDetails}
          />
        </>
      )}
    </>
  );
};
