import { combineReducers } from 'redux';
import {
  CLOSE_LAST_TRACE_MODE, FETCH_LAST_TRACE_LOAD, OPEN_LAST_TRACE_MODE, RESET_COMPANY_CONTEXT, SELECT_VEHICLE,
  UNSELECT_VEHICLE,
} from '../../actions';

function selectedVehicleReducer(state = null, { type, payload }) {
  switch (type) {
    case SELECT_VEHICLE:
      return { ...payload.vehicle, findOnMap: payload.findOnMap };
    case UNSELECT_VEHICLE:
      return null;
    default:
      return state;
  }
}

const lastTraceReducerInitState = { loading: false, trace: null };
function lastTraceReducer(state = lastTraceReducerInitState, { type, payload, meta }) {
  switch (type) {
    case FETCH_LAST_TRACE_LOAD.START:
      return { ...state, loading: true, actionId: payload.actionId };
    case FETCH_LAST_TRACE_LOAD.SUCCESS:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return { loading: false, trace: payload.data };
    case FETCH_LAST_TRACE_LOAD.FAIL:
      if (state.actionId !== meta.previousAction.payload.actionId) {
        return state;
      }
      return { loading: false, trace: null };
    default:
      return state;
  }
}

function lastTraceModeReducer(state = null, { type, payload }) {
  switch (type) {
    case OPEN_LAST_TRACE_MODE:
      return payload.vehicleId;
    case CLOSE_LAST_TRACE_MODE:
    case RESET_COMPANY_CONTEXT:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  vehicle: selectedVehicleReducer,
  lastTrace: lastTraceReducer,
  lastTraceMode: lastTraceModeReducer,
});
