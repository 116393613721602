import styled from 'styled-components';
import { Tabs } from 'antd';

export default styled(Tabs)`
  display: flex;
  flex-flow: column;
  height: 100%;
  .ant {
    &-tabs {
      &-extra-content {
        padding: 10px 0;
      }
      &-content-top {
        height: 100%;
      }
    }
  }
  & > .ant-tabs-nav {
    margin-bottom: 10px;
  }
`;
