import React from 'react';
import PropTypes from 'prop-types';
import { CloudServerOutlined, DashboardOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Tooltip } from 'antd';
import { historicalPosition } from '../../../../types';
import { DEFAULT_DATE_TIME } from '../../../../utils/constants/timeFormats';
import { Speed, TruckIcon } from '../../../../components';
import { PlaybackDetails, PlaybackDetailsItem } from './HistoryPlayComponent.styled';

const details = [
  {
    label: <CloudServerOutlined />,
    tooltipKey: 'PROPERTIES.SERVER_TIMESTAMP',
    renderValue: (loading, position, t) => (loading || !position ? t('COMMON.MISSING')
      : moment.unix(position.serverTimestamp).format(DEFAULT_DATE_TIME)),
  },
  {
    label: <TruckIcon style={{ fontSize: '20px' }} />,
    tooltipKey: 'PROPERTIES.TRUCK_TIMESTAMP',
    renderValue: (loading, position, t) => (loading || !position ? t('COMMON.MISSING')
      : moment.unix(position.positionTimestamp).format(DEFAULT_DATE_TIME)),
  },
  {
    label: <DashboardOutlined />,
    tooltipKey: 'PROPERTIES.SPEED',
    renderValue: (loading, position, t) => (loading || !position ? t('COMMON.MISSING')
      : <Speed value={position.speed} />),
  },
];

const renderDetailsItems = (loading, position, t) => details.map((item) => (
  <Col key={item.tooltipKey} flex={1 / details.length}>
    <Tooltip placement="top" title={t(item.tooltipKey)}>
      <PlaybackDetailsItem>
        {item.label}
        {item.renderValue(loading, position, t)}
      </PlaybackDetailsItem>
    </Tooltip>
  </Col>
));

const HistoryPlayPositionDetailsComponent = ({ position, loading }) => {
  const { t } = useTranslation();

  return (
    <PlaybackDetails align="center">
      {renderDetailsItems(loading, position, t)}
    </PlaybackDetails>
  );
};

HistoryPlayPositionDetailsComponent.propTypes = {
  position: historicalPosition,
  loading: PropTypes.bool.isRequired,
};

HistoryPlayPositionDetailsComponent.defaultProps = {
  position: null,
};

export default HistoryPlayPositionDetailsComponent;
