/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Form, Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { HStretched, StyledTabs } from '../../../../components';
import { MonitoringRequestType } from '../../../../types/request.type';
import FormSection from '../../../../components/Form/FormSection';
import TimeDetails from '../../../../components/Time/TimeDetails';
import RequestDetailsSection from './RequestDetailsSection';
import RequestCarrierDetails from './RequestCarrierDetails';
import StopSection from '../../../../components/Stop/StopSection';
import { fetchCompanyVehicles } from '../../../../store/actions/requests/create-request.actions';
import { getRequestCompanyVehicles } from '../../../../store/selectors';

const RequestCreationForm = ({
  companies, commonForm, timeMonitoringForm,
  tourMonitoringForm, handleSubmit, handleCancel,
}) => {
  const { vehicles } = useSelector(getRequestCompanyVehicles);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [requestTypeTab, setRequestTypeTab] = useState(MonitoringRequestType.TIME);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(fetchCompanyVehicles(selectedCompanyId));
    }
  }, [selectedCompanyId, dispatch]);

  const clearVehicleField = () => {
    commonForm.setFields([{
      name: 'vehicleId',
      value: null,
    }]);
  };

  const findAvailableVehicles = (companyId) => {
    clearVehicleField();
    setSelectedCompanyId(companyId);
  };

  const toggleRequestTypeTab = () => {
    if (requestTypeTab === MonitoringRequestType.TIME) {
      setRequestTypeTab(MonitoringRequestType.TOUR);
    } else {
      setRequestTypeTab(MonitoringRequestType.TIME);
    }
  };

  const addNextStop = useCallback((addRow) => {
    const stops = tourMonitoringForm.getFieldValue('stops');
    if (!stops || stops.length === 0) {
      addRow();
    }
    addRow();
  }, [tourMonitoringForm]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Form
        style={{ marginTop: '12px', marginBottom: '40px' }}
        form={commonForm}
        layout="vertical"
      >
        <FormSection title={t('REQUEST.NEW_REQUEST')}>
          <RequestDetailsSection />
        </FormSection>
        <FormSection title={t('REQUEST.CARRIER_DETAILS')}>
          <RequestCarrierDetails
            form={commonForm}
            companies={companies}
            findAvailableVehicles={findAvailableVehicles}
            companyVehicles={vehicles}
          />
        </FormSection>
      </Form>
      <FormSection title={t('REQUEST.CHOOSE_MONITORING_TYPE')}>
        <StyledTabs
          defaultActiveKey={MonitoringRequestType.TIME}
          activeKey={requestTypeTab}
          size="small"
          onChange={() => toggleRequestTypeTab()}
        >
          <Tabs.TabPane
            tab={t('REQUEST.TIME_MONITORING')}
            key={MonitoringRequestType.TIME}
          >
            <Form
              form={timeMonitoringForm}
              layout="vertical"
            >
              <FormSection subtitle={t('REQUEST.TRIP_TIME_DESCRIPTION')} noDivider>
                <TimeDetails
                  form={timeMonitoringForm}
                  fromDateLabel={t('COMMON.START_DATE')}
                  fromDateRequiredMessage={t('COMMON.START_DATE_REQUIRED')}
                  toDateLabel={t('COMMON.END_DATE')}
                  toDateRequiredMessage={t('COMMON.END_DATE_REQUIRED')}
                  toDateAfterStartMessage={t('COMMON.END_DATE_MUST_NOT_AFTER_START')}
                />
              </FormSection>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t('REQUEST.TOUR_MONITORING')}
            key={MonitoringRequestType.TOUR}
          >
            <Form
              form={tourMonitoringForm}
              layout="vertical"
            >
              <FormSection subtitle={t('REQUEST.TRIP_DETAILS_DESCRIPTION')} noDivider>
                <Form.List name="stops" initialValue={[{}, {}]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <StopSection
                          field={field}
                          remove={remove}
                          form={tourMonitoringForm}
                          possibleRemove={index !== 0 && index !== 1}
                          index={index}
                          key={index}
                        />
                      ))}
                      <Divider style={{ marginBottom: '10px' }} />
                      <HStretched _justify="flex-start">
                        <Button
                          type="link"
                          size="large"
                          onClick={() => addNextStop(add)}
                          icon={<PlusCircleOutlined />}
                        >
                          {t('ORDER.ADD_NEXT_STOP')}
                        </Button>
                      </HStretched>
                      <Divider style={{ marginTop: '10px' }} />
                    </>
                  )}
                </Form.List>
              </FormSection>
            </Form>
          </Tabs.TabPane>
        </StyledTabs>

      </FormSection>

      <HStretched _justify="flex-end">
        <Button type="link" size="large" style={{ marginRight: '12px' }} onClick={handleCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <Button type="primary" size="large" onClick={() => handleSubmit(requestTypeTab)}>
          {t('COMMON.CREATE')}
        </Button>
      </HStretched>
    </div>
  );
};

RequestCreationForm.propTypes = {
  creation: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  companies: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      taxNumber: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  commonForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  timeMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  tourMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default RequestCreationForm;
