import H from '@here/maps-api-for-javascript';
import EndEventInfoContent from './EndEventInfoContent';

export default (tripDetails = {}, event, vehicle, onClose, t, config) => {
  const { vehicleCompany, licencePlateNumber, id: vehicleId } = vehicle.vehicle;
  const { name: companyName } = vehicleCompany || {};
  const { avgSpeed } = tripDetails;
  const {
    location, duration, startTimestamp, endTimestamp, type,
  } = event;
  const infoBubble = new H.ui.InfoBubble({
    lat: location.position.latitude,
    lng: location.position.longitude,
  }, {
    content: EndEventInfoContent({
      type,
      duration,
      startTimestamp,
      endTimestamp,
      companyName,
      licencePlateNumber,
      location,
      avgSpeed,
      vehicleId,
      onClose,
      config,
    }, t),
  });
  infoBubble.addClass('trip-info-infobubble');
  infoBubble.addClass('above-marker-infobubble');
  infoBubble.close();
  return infoBubble;
};
