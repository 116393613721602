export const isAccepted = (requestDetails) => requestDetails.state === 'ACCEPTED';

export const isDeclined = (requestDetails) => requestDetails.state === 'DECLINED';

export const isPending = (requestDetails) => requestDetails.state === 'PENDING';

export const isDeactivated = (requestDetails) => requestDetails.state === 'DEACTIVATED';

export const isDeactivatedAfterAcceptation = (requestDetails) => isDeactivated(requestDetails)
  && requestDetails.acceptedInfo.present;

export const isAcceptedInfoShown = (requestDetails) => isAccepted(requestDetails)
  || isPending(requestDetails) || isDeactivatedAfterAcceptation(requestDetails);

export const isTimeMonitoring = (requestDetails) => requestDetails.monitoringType === 'TIME';

export const isIncoming = (requestDetails) => requestDetails.requestType === 'INCOMING';

export const isOutgoing = (requestDetails) => requestDetails.requestType === 'OUTGOING';
