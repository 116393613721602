import React from 'react';
import PropTypes from 'prop-types';
import { Empty, message, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { etaPropTypes, geofencingDataPropTypes } from '../../../types';
import { calculateEta } from '../../../store/actions';
import { isSuccess } from '../../../utils/responseUtils';
import TripStop from './TripStop';
import { OrderStatus, OrderStatusPropTypes } from '../../../types/enums/orderType.enum';
import { stopsPropTypes } from '../../../types/stop.type';
import { Panel, StyledCollapse } from '../../../components';
import { stopsByTimeSlotAndSequence } from '../../../types/mappers';
import { getMonitoringOrderSnapshot } from '../../../store/selectors';

const getUnableToEstimateReason = (unableToEstimate) => `ORDER.ETA_FAILURE.${unableToEstimate.reasonCode}`;

function getEtaDisabled(hasPosition, eta) {
  if (!hasPosition) {
    return { disabled: true, tooltipKey: 'ORDER.ETA_FAILURE.ETA_DISABLED_NO_POSITION' };
  }
  if (eta.loading) {
    return { disabled: true, tooltipKey: 'ORDER.ETA_FAILURE.ETA_DISABLED_LOADING' };
  }
  return { disabled: false, tooltipKey: null };
}

const TourDetailsCard = ({
  geofencingWithEtaEnabled,
  geofencingData,
  stops,
  orderId,
  shipperCompanyId,
  eta,
  selectedVehicle,
  hasPosition,
  orderStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const monitoringOrderSnapshot = useSelector(getMonitoringOrderSnapshot);

  const onCalculateEta = (stop) => {
    dispatch(calculateEta({ orderId, shipperCompanyId, stopId: stop.id }))
      .then((action) => {
        if (isSuccess(action)) {
          const { unableToEstimate } = action.payload.data;
          const selectedNotEstimated = unableToEstimate
            && unableToEstimate.find(({ vehicleId }) => vehicleId === selectedVehicle);
          if (selectedNotEstimated) {
            message.error(t(getUnableToEstimateReason(selectedNotEstimated), 10));
          }
        }
      });
  };

  function getEtaForStop(stopId) {
    return eta.data[stopId] && eta.data[stopId].estimated
      && eta.data[stopId].estimated[selectedVehicle];
  }

  function hasGeofencingData(stopId) {
    if (geofencingData === null) {
      return false;
    }
    return geofencingData.forStops.includes(stopId);
  }

  function getGeofencingData(stopId) {
    if (!hasGeofencingData(stopId)) {
      return null;
    }

    return geofencingData.vehicles[0].stops.filter((stop) => stop.stopId === stopId)[0];
  }

  function hasMonitoringOrderSnapshotData(stopId) {
    if (monitoringOrderSnapshot === null) {
      return false;
    }
    return monitoringOrderSnapshot.stopSnapshots.map((stopSnapshot) => stopSnapshot.stopId).includes(stopId);
  }

  function getMonitoringOrderSnapshotData(stopId) {
    if (!hasMonitoringOrderSnapshotData(stopId)) {
      return null;
    }

    return monitoringOrderSnapshot.stopSnapshots.filter((stop) => stop.stopId === stopId)[0];
  }

  function getEtaStopLoading(stopId) {
    return eta.data[stopId] && eta.data[stopId].loading;
  }

  return (
    <StyledCollapse defaultActiveKey={1}>
      <Panel header={t('ORDER.TRIP_DETAILS')} key="1">
        {(stops && stops.length) ? (
          <Timeline>
            {stops && stops.sort(stopsByTimeSlotAndSequence)
              .map((stop, index) => (
                <TripStop
                  geofencingWithEtaEnabled={geofencingWithEtaEnabled}
                  hasGeofencingData={hasGeofencingData(stop.id)}
                  geofencingData={getGeofencingData(stop.id)}
                  monitoringOrderSnapshot={{
                    hasData: hasMonitoringOrderSnapshotData(stop.id),
                    data: getMonitoringOrderSnapshotData(stop.id),
                  }}
                  eta={{
                    onCalculate: onCalculateEta,
                    data: getEtaForStop(stop.id),
                    loading: getEtaStopLoading(stop.id),
                    ...getEtaDisabled(hasPosition, eta, orderStatus, stop),
                  }}
                  stop={stop}
                  index={index}
                  key={stop.id}
                />
              ))}
          </Timeline>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Panel>
    </StyledCollapse>
  );
};

TourDetailsCard.propTypes = {
  stops: stopsPropTypes,
  orderId: PropTypes.string,
  shipperCompanyId: PropTypes.string,
  geofencingWithEtaEnabled: PropTypes.bool.isRequired,
  eta: etaPropTypes.isRequired,
  selectedVehicle: PropTypes.string,
  hasPosition: PropTypes.bool,
  orderStatus: OrderStatusPropTypes,
  geofencingData: geofencingDataPropTypes,
};

TourDetailsCard.defaultProps = {
  stops: null,
  orderId: null,
  selectedVehicle: null,
  shipperCompanyId: null,
  hasPosition: false,
  orderStatus: OrderStatus.UNKNOWN,
  geofencingData: {},
};

export default TourDetailsCard;
