import React from 'react';
import PropTypes from 'prop-types';
import { stopPlacePropTypes } from '../../../types';

function prepareStreet(place) {
  if (!place) {
    return null;
  }

  const result = [place.street, place.houseNumber].filter((v) => v).join(' ');
  if (result) {
    return place.localNumber ? `${result}/${place.localNumber}` : result;
  }
  return result;
}

function prepareCountry(place) {
  if (!place) {
    return null;
  }

  const city = [place.postcode, place.city].filter((v) => v).join(' ');
  return [place.country, city].filter((v) => v).join(', ');
}

function prepareCoordinates(latitude, longitude) {
  if (latitude && longitude) {
    return `${parseFloat(latitude).toFixed(4)}, ${parseFloat(longitude).toFixed(4)}`;
  }
  return null;
}

const StopLocation = ({
  place, inline, latitude, longitude,
}) => {
  const street = prepareStreet(place);
  const country = prepareCountry(place);
  const coordinates = prepareCoordinates(latitude, longitude);
  // eslint-disable-next-line no-nested-ternary
  const lineBreak = street && country ? (inline ? ', ' : <br />) : '';

  if (!street && !country) {
    if (coordinates) {
      return coordinates;
    }
    return <></>;
  }

  return (
    <span>
      {street}
      {lineBreak}
      {country}
    </span>
  );
};

StopLocation.propTypes = {
  place: stopPlacePropTypes.isRequired,
  inline: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

StopLocation.defaultProps = {
  inline: false,
  latitude: null,
  longitude: null,
};

export default StopLocation;
