import styled from 'styled-components';
import {
  Avatar, Button, Divider, Layout, Menu,
} from 'antd';
import styledTheme from '../../../theme';
import { InlineFlex } from '../../Styled';

export const Logo = styled.img`
  width: 53px;
  margin: 15px 0;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const AvatarWrapper = styled(ImageWrapper)`
  padding-left: ${({ collapsed }) => (collapsed ? '24px' : '20px')};
`;

export const SiderContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LayoutSider = styled(Layout.Sider)`
  background-color: ${() => styledTheme.color.backgroundDark1};
  width: 148px !important;
  min-width: 148px !important;
  max-width: 148px !important;
  flex: 0 0 148px !important;
`;

export const StyledMenu = styled(Menu)`
  border-right: none;
  background-color: transparent !important;
  &:after {
    display: none;
  }
`;

export const MenuItem = styled(Menu.Item)`
  padding-left: 10px !important;
  padding-right: 4px !important;
  &.ant-menu-item {
    &-selected {
      background-color: ${({ theme }) => theme.color.primary} !important;
    }
    .anticon {
      font-size: 16px;
      margin-right: 8px;
    }
    
    & > a {
      color: ${({ theme }) => theme.color.defaultLight};
    }
  }
  &::after {
    left: 0;
    right: auto !important;
  }
`;

export const MenuItemTitle = styled.span`
  font-size: 14px;
`;

export const UserAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.color.primary};
  min-width: 32px;
  min-height: 32px;
  cursor: default;
`;

export const InlineFlexMinHeight = styled(InlineFlex)`
  margin: 20px 0;
`;

export const LogoutDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.color.primary};
  margin: 0;
`;

export const LogoutButton = styled(Button)`
  color: ${({ theme }) => theme.color.white};
  font-size: 20px;
  margin: 4px 0;
`;
