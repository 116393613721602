import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const InactiveSharingActionsComponent = ({ tooltips, disabled, restoreSharing }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="topLeft"
      title={t(tooltips.restore)}
    >
      <Button
        type="link"
        disabled={disabled}
        icon={<RedoOutlined />}
        onClick={restoreSharing}
      />
    </Tooltip>
  );
};

InactiveSharingActionsComponent.propTypes = {
  tooltips: PropTypes.shape({
    restore: PropTypes.string.isRequired,
  }).isRequired,
  restoreSharing: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default InactiveSharingActionsComponent;
