import { COMPANY_FILTER, VEHICLE_FILTER } from './filters.mapper';
import { ConfigurationType } from '../tableConfiguration.type';

export const vehiclesFormToConfiguration = ({ id, name, vehicleIds }) => ({
  id,
  name,
  filters: {
    [VEHICLE_FILTER]: vehicleIds,
  },
});

export const getConfigurationType = (filters) => {
  if (!filters) {
    return null;
  }

  if (filters[VEHICLE_FILTER]) {
    return ConfigurationType.Vehicles;
  }

  if (filters[COMPANY_FILTER]) {
    return ConfigurationType.Companies;
  }

  return null;
};

export const getFilterValues = (filters) => {
  const type = getConfigurationType(filters);
  if (type === ConfigurationType.Vehicles) {
    return filters[VEHICLE_FILTER];
  }
  if (type === ConfigurationType.Companies) {
    return filters[COMPANY_FILTER];
  }
  return [];
};
