import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoBackButton, GoBackDivider } from '../../../components/Styled/Custom';
import { orderPositionsPerVehiclePropTypes } from '../../../types';

function getVehiclesOptions(positions) {
  return positions && Object.values(positions)
    .filter((p) => !!p)
    .map(({ licencePlateNumber, vehicleId }) => ({
      label: licencePlateNumber,
      value: vehicleId,
    }));
}

const OrderDetailsExtrasBar = ({
  positions, loading, selectedVehicle, onVehicleSelected, size,
}) => {
  const { t } = useTranslation();
  const [vehicleOptions, setVehicleOptions] = useState([]);

  useEffect(() => setVehicleOptions(getVehiclesOptions(positions)), [setVehicleOptions, positions]);

  return (
    <Row align="middle" style={{ margin: '0' }} gutter={[0, 8]} justify="flex-start">
      <Col>
        <GoBackButton size={size} />
        <GoBackDivider type="vertical" />
      </Col>
      <Col>
        <Select
          style={{ minWidth: '120px' }}
          size={size}
          disabled={loading || !vehicleOptions.length}
          value={selectedVehicle || t('COMMON.NO_VEHICLE_SELECTED')}
          onChange={onVehicleSelected}
          options={vehicleOptions}
        />
      </Col>
    </Row>
  );
};

OrderDetailsExtrasBar.propTypes = {
  selectedVehicle: PropTypes.string,
  positions: orderPositionsPerVehiclePropTypes,
  loading: PropTypes.bool.isRequired,
  onVehicleSelected: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

OrderDetailsExtrasBar.defaultProps = {
  positions: {},
  selectedVehicle: null,
  size: 'small',
};

export default OrderDetailsExtrasBar;
