import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import moment from 'moment';
import { historicalPosition } from '../../../../types';
import HistoryPlayPositionDetailsComponent from './HistoryPlayPositionDetailsComponent';
import { PlaybackSlider, PlaybackWrapper } from './HistoryPlayComponent.styled';
import HistoryPlaySettingsComponent from './HistoryPlaySettingsComponent';
import PlayPauseComponent from './PlayPauseComponent';
import { DEFAULT_DATE_TIME } from '../../../../utils/constants/timeFormats';

const HistoryPlayComponent = ({
  history, loading, onRenderPath, drawingTrace, historyIndex, setHistoryIndex,
  isEnabledPropableRoute,
}) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [playing, setPlaying] = useState(false);
  const [playbackDisabled, setPlaybackDisabled] = useState(true);

  useEffect(() => {
    setPlaybackDisabled(loading || !history || history.length < 2 || isEnabledPropableRoute);
  }, [loading, history, isEnabledPropableRoute]);

  useEffect(() => {
    function setLastPosition() {
      if (!history) {
        return;
      }
      setHistoryIndex(history.length - 1);
    }

    setLastPosition();
  }, [history, setHistoryIndex]);

  useEffect(() => {
    function setPositionAccordingToCurrentIndex() {
      if (history && historyIndex <= history.length && !drawingTrace) {
        setCurrentPosition(history[historyIndex]);
        onRenderPath(historyIndex);
      }
    }

    setPositionAccordingToCurrentIndex();
  }, [history, playing, historyIndex, onRenderPath, drawingTrace]);

  const getSliderTooltip = (value) => {
    if (!history || !history.length || !history[value]) {
      return null;
    }
    const end = moment.unix(history[value].serverTimestamp);
    return end ? end.format(DEFAULT_DATE_TIME) : null;
  };

  const onPlayerTick = () => {
    const newIndex = historyIndex + 1;
    if (newIndex >= history.length) {
      setPlaying(false);
    } else {
      setHistoryIndex(newIndex % history.length);
    }
  };

  const onPlayingChange = (newPlaying) => {
    if (newPlaying && (historyIndex + 1 >= history.length)) {
      setHistoryIndex(0);
    }
    setPlaying(newPlaying);
  };

  return (
    <PlaybackWrapper>
      <PlaybackSlider
        max={history ? history.length - 1 : 0}
        disabled={playbackDisabled}
        value={historyIndex}
        onChange={(value) => {
          setHistoryIndex(value);
          setPlaying(false);
        }}
        tipFormatter={(value) => getSliderTooltip(value)}
      />
      <Row
        align="middle"
        justify="space-between"
      >
        <Col xs={1}>
          <PlayPauseComponent
            disabled={playbackDisabled}
            playing={playing}
            onPlayingChange={onPlayingChange}
            playbackSpeed={playbackSpeed}
            onTick={onPlayerTick}
          />
        </Col>
        <Col xs={12}>
          <HistoryPlayPositionDetailsComponent
            loading={loading}
            position={currentPosition}
          />
        </Col>
        <Col xs={1}>
          <HistoryPlaySettingsComponent
            disabled={playbackDisabled}
            onPlaybackSpeedChange={setPlaybackSpeed}
          />
        </Col>
      </Row>
    </PlaybackWrapper>
  );
};

HistoryPlayComponent.propTypes = {
  historyIndex: PropTypes.number.isRequired,
  setHistoryIndex: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(historicalPosition),
  loading: PropTypes.bool.isRequired,
  onRenderPath: PropTypes.func.isRequired,
  drawingTrace: PropTypes.bool.isRequired,
  isEnabledPropableRoute: PropTypes.bool.isRequired,
};

HistoryPlayComponent.defaultProps = {
  history: null,
};

export default HistoryPlayComponent;
