import { OrderContext } from '../../types';

// overall store for entire orders subtree
export const getOrdersAutoRefresh = (state) => state.orders.pageState.autoRefresh;
export const getActiveOrdersType = (state) => state.orders.pageState.activeOrdersType;
export const getOrdersRoot = (state) => state.orders;
export const getOrderDetails = (state) => state.orders.details;
export const getMonitoringOrderSnapshot = (state) => state.orders.snapshot;

// type-specific orders store
export const getOrdersTypeRoot = (state, orderType) => getOrdersRoot(state)[orderType];
export const getOrdersTypePagination = (state, orderType) => getOrdersTypeRoot(state, orderType).pagination;
export const getOrdersTypeSorting = (state, orderType) => getOrdersTypeRoot(state, orderType).sorting;
export const getFetchedOrdersType = (state, orderType) => getOrdersTypeRoot(state, orderType).orders;
export const getMonitoringOrdersRoutePoints = (state, orderType) => getOrdersTypeRoot(state, orderType).routes;
export const getOrdersPagination = (state) => ({
  [OrderContext.IN]: getOrdersTypePagination(state, OrderContext.IN),
  [OrderContext.OUT]: getOrdersTypePagination(state, OrderContext.OUT),
});

// form modal
export const getOrderCreationModalData = (state) => state.orders.createOrder;
