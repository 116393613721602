import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  getActiveOrdersType, getBothOrdersFilters, getCurrentInterfaceConfigurationsRoot, getOrdersRoot,
  isCurrentInterfaceConfigurationLoading, TableName,
} from '../../store/selectors';
import { StyledTabs } from '../../components';
import { switchOrdersTab } from '../../store/actions';
import OrderTypeContentComponent from './OrderTypeContentComponent';
import { OrderContext, OrderType } from '../../types';
import OrdersHeaderComponent from './OrdersHeaderComponent';

function OrdersContentHolder({ context }) {
  const history = useHistory();
  const { t } = useTranslation();
  const activeType = useSelector(getActiveOrdersType);
  const ordersRoot = useSelector(getOrdersRoot);
  const filters = useSelector(getBothOrdersFilters);
  const interfaceLoading = useSelector(isCurrentInterfaceConfigurationLoading);
  const interfaceConfigurations = useSelector(getCurrentInterfaceConfigurationsRoot);
  const dispatch = useDispatch();
  const { type = OrderType.TIME } = useParams();

  useEffect(() => {
    const tab = type || OrderType.TIME;
    if (tab !== activeType) {
      dispatch(switchOrdersTab(tab));
    }
  }, [type, activeType, dispatch]);

  const contextPathFragment = context === OrderContext.IN ? 'incoming' : 'outgoing';
  const typePathFragment = type === OrderType.TIME ? 'time' : 'tour';

  const breadcrumbUrl = `/orders/${contextPathFragment}`;
  const breadcrumbLabel = context === OrderContext.IN ? t('BREADCRUMB.INCOMING') : t('BREADCRUMB.OUTGOING');

  const breadcrumbTypeUrl = `/orders/${contextPathFragment}/${typePathFragment}`;
  const breadcrumbTypeLabel = type === OrderType.TIME
    ? t('BREADCRUMB.TIME_MONITORING')
    : t('BREADCRUMB.TOUR_MONITORING');

  const refreshIntervalSecondsSent = interfaceConfigurations[TableName.OrdersSent].refreshIntervalSeconds;
  const refreshIntervalSecondsReceived = interfaceConfigurations[TableName.OrdersReceived].refreshIntervalSeconds;

  return (
    <>
      <BreadcrumbsItem to={breadcrumbUrl}>
        {breadcrumbLabel}
      </BreadcrumbsItem>
      <BreadcrumbsItem to={breadcrumbTypeUrl}>
        {breadcrumbTypeLabel}
      </BreadcrumbsItem>
      <StyledTabs
        defaultActiveKey={OrderContext.OUT}
        activeKey={activeType}
        size="small"
        onChange={(orderType) => {
          history.push({
            pathname: `/orders/${contextPathFragment}/${orderType}`,
          });
        }}
        tabBarExtraContent={(
          <OrdersHeaderComponent
            orderContext={context}
            orders={context === OrderContext.IN ? ordersRoot.in.orders : ordersRoot.out.orders}
            pagination={context === OrderContext.IN ? ordersRoot.in.pagination : ordersRoot.out.pagination}
            sorting={context === OrderContext.IN ? ordersRoot.in.sorting : ordersRoot.out.sorting}
            interfaceLoading={interfaceLoading}
            refreshIntervalSeconds={context === OrderContext.IN
              ? refreshIntervalSecondsReceived
              : refreshIntervalSecondsSent}
            activeType={activeType}
            filters={filters[context]}
            orderType={type}
          />
        )}
      >
        <Tabs.TabPane
          tab={t('BREADCRUMB.TIME_MONITORING')}
          key={OrderType.TIME}
        >
          { type === OrderType.TIME && (
            (
              context === OrderContext.OUT && (
                <>
                  <OrderTypeContentComponent
                    orderContext={OrderContext.OUT}
                    orders={ordersRoot.out.orders}
                    routes={ordersRoot.out.routes}
                    pagination={ordersRoot.out.pagination}
                    sorting={ordersRoot.out.sorting}
                    interfaceLoading={interfaceLoading}
                    refreshIntervalSeconds={refreshIntervalSecondsSent}
                    activeType={activeType}
                    isActive={OrderContext.OUT === context}
                    filters={filters[OrderContext.OUT]}
                    orderType={type}
                  />
                  { ordersRoot.out.orders.data.totalElements === 0 && (
                  <Link to="/orders/new">{ t('ORDER.CLICK_HERE_TO_CREATE') }</Link>
                  ) }
                </>
              )
            ) || (
              context === OrderContext.IN && (
                <OrderTypeContentComponent
                  orderContext={OrderContext.IN}
                  orders={ordersRoot.in.orders}
                  routes={ordersRoot.in.routes}
                  pagination={ordersRoot.in.pagination}
                  sorting={ordersRoot.in.sorting}
                  interfaceLoading={interfaceLoading}
                  refreshIntervalSeconds={refreshIntervalSecondsReceived}
                  activeType={activeType}
                  isActive={OrderContext.IN === context}
                  filters={filters[OrderContext.IN]}
                  orderType={type}
                />
              )
            )
          ) }
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('BREADCRUMB.TOUR_MONITORING')}
          key={OrderType.TOUR}
        >
          { type === OrderType.TOUR && (
            (
              context === OrderContext.OUT && (
                <>
                  <OrderTypeContentComponent
                    orderContext={OrderContext.OUT}
                    orders={ordersRoot.out.orders}
                    routes={ordersRoot.out.routes}
                    pagination={ordersRoot.out.pagination}
                    sorting={ordersRoot.out.sorting}
                    interfaceLoading={interfaceLoading}
                    refreshIntervalSeconds={refreshIntervalSecondsSent}
                    activeType={activeType}
                    isActive={OrderContext.OUT === context}
                    filters={filters[OrderContext.OUT]}
                    orderType={type}
                  />
                  { ordersRoot.out.orders.data.totalElements === 0 && (
                    <Link to="/orders/new">{ t('ORDER.CLICK_HERE_TO_CREATE') }</Link>
                  ) }
                </>
              )
            ) || (
              context === OrderContext.IN && (
                <OrderTypeContentComponent
                  orderContext={OrderContext.IN}
                  orders={ordersRoot.in.orders}
                  routes={ordersRoot.in.routes}
                  pagination={ordersRoot.in.pagination}
                  sorting={ordersRoot.in.sorting}
                  interfaceLoading={interfaceLoading}
                  refreshIntervalSeconds={refreshIntervalSecondsReceived}
                  activeType={activeType}
                  isActive={OrderContext.IN === context}
                  filters={filters[OrderContext.IN]}
                  orderType={type}
                />
              )
            )
          ) }
        </Tabs.TabPane>
      </StyledTabs>
    </>
  );
}

OrdersContentHolder.propTypes = {
  context: PropTypes.string.isRequired,
};

export default OrdersContentHolder;
