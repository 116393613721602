import H from '@here/maps-api-for-javascript';
import { MAP_LINES_Z_INDEX } from './mapConsts';
import theme from '../../../theme';

const DEFAULT_CONFIG = {
  style: {
    strokeColor: theme.color.lastRouteStroke,
    lineWidth: 5,
    lineJoin: 'miter',
    lineCap: 'round',
  },
  visibility: true,
  volatility: true,
  zIndex: MAP_LINES_Z_INDEX,
};

const applyConfigOverrides = (config) => {
  if (!config) {
    return DEFAULT_CONFIG;
  }

  const style = config.style ? { ...DEFAULT_CONFIG.style, ...config.style } : DEFAULT_CONFIG.style;

  return { ...DEFAULT_CONFIG, ...config, style };
};

export const createContinuousLine = (points, config) => {
  const continuousLine = new H.geo.LineString();

  points.forEach((point) => continuousLine.pushPoint(point));
  if (points && points.length === 1) {
    continuousLine.pushPoint(points[0]);
  }
  const route = new H.map.Polyline(continuousLine, applyConfigOverrides(config));

  if (config) {
    if (config.onTap) {
      route.addEventListener('tap', (event) => config.onTap(event));
      route.setData({ ...(route.getData() || {}), clickable: true });
    }
    if (config.onPointerMove) {
      route.addEventListener('pointermove', (event) => config.onPointerMove(event));
    }
    if (config.onPointerLeave) {
      route.addEventListener('pointerleave', (event) => config.onPointerLeave(event));
    }
  }
  return route;
};

export const createLineAsSteps = (points, config) => {
  const steps = [];
  for (let i = 0; i < points.length - 1; i += 1) {
    const step = { index: i + 1, start: points[i], end: points[i + 1] };
    const lineString = new H.geo.LineString();
    lineString.pushPoint(step.start);
    lineString.pushPoint(step.end);
    const line = new H.map.Polyline(lineString, {
      ...applyConfigOverrides(config), data: step.end,
    });
    if (config) {
      if (config.onTap) {
        line.addEventListener('tap', (event) => config.onTap(step, event));
        line.setData({ ...(line.getData() || {}), clickable: true });
      }
      if (config.onPointerMove) {
        line.addEventListener('pointermove', (event) => config.onPointerMove(step, event));
      }
      if (config.onPointerLeave) {
        line.addEventListener('pointerleave', (event) => config.onPointerLeave(step, event));
      }
    }
    steps.push(line);
  }
  return steps;
};

export default (points, config) => {
  const steps = createLineAsSteps(points, config);
  const entireRoute = createContinuousLine(points, config);

  return { routeAsSeparatedLines: steps, routeAsOneLine: entireRoute };
};
