import React from 'react';
import { Route } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CarriersContentHolder from './CarriersContentHolder';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import { getSelectedCompany } from '../../store/selectors';

export default () => {
  const { t } = useTranslation();
  const selectedCompany = useSelector(getSelectedCompany);

  return (
    <>
      <BreadcrumbsItem to="/carriers">{t('BREADCRUMB.CARRIERS')}</BreadcrumbsItem>
      {
        !selectedCompany ? <SelectCompanyFirstComponent /> : (
          <>
            <Route
              exact
              path="/carriers"
              component={() => <CarriersContentHolder context="search" />}
            />
            <Route
              exact
              path="/carriers/search"
              component={() => <CarriersContentHolder context="search" />}
            />
            <Route
              exact
              path="/carriers/list"
              component={() => <CarriersContentHolder context="list" />}
            />
          </>
        )
      }
    </>
  );
};
