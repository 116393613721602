import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const backendReplacements = {
  '^Tour with given internalId.*$': 'ORDER.ERROR.TOUR_EXISTS',
};

const ErrorMessageContent = ({ status, data }) => {
  const { t } = useTranslation();
  const findReplacement = (
    backendMessage,
  ) => {
    const replacement = Object.entries(backendReplacements).find(([key]) => new RegExp(key).test(backendMessage));
    if (replacement) {
      return t(replacement[1]);
    }
    return backendMessage;
  };

  return (
    <>
      {status
      && (
        <Typography.Text>
          {t(`ERROR.HTTP.${status}`)}
        </Typography.Text>
      )}
      {status && data && data.message && ': '}
      {data && data.message && (
        <Typography.Text strong>
          <span style={{ whiteSpace: 'pre-wrap' }}>{findReplacement(data.message)}</span>
        </Typography.Text>
      )}
    </>
  );
};

ErrorMessageContent.propTypes = {
  status: PropTypes.number,
  data: PropTypes.shape({
    message: PropTypes.string,
    error: PropTypes.string,
    path: PropTypes.string,
    status: PropTypes.number,
    timestamp: PropTypes.string,
  }),
};

ErrorMessageContent.defaultProps = {
  data: undefined,
  status: undefined,
};

export default ErrorMessageContent;
